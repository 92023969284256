import { LOADING } from '../../../Login/redux/types';
import { SAVE_SIGNIN_DATA } from '../../../Otp/redux/types';
import { addRoleToUser, postSignUpUser, setRoleToUser } from '../../../../utils/APIs/endpoints';
import { RESET_SIGNUP_DATA } from '../types';

const showLoading = (isLoading) => ({
  type: LOADING,
  payload: isLoading,
});

const sendSignupData = (signupData) => {
  return async (dispatch) => {
    try {
      showLoading(true);
      const response = await postSignUpUser(signupData);
      if(response?.status == 201){
        dispatch({ type: SAVE_SIGNIN_DATA, payload: response.data });
        localStorage.setItem("user_loggedIn",true)
        showLoading(false);
        return { data: response.data, statusCode: response.status };
      } else{
        showLoading(false);
        return { data: response.data, statusCode: response.status };
      }
    } catch (error) {
      //status code 404
      showLoading(false);
      return {
        data: error?.response?.data,
        statusCode: error?.response?.status,
      };
    }
  };
};

export const resetCompleteProfileData = () =>
  function (dispatch) {
    dispatch({ type: RESET_SIGNUP_DATA });
  };



  export const addRoleData = (id, data,successCallback=() => {}, errorCallback=() => {}) => {
    return async(dispatch) => {
      dispatch(showLoading(true));
        try {
            const response = await addRoleToUser(id, data);
            if (response.status === 200){
                console.log("addRoleData :", response.data);
                successCallback(response.data)
                dispatch(showLoading(false));
            } else{

                errorCallback(response.data)
                dispatch(showLoading(false));
            }
        } catch (error) {
            if(error?.response?.data){
                errorCallback(error?.response?.data?.message);  
            }
            // errorCallback(error?.response?.data.error);
            dispatch(showLoading(false));

        }
    }
}

export const setRoleData = (id, olduserData, data,successCallback=() => {}, errorCallback=() => {}) => {
  return async(dispatch) => {
    dispatch(showLoading(true));
      try {
          const response = await setRoleToUser(id, data);
          if (response.status === 200){
              console.log("setRoleData :", response.data);
              // dispatch data to reducer to update roles
              let newUserData = response?.data?.user;
              // let newUsrData = { ...olduserData };
              let newUsrData = JSON.parse(JSON.stringify(olduserData));
              newUsrData.roles = newUserData.roles;
              delete newUserData?.roles;
              newUsrData.user = {...olduserData.user, ...newUserData};
              console.log("===updateUserData,newUsrData", newUsrData);
              await dispatch({ type: SAVE_SIGNIN_DATA, payload: newUsrData });
              dispatch(showLoading(false));
              successCallback(newUsrData)
          } else{
              dispatch(showLoading(false));
              errorCallback(response.data)
          }
      } catch (error) {
          dispatch(showLoading(false));
          if(error?.response?.data){
              errorCallback(error?.response?.data?.message);  
          }
          // errorCallback(error?.response?.data.error);
      }
  }
}

export { sendSignupData };
