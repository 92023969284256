import {
	FRL_PROFILE_PHOTO,
	RESET_FRL_PROFILE_DATA,
	FRL_PROFILE_DOB,
	FRL_PROFILE_EDU,
	FRL_PROFILE_LANGUAGE,
	FRL_GET_CERTIFICATE_DATA,
	FRL_UPDATE_CERTIFICATE_DATA,
	FRL_Delete_CERTIFICATE_DATA,
	SET_LOCATION_COORDS,
	SET_PROFILE_DETAILS_ADDRESS_1,
	SET_PROFILE_DETAILS_ADDRESS_2,
	SET_PROFILE_DETAILS_CITY,
	SET_PROFILE_DETAILS_STATE,
	SET_ALL_CITY,
	FRL_SET_CERTIFICATE_DATA,
	SET_ALL_STATE,
	FRL_DELETED_CERTIFICATE_DATA,
	CLEAR_Delete_CERTIFICATE_DATA,
	FETCH_EDUCATION,
	FETCH_LANGUAGE,
	SET_PROFILE_DETAILS_AREANAME,
	SET_PROFILE_DETAILS_PINCODE,
} from '../types';

const INITIAL_STATE = {
	profileImage: null,
	profileDob: null,
	education: [],
	language: [],
	certificates: [],
	deletedCertificates: [],
	locationCoords: { latitude: null, longitude: null },
	address1: '',
	city: null,
	states: null,
	allCity: [],
	allState: [],
	address2: null,
	select_education: {},
	select_language: {},
	areaname: null,
	pincode: { pincode: [''] },
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FRL_PROFILE_PHOTO:
			return { ...state, profileImage: action.payload };

		case SET_LOCATION_COORDS:
			return { ...state, locationCoords: action.payload };

		case FRL_PROFILE_DOB:
			return { ...state, profileDob: action.payload };

		case FETCH_EDUCATION:
			return { ...state, education: action.payload };

		case FRL_PROFILE_EDU:
			return { ...state, select_education: action.payload };

		case FRL_PROFILE_LANGUAGE:
			return { ...state, language: action.payload };

		case FETCH_LANGUAGE:
			return { ...state, select_language: action.payload };

		case FRL_GET_CERTIFICATE_DATA:
			return { ...state, certificates: action.payload };
		case FRL_SET_CERTIFICATE_DATA: {
			const uniqueId = generateUniqueId();
			let payloadWithId;
			if (action.payload.id) {
				payloadWithId = { ...action.payload };
			} else {
				payloadWithId = { ...action.payload, id: uniqueId };
			}

			return { ...state, certificates: [...state.certificates, payloadWithId] };
		}
		case FRL_DELETED_CERTIFICATE_DATA: {
			const s = {
				...state,
				deletedCertificates: [...state.deletedCertificates, action.payload],
			};
			console.log('==FRL_DELETED_CERTIFICATE_DATA reducer', s);
			return s;
		}
		case FRL_UPDATE_CERTIFICATE_DATA: {
			const id = action.payload.id;
			// console.log("===updatedCertificate payload", action.payload);
			const updatedCertificate = state.certificates.map((item) => {
				if (item.id === id) {
					return action.payload;
				}
				return item;
			});
			// console.log("===updatedCertificate", updatedCertificate);
			return { ...state, certificates: updatedCertificate };
		}
		case FRL_Delete_CERTIFICATE_DATA: {
			// console.log("==FRL_Delete_CERTIFICATE_DATA", action.payload);
			const id = action.payload;
			const updatedCertificates = state.certificates.filter(
				(item) => item.id !== id
			);
			return { ...state, certificates: updatedCertificates };
		}
		case CLEAR_Delete_CERTIFICATE_DATA: {
			return { ...state, deletedCertificates: [] };
		}

		case SET_PROFILE_DETAILS_ADDRESS_1:
			return { ...state, address1: action.payload };

		case SET_PROFILE_DETAILS_ADDRESS_2:
			return { ...state, address2: action.payload };

		case SET_PROFILE_DETAILS_CITY:
			return { ...state, city: action.payload };

		case SET_PROFILE_DETAILS_STATE:
			return { ...state, states: action.payload };

		case SET_ALL_CITY:
			return { ...state, allCity: action.payload };

		case SET_ALL_STATE:
			return { ...state, allState: action.payload };

		case SET_PROFILE_DETAILS_AREANAME:
			return { ...state, areaname: action.payload };

		case SET_PROFILE_DETAILS_PINCODE:
			return { ...state, pincode: action.payload };

		case RESET_FRL_PROFILE_DATA:
			return INITIAL_STATE;
		default:
			return state;
	}
};

function generateUniqueId() {
	const timestamp = new Date().getTime(); // Get current timestamp
	const randomNum = Math.floor(Math.random() * 10000); // Generate random number

	return `${timestamp}-${randomNum}`; // Combine timestamp and random number to create unique ID
}

