import { SAVE_WIDGET_DETAILS } from "../types"

const initialState = {
    child_widget: []
}


const creditVasReducer = (state=initialState, action) => {
    switch(action.type){
        case SAVE_WIDGET_DETAILS:
            return {
                ...state,
                child_widget: action.payload
            }
        default:
            return state
    }

}


export default creditVasReducer;