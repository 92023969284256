// CODENAME CONSTANTS BASED ON COUNTRY NAMES
export const INDIA = {
  countryCodeName: 'IN',
  countryCodeNumber: '+91',
  documentCount: 3,
};
export const AUSTRALIA = {
  countryCodeName: 'AU',
  countryCodeNumber: '+61',
  documentCount: 1,
};
