import { loading } from "../../../../store/actions/rootActions";
import { getApplicantData } from "../../../../utils/APIs/endpoints";
import { JOB_APPLICANT_DATA } from "../types";


export const getApplicantDataDetails = (id) => {
    return async (dispatch) => {
      dispatch(loading(true));
      try {
        const response = await getApplicantData(id);
        if (response.status === 200) {
          dispatch({ type: JOB_APPLICANT_DATA, payload: response?.data?.data });
        }
      } catch (error) {
        console.log('error on getApplicantData', error);
      } finally {
        dispatch(loading(false));
      }
    };
  };