import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  CardContent,
  Box,
  Card,
  CardMedia,
  List,
  ListItem,
  Tabs,
  Avatar,
  ListItemAvatar,
  Tab,
  Badge,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { ReactComponent as CategoryMediaIcon } from '../../assets/category-media-icon.svg';
import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';
// import { TabPanel } from '@mui/lab';
import StarIcon from '@mui/icons-material/Star';
import PropTypes from 'prop-types';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
// import CourtneyImg from '../../assets/courtney-profile.jpg';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { COMPANY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { getJobRatingDataApi } from './redux/actions/job-post-details-action';
import Responsive from '../../utils/responsive';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  },
}));

const theme = createTheme();

const JobPostDetailForm = ({ job, ratings, getJobRatingDataApi, userData }) => {
  console.log('JobPostDetailForm job', job);

  const history = useHistory();
  let location = useLocation();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className="b2b-detail-tabpanel"
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3, px: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // console.log("JobID data on details :", job);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log('Rating data on review :', ratings);
  }, [ratings]);

  useEffect(() => {
    getJobRatingDataApi(job?.registration?.id);
  }, [job]);

  useEffect(() => {
    console.log('Job_details : ', job);
  }, [job]);

  // const [ratingValue, setRatingValue] = React.useState(3);

  // const ratings = [
  //     { stars: 5, progress: 90 },
  //     { stars: 4, progress: 75 },
  //     { stars: 3, progress: 50 },
  //     { stars: 2, progress: 25 },
  //     { stars: 1, progress: 10 },
  // ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.mode === 'light' ? '#f5f5f6' : '#f5f5f6',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#864FFD' : '#864FFD',
    },
  }));

  const classes = useStyles();
  let categoriesToShow = job?.categories?.map((item) => {
    return item?.title;
  });
  let last_update = moment(job?.createdAt)?.fromNow();

  console.log('id rating :', ratings?.data?.id);

  const handleRatingData = (rating) => {
    if (rating == 0) {
      return parseInt(rating)?.toFixed(1);
    } else {
      return parseFloat(rating)?.toFixed(1);
    }
  };
  const ratingData = handleRatingData(ratings?.data?.rating);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <div className="my-3 ms-2 d-flex align-items-center">
          <ArrowBackIosIcon
            onClick={() => {
              history.replace(location?.state?.from ?? '/job-listing');
            }}
          />
          <ElynkerLogoComponent>
            <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
          </ElynkerLogoComponent>
        </div>
        <Divider
          component="div"
          className="main-divider mb-3"
          role="presentation"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
          className="job-detail-custom-container"
        >
          <Grid item xs={12}>
            {Responsive(
              <>
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-15  text-grey font-skmodernist"
                  gutterBottom
                  style={{ whiteSpace: 'pre-line' }}
                >
                  Looking for:
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-20 fs-md-32 fw-bold font-skmodernist text-capitalize"
                  gutterBottom
                  style={{ whiteSpace: 'pre-line', marginBottom: '12px' }}
                >
                  {job?.title}
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-13 mb-2 text-grey font-gotham"
                  gutterBottom
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {categoriesToShow}
                </Typography>
              </>,
              <>
                <Box sx={{ marginTop: '30px' }}>
                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-15  text-grey font-skmodernist"
                    gutterBottom
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    Looking for:
                  </Typography>
                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-20 fs-md-32 fw-bold font-skmodernist text-capitalize"
                    gutterBottom
                    style={{ whiteSpace: 'pre-line', marginBottom: '12px' }}
                  >
                    {job?.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-13 mb-2 text-grey font-gotham"
                    gutterBottom
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {categoriesToShow}
                  </Typography>
                </Box>
              </>
            )}

            <form style={{ marginTop: '20px' }}>
              {Responsive(
                <>
                  <Box className="freelancer-detail-card">
                    <Card className="card">
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <div className="d-flex justify-content-between">
                        <CardMedia
                          className="category-media"
                          image={
                            job?.registration?.image_path
                              ? `${COMPANY_LOGO_BASE_URL}${job?.registration?.image_path}`
                              : require('../../assets/elynker_default_image.jpg')
                          }
                          sx={{
                            padding: '1em 1em 0 1em',
                            objectFit: 'contain',
                          }}
                        >
                          {/* <CategoryMediaIcon className="category-media" /> */}
                        </CardMedia>
                        <CardContent className="content p-0">
                          <div className="d-flex justify-content-between align-items-start w-100">
                            <Typography
                              variant="h5"
                              className="fs-15 text-darkgrey mb-1 fw-normal font-skmodernist"
                              component="div"
                            >
                              {job?.registration?.company_name}
                            </Typography>
                            <div className="d-flex align-items-center">
                              <IconButton className="p-1">
                                <RatingIcon />
                              </IconButton>
                              <Typography
                                variant="h5"
                                className="fs-13 text-darkgrey fw-normal  font-gotham"
                                component="div"
                              >
                                {ratingData}
                              </Typography>
                            </div>
                          </div>

                          <Typography
                            variant="body2"
                            className="fs-13 text-secondary mb-1 font-skmodernist"
                          >
                            {job?.registration?.city}
                          </Typography>
                        </CardContent>
                      </div>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                    </Card>
                  </Box>

                  <Box>
                    <List>
                      <ListItem className="b2b-list-item">
                        <Card className="card">
                          <CardContent className="p-0 d-flex justify-content-between mt-1">
                            <div className="d-flex flex-column">
                              <Typography
                                variant="body2"
                                className="fs-14 text-gray mb-0 font-skmodernist"
                              >
                                Salary Upto
                              </Typography>
                              <Typography
                                variant="body2"
                                className="fs-20 fw-bold text-secondary mb-1 font-skmodernist"
                              >
                                ₹ {job?.salary_offered}/{' '}
                                <span className="fs-14 text-lightgrey fw-normal">
                                  Per Month
                                </span>
                              </Typography>
                            </div>
                          </CardContent>
                          <Divider
                            component="div"
                            className="mt-2 mb-3"
                            role="presentation"
                          />
                          <CardContent className="p-0 d-flex justify-content-end">
                            {job?.registration?.id !==
                            userData?.registration?.id ? (
                              <Button
                                variant="contained"
                                className="apply-btn "
                                onClick={() => {
                                  history.push('/job-apply');
                                }}
                              >
                                {/* <WhatsappIcon className="me-2" /> */}
                                Apply
                              </Button>
                            ) : (
                              <div>This Job is posted by You.</div>
                            )}
                          </CardContent>
                        </Card>
                      </ListItem>
                    </List>
                  </Box>
                </>,
                <>
                  <Box className="freelancer-detail-card test-cardddd">
                    <Card className="card">
                      <Divider
                        component="div"
                        className="my-4"
                        role="presentation"
                      />
                      <div className="d-flex justify-content-between">
                        <CardMedia
                          className="category-media"
                          image={
                            job?.registration?.image_path
                              ? `${COMPANY_LOGO_BASE_URL}${job?.registration?.image_path}`
                              : require('../../assets/elynker_default_image.jpg')
                          }
                          sx={{
                            padding: '1em 1em 0 1em',
                            objectFit: 'contain',
                          }}
                        >
                          {/* <CategoryMediaIcon className="category-media" /> */}
                        </CardMedia>
                        <CardContent className="content p-0 d-flex justify-content-between align-items-center">
                          <div style={{ width: '40%' }}>
                            <div className="d-flex justify-content-between align-items-start w-100">
                              <Typography
                                variant="h5"
                                className="fs-16 text-darkgrey mb-1 fw-normal font-skmodernist"
                                component="div"
                              >
                                {job?.registration?.company_name}
                              </Typography>
                              <div className="d-flex align-items-center">
                                <IconButton className="p-1">
                                  <RatingIcon />
                                </IconButton>
                                <Typography
                                  variant="h5"
                                  className="fs-14 text-darkgrey fw-normal  font-gotham"
                                  component="div"
                                >
                                  {ratingData}
                                </Typography>
                              </div>
                            </div>

                            <Typography
                              variant="body2"
                              className="fs-14 text-secondary mb-1 font-skmodernist"
                            >
                              {job?.registration?.city}
                            </Typography>
                          </div>

                          <div className="d-flex flex-column">
                            <Typography
                              variant="body2"
                              className="fs-14 text-gray mb-0 font-skmodernist"
                            >
                              Salary Upto
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-20 fw-bold text-secondary mb-1 font-skmodernist"
                            >
                              ₹ {job?.salary_offered}/{' '}
                              <span className="fs-14 text-lightgrey fw-normal">
                                Per Month
                              </span>
                            </Typography>
                          </div>

                          <div className="">
                            {job?.registration?.id !==
                            userData?.registration?.id ? (
                              <Button
                                variant="contained"
                                className="apply-btn "
                                onClick={() => {
                                  history.push('/job-apply');
                                }}
                              >
                                {/* <WhatsappIcon className="me-2" /> */}
                                Apply
                              </Button>
                            ) : (
                              <div>This Job is posted by You.</div>
                            )}
                          </div>
                        </CardContent>
                      </div>
                      <Divider
                        component="div"
                        className="my-4"
                        role="presentation"
                      />
                    </Card>
                  </Box>
                </>
              )}

              {Responsive(
                <>
                  <Box>
                    <Box className="d-flex justify-content-between align-items-start w-100">
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 mb-2 text-lightgray  font-gotham w-50"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        Min. Experience:
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14  text-darkgrey font-gotham w-50"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {job?.min_experience} Year
                      </Typography>
                    </Box>
                    <Box className="d-flex justify-content-between align-items-start w-100">
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 mb-2 text-lightgray  font-gotham w-50"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        Location:
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14  text-darkgrey font-gotham w-50"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {job?.registration?.city}
                      </Typography>
                    </Box>
                    <Box className="d-flex justify-content-between align-items-start w-100">
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 mb-2 text-lightgray  font-gotham w-50"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        Job Posted Time:
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14  text-darkgrey font-gotham w-50"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {last_update}
                      </Typography>
                    </Box>
                  </Box>
                </>,
                <>
                  <Box>
                    <Box
                      className="d-flex align-items-start"
                      sx={{ gap: '30px' }}
                    >
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 mb-2 text-lightgray  font-gotham"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line', width: '150px' }}
                      >
                        Min. Experience:
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14  text-darkgrey font-gotham w-70"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {job?.min_experience} Year
                      </Typography>
                    </Box>
                    <Box
                      className="d-flex align-items-start"
                      sx={{ gap: '30px' }}
                    >
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 mb-2 text-lightgray  font-gotham"
                        gutterBottom
                        style={{ whiteSpace: 'pre-line', width: '150px' }}
                      >
                        Location:
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14  text-darkgrey font-gotham "
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {job?.registration?.city}
                      </Typography>
                    </Box>
                    <Box
                      className="d-flex align-items-stat"
                      sx={{ gap: '30px' }}
                    >
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 mb-2 text-lightgray  font-gotham "
                        gutterBottom
                        style={{ whiteSpace: 'pre-line', width: '150px' }}
                      >
                        Job Posted Time:
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14  text-darkgrey font-gotham "
                        gutterBottom
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {last_update}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                  className="b2b-detail-tabs"
                >
                  <Tab
                    label="Overview"
                    className="fs-15 font-skmodernist text-capitalize"
                    {...a11yProps(0)}
                  />
                  {/* <Tab label="Catalog"  className="fs-15 font-skmodernist text-capitalize" {...a11yProps(1)} /> */}

                  <Tab
                    label={
                      <React.Fragment>
                        <span className="fs-15 font-skmodernist text-capitalize b2b-detail-tabs-title">
                          Reviews
                        </span>
                        <Badge
                          badgeContent={ratings?.data?.reviews.length || 0}
                          color="error"
                          className="b2b-detail-tabs-badge"
                        />{' '}
                        {/* Adjust badge content and color as needed */}
                      </React.Fragment>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <Typography className="fs-14 font-gotham text-gray mb-3 text-justify">
                    {job?.description}
                  </Typography>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      {ratings?.reviewStarsCount &&
                        ratings?.reviewStarsPercentages &&
                        Object.keys(ratings?.reviewStarsCount)
                          ?.sort((a, b) => Number(b) - Number(a))
                          ?.map((rating, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-1"
                            >
                              <Typography
                                variant="h6"
                                className="fs-15 font-skmodernist text-darkgrey me-1"
                              >
                                {rating}
                              </Typography>
                              <StarIcon
                                fontSize="small"
                                sx={{ color: '#faaf00' }}
                              />
                              {/* <LinearProgress variant="determinate" /> */}
                              <BorderLinearProgress
                                variant="determinate"
                                value={ratings?.reviewStarsPercentages[rating]}
                                style={{ width: '150px', marginLeft: '10px' }}
                              />
                            </div>
                          ))}
                    </div>
                    <Box className="text-end">
                      <Typography
                        variant="h5"
                        className="fs-40 fw-bold font-skmodernist"
                      >
                        {ratingData}
                      </Typography>
                      <Rating
                        name="average-rating"
                        value={ratingData}
                        precision={0.1}
                        readOnly
                      />
                      <Typography
                        variant="subtitle1"
                        className="fs-14 font-skmodernist"
                      >
                        {ratings?.data?.reviews.length || 0} {'reviews'}
                      </Typography>
                    </Box>
                  </div>
                  <Divider
                    component="div"
                    className="mt-4 main-divider"
                    role="presentation"
                  />
                  {job?.registration?.vendor_reviews?.map((item) => (
                    <ListItem
                      alignItems="flex-start"
                      className="review-list"
                      key={item?.id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt="Courtney Henry"
                          src={
                            COMPANY_LOGO_BASE_URL + item?.reviewer_image_path
                          }
                          className={classes.avatar}
                        />
                      </ListItemAvatar>
                      <Box display="flex" flexDirection="column">
                        <Typography
                          variant="subtitle1"
                          className="fs-16 text-darkgray font-skmodernist mb-1"
                        >
                          {item?.reviewer_name}
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          className="mb-1"
                        >
                          <Rating
                            name="read-only"
                            value={item?.review_star}
                            readOnly
                            size="small"
                            precision={0.1}
                          />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className="fs-14 font-skmodernist text-lightgray"
                            style={{ marginLeft: '8px' }}
                          >
                            {moment(item?.createdAt)?.fromNow()}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          className="fs-14 font-gotham text-gray"
                        >
                          {item?.review_description}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                  {/* <ListItem alignItems="flex-start" className="review-list">
                                        <ListItemAvatar>
                                            <Avatar alt="Courtney Henry" src={CourtneyImg} className={classes.avatar} />
                                        </ListItemAvatar>
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="subtitle1" className="fs-16 text-darkgray font-skmodernist mb-1">Jane Cooper</Typography>
                                            <Box display="flex" alignItems="center" className="mb-1">
                                                <Rating name="read-only" value={4} readOnly size='small' />
                                                <Typography variant="body2" color="textSecondary" className="fs-14 font-skmodernist text-lightgray" style={{ marginLeft: '8px' }}>
                                                    2 hours ago
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" className="fs-14 font-gotham text-gray">
                                                Lorem ipsum dolor sit amet, consectetur text adipiscing  elit. Proin felis.
                                            </Typography>
                                        </Box>
                                    </ListItem> */}
                </TabPanel>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

JobPostDetailForm.propTypes = {
  job: PropTypes.object,
  ratings: PropTypes.object,
  getJobRatingDataApi: PropTypes.func,
  userData: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { job, ratings } = state.jobDetail;
  const { userData } = state.signin;
  console.log('userData jobdetails', userData);

  return {
    job,
    ratings,
    userData,
  };
};

export default connect(mapStateToProps, {
  getJobRatingDataApi,
})(JobPostDetailForm);
