import React, { useState, useEffect } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
	Container,
	Grid,
	Typography,
	Button,
	Backdrop,
	CircularProgress,
	FormHelperText,
	Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
// import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';
import { loading } from '../../../store/actions/rootActions';
import { updateUserData } from '../FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import PropTypes from 'prop-types';
import useValidation from '../../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../../utils/validation';
// import { setProfessionalRole } from './redux/actions/fl-add-profession-action';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';
import { MuiChipsInput } from 'mui-chips-input';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import { stickyButtonStyle } from '../../../utils/sx-styles';
import {
	GAEvents,
	handleErrorsAndScroll,
	scrollToInput,
} from '../../../utils/helper-funtions';
import { CLICKED, FL_ADDROLE_TITLE, LOAD } from '../../../utils/constants';

const theme = createTheme();
const categoryText = 'Got it. Now, add a Title to Tell the World what you do';

const FLAddProfessionalRoleForm = ({
	onNextHandler,
	onBackHandler,
	userData,
	updateUserData,
	isLoading,
	loading,
	// pushMessage,
	// setProfessionalRole,
	// freelancerRole
}) => {
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	const [role, setRole] = useState(userData?.registration?.freelancer_role);
	const { errors, validateInput } = useValidation();
	const [chips, setChips] = React.useState(
		userData?.registration?.freelancer_skills || []
	);

	const MuiChipsInputStyled = styled(MuiChipsInput)`
		& input {
			color: red;
		}
		&.MuiOutlinedInput-input {
			padding: 0px !important;
		}
	`;
	const handleBackButton = async () => {
		loading(true);
		let newUserData = {
			active_steps: 0,
			steps_completed: false,
			freelancer_role: role,
			freelancer_skills: chips,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken,
			onBackHandler
		);
		loading(false);
	};

	const onChangeRole = (e) => {
		const input = e.target.value;
		// const regex = /^[a-zA-Z\s]*$/;
		// if (regex.test(input)) {
		// setProfessionalRole(input)
		// setRole(String(input).trim());
		setRole(String(input));
		validateInput('flRole', String(input).trim());
		// }
		// setCompanyName(input);
	};
	function handleSelecetedTags(items) {
		console.log('handleSelecetedTags', items);
		if (items?.length > 5) {
			validateInput('flSkills', items?.length);
			return;
		} else {
			validateInput('flSkills', items?.length);
			setChips(items);
		}
		console.log('Selected Language :', items);
	}
	const submitAddProfessional = async () => {
		if (role == null || role == '') {
			validateInput('flRole', role);
			scrollToInput('flRole');
			return;
		}
		console.log('====chips', chips);
		if (chips == null || chips == '' || chips?.length == 0) {
			validateInput('flSkills');
			scrollToInput('flSkills');
			return;
		}
		//
		// if(role == "" || role?.length == 0){
		//     alert("Professional role is mandatory");
		//     return;
		// }
		if (
			!hasAnyErrorPresent(errors) ||
			errors?.flSkills === 'You can add upto 5 Skills.'
		) {
			loading(true);
			let newUserData = {
				active_steps: 2,
				steps_completed: false,
				freelancer_role: role,
				freelancer_skills: chips,
			};
			console.log('==userData+newUserData', newUserData);

			await updateUserData(
				userData,
				newUserData,
				userData.registration.id,
				userData.accessToken
			);
			loading(false);
			onNextHandler();
		} else {
			handleErrorsAndScroll(errors);
		}
	};

	useEffect(() => {
		GAEvents(FL_ADDROLE_TITLE, "Freelancer Onboarding", LOAD,{
            page_title: 'Add Role',
            page_location: window.location.href,
            page_path: window.location.pathname,
            userId: userData?.id || sessionStorage.getItem('random_id'),
			reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id')

        });
		setRole(userData?.registration?.freelancer_role);
	}, []);

	async function handleSkipButton() {
		loading(true);
		let newUserData = {
			active_steps: 2,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken
		);
		loading(false);
		onNextHandler();
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				{isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						{/* <div className='my-3 ms-2'>
                            <ElynkerLogo width={'100px'} height={'30px'} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" /> */}
						<Typography
							variant="h4"
							align="left"
							alignItems="center"
							className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
							gutterBottom
							style={{ whiteSpace: 'pre-line' }}
						>
							{categoryText}
						</Typography>

						<Typography
							variant="body1"
							align="left"
							className="fs-14 text-grey fw-normal font-gotham"
							gutterBottom
						>
							It&apos;s the very first thing clients see, so make it count.Stand
							out by describing your expertise in your own words.
						</Typography>

						<form
							style={{ marginTop: '40px' }}
							onSubmit={(e) => {
								e.preventDefault();
								GAEvents(FL_ADDROLE_TITLE, "Freelancer Onboarding", CLICKED,{
									userId: userData?.id || sessionStorage.getItem('random_id'),
									title: "Next"
								});
								submitAddProfessional();
							}}
						>
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<InputLabel
									htmlFor="inputRole"
									className="text-start fs-14 text-black font-skmodernist"
								>
									Professional Role <span className="text-mandatory">*</span>
								</InputLabel>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<OutlinedInput
										id="flRole"
										// label="Mobile Number"
										type="text"
										placeholder="Enter your role"
										autoFocus
										fullWidth
										margin="none"
										inputProps={{
											maxLength: 50,
										}}
										value={role}
										onChange={onChangeRole}
									/>
								</FormControl>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.flRole}
								</FormHelperText>
							</Grid>
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<InputLabel
									htmlFor="inputRole"
									className="text-start fs-14 text-black font-skmodernist"
								>
									Add Skills <span className="text-mandatory">*</span>
								</InputLabel>
								<FormControl
									id="flSkills"
									fullWidth
									sx={{ my: 1 }}
									variant="outlined"
								>
									<MuiChipsInputStyled
										className="MuiChipsInputCustom"
										value={chips}
										onChange={handleSelecetedTags}
									/>
								</FormControl>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.flSkills}
								</FormHelperText>
							</Grid>

							<div style={{ marginTop: '100px' }}>
								{isTabletOrMobile && (
									<Box sx={{ width: '100%' }}>
										<Box sx={[stickyButtonStyle]}>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
												sx={{
													borderRadius: '6px',
													textTransform: 'capitalize',
												}}
												// onClick={() => {}}
											>
												Next Step
											</Button>
											<div style={{display: "flex", justifyContent: "space-between"}}>
												<Button
													variant="text"
													type="button"
													fullWidth
													className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
													onClick={()=>{
														GAEvents(FL_ADDROLE_TITLE, "Freelancer Onboarding", CLICKED, {
															userId: userData?.id || sessionStorage.getItem('random_id'),
															reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
															title: "Back"
														});
														handleBackButton();
													}}
												>
													Back
												</Button>
												<Button
													onClick={()=>{
														GAEvents(FL_ADDROLE_TITLE, "Freelancer Onboarding", CLICKED, {
															userId: userData?.id || sessionStorage.getItem('random_id'),
															reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
															title: "Skip for Now"
														});
														handleSkipButton();
													}}
													variant="text"
													type="button"
													fullWidth
													className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
												>
													Skip for Now
												</Button>
											</div>
										</Box>
									</Box>
								)}

								{isDesktopOrLaptop && (
									<>
										<Box className="d-flex justify-content-between align-items-center my-4">
											<Button
												variant="text"
												type="button"
												className="btn-web-back"
												onClick={()=>{
													GAEvents(FL_ADDROLE_TITLE, "Freelancer Onboarding", CLICKED, {
														userId: userData?.id || sessionStorage.getItem('random_id'),
														reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
														title: "Back"
													});
													handleBackButton();
												}}
											>
												Back
											</Button>
											<Button
												onClick={()=>{
													GAEvents(FL_ADDROLE_TITLE, "Freelancer Onboarding", CLICKED, {
														userId: userData?.id || sessionStorage.getItem('random_id'),
														reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
														title: "Skip for Now"
													});
													handleSkipButton();
												}}
												variant="text"
												type="button"
												className="btn-web-back"
											>
												Skip for Now
											</Button>
											<Button
												type="submit"
												variant="contained"
												className="btn-web-next"
											>
												Next Step
											</Button>
										</Box>
									</>
								)}
							</div>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

FLAddProfessionalRoleForm.propTypes = {
	updateUserData: PropTypes.func,
	loading: PropTypes.func,
	isLoading: PropTypes.bool,
	userData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	onNextHandler: PropTypes.func,
	onBackHandler: PropTypes.func,
	// setProfessionalRole: PropTypes.func,
	freelancerRole: PropTypes.string,
	pushMessage: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { isLoading } = state.root;
	const { freelancerRole } = state.flAddProfRole;

	// console.log("State :", state);
	return {
		isLoading,
		userData,
		freelancerRole,
	};
};

export default connect(mapStateToProps, {
	updateUserData,
	loading,
	pushMessage,
	// setProfessionalRole
})(FLAddProfessionalRoleForm);

