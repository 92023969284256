import React from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { ReactComponent as ProfileImageEditIcon } from '../../assets/profile-edit-icon.svg';
import { Tooltip } from '@mui/material';
// import defaultProfileImage from '../../assets/defaultProfileImage.png';
const StyledAvatar = styled(Avatar)(({ theme, height = 100, width = 100, fontSize = 40 }) => ({
  backgroundColor: deepOrange[500],
  color: theme.palette.getContrastText(deepOrange[500]),
  width: width,
  height: height,
  fontSize: fontSize,
}));

const Container = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const IconWrapper = styled('div')(({ iconStyle = {} }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  cursor: 'pointer',
  zIndex: 100,
  ...iconStyle,
}));

const DefaultLogo = ({
  name,
  handleClick,
  height,
  width,
  iconStyle,
  iconHeight = 34,
  iconWidth = 34,
  showEdit = true,
  fontSize
}) => {
  const initials = name ? name.substring(0, 2).toUpperCase() : 'EL';
  return (
    <Container>
      {showEdit && (
        <IconWrapper onClick={handleClick} iconStyle={iconStyle}>
          <Tooltip title="Edit">
            <ProfileImageEditIcon height={iconHeight} width={iconWidth} />
          </Tooltip>
        </IconWrapper>
      )}
      {/* <img height={130} width={130} src={defaultProfileImage} alt="" /> */}
      { <StyledAvatar height={height} width={width} fontSize={fontSize}>
        {initials}
      </StyledAvatar> }
    </Container>
  );
};

DefaultLogo.propTypes = {
  name: PropTypes.string,
  handleClick: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  iconStyle: PropTypes.object,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  showEdit: PropTypes.bool,
  fontSize: PropTypes.number,
};

export default DefaultLogo;
