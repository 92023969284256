import {
  SAVE_BUSINESS_DOCUMENTS,
  SET_ACTIVE_STEP,
  SET_STEPPER_DATA,
  RESET_B2B_ONBOARD_DATA,
} from '../types';

const INITIAL_STATE = {
  activeStep: 0,
  businessDocuments: null,
  stepperData: [
    {
      id: 0,
      name: 'Company Details',
      completed: false,
      active: true,
      error: false,
      icon: 2,
    },

    {
      id: 1,
      name: 'Profile Details',
      completed: false,
      active: false,
      error: false,
      icon: 3,
    },
    {
      id: 2,
      name: 'Offered Services & Product Details',
      completed: false,
      active: false,
      error: false,
      icon: 3,
    },
  ],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case SAVE_BUSINESS_DOCUMENTS:
      return { ...state, businessDocuments: action.payload };
    case SET_STEPPER_DATA:
      return { ...state, stepperData: action.payload };
    case RESET_B2B_ONBOARD_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
