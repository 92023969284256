import { ACN_NUMBER, RESET_AU_DOC_DATA } from '../types';

const INITIAL_STATE = {
  acn: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACN_NUMBER:
      return { ...state, acn: action.payload };
    case RESET_AU_DOC_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
