/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Badge,
  Select,
  MenuItem,
  TextareaAutosize,
  FormHelperText,
  ListItemAvatar,
  Avatar,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { ReactComponent as CheckCircleIcon } from '../../assets/check-circle-icon.svg';

import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import StarIcon from '@mui/icons-material/Star';
import ProductCloseIcon from '../../assets/product-close-icon.svg';

import Drawer from '@mui/material/Drawer';
import Resizer from 'react-image-file-resizer';

import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import InputLabel from '@mui/material/InputLabel';
import ImageDialog from '../../components/ImageOverlay';

import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import {
  postMemberContacted,
  getVendorDetails,
} from '../B2BCategory/redux/actions/b2b-category-action';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';

import Rating from '@mui/material/Rating';
import { connect, useDispatch } from 'react-redux';
import {
  PRODUCT_IMAGE_BASE_URL,
  COVER_IMAGE_BASE_URL,
  COMPANY_LOGO_BASE_URL,
  UPLOAD_REVIEW_IMAGE,
  SOCIAL_MEDIA_IMAGE_PATH,
  WHATSAPP_URL,
} from '../../utils/APIs/api';
import { ArrowBackIos } from '@mui/icons-material';

import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import useValidation from '../../hooks/useValidation';
import {
  addFeedback,
  removeFeedbackImages,
  saveFeedback,
  getFeedback,
  clearFeedBackFormData,
} from './redux/actions/b2bDetailActions';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import defaultProfileImage from '../../assets/defaultProfileImage.png';

import { hasAnyErrorPresent } from '../../utils/validation';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { styled } from '@mui/material/styles';
import Responsive from '../../utils/responsive';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const theme = createTheme();
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { updateVendorUserData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { useMediaQuery } from 'react-responsive';
import ConnectWithModal from './Connect-With-Modal';
import { DIALOG_NOT_LOGIN_MSG, DIALOG_TITLE, SEO_B2B_DETAIL_DESC, SEO_B2B_DETAIL_KEYWORDS, SEO_B2B_DETAIL_TITLE, SEO_B2B_DETAIL_URL, SEO_FAV_ICON_URL } from '../../utils/constants';
import {
  GAEvents,
  sendDefaultPageEvent,
  truncateText,
} from '../../utils/helper-funtions';
import { setLoading } from '../../store/actions/rootActions';
import { SET_WA_ENQUIRY } from '../B2BCategory/redux/types';
import DefaultLogo from '../../components/StyledAvatar/styledAvatar';
import { Helmet } from 'react-helmet';
const useStyles = makeStyles(() => ({
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  reviewAvatar: {
    width: '50px !important',
    height: '50px !important',
    borderRadius: '50%',
    marginRight: '10px',
  },
}));
function findMinimumBudget(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // return null if data is not an array or empty
  }

  let minBudget = parseFloat(data[0].budget); // initialize minBudget with the first budget value

  for (let i = 1; i < data.length; i++) {
    const budget = parseFloat(data[i].budget);
    if (!isNaN(budget) && budget < minBudget) {
      minBudget = budget; // update minBudget if a smaller budget value is found
    }
  }

  return minBudget;
}

const B2BDetailForm = ({
  cover_image,
  company_name,
  about_company,
  products,
  address1,
  address2,
  social_links,
  whatsapp_number,
  contacted_members,
  reviews,
  image_path,
  postMemberContacted,
  id,
  categories,
  pushMessage,
  addFeedback,
  b2bDetails,
  removeFeedbackImages,
  saveFeedback,
  userData,
  rating,
  reviewStarsPercentages,
  updateVendorUserData,
  getVendorDetails,
  user,
  clearFeedBackFormData,
  vendorsDetails,
  vendorId,
  userId,
  uuid,
  isLoading,
  setLoading,
  seller_websites,
  name
}) => {
  const { vId } = useParams();
  const { feedback } = b2bDetails;
  const { errors, validateInput, setErrors } = useValidation();
  const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
  const [openCallModal, setOpenCallModal] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const [allproducts, setAllProducts] = useState(products);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categories[0]?.title || ''
  );
  const [currentProduct, setCurrentProduct] = useState();
  const handleCloseCallModal = () => setOpenCallModal(false);
  const toggleCallBackDrawer = () => {
    setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
  };

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    // GAPageView("/b2b-detail", "page_vendor_details", "Business Vendor detail vendor_slug: "+vId);
    const pagetitle = 'Elynker - Business Profile - ' + company_name;
    sendDefaultPageEvent(
      userData,
      'Business Detail Page',
      window.location.href,
      window.location.pathname
    );
    GAEvents('Business Detail', {
      page_title: pagetitle,
      page_location: window.location.href,
      page_path: window.location.pathname,
      business_name: company_name,
      business_category: selectedCategoryId,
      business_city: userData?.registration?.city,
    });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    getVendorDetails(vId);
    setAllProducts(products);
  }, []);
  const inputRef = React.useRef(null);
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  // eslint-disable-next-line
  const [feedbackDetails, setFeedbackDetails] = React.useState();
  const handleImageClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };
  const changeHandler = async (e) => {
    const files = e.target.files;

    const currentImages = feedback?.images ? Array.from(feedback.images) : [];
    console.log('feedback.images', feedback.images);
    console.log('currentImages.length', currentImages.length);
    if (currentImages.length + files.length > 5) {
      pushMessage('You can upload up to 5 photos.');
      e.preventDefault();
      return;
    }

    let compressedImages = await compressImages(files);
    // console.log('change handler compressed', [
    //   ...currentImages,
    //   ...compressedImages,
    // ]);

    addFeedback({ image: compressedImages });
    e.target.value = null;
  };

  React.useEffect(() => {
    dispatch({ type: SET_WA_ENQUIRY, payload: null });
  }, []);
  const compressImages = (files) => {
    return Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            500, // New width
            500, // New height
            'JPEG', // Format
            75, // Quality
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    );
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className="b2b-detail-tabpanel"
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3, px: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  React.useEffect(() => {
    console.log('feedback?.images useffect', feedback?.images);
  }, [feedback?.images]);
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      // For setting first category in list
      setSelectedCategoryId('All Category');
      setAllProducts(products);
    }
  };
  const handleCategoryChange = (event) => {
    // setSelectedCategoryId(event.target.value)
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.mode === 'light' ? '#f5f5f6' : '#f5f5f6',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#864FFD' : '#864FFD',
    },
  }));

  const handleClick = (url) => {
    // Navigate to the provided URL
    window.open(url, '_blank');
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [isFeedBackDrawerOpen, setIsFeedBackDrawerOpen] = useState(false);
  const toggleFeedBackDrawer = () => {
    if (!isFeedBackDrawerOpen) {
      clearFeedBackFormData();
    }
    setIsFeedBackDrawerOpen(!isFeedBackDrawerOpen);
    setErrors(null);
    addFeedback({ feedbackRating: 0, feedbackDetail: null, images: null });
  };

  const onPasteFeedbackDetails = (event) => {
    event.preventDefault();
    // clipboardData = event.clipboardData || event?.window?.clipboardData || event.originalEvent.clipboardData;
    const input = (event.clipboardData || event.window.clipboardData).getData(
      'text'
    );
    // Get the current input selection
    const inputCtrl = event.target;
    const start = inputCtrl.selectionStart;
    const end = inputCtrl.selectionEnd;

    let newInput;
    if(feedbackDetails === undefined){
      newInput= input;
    }else {
      newInput= feedbackDetails?.substring(0, start) + input + feedbackDetails?.substring(end);
    }

    setFeedbackDetails(newInput);
    validateInput('feedbackDetail', newInput);
    addFeedback({ feedbackDetail: newInput });
  };

  const onChangeFeedbackDetails = (event) => {
    const value = event.target.value;
    setFeedbackDetails(value);
    validateInput('feedbackDetail', value);
    addFeedback({ feedbackDetail: value });
  };
  const StyledImageContainer = styled('div')({
    textAlign: 'left',
  });
  const openLocation = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address2
    )}`;
    window.open(url, '_blank');
  };
  const [viewImagedialogOpen, setViewImagedialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleViewImageClick = (image) => {
    setSelectedImage(image);
    setViewImagedialogOpen(true);
  };
  const handleViewImageCloseDialog = () => {
    setViewImagedialogOpen(false);
    setSelectedImage('');
  };
  const handleCall = () => {
    if (userData?.registration?.id !== undefined) {
      if (user?.mobile_number !== null && user?.mobile_number !== '') {
        postMemberContacted(id, user?.mobile_number);
        window.location.href = `tel:${user?.country_code}${user?.mobile_number}`;
      } else {
        pushMessage('Phone number not found.');
      }
    } else {
      handleOpenCallModal();
    }
    handleCloseModal();
  };

  const handleWhatsappCall = () => {
    if (userData?.registration?.id !== undefined) {
      if (id && whatsapp_number) {
        let cc = user?.country_code?.substring(1, user?.country_code?.length);
        postMemberContacted(id, whatsapp_number);
        const whatsappUrl = `${WHATSAPP_URL}${cc}${whatsapp_number}`;
        window.open(whatsappUrl, '_blank');
      } else {
        pushMessage('Whatsapp number not found.');
      }
    } else {
      handleOpenCallModal();
    }
    handleCloseModal();
  };
  const deleteProdImage = (index) => {
    console.log('deleteProdImage12', index);
    removeFeedbackImages(index);
  };
  const onFeedbackSubmit = async () => {
    if (!feedback?.feedbackRating) {
      validateInput('feedbackRating', '');
    } else {
      if (!hasAnyErrorPresent(errors)) {
        const res = await saveFeedback(userData, feedback, id);
        pushMessage(res);
        GAEvents('FeedBack Submit', 'feedback_submit', 'Feedback submit', {
          reviewer_user_id: userData?.user?.id || '',
          review_description: feedback?.feedbackDetail || '',
          review_star: feedback?.feedbackRating || '',
          registrationId: id || '',
        });
        setIsFeedBackDrawerOpen(false);
        updateVendorUserData(user, { rating: feedback?.feedbackRating }, id);
        getVendorDetails(uuid);
        addFeedback({ feedbackRating: 0, feedbackDetail: null, images: null });
        setErrors(null);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SEO_B2B_DETAIL_TITLE}</title>
        <link rel="canonical" href={SEO_B2B_DETAIL_URL} />
        <meta name="description" content={SEO_B2B_DETAIL_DESC} />
        <meta property="og:title" content={SEO_B2B_DETAIL_TITLE} />
        <meta property="og:description" content={SEO_B2B_DETAIL_DESC} />
        <meta property="og:url" content={SEO_B2B_DETAIL_URL} />
        <meta property="og:image" content={SEO_FAV_ICON_URL} />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content={SEO_B2B_DETAIL_KEYWORDS}
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl">
          <Dialog
            open={openCallModal}
            onClose={handleCloseCallModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="sm"
            fullWidth
          >
            {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
            <DialogTitle className="custom-border-header pb-0">
              <Box className="position-relative d-flex justify-content-between">
                <Typography
                  className="fs-22 fw-bold font-skmodernist"
                  id="alert-dialog-title"
                >
                  {DIALOG_TITLE}
                </Typography>
                <Box>
                  <CloseIcon
                    onClick={() => {
                      handleCloseCallModal();
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {DIALOG_NOT_LOGIN_MSG}
              </DialogContentText>
            </DialogContent>

            <DialogActions className="modal-footer">
              <Button onClick={handleCloseCallModal}>No</Button>
              <Button
                onClick={() => {
                  handleCloseCallModal();
                  dispatch({
                    type: SET_WA_ENQUIRY,
                    payload: {
                      isTrue: true,
                      redirect_to: '/b2b-detail/',
                      vendor_slug: vId,
                    },
                  });

                  history.push('/login');
                }}
                className="btn-save"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '67vh' }}
          >
            {isLoading && (
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Grid item xs={12}>
              <div
                className="py-3 ms-2 d-flex align-items-center"
                style={{ height: '80px' }}
              >
                <ArrowBackIosIcon onClick={() => history.goBack()} />
                <ElynkerLogoComponent>
                  <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
                </ElynkerLogoComponent>
              </div>
              <Divider
                component="div"
                className="main-divider mt-2"
                role="presentation"
              />
              <Box sx={{ margin: '0 -16px' }}>
                <CardMedia
                  className="profile-background-2"
                  style={{ backgroundPosition: 'center' }}
                  onClick={() =>
                    handleViewImageClick(
                      cover_image
                        ? COVER_IMAGE_BASE_URL + cover_image
                        : require('../../assets/profile_cover_bg.jpg')
                    )
                  }
                  image={
                    cover_image
                      ? COVER_IMAGE_BASE_URL + cover_image
                      : require('../../assets/profile_cover_bg.jpg')
                  }
                ></CardMedia>
              </Box>
              <form style={{ marginTop: '50px' }} className="b2b-detail-form">
                {Responsive(
                  <Box className="b2b-detail-card">
                    <Card className="card">
                      <div className="d-flex justify-content-between">
                      {image_path ? (
                        <CardMedia>
                          <img
                            alt="image"
                            loading="lazy"
                            className="category-media"
                            onClick={() =>
                              handleViewImageClick(
                                image_path
                                  ? COMPANY_LOGO_BASE_URL + image_path
                                  : defaultProfileImage
                              )
                            }
                            src={
                              image_path
                                ? COMPANY_LOGO_BASE_URL + image_path
                                : defaultProfileImage
                            }
                          />
                        </CardMedia>
                        ) : (
                          <DefaultLogo
                            name={company_name || name}
                            showEdit={false}
                          />
                        )}
                        <CardContent className="content p-0">
                          <div className="d-flex justify-content-between align-items-start w-100">
                            <Typography
                              variant="h5"
                              className="fs-20 text-black mb-1 fw-bold font-skmodernist"
                              component="div"
                            >
                              {company_name}
                            </Typography>
                            <div className="d-flex align-items-center">
                              <IconButton className="p-1">
                                <RatingIcon />
                              </IconButton>
                              <Typography
                                variant="h5"
                                className="fs-13 text-darkgrey fw-normal  font-gotham"
                                component="div"
                              >
                                {rating}
                              </Typography>
                            </div>
                          </div>

                          <Typography
                            variant="body2"
                            className="fs-14 text-grey mb-1 font-gotham"
                          >
                            {address1}
                          </Typography>

                          <Typography
                            variant="body2"
                            className="fs-13 text-secondary mb-1 font-gotham"
                          >
                            {/* Owned by Narendra */}
                          </Typography>
                        </CardContent>
                      </div>
                      <Divider
                        component="div"
                        className="mt-2 mb-2"
                        role="presentation"
                      />
                      <CardContent className="p-0 d-flex justify-content-between mt-1">
                        <div className="d-flex flex-column">
                          <Typography
                            variant="body2"
                            className="fs-13 text-darkgrey mb-1 font-skmodernist"
                          >
                            Members
                          </Typography>
                          <Typography
                            variant="body2"
                            className="fs-13 text-darkgrey mb-1 font-skmodernist"
                          >
                            {contacted_members}
                          </Typography>
                        </div>
                        <div className="d-flex flex-column">
                          <Typography
                            variant="body2"
                            className="fs-13 text-darkgrey mb-1 font-skmodernist"
                          >
                            Products
                          </Typography>
                          <Typography
                            variant="body2"
                            className="fs-13 text-darkgrey mb-1 font-skmodernist"
                          >
                            {products?.length}
                          </Typography>
                        </div>
                        <div className="d-flex align-items-center"></div>
                      </CardContent>
                    </Card>
                  </Box>,
                  <Box className="b2b-detail-web-card">
                    <Card className="card">
                      <div className="d-flex justify-content-between">
                      {image_path ? (
                        <CardMedia>
                          <img
                            alt="image"
                            loading="lazy"
                            className="category-media"
                            onClick={() =>
                              handleViewImageClick(
                                image_path
                                  ? COMPANY_LOGO_BASE_URL + image_path
                                  : defaultProfileImage
                              )
                            }
                            src={
                              image_path
                                ? COMPANY_LOGO_BASE_URL + image_path
                                : defaultProfileImage
                            }
                          />
                        </CardMedia>
                        ) : (
                          <DefaultLogo
                            name={company_name || name}
                            showEdit={false}
                          />
                        )}
                        <CardContent className="content p-0">
                          <Typography
                            variant="h5"
                            className="fs-22 text-black mb-2 fw-bold font-skmodernist"
                            component="div"
                          >
                            {company_name}
                          </Typography>

                          <div className="p-0 d-flex mt-1 web-card-gap mb-3">
                            <div className="d-flex flex-column">
                              <Typography
                                variant="body2"
                                className="fs-15 text-grey mb-1 font-gotham"
                              >
                                {address1}
                              </Typography>
                            </div>
                            <div className="d-flex flex-column">
                              <Typography
                                variant="body2"
                                className="fs-14 text-darkgrey mb-1 font-skmodernist"
                              >
                                Members
                              </Typography>
                              <Typography
                                variant="body2"
                                className="fs-14 text-darkgrey mb-1 font-skmodernist"
                              >
                                {contacted_members}
                              </Typography>
                            </div>
                            <div className="d-flex flex-column">
                              <Typography
                                variant="body2"
                                className="fs-14 text-darkgrey mb-1 font-skmodernist"
                              >
                                Products
                              </Typography>
                              <Typography
                                variant="body2"
                                className="fs-14 text-darkgrey mb-1 font-skmodernist"
                              >
                                {products?.length}
                              </Typography>
                            </div>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <div className="d-flex  align-items-center w-100">
                            <IconButton className="p-1">
                              <RatingIcon />
                            </IconButton>
                            <Typography
                              variant="h5"
                              className="fs-13 text-darkgrey fw-normal  font-gotham"
                              component="div"
                            >
                              {rating}
                            </Typography>
                          </div>
                        </CardContent>
                      </div>
                    </Card>
                  </Box>
                )}
                <Divider
                  component="div"
                  className="main-divider my-3"
                  role="presentation"
                />

                <Box>
                  <Typography
                    variant="h5"
                    className="fs-20 fs-md-32 mb-3 fw-bold font-skmodernist"
                    gutterBottom
                  >
                    Top Products
                  </Typography>
                  <div className="mb-3 top-products">
                    <Stack
                      direction="row"
                      spacing={1.5}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {products &&
                        products.map((p, i) => <Chip key={i} label={p?.title} />)}
                    </Stack>
                  </div>
                </Box>

                <Divider component="div" className="my-4" role="presentation" />

                <Box>
                  <Typography
                    variant="h5"
                    className="fs-20 fs-md-32 mb-3 fw-bold font-skmodernist"
                    gutterBottom
                  >
                    About {company_name}
                  </Typography>

                  <List>
                    {Responsive(
                      <ListItem className="b2b-list-item">
                        <Card className="card">
                          <CardContent className="p-0 d-flex justify-content-between mt-1">
                            <div className="d-flex flex-column">
                              {vendorsDetails?.additional_detail1 && (
                                <div className="d-flex align-items-center mb-1">
                                  <CheckCircleIcon
                                    style={{
                                      marginRight: '12px',
                                      marginTop: '3px',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    className="fs-14 text-darkgrey font-skmodernist"
                                  >
                                    {vendorsDetails?.additional_detail1}
                                  </Typography>
                                </div>
                              )}
                              {vendorsDetails?.additional_detail2 && (
                                <div className="d-flex align-items-center mb-1">
                                  <CheckCircleIcon
                                    style={{
                                      marginRight: '12px',
                                      marginTop: '3px',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    className="fs-14 text-darkgrey font-skmodernist"
                                  >
                                    {vendorsDetails?.additional_detail2}
                                  </Typography>
                                </div>
                              )}
                              {vendorsDetails?.additional_detail3 && (
                                <div className="d-flex align-items-center mb-1">
                                  <CheckCircleIcon
                                    style={{
                                      marginRight: '12px',
                                      marginTop: '3px',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    className="fs-14 text-darkgrey font-skmodernist"
                                  >
                                    {vendorsDetails?.additional_detail3}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </CardContent>
                          <Divider
                            component="div"
                            className="mt-2 mb-3"
                            role="presentation"
                          />
                          <CardContent className="p-0 d-flex justify-content-between">
                            <Button
                              variant="contained"
                              className="call-now-btn "
                              onClick={() => {
                                const mobileNo = user?.mobile_number
                                  ? '<mobile_no>'
                                  : '';
                                GAEvents(
                                  'call_button',
                                  'call_button',
                                  'Clicked on vendor card item Call button',
                                  {
                                    vendor_uuid: vId || '',
                                    company_name: company_name || '',
                                    vendor_id: id,
                                    phone: mobileNo,
                                  }
                                );
                                handleCall();
                              }}
                            >
                              <CallNowIcon className="me-2" />
                              Call Now
                            </Button>
                            <Button
                              variant="contained"
                              className="whatsapp-btn "
                              onClick={() => {
                                const whatsappNo = whatsapp_number
                                  ? '<whatsapp_no>'
                                  : '';
                                GAEvents(
                                  'whatsapp_chat_button',
                                  'whatsapp_chat_button',
                                  'Clicked on vendor card item Whatsapp button',
                                  {
                                    uuid: vId || '',
                                    company_name: company_name || '',
                                    vendor_id: id,
                                    whatsapp: whatsappNo,
                                  }
                                );
                                handleWhatsappCall();
                              }}
                              rel="noopener noreferrer"
                            >
                              <WhatsappIcon className="me-2" />
                              WhatsApp
                            </Button>
                          </CardContent>
                        </Card>
                      </ListItem>,
                      <ListItem className=" b2b-list-detail-web-item">
                        <Card className="card">
                          <CardContent className="p-0 d-flex justify-content-between align-items-center mt-1">
                            <Box className="d-flex flex-column" rowGap={1}>
                              {vendorsDetails?.additional_detail1 && (
                                <div className="d-flex align-items-center mb-1">
                                  <CheckCircleIcon
                                    style={{
                                      marginRight: '12px',
                                      marginTop: '3px',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    className="fs-15 text-darkgrey font-skmodernist"
                                  >
                                    {vendorsDetails?.additional_detail1}
                                  </Typography>
                                </div>
                              )}
                              {vendorsDetails?.additional_detail2 && (
                                <div className="d-flex align-items-center mb-1">
                                  <CheckCircleIcon
                                    style={{
                                      marginRight: '12px',
                                      marginTop: '3px',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    className="fs-15 text-darkgrey font-skmodernist"
                                  >
                                    {vendorsDetails?.additional_detail2}
                                  </Typography>
                                </div>
                              )}
                              {vendorsDetails?.additional_detail3 && (
                                <div className="d-flex align-items-center mb-1">
                                  <CheckCircleIcon
                                    style={{
                                      marginRight: '12px',
                                      marginTop: '3px',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    className="fs-15 text-darkgrey font-skmodernist"
                                  >
                                    {vendorsDetails?.additional_detail3}
                                  </Typography>
                                </div>
                              )}
                            </Box>
                            <Box
                              className="p-0 d-flex justify-content-between"
                              columnGap={2}
                            >
                              <Button
                                variant="contained"
                                className="call-now-web-btn"
                                onClick={() => {
                                  const mobileNo = user?.mobile_number
                                    ? '<mobile_no>'
                                    : '';
                                  GAEvents(
                                    'call_button',
                                    'call_button',
                                    'Clicked on vendor card item Call button',
                                    {
                                      vendor_uuid: vId || '',
                                      company_name: company_name || '',
                                      vendor_id: id,
                                      phone: mobileNo,
                                    }
                                  );
                                  handleCall();
                                }}
                              >
                                <CallNowIcon className="me-2" />
                                Call Now
                              </Button>
                              <Button
                                variant="contained"
                                className="whatsapp-web-btn"
                                onClick={() => {
                                  const whatsappNo = whatsapp_number
                                    ? '<whatsapp_no>'
                                    : '';
                                  GAEvents(
                                    'whatsapp_chat_button',
                                    'whatsapp_chat_button',
                                    'Clicked on vendor card item Whatsapp button',
                                    {
                                      uuid: vId || '',
                                      company_name: company_name || '',
                                      vendor_id: id,
                                      whatsapp: whatsappNo,
                                    }
                                  );
                                  handleWhatsappCall();
                                }}
                                rel="noopener noreferrer"
                              >
                                <WhatsappIcon className="me-2" />
                                WhatsApp
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </ListItem>
                    )}
                  </List>
                </Box>
                {seller_websites && seller_websites.length > 0 && (
                  <Box className="mb-3">
                    <Typography
                      variant="h5"
                      className="fs-20 fs-md-32 mb-1 fw-bold font-skmodernist"
                      gutterBottom
                    >
                      Website
                    </Typography>

                    <a
                      href={seller_websites[0].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleClick(seller_websites[0].url)}
                    >
                      {seller_websites[0].url}
                    </a>
                  </Box>
                )}
                <Box>
                  <Typography
                    variant="h5"
                    className="fs-20 fs-md-32 mb-3 fw-bold font-skmodernist"
                    gutterBottom
                  >
                    Social Links
                  </Typography>

                  <Stack direction="row" spacing={2}>
                    {social_links &&
                      social_links.map((s) => (
                        <a
                          href={s.social_url}
                          key={s.id}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => handleClick(s.social_url)}
                        >
                          <img
                            src={`${SOCIAL_MEDIA_IMAGE_PATH}/${s.social_media_master.media_image_path}`}
                            alt={s.social_name}
                            style={{ width: '38px', height: '38px' }}
                          />
                        </a>
                      ))}
                  </Stack>
                </Box>

                <Divider component="div" className=" my-4" role="presentation" />

                <Box className="mb-3">
                  <div
                    onClick={openLocation}
                    className="d-flex align-items-center justify-content-between mb-3"
                  >
                    <Typography
                      variant="h5"
                      className="fs-20 fs-md-32 fw-bold font-skmodernist"
                      gutterBottom
                    >
                      Address
                    </Typography>
                    {/* <div className="d-flex align-items-center">
                      <Typography
                        className="fs-14 fw-normal text-secondary font-gotham me-2"
                        sx={{ cursor: 'pointer' }}
                      >
                        View Map
                      </Typography>
                      <ArrowRightIcon />
                    </div> */}
                  </div>
                  <div
                    // onClick={openLocation}
                    className="d-flex align-items-center justify-content-between mb-3"
                  >
                    <Typography
                      variant="h5"
                      className="fs-16 font-skmodernist"
                      gutterBottom
                    >
                      {address2}
                    </Typography>
                  </div>
                  <div>
                    {/* <MapImg width={'100%'} />
                    */}
                    {/* <MapView address={address2} /> */}
                  </div>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="secondary tabs example"
                    className="b2b-detail-tabs"
                  >
                    <Tab
                      label="Overview"
                      className="fs-15 font-skmodernist text-capitalize"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <React.Fragment>
                          <span className="fs-15 font-skmodernist text-capitalize b2b-detail-tabs-title">
                            Catalog
                          </span>
                          <Badge
                            badgeContent={products.length || 0}
                            color="error"
                            className="b2b-detail-tabs-badge"
                          />{' '}
                          {/* Adjust badge content and color as needed */}
                        </React.Fragment>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={
                        <React.Fragment>
                          <span className="fs-15 font-skmodernist text-capitalize b2b-detail-tabs-title">
                            Reviews
                          </span>
                          <Badge
                            badgeContent={reviews?.length || 0}
                            color="error"
                            className="b2b-detail-tabs-badge"
                          />{' '}
                          {/* Adjust badge content and color as needed */}
                        </React.Fragment>
                      }
                      {...a11yProps(2)}
                    />
                  </Tabs>

                  <TabPanel value={value} index={0}>
                    <Typography className="fs-14 font-gotham text-gray mb-3">
                      {about_company}
                    </Typography>

                    {vendorId !== userId && (
                      <Box className="rating-review-card mt-4">
                        <Card className="card">
                          <CardContent className="p-0 d-flex justify-content-between mt-1">
                            <div className="d-flex flex-column">
                              <Typography
                                variant="body2"
                                className="fs-16 text-black fw-bold font-skmodernist mb-2"
                              >
                                Ratings / Reviews
                              </Typography>
                              <Box>
                                <Rating
                                  name="read-only"
                                  value={rating}
                                  size="small"
                                  precision={0.1}
                                  readOnly
                                />
                              </Box>
                            </div>

                            <Button
                              variant="contained"
                              className="submit-rating-btn"
                              onClick={() => {
                                if (userData?.registration?.id === undefined) {
                                  setOpenCallModal(true);
                                } else {
                                  toggleFeedBackDrawer();
                                }
                              }}
                            >
                              Submit Rating
                            </Button>
                          </CardContent>
                        </Card>
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {!currentProduct && (
                      <>
                        <FormControl
                          sx={{ minWidth: 180 }}
                          size="small"
                          className="mb-3"
                        >
                          <Select
                            id="demo-select-small"
                            variant="outlined"
                            onChange={handleCategoryChange}
                            value={selectedCategoryId}
                          >
                            <MenuItem
                              onClick={() => {
                                setSelectedCategoryId('All Category');
                                setAllProducts(products);
                              }}
                              key={55}
                              value={'All Category'}
                            >
                              All Category
                            </MenuItem>
                            {categories &&
                              categories.map((c) => (
                                <MenuItem
                                  onClick={() => {
                                    setSelectedCategoryId(c?.title);
                                    setAllProducts(
                                      products.filter(
                                        (p) => p?.category_id == c.id
                                      )
                                    );
                                  }}
                                  key={c.id}
                                  value={c?.title}
                                >
                                  {c?.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={3}
                          className="mb-4"
                        >
                          {isTabletOrMobile && (
                            <>
                              {allproducts?.map((item) => {
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    md={3}
                                    key={item.img}
                                    onClick={() =>
                                      setCurrentProduct({
                                        ...item,
                                        viewMore: false,
                                      })
                                    }
                                  >
                                    <StyledImageContainer>
                                      <img
                                        src={`${PRODUCT_IMAGE_BASE_URL}${item.default_image}`}
                                        alt="product-image"
                                        loading="lazy"
                                        style={{
                                          marginBottom: '8px',
                                          width: '100%',
                                          height: '180px',
                                          borderRadius: '10px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                      <Typography className="fs-16 text-darkgrey font-skmodernist">
                                        {item.title}
                                      </Typography>

                                      <Typography className="fs-14 fw-bold text-secondary text-darkgrey">
                                        ₹{' '}
                                        {parseInt(item.budget) +
                                          '/' +
                                          item?.unit || ''}
                                      </Typography>
                                    </StyledImageContainer>
                                  </Grid>
                                );
                              })}
                            </>
                          )}
                          {isDesktopOrLaptop && (
                            <>
                              {allproducts?.map((item) => {
                                return (
                                  <Grid item xs={6} md={3} key={item.img}>
                                    <StyledImageContainer
                                      onClick={() => setCurrentProduct(item)}
                                    >
                                      <img
                                        src={`${PRODUCT_IMAGE_BASE_URL}${item.default_image}`}
                                        alt="product-image"
                                        loading="lazy"
                                        style={{
                                          marginBottom: '8px',
                                          width: '100%',
                                          height: '280px',
                                          borderRadius: '10px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                      <Typography className="fs-18 fw-bold text-darkgrey my-2 font-skmodernist">
                                        {item.title}
                                      </Typography>

                                      <Typography className="fs-18 fw-bold text-secondary text-darkgrey">
                                        ₹{' '}
                                        {parseInt(item.budget) +
                                          '/' +
                                          item?.unit || ''}
                                      </Typography>
                                    </StyledImageContainer>
                                  </Grid>
                                );
                              })}
                            </>
                          )}
                        </Grid>
                      </>
                    )}

                    {isTabletOrMobile && (
                      <>
                        {currentProduct && (
                          <Box>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginBottom: '20px',
                              }}
                            >
                              <ArrowBackIos
                                onClick={() => {
                                  setCurrentProduct(null);
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <img
                                src={`${PRODUCT_IMAGE_BASE_URL}${currentProduct.default_image}`}
                                alt="product-image"
                                loading="lazy"
                                onClick={() =>
                                  handleViewImageClick(
                                    currentProduct?.product_images
                                      ?.split(',')
                                      .map((i) => PRODUCT_IMAGE_BASE_URL + i)
                                  )
                                }
                                style={{
                                  marginBottom: '8px',
                                  width: '100%',
                                  height: '220px',
                                  borderRadius: '8px',
                                  cursor: 'pointer',
                                  objectFit: 'contain',
                                }}
                              />
                            </div>
                            <Stack
                              direction="row"
                              spacing={1.5}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              useFlexGap
                              flexWrap="wrap"
                              marginBottom={'8px'}
                            >
                              <Typography className="fs-18 fw-bold font-skmodernist text-black mb-0">
                                {currentProduct?.title}
                              </Typography>
                              <Typography className="fs-14 fw-bold text-secondary text-darkgrey">
                                {findMinimumBudget(products) +
                                  '/' +
                                  currentProduct?.unit || ''}
                              </Typography>
                            </Stack>
                            {currentProduct?.moq && (
                              <Typography className="fs-14 fw-bold text-darkgrey mb-3">
                                {'Min. Order Quantity: ' + currentProduct?.moq}
                              </Typography>
                            )}
                            {currentProduct?.description && (
                              <Typography className="fs-14 font-gotham text-gray mb-3">
                                {truncateText(
                                  currentProduct?.description,
                                  currentProduct
                                )}{' '}
                                <Button
                                  onClick={() => {
                                    var updatedProduct = {
                                      ...currentProduct,
                                      viewMore: !currentProduct.viewMore,
                                    };
                                    setCurrentProduct(updatedProduct);
                                  }}
                                  sx={{
                                    textTransform: 'capitalize',
                                    color: '#864ffd',
                                    padding: '0px',
                                  }}
                                >
                                  {currentProduct?.viewMore
                                    ? 'view less'
                                    : 'view more'}
                                </Button>
                              </Typography>
                            )}
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              onClick={toggleCallBackDrawer}
                              className="mt-3 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                              sx={{
                                borderRadius: '6px',
                                textTransform: 'capitalize',
                                height: '50px',
                                minWidth: '145px',
                              }}
                            >
                              Call / Connect
                            </Button>
                          </Box>
                        )}
                      </>
                    )}

                    {isDesktopOrLaptop && (
                      <>
                        {currentProduct && (
                          <Box
                            className="desktop-catalog-card"
                            sx={{
                              border: '1px solid #E3E3E3',
                              borderRadius: '8px',
                              padding: '20px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginBottom: '20px',
                              }}
                            >
                              <ArrowBackIos
                                onClick={() => {
                                  setCurrentProduct(null);
                                }}
                              />
                            </div>
                            <Box className="d-flex">
                              <div className="mb-3">
                                <img
                                  src={`${PRODUCT_IMAGE_BASE_URL}${currentProduct.default_image}`}
                                  alt="product-image"
                                  loading="lazy"
                                  onClick={() =>
                                    handleViewImageClick(
                                      currentProduct?.product_images
                                        ?.split(',')
                                        .map((i) => PRODUCT_IMAGE_BASE_URL + i)
                                    )
                                  }
                                  style={{
                                    width: '273px',
                                    height: '270px',
                                    borderRadius: '8px',
                                    marginRight: '26px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                              <Stack
                                direction="row"
                                spacing={1.5}
                                alignItems={'start'}
                                justifyContent={'space-between'}
                                useFlexGap
                                width={'100%'}
                              >
                                <Box style={{ width: '80%' }}>
                                  <Typography className="fs-22 fw-bold font-skmodernist text-black mb-3">
                                    {currentProduct?.title}
                                  </Typography>
                                  {currentProduct?.moq && (
                                    <Typography className="fs-14 fw-gotham text-black">
                                      {'Min. Order Quantity: ' +
                                        currentProduct?.moq}
                                    </Typography>
                                  )}
                                  <Typography className="fs-18 fw-bold text-secondary text-darkgrey">
                                    <span className="fs-14 font-skmodernist text-black">
                                      Starting at
                                    </span>
                                    <br></br>₹ {findMinimumBudget(products) + '/'}
                                    <span className="fs-13 font-skmodernist text-grey">
                                      {currentProduct?.unit || ''}
                                    </span>
                                  </Typography>
                                  {currentProduct?.description && (
                                    <Typography className="fs-15 font-gotham text-gray mb-3 mt-2">
                                      {truncateText(
                                        currentProduct?.description,
                                        currentProduct
                                      )}{' '}
                                      <Button
                                        onClick={() => {
                                          var updatedProduct = {
                                            ...currentProduct,
                                            viewMore: !currentProduct.viewMore,
                                          };
                                          setCurrentProduct(updatedProduct);
                                        }}
                                        sx={{
                                          textTransform: 'capitalize',
                                          color: '#864ffd',
                                          padding: '0px',
                                        }}
                                      >
                                        {currentProduct?.viewMore
                                          ? 'view less'
                                          : 'view more'}
                                      </Button>
                                    </Typography>
                                  )}
                                </Box>
                                <Box alignContent={'center'} height={'100%'}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => handleOpenModal()}
                                    className=" py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                                    sx={{
                                      borderRadius: '6px',
                                      textTransform: 'capitalize',
                                      height: '50px',
                                      minWidth: '145px',
                                    }}
                                  >
                                    Call / Connect
                                  </Button>
                                  <ConnectWithModal
                                    open={openModal}
                                    onClose={handleCloseModal}
                                    company_name={company_name}
                                    handleCall={handleCall}
                                    handleWhatsappCall={handleWhatsappCall}
                                  />
                                </Box>
                              </Stack>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column align-items-center">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: theme.spacing(1),
                          }}
                        >
                          {reviewStarsPercentages &&
                            Object.keys(reviewStarsPercentages)
                              .sort((a, b) => b - a)
                              .map((r) => {
                                return (
                                  <div
                                    key={r}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      className="fs-15 font-skmodernist text-darkgrey me-1"
                                    >
                                      {r}
                                    </Typography>
                                    <StarIcon
                                      fontSize="small"
                                      sx={{ color: '#faaf00' }}
                                    />
                                    <BorderLinearProgress
                                      variant="determinate"
                                      value={reviewStarsPercentages[r]}
                                      style={{
                                        width: '150px',
                                        marginLeft: '10px',
                                      }}
                                    />
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                      <Box className="text-end">
                        <Typography
                          variant="h5"
                          className="fs-40 fw-bold font-skmodernist"
                        >
                          {Number(rating).toFixed(1)}
                        </Typography>
                        <Rating
                          name="average-rating"
                          value={rating}
                          precision={0.1}
                          readOnly
                        />
                        <Typography
                          variant="subtitle1"
                          className="fs-14 font-skmodernist"
                        >
                          {reviews?.length} reviews
                        </Typography>
                      </Box>
                    </div>
                    <Divider
                      component="div"
                      className="mt-4 main-divider"
                      role="presentation"
                    />
                    {reviews &&
                      reviews?.map((f) => {
                        const duration = moment(f.createdAt).format(
                          'DD MMMM YYYY'
                        );
                        return (
                          <>
                            <ListItem
                              alignItems="flex-start"
                              className="review-list"
                            >
                              {
                                f?.reviewer_image_path ? (
                                  <ListItemAvatar>
                                  <Avatar
                                    alt={f.reviewer_image_path || 'Profile Image'}
                                    src={
                                      f.reviewer_image_path
                                        ? COMPANY_LOGO_BASE_URL +
                                          f.reviewer_image_path
                                        : require('../../assets/defaultProfileImage.png')
                                    }
                                    className={classes.avatar}
                                  />
                                </ListItemAvatar>
                                ) : (
                                  <ListItemAvatar>
                                  <DefaultLogo
                                      name={f?.reviewer_name || ''}
                                      showEdit={false}
                                      height={40}
                                      width={40}
                                      fontSize={15}
                                    />
                                  </ListItemAvatar>
                                )
                              }
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  variant="subtitle1"
                                  className="fs-16 text-darkgray font-skmodernist mb-1"
                                >
                                  {f.reviewer_name}
                                </Typography>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  className="mb-1"
                                >
                                  <Rating
                                    name="read-only"
                                    value={f?.review_star}
                                    readOnly
                                    size="small"
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className="fs-14 font-skmodernist text-lightgray"
                                    style={{ marginLeft: '8px' }}
                                  >
                                    {duration}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body2"
                                  className="fs-14 font-gotham text-gray"
                                >
                                  {f.review}
                                </Typography>
                                {/* code to display the review image */}
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  flexWrap="wrap"
                                >
                                  {f?.image_path &&
                                    f.image_path
                                      .split(',')
                                      .map((imageUrl, index) => (
                                        <img
                                          onClick={() =>
                                            handleViewImageClick(
                                              f?.image_path
                                                ?.split(',')
                                                .map(
                                                  (i) => UPLOAD_REVIEW_IMAGE + i
                                                )
                                            )
                                          }
                                          key={index}
                                          src={
                                            imageUrl.trim()
                                              ? UPLOAD_REVIEW_IMAGE +
                                                imageUrl.trim()
                                              : require('../../assets/elynker_default_image.jpg')
                                          }
                                          alt={`RvImg${index}`}
                                          style={{
                                            width: '100px',
                                            height: '100px',
                                            marginRight: '20px',
                                          }}
                                        />
                                      ))}
                                </Box>
                              </Box>
                            </ListItem>
                          </>
                        );
                      })}
                  </TabPanel>
                </Box>

                <Drawer
                  xs={12}
                  anchor="bottom"
                  open={isCallBackDrawerOpen}
                  onClose={toggleCallBackDrawer}
                  sx={{
                    '& .MuiDrawer-paper': {
                      height: 'auto',
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                    },
                  }}
                >
                  <div className="p-3">
                    <div className="d-flex justify-content-between mb-3">
                      <Typography
                        variant="h5"
                        align="left"
                        alignItems="center"
                        className="fs-18 mb-2 fw-bold font-skmodernist "
                        gutterBottom
                      >
                        Connect with {company_name}
                      </Typography>
                      <CloseIcon onClick={toggleCallBackDrawer} />
                    </div>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      onClick={handleCall}
                      className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      Call
                    </Button>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="mb-3 py-3 send-req-btn"
                      onClick={handleWhatsappCall}
                      rel="noopener noreferrer"
                    >
                      Send Requirement on WhatsApp
                    </Button>
                  </div>
                </Drawer>

                <Drawer
                  xs={12}
                  anchor="bottom"
                  open={isFeedBackDrawerOpen}
                  onClose={toggleFeedBackDrawer}
                  sx={{
                    '& .MuiDrawer-paper': {
                      height: 'auto',
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                    },
                  }}
                >
                  <div className="p-3">
                    <div className="d-flex justify-content-between mb-3">
                      <Typography
                        variant="h5"
                        align="left"
                        alignItems="center"
                        className="fs-18 mb-2 fw-bold font-skmodernist "
                        gutterBottom
                      >
                        Feedback
                      </Typography>
                      <CloseIcon onClick={toggleFeedBackDrawer} />
                    </div>

                    <div className="mb-4">
                      <Box className="text-center">
                        <Rating
                          name="read-only"
                          value={feedback?.feedbackRating}
                          onChange={(event, newValue) => {
                            addFeedback({ feedbackRating: newValue });
                            validateInput('feedbackRating', newValue);
                          }}
                          size="large"
                        />
                      </Box>
                    </div>
                    <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                      {errors?.feedbackRating}
                    </FormHelperText>

                    <div className=" mb-3">
                      <InputLabel
                        htmlFor="inputCompanyName"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Care to Share more
                      </InputLabel>
                      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                        <TextareaAutosize
                          id="inputComment"
                          placeholder="Type your feedback"
                          value={feedback?.feedbackDetail}
                          onChange={onChangeFeedbackDetails}
                          onPaste={onPasteFeedbackDetails}
                          minRows={3} // You can adjust the number of rows as needed
                          maxRows={2} // You can adjust the number of rows as needed
                        />
                      </FormControl>
                    </div>

                    <div className="mb-3">
                      {feedback?.images === undefined ||
                      feedback?.images === null ||
                      feedback?.images?.length === 0 ? (
                        <Grid md={6} lg={6} xs={12} className=" mb-3">
                          <InputLabel
                            htmlFor="inputCompanyName"
                            className="text-start fs-14 text-black font-skmodernist"
                          >
                            Upload Image (Max 5){' '}
                            <span className="text-muted fs-12">
                              (JPEG or PNG files only, max size 2 MB)
                            </span>{' '}
                          </InputLabel>

                          <input
                            style={{ display: 'none' }}
                            id="image"
                            ref={inputRef}
                            multiple
                            type="file"
                            accept="image/*"
                            onChange={changeHandler}
                          />

                          <Button
                            id="add_photo"
                            type="button"
                            fullWidth
                            variant="outlined"
                            name="add_photo"
                            value="add_photo"
                            className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                            onClick={handleImageClick}
                            sx={{
                              borderRadius: '6px',
                              textTransform: 'capitalize',
                            }}
                          >
                            <img
                              src={AddBtnIcon}
                              alt="add-btn-icon"
                              className="user-logo"
                            />
                          </Button>
                        </Grid>
                      ) : (
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <InputLabel
                              htmlFor="inputproduct"
                              className="text-start fs-14 text-black font-skmodernist"
                            >
                              Upload Image (Max 5) <br />
                              <span className="text-muted fs-12">
                                (JPEG or PNG files only)
                              </span>
                            </InputLabel>
                            <Typography
                              variant="body1"
                              align="left"
                              className="fs-14 text-primary fw-normal font-gotham"
                              onClick={toggleDrawer}
                              gutterBottom
                            >
                              {`View All (${feedback?.images.length})`}
                            </Typography>
                          </div>

                          <div className="upload-image-container my-1">
                            {feedback?.images &&
                              Array.from(feedback?.images)
                                ?.filter(
                                  (img) =>
                                    img instanceof File ||
                                    (typeof img === 'object' &&
                                      Object.keys(img).length !== 0)
                                )
                                ?.slice(0, 3)
                                ?.map((img, imgIdx) => {
                                  return (
                                    <div
                                      className="image-inner-field"
                                      key={imgIdx}
                                    >
                                      <img
                                        onClick={() =>
                                          handleViewImageClick(
                                            typeof img === 'string'
                                              ? img
                                              : URL.createObjectURL(img)
                                          )
                                        }
                                        src={
                                          typeof img === 'string'
                                            ? img
                                            : URL.createObjectURL(img)
                                        }
                                        alt="product-image"
                                        className="product-image"
                                      />
                                      <img
                                        src={ProductCloseIcon}
                                        alt="product-close-icon"
                                        className="product-close-icon"
                                        onClick={() => {
                                          deleteProdImage(imgIdx);
                                        }}
                                      />
                                    </div>
                                  );
                                })}

                            <div className="image-inner-field">
                              <input
                                style={{ display: 'none' }}
                                id="image"
                                ref={inputRef}
                                multiple
                                type="file"
                                accept="image/*"
                                onChange={changeHandler}
                              />
                              <Button
                                type="button"
                                fullWidth
                                variant="outlined"
                                name="add_more_photo"
                                value="add_more_photo"
                                className="add-button image-inner-field fs-15 font-skmodernist fw-bold"
                                sx={{
                                  borderRadius: '6px',
                                  textTransform: 'capitalize',
                                }}
                                onClick={handleImageClick}
                              >
                                <img
                                  src={AddBtnIcon}
                                  alt="add-btn-icon"
                                  className="user-logo"
                                />
                              </Button>
                            </div>
                          </div>

                          <Drawer
                            xs={12}
                            anchor="bottom"
                            open={isDrawerOpen}
                            onClose={toggleDrawer}
                            sx={{
                              '& .MuiDrawer-paper': {
                                height: '90%',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                              },
                            }}
                          >
                            <div className="p-3">
                              <div className="d-flex justify-content-between mb-3">
                                <Typography
                                  variant="h5"
                                  align="left"
                                  alignItems="center"
                                  className="fs-18 mb-2 fw-bold font-skmodernist "
                                  gutterBottom
                                >
                                  Photos
                                </Typography>
                                <CloseIcon onClick={toggleDrawer} />
                              </div>
                              <div className="product-grid mt-3">
                                {feedback.images &&
                                  Array.from(feedback.images)
                                    ?.filter(
                                      (img) =>
                                        img instanceof File ||
                                        (typeof img === 'object' &&
                                          Object.keys(img).length !== 0)
                                    )
                                    ?.map((img, imgIdx) => {
                                      return (
                                        <div
                                          className="image-inner-field"
                                          key={imgIdx}
                                        >
                                          <img
                                            alt="product-image"
                                            onClick={() =>
                                              handleViewImageClick(
                                                typeof img === 'string'
                                                  ? img
                                                  : URL.createObjectURL(img)
                                              )
                                            }
                                            className="product-image"
                                            src={
                                              typeof img === 'string'
                                                ? img
                                                : URL.createObjectURL(img)
                                            }
                                          />
                                          <img
                                            src={ProductCloseIcon}
                                            alt="product-close-icon"
                                            className="product-close-icon"
                                            onClick={() => {
                                              deleteProdImage(imgIdx);
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                              </div>
                            </div>
                          </Drawer>
                        </div>
                      )}
                    </div>

                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      onClick={onFeedbackSubmit}
                      className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      Submit
                    </Button>
                  </div>
                </Drawer>
              </form>
            </Grid>
          </Grid>
          <ImageDialog
            open={viewImagedialogOpen}
            onClose={handleViewImageCloseDialog}
            image={selectedImage}
          />
        </Container>
      </ThemeProvider>
    </>
  );
};
B2BDetailForm.propTypes = {
  userData: PropTypes.object,
  vendorsDetails: PropTypes.object,
  cover_image: PropTypes.string,
  company_name: PropTypes.string,
  about_company: PropTypes.string,
  created_by: PropTypes.string,
  products: PropTypes.array,
  address1: PropTypes.string,
  address2: PropTypes.string,
  address: PropTypes.string,
  social_links: PropTypes.array,
  whatsapp_number: PropTypes.string,
  contacted_members: PropTypes.string,
  reviews: PropTypes.array,
  image_path: PropTypes.string,
  postMemberContacted: PropTypes.func,
  id: PropTypes.string,
  categories: PropTypes.array,
  pushMessage: PropTypes.func,
  b2bDetails: PropTypes.object,
  addFeedback: PropTypes.func,
  removeFeedbackImages: PropTypes.func,
  saveFeedback: PropTypes.func,
  getFeedback: PropTypes.func,
  updateVendorUserData: PropTypes.func,
  rating: PropTypes.string,
  reviewStarsPercentages: PropTypes.string,
  getVendorDetails: PropTypes.func,
  user: PropTypes.object,
  rating_member_count: PropTypes.string,
  clearFeedBackFormData: PropTypes.func,
  vendorId: PropTypes.string,
  userId: PropTypes.string,
  uuid: PropTypes.string,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  seller_websites: PropTypes.array,
  name: PropTypes.string
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const b2bDetails = state.b2bDetails;
  const { vendorsDetails } = state.b2bVendorlisting;
  const vendorId = vendorsDetails?.userId;
  const userId = userData?.user?.id;
  const {
    id = null,
    cover_image = '',
    categories = [],
    reviewStarsPercentages,
    company_name = '',
    about_company = '',
    created_by = '',
    products = [],
    rating_member_count = 0,
    address1 = '',
    address2 = '',
    social_links = [],
    seller_websites = [],
    image_path = '',
    whatsapp_number = '',
    contacted_members = 0,
    reviews = [],
    rating = 0,
    user,
    uuid,
    name
  } = vendorsDetails;
  const { isLoading } = state.root;
  return {
    userData,
    vendorsDetails,
    cover_image,
    company_name,
    about_company,
    created_by,
    products,
    social_links,
    address1,
    address2,
    whatsapp_number,
    reviews,
    contacted_members,
    image_path,
    id,
    categories,
    b2bDetails,
    rating,
    reviewStarsPercentages,
    user,
    rating_member_count,
    vendorId,
    userId,
    uuid,
    isLoading,
    seller_websites,
    name
  };
};
export default connect(mapStateToProps, {
  postMemberContacted,
  getVendorDetails,
  pushMessage,
  addFeedback,
  removeFeedbackImages,
  getFeedback,
  saveFeedback,
  updateVendorUserData,
  clearFeedBackFormData,
  setLoading,
})(B2BDetailForm);
