import {
  DEMO_TYPE,
  UPDATE_ROOT_STATE,
  SHOW_LOADER,
  HIDE_LOADER,
  SEARCH_FROM,
} from '../types';

// DEMO ACTION BOILERPLATE
export const demoAction = (
  params = { prop: 'demoState', value: 'Hi there!' }
) =>
  async function (dispatch) {
    // API request or other handling
    dispatch({ type: DEMO_TYPE, payload: params });
  };

export const updateRootState = (state) =>
  function (dispatch) {
    dispatch({ type: UPDATE_ROOT_STATE, payload: state });
  };

export const loading = (bool) => {
  return bool
    ? {
        type: SHOW_LOADER,
        payload: bool,
      }
    : {
        type: HIDE_LOADER,
        payload: bool,
      };
};

export const setSearchFrom = (data) =>
  function (dispatch) {
    dispatch({ type: SEARCH_FROM, payload: data });
  };

export const clearSearchFrom = () =>
  function (dispatch) {
    dispatch({ type: SEARCH_FROM, payload: '' });
  };

export const setLoading = (loader) =>
  function (dispatch) {
    dispatch({ type: loader ? SHOW_LOADER : HIDE_LOADER, payload: loader });
  };
