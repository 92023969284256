import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
// import PropTypes from 'prop-types';
import {
	Container,
	Grid,
	// AppBar,
	// Toolbar,
	// IconButton,
	Typography,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as ElynkerShortLogo } from '../../assets/elynker-short-logo.svg';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import UserIcon from '../../assets/user-purple-icon.svg';
import PlanIcon from '../../assets/plan-icon.svg';
import PlanType from '../../assets/plan-type-icon.svg';
import RenewalDateIcon from '../../assets/renewal-date-icon.svg';
import AmountIcon from '../../assets/amount-icon.svg';
import StatusIcon from '../../assets/status-icon.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const theme = createTheme();

const ViewSubscriptionForm = ({ viewSubscriptionDetail, orderData }) => {
	const history = useHistory();
	// useEffect(() => {
	// 	console.log('viewSubscriptionDetail', viewSubscriptionDetail);
	// }, [viewSubscriptionDetail]);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2 d-flex align-items-center">
							<ArrowBackIosIcon
								onClick={() => {
									history.goBack();
								}}
							/>
							<ElynkerLogoComponent>
								<ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
							</ElynkerLogoComponent>
						</div>
						<Divider
							component="div"
							className="main-divider mb-3"
							role="presentation"
						/>

						<form style={{ marginTop: '50px' }}>
							<div className="d-flex justify-content-center mb-3">
								<ElynkerShortLogo width={'112px'} height={'112px'} />
							</div>
							<div className="text-center mb-4">
								<ElynkerLogo width={'82px'} height={'24px'} />
							</div>
							<div className="d-flex align-items-center justify-content-between my-3">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={PlanIcon}
											alt="user-logo"
											width={24}
											height={24}
											className="user-logo me-3"
										/>
									</div>
									<Typography
										className=" fs-15 font-skmodernist text-darkgrey fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										Plan
									</Typography>
								</div>

								<div>
									<Typography
										className=" fs-15 font-skmodernist text-black fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										{viewSubscriptionDetail?.plan}
									</Typography>
								</div>
							</div>
							<Divider component="div" className="mb-3" role="presentation" />

							<div className="d-flex align-items-center justify-content-between my-3">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={PlanType}
											alt="user-logo"
											width={24}
											height={24}
											className="user-logo me-3"
										/>
									</div>
									<Typography
										className=" fs-15 font-skmodernist text-darkgrey fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										Plan Type
									</Typography>
								</div>

								<div>
									<Typography
										className=" fs-15 font-skmodernist text-black fw-bold line-height"
										style={{
											whiteSpace: 'pre-line',
											textTransform: 'capitalize',
										}}
									>
										{viewSubscriptionDetail?.plan_type}
									</Typography>
								</div>
							</div>
							<Divider component="div" className=" mb-3" role="presentation" />

							<div className="d-flex align-items-center justify-content-between my-3">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={RenewalDateIcon}
											alt="user-logo"
											width={24}
											height={24}
											className="user-logo me-3"
										/>
									</div>
									<Typography
										className=" fs-15 font-skmodernist text-darkgrey fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										Renewal Date
									</Typography>
								</div>

								<div>
									<Typography
										className=" fs-15 font-skmodernist text-black fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										{viewSubscriptionDetail?.renewalDate}
									</Typography>
								</div>
							</div>
							<Divider component="div" className=" mb-3" role="presentation" />

							<div className="d-flex align-items-center justify-content-between my-3">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={AmountIcon}
											alt="user-logo"
											width={24}
											height={24}
											className="user-logo me-3"
										/>
									</div>
									<Typography
										className=" fs-15 font-skmodernist text-darkgrey fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										Amount
									</Typography>
								</div>

								<div>
									<Typography
										className=" fs-15 font-skmodernist text-black fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										{viewSubscriptionDetail?.amount}
									</Typography>
								</div>
							</div>
							<Divider component="div" className=" mb-3" role="presentation" />
							<div className="d-flex align-items-center justify-content-between my-3">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={StatusIcon}
											alt="user-logo"
											width={24}
											height={24}
											className="user-logo me-3"
										/>
									</div>
									<Typography
										className=" fs-15 font-skmodernist text-darkgrey fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										Status
									</Typography>
								</div>

								<div>
									<Typography
										className=" fs-15 font-skmodernist text-black fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										{viewSubscriptionDetail?.status ? 'Active' : 'Inactive'}
									</Typography>
								</div>
							</div>
							<Divider component="div" className=" mb-3" role="presentation" />
							<div className="d-flex align-items-center justify-content-between my-3">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={PlanIcon}
											alt="user-logo"
											width={24}
											height={24}
											className="user-logo me-3"
										/>
									</div>
									<Typography
										className=" fs-15 font-skmodernist text-darkgrey fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										Order ID
									</Typography>
								</div>

								<div>
									<Typography
										className=" fs-15 font-skmodernist text-black fw-bold line-height"
										style={{ whiteSpace: 'pre-line' }}
									>
										{orderData[orderData?.length-1]?.razorpay_order_id}
									</Typography>
								</div>
							</div>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

ViewSubscriptionForm.propTypes = {
	viewSubscriptionDetail: PropTypes.object,
	orderData: PropTypes.array
};

const mapStateToProps = (state) => {
	const { viewSubscriptionDetail, orderData } = state.Subscription;

	return { viewSubscriptionDetail, orderData };
};

// export default connect(mapStateToProps, {

// })(UpdateProfileForm);

export default connect(mapStateToProps, {})(ViewSubscriptionForm);

