import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Paper } from '@mui/material';
const ResponsiveImageCarousel = ({ images = [
    'https://via.placeholder.com/400x300?text=Image+1',
    'https://via.placeholder.com/400x300?text=Image+2',
    'https://via.placeholder.com/400x300?text=Image+3',
    'https://via.placeholder.com/400x300?text=Image+4',
    'https://via.placeholder.com/400x300?text=Image+5',
] }) => {
    return (
        <Carousel
            autoPlay={true}
            showThumbs={true}
            showArrows={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}>
            {images && images.map((image, index) => (
                <div key={index}>
                    <Paper elevation={3}>
                        <img src={image} alt={`Slide ${index}`} />
                    </Paper>
                </div>
            ))}

        </Carousel>
    );
};



ResponsiveImageCarousel.propTypes = {
    images: PropTypes.array,
};
export default ResponsiveImageCarousel;
