import { deleteSingleBanner, getBannersData, uploadBanner } from '../../../../../utils/APIs/endpoints';
import { STATUS_CODE_200, STATUS_CODE_201, STATUS_CODE_500 } from '../../../../../utils/constants';
import { FRL_BIO, GET_BANNER_DATA, REMOVE_BANNER_DATA, RESET_FRL_BIO_DATA } from '../types';


export const setFrlBio = (bio) =>
  function (dispatch) {
    dispatch({ type: FRL_BIO, payload: bio });
  };


export const getBannerData = (regId) =>
  async function (dispatch) {
    try {
      const response = await getBannersData(regId);

      if (response.status === STATUS_CODE_200) {
        dispatch({ type: GET_BANNER_DATA, payload: response?.data?.data });

      }
    } catch (error) {
      if (error?.response?.data?.statusCode === STATUS_CODE_500) {
        console.log(
          '[add-bio-banner-action] get banner Error code 500',
          error
        );
      }
      console.log(
        '[add-bio-banner-action] get banner Error',
        error
      );
    }
  };

export const uploadBannerData = (
  banner,
  banner_name,
  registrationId,
  successCallback,
  errorCallback,
) =>
  async function () {
    if (!banner) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('images', banner);
      formData.append('banner_name', banner_name);
      const response = await uploadBanner(registrationId, formData, (progressEvent) => {
        /* eslint-disable no-unused-vars */
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        /* eslint-enable no-unused-vars */
      });
      if (response.status === STATUS_CODE_201) {
        successCallback(response.data);
      }
    } catch (error) {
      if (error?.response?.data?.error)
        errorCallback(error?.response?.data?.error);
      console.log(
        '[upload-banner-action] Uploading banner Error',
        error
      );

    }
  };

export const deleteBanner = (bannerId,
  onDeleteSuccess,
  errroCallback) =>
  async function (dispatch) {
    try {
      const response = await deleteSingleBanner(bannerId);
      if (response.status === STATUS_CODE_201) {
        dispatch({ type: REMOVE_BANNER_DATA, payload: response.data })
        onDeleteSuccess(response.data);
      } else {
        errroCallback(response.data);
      }
    } catch (err) {
      console.log(
        '[add-bio-action-action] banner delete Error',
        err
      );
      errroCallback(err);
    }
  };

export const resetFLAddBioData = () =>
  function (dispatch) {
    dispatch({ type: RESET_FRL_BIO_DATA });
  };