import {
  COMPANY_NAME,
  RESET_COMPANY_DATA,
  ABOUT_COMPANY,
  RESET_B2B_COMPANY_DATA,
  SET_ADDITIONAL_DETAILS1,
  SET_ADDITIONAL_DETAILS2,
  SET_ADDITIONAL_DETAILS3
} from '../types';

const INITIAL_STATE = {
  companyName: '',
  aboutCompany: '',
  additionalDetails1: "",
  additionalDetails2: "",
  additionalDetails3: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMPANY_NAME:
      return { ...state, companyName: action.payload };
    case ABOUT_COMPANY:
      return { ...state, aboutCompany: action.payload };
    case SET_ADDITIONAL_DETAILS1:
      return { ...state, additionalDetails1: action.payload };
    case SET_ADDITIONAL_DETAILS2:
      return { ...state, additionalDetails2: action.payload };
    case SET_ADDITIONAL_DETAILS3:
      return { ...state, additionalDetails3: action.payload };
    case RESET_COMPANY_DATA:
      return INITIAL_STATE;
    case RESET_B2B_COMPANY_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
