import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput, TextareaAutosize, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useValidation from '../../hooks/useValidation';


const AddCertificationModal = ({ open, onClose, certName, handleCertNameChange, certDesc , handleCertDescChange, certDate, setCertDate, saveCertificate, isEditing }) => {

    const { errors } = useValidation();
   

    const handleCancel = () => {
        onClose(true); // Close the modal
    };



    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="custom-border-header pb-0">
                <Box className="position-relative d-flex justify-content-between">
                    <Typography className="fs-22 fw-bold font-skmodernist" >
                        {isEditing ? "Add" : "Edit"} Certification
                    </Typography>
                    <Box >
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="row">


                    <div className="mb-3">
                        <InputLabel
                            htmlFor="inputEducation"
                            className="text-start fs-14 text-black font-skmodernist"
                        >
                            Name <span className="text-mandatory">*</span>
                        </InputLabel>
                        <FormControl
                            fullWidth
                            sx={{ my: 1 }}
                            variant="outlined"
                        >
                            <OutlinedInput
                                id="inputEducation"
                                type="text"
                                placeholder="Enter name"
                                autoFocus
                                fullWidth
                                margin="none"
                                value={certName}
                                onChange={handleCertNameChange}
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />
                        </FormControl>
                        <FormHelperText
                            sx={{ color: 'red' }}
                            id="email-helper-text"
                        >
                            {errors.flAddress2}
                        </FormHelperText>
                    </div>
                </div>

                <div className=" mb-3">
                    <InputLabel
                        htmlFor="inputCompanyName"
                        className="text-start fs-14 text-black font-skmodernist"
                    >
                        Description
                    </InputLabel>

                    <FormControl
                        fullWidth
                        sx={{ my: 1 }}
                        variant="outlined"
                    >
                        <TextareaAutosize
                            id="inputAdditionalInfo"
                            placeholder="Enter description"
                            minRows={3} // You can adjust the number of rows as needed
                            maxRows={2} // You can adjust the number of rows as needed
                            value={certDesc}
                            onChange={handleCertDescChange}
                            maxLength={500}
                        />
                    </FormControl>
                </div>


                <div className="mb-5 ">
                    <InputLabel
                        htmlFor="dateOfBirth"
                        className="text-start fs-14 text-black font-skmodernist"
                    >
                        Issued on
                    </InputLabel>
                    <LocalizationProvider
                        className="test-0"
                        dateAdapter={AdapterMoment}
                    >
                        <DemoContainer
                            className="test-1"
                            components={['DatePicker']}
                        >
                            <DatePicker
                                className="test-2"
                                format="DD-MM-YYYY"
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                )}
                                value={certDate}
                                onChange={(newValue) => setCertDate(newValue)}
                                disableFuture
                            />
                            {/* <DesktopDatePicker  /> */}
                            {/* <MobileDatePicker /> */}
                        </DemoContainer>
                    </LocalizationProvider>
                </div>








            </DialogContent>
            <DialogActions className="modal-footer">
                <Button onClick={handleCancel} className="btn-cancel me-2">
                    Cancel
                </Button>
                <Button onClick={saveCertificate} className="btn-save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddCertificationModal.propTypes = {
    open: PropTypes.bool.isRequired, // 'open' prop should be a boolean and is required
    onClose: PropTypes.func.isRequired,
    certName: PropTypes.string,
    handleCertNameChange: PropTypes.func,
    certDesc: PropTypes.string,
    handleCertDescChange: PropTypes.func,
    certDate: PropTypes.func,
    setCertDate: PropTypes.string,
    saveCertificate: PropTypes.func,
    isEditing: PropTypes.bool
};

export default AddCertificationModal;