import { RESET_TEMPLATE_DATA, SET_PAGE_DATA_FROM_KEY, SET_TEMPLATE_RENDER } from '../types';

const INITIAL_STATE = {
	set_template: '',
	pageContent: [],
};

const templatePageReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_TEMPLATE_RENDER:
			return {
				...state,
				set_template: action.payload,
			};
		case SET_PAGE_DATA_FROM_KEY:
			return {
				...state,
				pageContent: action.payload,
			};
		case RESET_TEMPLATE_DATA:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default templatePageReducer;
