// B2B OFFERED SERVICES FORM TYPES
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const SET_OFFERED_SERVICES_LOADING = 'SET_OFFERED_SERVICES_LOADING';
export const SET_LVL1_CATEGORIES = 'SET_LVL1_CATEGORIES';
export const SET_FLANCER_LVL1_CATEGORIES = 'SET_FLANCER_LVL1_CATEGORIES';
export const SET_LVL2_CATEGORIES = 'SET_LVL2_CATEGORIES';
export const SET_LVL3_CATEGORIES = 'SET_LVL3_CATEGORIES';
export const SET_SELECTED_LVL1_CATEGORIES = 'SET_SELECTED_LVL1_CATEGORIES';
export const SET_BUSINESS_TYPE = 'SET_BUSINESS_TYPE';
export const SET_SELECTED_COVER_IMAGE = 'SET_SELECTED_COVER_IMAGE';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const SET_WEB_ADD_PRODUCT_TYPE = 'SET_WEB_ADD_PRODUCT_TYPE';
export const RESET_OFFERED_SERVICE_DATA = 'RESET_OFFERED_SERVICE_DATA';
export const SET_BULK_PRODUCT = 'SET_BULK_PRODUCT';
