import { SET_ALL_JOBS,
     SAVE_JOB_SERCH_TEXT, 
     SEARCH_JOBS,
     SET_JOBS_ALL_CITY,
     SET_FILTER_JCITY,
     SET_FILTER_JEXP,
     SET_JOBS_ALL_CATEGORY,
     SET_FILTER_JCATEGORY,
     SET_FILTER_JSORT,
     SET_JOB_FILTER_CITY,
     RESET_JOB_CITY_FILTER,
     RESET_ALL_JOBS_DATA,
     SET_JOBS_ALL_EXP
     } from "../types";


const INITIAL_STATE = {
    searchJobText: 'Job',
    jobs: [],
    jobsDetails: [],
    allJCity: undefined,
    filterJCity: [],
    allJCategory: [],
    saveFilterCategory: [],
    allJMinExp:[],
    saveFilterMinExp: [],
    saveFilterCity: [],
    saveFilterSort: {},
  };

  export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ALL_JOBS :
        return {
            ...state,
            jobs: action.payload
        };
        case SEARCH_JOBS :
            return {
                ...state
            };
        case SET_JOBS_ALL_CITY : // save full array of city
            return {
                ...state,
                allJCity: action.payload
            };
        case SET_FILTER_JCITY : //set filter array of city
            return {
                ...state
            };
        case SET_JOBS_ALL_EXP :
            return {
                ...state,
                allJMinExp: action.payload
            }
        case SET_FILTER_JEXP :
            return {
                ...state,
                saveFilterMinExp: action.payload
            };
        case SET_JOBS_ALL_CATEGORY:
            return {
                ...state,
                allJCategory:  action.payload
            }
        case SET_FILTER_JCATEGORY :
            return {
                ...state,
                saveFilterCategory: action.payload
            };
        case SET_JOB_FILTER_CITY :
            return {
                ...state,
                saveFilterCity: action.payload
            };
        case SET_FILTER_JSORT :
            return {
                ...state,
                saveFilterSort: action.payload
            };
        case RESET_JOB_CITY_FILTER :
            return {
                ...state
            };
        case RESET_ALL_JOBS_DATA :
            return {
                ...state
            };
        case SAVE_JOB_SERCH_TEXT:
            return {
                ...state,
                searchJobText: action.payload

            }
        default:
            return state;
    }
}