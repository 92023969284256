import { getHomepageJob } from "../../../../utils/APIs/endpoints"
import { GET_HOMEPAGE_JOBS_ACTIVE } from "../types";

export const getHomepageJobs = () => async function (dispatch) {
    try {
        const response = await getHomepageJob();
        if(response.status === 200) {
            dispatch({type: GET_HOMEPAGE_JOBS_ACTIVE, payload: response.data?.data || []})
        }

    } catch (error) {
        console.log('Error Fetching getHomepageJobs', error);
    }
}
