import {
  SET_COMPANY_LOGO,
  SET_COMPANY_LOGO_UPLOAD_PROGRESS,
  SET_COVER_IMG_UPLOAD_PROGRESS,
  SET_LOCATION_COORDS,
  SET_PROFILE_DETAILS_ADDRESS_1,
  SET_PROFILE_DETAILS_ADDRESS_2,
  SET_PROFILE_DETAILS_CITY,
  SET_PROFILE_DETAILS_STATE,
  SET_SELECTED_COVER_IMAGE,
  SET_SOCIAL_DATA_OBJECT,
  SET_SOCIAL_LINKS,
  SET_ALL_CITY,
  SET_ALL_STATE,
  RESET_PROFILE_DETAILS_DATA,
  SET_SOCIAL_NAME,
  SET_SOCIAL_MEDIA_MASTER,
  FILTERED_CITY_DATA,
  SET_SELECTED_WHATSAPP,
  SET_PROFILE_DETAILS_AREANAME,
  SET_PROFILE_DETAILS_PINCODE,
  SET_DELETED_SOCIAL_LINK,
  RESET_DELETED_SOCIAL_LINK,
} from '../types';

const INITIAL_STATE = {
  companyLogo: null,
  // locationCoords: { latitude: null, longitude: null },
  locationCoords: '',
  socialLinks: [],
  socialMediaMaster: [],
  selectedCoverImage: null,
  coverImgUploadProgress: 0.0,
  companyLogoUploadProgress: 0.0,
  address1: '',
  city: null,
  whatsapp: null,
  states: null,
  allCity: [],
  allState: [],
  address2: null,
  socialName: null,
  socialData: { social_name: '', social_url: '' },
  filterCity: [],
  areaname: null,
  pincode: null,
  delSocialLinks: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_LOGO:
      return { ...state, companyLogo: action.payload };
    case SET_LOCATION_COORDS:
      return { ...state, locationCoords: action.payload };
    case SET_SOCIAL_LINKS: {
      console.log('SET_SOCIAL_LINKS 1234');

      return { ...state, socialLinks: action.payload };
    }
    case SET_COVER_IMG_UPLOAD_PROGRESS:
      return { ...state, coverImgUploadProgress: action.payload };
    case SET_SELECTED_COVER_IMAGE:
      return { ...state, selectedCoverImage: action.payload };
    case SET_COMPANY_LOGO_UPLOAD_PROGRESS:
      return { ...state, companyLogoUploadProgress: action.payload };
    case SET_PROFILE_DETAILS_ADDRESS_1:
      return { ...state, address1: action.payload };
    case SET_SELECTED_WHATSAPP:
      return { ...state, whatsapp: action.payload };
    case SET_PROFILE_DETAILS_ADDRESS_2:
      return { ...state, address2: action.payload };
    case SET_PROFILE_DETAILS_CITY:
      return { ...state, city: action.payload };
    case SET_PROFILE_DETAILS_STATE:
      return { ...state, states: action.payload };
    case SET_SOCIAL_NAME:
      return { ...state, socialName: action.payload };
    case SET_SOCIAL_DATA_OBJECT:
      return { ...state, socialData: action.payload };
    case SET_ALL_CITY:
      return { ...state, allCity: action.payload };
    case SET_ALL_STATE:
      return { ...state, allState: action.payload };
    case SET_SOCIAL_MEDIA_MASTER:
      return { ...state, socialMediaMaster: action.payload };
    case FILTERED_CITY_DATA:
      return { ...state, filterCity: action.payload };
    case SET_PROFILE_DETAILS_AREANAME:
      return { ...state, areaname: action.payload };
    case SET_PROFILE_DETAILS_PINCODE:
      return { ...state, pincode: action.payload };
    case SET_DELETED_SOCIAL_LINK:
      return {
        ...state,
        delSocialLinks: [...state.delSocialLinks, action.payload],
      };
    case RESET_DELETED_SOCIAL_LINK:
      return {
        ...state,
        delSocialLinks: [],
      };
    case RESET_PROFILE_DETAILS_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
