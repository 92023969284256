import { SET_SELECTED_VCATEGORY, SET_SELECTED_VCATEGORY_UUID } from "../types";


/* eslint-disable no-unused-vars */
export const setSelectedVCategory = (cate) => async (dispatch) => {
    try {
        dispatch({ type: SET_SELECTED_VCATEGORY, payload: cate });
    } catch (error) {
      dispatch({ type: SET_SELECTED_VCATEGORY, payload: null });
    }
  };

export const setSelectedVCategoryUUID = (uuid) => async (dispatch) => {
  try {
    dispatch({ type: SET_SELECTED_VCATEGORY_UUID, payload: uuid });
  } catch(error){
    dispatch({ type: SET_SELECTED_VCATEGORY_UUID, payload: null });
  }
}