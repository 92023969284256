import { SAVE_JOB_DATA, SET_RATING_DATA } from "../types";

const INITIAL_STATE = {
    searchJobText: 'Job',
    job: {},
    ratings: {}
  };

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_JOB_DATA :
            return {
                ...state,
                job: action.payload
            };
        case SET_RATING_DATA:
            return {
                ...state,
                ratings: action.payload
            }
        default:
            return state;
    }
}