import { deleteJOBApi, getApplicantCount, getJOBAPI } from "../../../../utils/APIs/endpoints";
import { APPLICANT_COUNT, SAVE_ALL_JOBS, SET_DELETE_JOB_POST_POPUP_VISIBLE, SET_VIEW_JOB } from "../types";

export const getAllJobs = (successCallback = () => {},
errorCallback = () => {}) =>
async function (dispatch) {
    try {
        const response = await getJOBAPI();
        if (response.status === 200) {
            await dispatch({
                type: SAVE_ALL_JOBS,
                payload: response.data.data,
            });
            successCallback(response.data.data);
        }
    } catch (error) {
        console.log(
            '[create-job-action] getFreelancerCategories Error',
            error
        );
        if(error?.response?.status == 404){
            await dispatch({
                type: SAVE_ALL_JOBS,
                payload: [],
            });
        }
        errorCallback(error);
    }
};

export const deleteSingleJob = (jobId, successCallback = () => {},
errorCallback = () => {}) => 
async function(dispatch) {
    try {
        const response = await deleteJOBApi(jobId);
        if (response.status === 200) {
            console.log("Job deleted successfully");
            dispatch(getAllJobs());
            successCallback(response.data);
        }
    } catch (error) {
        console.log(
            '[create-job-action] getFreelancerCategories Error',
            error
        );
        errorCallback(error);
    }
}

export const setDeletePopupVisible = (popupVisible) => function(dispatch) {
    dispatch({type: SET_DELETE_JOB_POST_POPUP_VISIBLE, payload: popupVisible})
}

export const setViewJobData = (view) => function(dispatch) {
    dispatch({type: SET_VIEW_JOB, payload: view})
}


export const getAllApplicantData = (id,successCallback = () => {},
errorCallback = () => {}) =>
async function (dispatch) {
    try {
        const response = await getApplicantCount(id);
        if (response.status === 200) {
            await dispatch({
                type: APPLICANT_COUNT,
                payload: response.data.data,
            });
            successCallback(response.data.data);
        }
    } catch (error) {
        console.log(
            '[create-job-action] get Applicant count Error',
            error
        );
        if(error?.response?.status == 404){
            await dispatch({
                type: APPLICANT_COUNT,
                payload: [],
            });
        }
        errorCallback(error);
    }
};