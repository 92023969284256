import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import '../src/utils/APIs/data/OTPData';
import { Helmet } from 'react-helmet';
import { SEO_APP_DESC, SEO_APP_TITLE, SEO_FAV_ICON_URL, SEO_PAGE_URL } from './utils/constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Helmet>
			<meta charSet="utf-8" />
			<title>{SEO_APP_TITLE}</title>
			<link rel="canonical" href={SEO_PAGE_URL} />
			<meta name="description" content={SEO_APP_DESC} />
			<meta property="og:title" content={SEO_APP_TITLE} />
			<meta property="og:description" content={SEO_APP_DESC} />
			<meta property="og:image" content={SEO_FAV_ICON_URL} />
			<meta property="og:url" content={SEO_PAGE_URL} />
			<meta property="og:type" content="website" />
		</Helmet>
		<App />
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

