import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Container, Grid, Typography, Button, Backdrop, CircularProgress, Menu, MenuItem, Box, } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as FulfilledIcon } from '../../assets/fulfilled-icon.svg';
import { ReactComponent as HoldIcon } from '../../assets/hold-icon.svg';
import { ReactComponent as MoreIcon } from '../../assets/horizontal-more-icon.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteSingleJob, getAllApplicantData, getAllJobs, setDeletePopupVisible } from './redux/actions/create-jobposted-action';
import { loading } from '../../store/actions/rootActions';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
// import JobPostCardItem from '../../components/JobPostCardItem/JobPostCardItem';
import { DeleteConfirmationPopup } from '../../components/ActionPopup';
import { putJOBPosting, resetCreateJobData, saveJobLocation, setJobActionType, setSingleJobData } from '../CreateJobRequirement/redux/actions/create-job-action';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import Responsive from '../../utils/responsive';


const theme = createTheme();
const categoryText = "Job Posted";

const JobPostedForm = ({
    getAllJobs,
    loading,
    isLoading,
    userData,
    // allJobs,
    // JobPostCardItem,
    deleteSingleJob,
    setJobActionType,
    setSingleJobData,
    resetCreateJobData,
    singleJob,
    putJOBPosting,
    pushMessage,
    applicant_count,
    getAllApplicantData,
    saveJobLocation,
    deletePopup,
    setDeletePopupVisible,
}) => {
    const history = useHistory();
    const [jobId, setJobId] = useState();
    // const [viewJob, setViewJob] = useState('');
    const successCallback = () => {
        loading(false);
    }
    const errorCallback = () => {
        loading(false);
    }
    const handleDelete = async () => {
        console.log('Item deleted');
        // loading(true);
        await deleteSingleJob(jobId, async () => {
            resetCreateJobData()
            setAnchorEl(null)
            pushMessage("Job Deleted Successfully")
            await setDeletePopupVisible(false);
            await getAllJobs(successCallback, errorCallback);
            getAllApplicantData(userData?.registration?.id, successCallback, errorCallback)
        });
        // loading(false);
        setDeletePopupVisible(false);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, data) => {
        console.log("handleClick data :", data);
        setSingleJobData(data);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const deleteJob = async (_jobId) => {
        console.log("JOBID data :", _jobId);
        setJobId(_jobId);
        setDeletePopupVisible(true);
        // >>>>>>> development
    };

    const editJob = (data) => {
        // if (typeof data?.subCategoryId === 'string') {
        //     data.category_id = Number(data?.category_id);
        // }
        console.log("editjob data:", data);
        setJobActionType('Edit');
        saveJobLocation(data?.job_location)
        setSingleJobData(data);
        history.push('/create-job-requirement');
    };

    const ViewJobData = (data) => {
        // if (typeof data?.subCategoryId === 'string') {
        //     data.category_id = Number(data?.category_id);
        // }
        setJobActionType('view')
        saveJobLocation(data?.job_location)
        setSingleJobData(data);
        history.push('/create-job-requirement');
    };
    useEffect(() => {
        loading(true);
        getAllJobs(successCallback, errorCallback);
        loading(false);
    }, []);

    // console.log("registration id data :", userData?.registration?.id);
    console.log("applicant dtaa id data :", applicant_count);
    // console.log("application data :", allJobs)
    // applicant_count

    useEffect(() => {
        loading(true);
        getAllApplicantData(userData?.registration?.id, successCallback, errorCallback)
        loading(false);
    }, []);

    const successJobPostCallback = async () => {
        loading(false);
        // clear data
        resetCreateJobData();
        // show Success dialog

        //redirect to prev page
        // history.goBack();
        await getAllJobs(successCallback, errorCallback);
        getAllApplicantData(userData?.registration?.id, successCallback, errorCallback);
        pushMessage("Job updated successfully");
    }
    const errorJobPostCallback = () => {
        loading(false);
        pushMessage("Job posting error");
    }

    const handleStatusSubmit = async (status) => {
        const jsonData = { status: status }

        await putJOBPosting(
            singleJob.id,
            jsonData,
            successJobPostCallback,
            errorJobPostCallback,
            setAnchorEl(null)
        );

    }

    const handleBackButton = () => {
        history.replace("/")
    }


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                {isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 'auto' }}>
                    <Grid item xs={12}>
                        <div className="my-3 ms-2 d-flex align-items-center">
                            <ArrowBackIosIcon onClick={() => {
                                resetCreateJobData();
                                history.push("/profile")
                            }} />
                            <ElynkerLogoComponent>
                                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} onClick={() => { history.push("/homepage") }} />
                            </ElynkerLogoComponent>
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" />
                        <Typography
                            variant="h4"
                            align="left"
                            alignItems="center"
                            className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
                            gutterBottom style={{ whiteSpace: 'pre-line' }}>
                            {categoryText}
                        </Typography>
                        <Card className="job-posted-card mb-3">
                            <CardContent className="job-posted-content">
                                <Typography variant="h5" className="fs-16 text-white mb-2 fw-bold font-skmodernist" component="div">
                                    Attract top talent. Post your job now!
                                </Typography>
                                <Typography variant="body2" className="fs-14 text-white mb-3 font-gotham">
                                    Expand your horizons and attract top talent by posting your job today!
                                </Typography>
                                <Button variant="contained" className="add-product-btn" onClick={() => {
                                    resetCreateJobData();
                                    history.push('/create-job-requirement')
                                }}>
                                    Post a Job
                                </Button>
                            </CardContent>
                        </Card>
                        <List >
                            <Grid
                                container
                                columnSpacing={3}
                                rowSpacing={1.5}
                                alignItems="start"
                            >
                                {applicant_count?.map((item) => (
                                    <Grid item md={6} lg={6} xs={12} key={item.id} >
                                        <ListItem className="list-item"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                ViewJobData(item) }}
                                        >
                                            <Card className="card d-flex">
                                                <CardContent className="content">
                                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                                        <Typography variant="h5" className="fs-16 text-black  fw-bold font-skmodernist" component="div">
                                                            {item.title}

                                                        </Typography>
                                                        {item?.status?.toLowerCase() !== "fulfilled" && <div className="actions mt-0">
                                                            <IconButton
                                                                className="cat-edit-icon p-0"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleClick(e, item)
                                                                }}
                                                            // onClick={() => {
                                                            //     editJob(item);
                                                            // }}
                                                            >
                                                                <MoreIcon />
                                                            </IconButton>
                                                            {/* <IconButton className="cat-delete-icon p-0" onClick={() => deleteJob(item.id)}>
                                                        <DeleteIcon />
                                                    </IconButton> */}
                                                        </div>}
                                                    </div>

                                                    {/* <Typography variant="body2" className="fs-13 text-grey mb-2 font-gotham">
                                                {item.description}
                                            </Typography> */}
                                                    <div className='d-flex justify-content-between align-items-end' >
                                                        <div>
                                                            <Typography variant="h5" className="fs-13 text-darkgrey font-skmodernist">
                                                                Upto
                                                            </Typography>

                                                            <Typography variant="h5" className="fs-15 text-secondary fw-bold font-skmodernist">
                                                            ₹ {item.salary_offered} <span className="fs-13 text-grey fw-light">/ Month</span>
                                                            </Typography>
                                                        </div>
                                                        <Typography variant="h5" className="fs-13 text-secondary fw-bold font-skmodernist" 
                                                         sx={{cursor:"pointer"}}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (item?.job_applications <= 0) {
                                                                pushMessage("There is no Applicant applied for this Job.")
                                                            } else {
                                                                history.push("/job-applications", {
                                                                    jobData: item
                                                                })
                                                            }

                                                        }}>
                                                            {item?.job_applications} Applications
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            // className="fs-13 text-fulfilled fw-bold mb-0 font-skmodernist"
                                                            className={item?.status?.toLowerCase() === "active" ? 'fs-13 text-pending fw-bold mb-0 font-skmodernist text-capitalize' : (item?.status.toLowerCase() === 'fulfilled' ? 'fs-13 text-fulfilled fw-bold mb-0 font-skmodernist text-capitalize' : (item?.status.toLowerCase() === 'hold' ? 'fs-13 text-hold fw-bold mb-0 font-skmodernist text-capitalize' : 'fs-13 text-hold fw-bold mb-0 font-skmodernist text-capitalize'))}
                                                        >

                                                            {item?.status}
                                                        </Typography>
                                                    </div>

                                                </CardContent>
                                            </Card>
                                        </ListItem>
                                    </Grid>

                                ))}
                            </Grid>
                        </List>

                        {
                            Responsive(
                                <></>,
                                <>
                                    <Box sx={{marginTop: '80px'}}>
                                        <Button
                                            onClick={handleBackButton}
                                            variant="text"
                                            type="button"
                                            className="btn-web-back"
                                        >
                                            Back
                                        </Button>
                                    </Box>

                                </>

                            )
                        }


                    </Grid>
                </Grid>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className="job-menu-popper"
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '17ch',
                            boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.12)'
                        },
                    }}
                >
                    <MenuItem className="job-popper-list" onClick={() => editJob(singleJob)}>
                        <EditIcon />
                        <Typography className="fs-16 font-gotham text-darkgrey ms-2"> Edit</Typography>
                    </MenuItem>
                    <MenuItem className="job-popper-list" onClick={() => { handleStatusSubmit("fulfilled") }}>
                        <FulfilledIcon />
                        <Typography className="fs-16 font-gotham text-darkgrey ms-2">Fulfilled</Typography>
                    </MenuItem>
                    {singleJob?.status == "hold" && <MenuItem className="job-popper-list" onClick={() => { handleStatusSubmit("active") }}>
                        <FulfilledIcon />
                        <Typography className="fs-16 font-gotham text-darkgrey ms-2">Active</Typography>
                    </MenuItem>}
                    {singleJob?.status !== "hold" && <MenuItem className="job-popper-list" onClick={() => { handleStatusSubmit("hold") }}>
                        <HoldIcon />
                        <Typography className="fs-16 font-gotham text-darkgrey ms-2">Hold</Typography>
                    </MenuItem>}
                    <MenuItem className="job-popper-list" onClick={() => {
                        console.log("deletd id is :", singleJob?.id);
                        deleteJob(singleJob?.id);
                    }}>
                        <DeleteIcon />
                        <Typography className="fs-16 font-gotham text-darkgrey ms-2"> Delete</Typography>
                    </MenuItem>
                </Menu>
                <DeleteConfirmationPopup
                    open={deletePopup}
                    onClose={() => setDeletePopupVisible(false)}
                    onConfirm={handleDelete}
                    message={'Are you sure you want to delete Job?'}
                />
            </Container>
        </ThemeProvider >
    );
};

JobPostedForm.propTypes = {
    loading: PropTypes.func,
    isLoading: PropTypes.bool,
    userData: PropTypes.object,
    allJobs: PropTypes.array,
    getAllJobs: PropTypes.func,
    // JobPostCardItem: PropTypes.func,
    deleteSingleJob: PropTypes.func,
    setJobActionType: PropTypes.func,
    setSingleJobData: PropTypes.func,
    resetCreateJobData: PropTypes.func,
    singleJob: PropTypes.object,
    pushMessage: PropTypes.func,
    putJOBPosting: PropTypes.func,
    applicant_count: PropTypes.object,
    getAllApplicantData: PropTypes.func,
    saveJobLocation: PropTypes.func,
    deletePopup: PropTypes.bool,
    setDeletePopupVisible: PropTypes.func,
};

const mapStateToProps = (state) => {
    // console.log(state?.createJobReducer);
    const { allJobs, applicant_count, deletePopup } = state.jobListReducer
    const { userData } = state.signin;
    const { isLoading } = state.root;
    const {
        singleJob,
    } = state.createJobReducer
    return {
        allJobs,
        userData,
        isLoading,
        singleJob,
        applicant_count,
        deletePopup
    };
};

export default connect(mapStateToProps, {
    getAllJobs,
    loading,
    deleteSingleJob,
    setJobActionType,
    setSingleJobData,
    resetCreateJobData,
    putJOBPosting,
    pushMessage,
    getAllApplicantData,
    saveJobLocation,
    setDeletePopupVisible,
})(JobPostedForm);
