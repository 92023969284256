/*eslint no-empty: "error"*/
import { loading } from '../../../../store/actions/rootActions';
import {
  getVenderListApi,
  postEnquiryApi,
  getEnquiryApi,
  getVendorDetailsApi,
  postMembersContactedApi,
  getFreelancerVendorDetailsApi,
  getSubCategoryVenderListApi,
  getVenderCityApi,
  getSubCategoryVenderCountApi,
  getCategoryVenderCountApi,
} from '../../../../utils/APIs/endpoints';
import { STATUS_CODE_200 } from '../../../../utils/constants';
import {
  SET_ALL_VENDORS,
  SET_VENDORS_DETAILS,
  SET_ENQUIRY,
  SET_VENDOR_CITY,
  SET_FILTER_VCITY,
  SAVE_FL_DETAILS,
  SET_FILTER_RATING,
  SET_FILTER_CATEGORY,
  SET_FILTER_CITY,
  SET_FILTER_NEARME,
  SET_FILTER_SORT,
  RESET_CITY_FILTER,
  RESET_ALL_VENDOR_DATA,
  MARK_NEAR_BY_CLICK,
  SET_SUBCATEGIRY_TREE_ATTRIBUTES,
  SET_HAS_MOREPAGE,
} from '../types';

export const getVendorList =
  (
    type,
    parentUUID,
    params,
    currentPage,
    successCallback = () => {},
    errorCallback = () => {},
    pushMessage
  ) =>
  async (dispatch) => {
    try {
      const response = await getVenderListApi(
        type,
        parentUUID,
        params,
        currentPage
      );
      console.log('getVendorList', response.data.data);
      if (response?.status === STATUS_CODE_200) {
        dispatch({
          type: SET_ALL_VENDORS,
          payload: response.data.data,
        });
        // const filtered = response?.data?.cities?.filter((e) => e !== null);
        // dispatch({
        //   type: SET_VENDOR_CITY,
        //   payload: filtered,
        // });

        if (response.data.data.length === 0) {
          pushMessage('No Vendor is available for this Category');
        }
        successCallback();
      } else {
        setHasMorePage(false);
        dispatch({ type: SET_ALL_VENDORS, payload: [] });
        errorCallback();
      }
    } catch (error) {
      // console.log('getVendorList api error', error);
      pushMessage('No Vendor is available for this Category');
      setHasMorePage(false);
      dispatch({ type: SET_ALL_VENDORS, payload: [] });
      errorCallback(error); // Pass the error to the error callback
    }
  };


  export const getCategoryVendorCount =
  (
    type,
    parentUUID,
    params,
    currentPage,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
  async () => {
    try {
      const response = await getCategoryVenderCountApi(
        type,
        parentUUID,
        params,
        currentPage
      );
      if (response?.status === STATUS_CODE_200) {
        successCallback(response.data);
      } else {
        errorCallback();
      }
    } catch (error) {
      errorCallback(error);
    }
  };

export const getVendorCities =
  (type, parentUUID, successCallback = () => {}, errorCallback = () => {}) =>
  async (dispatch) => {
    try {
      const response = await getVenderCityApi(type, parentUUID);
      console.log('getVendorCities response', response.data.cities);

      if (response?.status === STATUS_CODE_200) {
        // const filtered = response?.data?.cities?.filter((e) => e !== null);
        dispatch({
          type: SET_VENDOR_CITY,
          payload: response?.data?.cities || [],
        });
        successCallback(response.data);
      } else {
        errorCallback();
      }
    } catch (error) {
      errorCallback(error);
      dispatch({
        type: SET_VENDOR_CITY,
        payload: [],
      });
    }
  };
export const getSubCategoryVendorList =
  (
    type,
    params,
    currentPage,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await getSubCategoryVenderListApi(
        type,
        params,
        currentPage
      );
      if (response?.status === STATUS_CODE_200) {
        dispatch({
          type: SET_ALL_VENDORS,
          payload: response.data.data,
        });
        const filtered = response?.data?.cities?.filter((e) => e !== null);
        if (params == null || params === '') {
          dispatch({ type: SET_VENDOR_CITY, payload: filtered });
        }
        successCallback(response.data);
      } else {
        // dispatch({ type: SET_ALL_VENDORS, payload: [] });
        // if (currentPage > 1) {
        //   setCurrentPage((prev) => prev - 1);
        // }
        dispatch({ type: SET_ALL_VENDORS, payload: [] });
        errorCallback();
      }
    } catch (error) {
      // console.log('getSubCategoryVendorList api error', error);
      // if (currentPage > 1) {
      //   setCurrentPage((prev) => prev - 1);
      // }
      dispatch({ type: SET_ALL_VENDORS, payload: [] });
      errorCallback(error);
    }
  };

  export const getSubCategoryVendorCount =
  (
    type,
    params,
    currentPage,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
  async () => {
    try {
      const response = await getSubCategoryVenderCountApi(
        type,
        params,
        currentPage
      );
      if (response?.status === STATUS_CODE_200) {
        successCallback(response.data?.data);
      } else {
        errorCallback();
      }
    } catch (error) {
      errorCallback(error);
    }
  };


export const getVendorDetails = (reg_detail) => async (dispatch) => {
  try {
    const response = await getVendorDetailsApi(reg_detail);
    if (response) {
      dispatch({ type: SET_VENDORS_DETAILS, payload: response });
    } else {
      dispatch({ type: SET_VENDORS_DETAILS, payload: null });
    }
    dispatch(loading(false));
  } catch (error) {
    dispatch({ type: SET_VENDORS_DETAILS, payload: [] });
    dispatch(loading(false));
  }
};
export const getFreelancerVendorDetails = (reg_detail) => async (dispatch) => {
  try {
    const response = await getFreelancerVendorDetailsApi(reg_detail);
    if (response) {
      dispatch({ type: SET_VENDORS_DETAILS, payload: response });
    } else {
      dispatch({ type: SET_VENDORS_DETAILS, payload: null });
    }
  } catch (error) {
    dispatch({ type: SET_VENDORS_DETAILS, payload: [] });
  }
};
export const postMemberContacted =
  (registrationId, member_phone) => async () => {
    try {
      const response = await postMembersContactedApi(
        registrationId,
        member_phone
      );
      if (response) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // empty
    }
  };

export const postEnquiry = (id, payload) => async (dispatch) => {
  try {
    dispatch(loading(true));
    const enquiry = await postEnquiryApi(id, payload);
    dispatch({ type: SET_ENQUIRY, payload: { isTrue: true, ...enquiry } });
    return enquiry;
  } catch (error) {
    dispatch({ type: SET_ENQUIRY, payload: [] });
    return false;
  } finally {
    dispatch(loading(false));
  }
};
export const getEnquiryById =
  (id, type, callback = () => {}) =>
  async () => {
    try {
      const enquiry = await getEnquiryApi(id, type);
      // console.log('getEnquiryById response', enquiry);
      callback(enquiry[0], true);
      return enquiry;
    } catch (error) {
      // console.log('getEnquiryById error', error);
      callback({}, false);
      return error;
    }
  };

export const setAllVCity = (city) => async (dispatch) => {
  dispatch({ type: SET_VENDOR_CITY, payload: city });
};

export const setFilterVCity = (city) => async (dispatch) => {
  dispatch({ type: SET_FILTER_VCITY, payload: city });
};
export const saveFLDetails = (Details) => async (dispatch) => {
  dispatch({ type: SAVE_FL_DETAILS, payload: Details });
};

export const setFilterRating = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_RATING, payload: data });
};

export const setFilterCategory = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_CATEGORY, payload: data });
};

export const setVendorFilterCity = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_CITY, payload: data });
};

export const setFilterNearMe = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_NEARME, payload: data });
};

export const setFilterSort = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_SORT, payload: data });
};

export const resetVendorFilterCity = () => async (dispatch) => {
  dispatch({ type: RESET_CITY_FILTER });
};
export const resetAllVendorData = () => async (dispatch) => {
  dispatch({ type: RESET_ALL_VENDOR_DATA });
};

export const setNearByClickState = (data) => async (dispatch) => {
  dispatch({ type: MARK_NEAR_BY_CLICK, payload: data });
};

export const setSubCategoryTreeAtrributes = (data) => async (dispatch) => {
  // console.log('setSubCategoryTreeAtrributes', data);

  dispatch({ type: SET_SUBCATEGIRY_TREE_ATTRIBUTES, payload: data });
};

export const resetEnquiryData = () => async (dispatch) => {
  dispatch({ type: SET_ENQUIRY, payload: {} });
};

export const setEnquiryData = (data) => async (dispatch) => {
  dispatch({ type: SET_ENQUIRY, payload: data });
};

export const setHasMorePage = (data) => async (dispatch) => {
  dispatch({ type: SET_HAS_MOREPAGE, payload: data });
};

// checked, setChecked
