export const GET_MANAGE_WEBSITE_DATA = 'GET_MANAGE_WEBSITE_DATA';
export const SAVE_USER_URL = 'SAVE_USER_URL';
export const SET_SUBDOMAIN = 'SET_SUBDOMAIN';
export const SET_MANAGE_WEBSITE_DATA = 'SET_MANAGE_WEBSITE_DATA';
export const EDIT_TESTIMONIAL_WEBSITE_DATA = 'EDIT_TESTIMONIAL_WEBSITE_DATA';
export const DELETE_TESTIMONIAL_WEBSITE_DATA =
  'DELETE_TESTIMONIAL_WEBSITE_DATA';
export const SET_TESTIMONIAL_WEBSITE_DATA = 'SET_TESTIMONIAL_WEBSITE_DATA';
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const MARK_SELECTED_PRODUCT_DATA = 'MARK_SELECTED_PRODUCT_DATA';
export const SET_SUB_DOMAINS = 'SET_SUB_DOMAINS';
export const SET_ALL_TESTIMONIAL_WEBSITE_DATA =
  'SET_ALL_TESTIMONIAL_WEBSITE_DATA';
export const RESET_TESTIMONIAL_WEBSITE_DATA = 'RESET_TESTIMONIAL_WEBSITE_DATA';
export const SET_DELETED_TESTIMONIAL_WEBSITE_DATA =
  'SET_DELETED_TESTIMONIAL_WEBSITE_DATA';
export const RESET_MANAGED_WEBSITE_DATA =
'RESET_MANAGED_WEBSITE_DATA';