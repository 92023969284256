import {
  getParentCategory,
  getUpdatedUser,
  getUser,
  putUpdateUser,
  putUserData,
  saveBuisnessCardImage,
} from '../../../../utils/APIs/endpoints';
import { SAVE_SIGNIN_DATA, UPDATE_REG_DATA, UPDATE_SIGNIN_DATA } from '../../../Otp/redux/types';
import { RESET_UPDATE_PROFILE_DATA, UPDATE_PROFILE_DETAILS } from '../type';

export const getUpdateUserDetails = (
  userId,
  accessToken,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      console.log('[user-action] getUpdateUserDetails');
      const response = await getUser(userId, accessToken);
      if (response.status === 200) {
        console.log('[user-action] updateUserDetails Success', response.data);
        dispatch({ type: UPDATE_PROFILE_DETAILS, payload: response.data });
        successCallback(response.data);
      } else {
        console.log('[user-action] updateUserDetails Error', response.data);
        errorCallback(response?.data?.message);
      }
    } catch (err) {
      console.log('[user-action] updateUserDetails Error', err);
      errorCallback();
    }
  };
export const postBuisnessCard =
  (BuisnessImg, posted_by, successCallback, errorCallback) => async () => {
    const formData = new FormData();
    formData.append('image', BuisnessImg);
    formData.append('registrationId', posted_by);
    try {
      const res = await saveBuisnessCardImage(formData);
      successCallback(res);
    } catch (error) {
      errorCallback();
    }
  };

export const putUpdateUserDetails = (
  userId,
  userData,
  oldUserData,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      console.log('[user-action] putUpdateUserDetails request', userData);
      const response = await putUserData(userId, userData);
      if (response.status === 200) {
        console.log(
          '[user-action] putUpdateUserDetails Success',
          response.data.data,
          userData
        );
        dispatch({ type: UPDATE_PROFILE_DETAILS, payload: userData });
        // let newUsrData = { ...oldUserData };
        let newUsrData = JSON.parse(JSON.stringify(oldUserData));
        newUsrData.user = { ...oldUserData.user, ...response.data };
        await dispatch({
          type: SAVE_SIGNIN_DATA,
          payload: newUsrData,
        });

        successCallback(newUsrData);
      } else {
        console.log(
          '[user-action] putUpdateUserDetails Error',
          response.data.data
        );
        errorCallback(response?.data?.message);
      }
    } catch (err) {
      console.log('[user-action] putUpdateUserDetails Error', err);
      errorCallback(err);
    }
  };
export const putUpdateAboutCompanyDetails = (
  userId,
  userData,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await putUpdateUser(userId, userData);
      if (response.status === 200) {
        console.log(
          '[user-action] putUpdateAboutCompanyDetails Success',
          response.data.updatedRegistration,
          userData
        );
        dispatch({ type: UPDATE_PROFILE_DETAILS, payload: userData });
        //dispatch otp reducer type
        dispatch({type: UPDATE_REG_DATA, payload: response?.data?.updatedRegistration})
        successCallback(response.updatedRegistration);
      } else {
        console.log(
          '[user-action] putUpdateAboutCompanyDetails Error',
          response.data.data
        );
        errorCallback(response?.data?.message);
      }
    } catch (err) {
      console.log('[user-action] putUpdateAboutCompanyDetails Error', err);
      errorCallback();
    }
  };
export const getUpdateAboutCompanyDetails = (
  userId,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await getUpdatedUser(userId);
      if (response.status === 200) {
        console.log(
          '[user-action] putUpdateAboutCompanyDetails Success',
          response.data.data
        );
        dispatch({ type: UPDATE_PROFILE_DETAILS, payload: response.data.data });
        dispatch({ type: UPDATE_SIGNIN_DATA, payload: response.data.data });
        //UPDATE_SIGNIN_DATA
        successCallback(response.data);
      } else {
        console.log(
          '[user-action] putUpdateAboutCompanyDetails Error',
          response.data
        );
        errorCallback(response?.data?.message);
      }
    } catch (err) {
      console.log('[user-action] putUpdateAboutCompanyDetails Error', err);
      errorCallback();
    }
  };
export const getProductMainCategory = (
  regId,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await getParentCategory(regId);
      if (response.status === 200) {
        console.log(
          '[user-action] getProductMainCategory Success',
          response.data.data
        );
        dispatch({
          type: UPDATE_PROFILE_DETAILS,
          payload: { categories: response.data.data },
        });
        successCallback(response.data);
      } else {
        console.log(
          '[user-action] getProductMainCategory Error',
          response.data
        );
        errorCallback(response?.data?.message);
      }
    } catch (err) {
      console.log('[user-action] getProductMainCategory Error', err);
      errorCallback();
    }
  };

export const resetUpdateProfileeData = () =>
  function (dispatch) {
    dispatch({ type: RESET_UPDATE_PROFILE_DATA });
  };
