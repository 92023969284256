// Form.js
import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import FormField from './FormField';
import PropTypes from 'prop-types';
import { pushMessage } from '../MessageBox/redux/actions/message-box-action';
import Responsive from '../../utils/responsive';
const FormService = ({
  mobile_number,
  name,
  email,
  onSubmit,
  validateInput,
  errors,
  submitBtnText = 'Submit',
  userData = null,
  hideForm = false
}) => {
  const [formData, setFormData] = React.useState({
    mobileNumber: mobile_number,
    name,
    email,
  })

  const onChangeHandler = (e) => {
    const { value, id } = e.target
    console.log("==onChangeHandler", value, id);
    const mobileNumberRegex = /^\d{0,10}$/;

    validateInput(id, value)
    if (id === "mobileNumber" && mobileNumberRegex.test(value)) {
      console.log("mobileNumber mobileNumberRegex.test(value)", value);
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
      console.log("mobileNumber onChangeHandler", formData);
    } else if (id !== "mobileNumber") {
      console.log("mobileNumber onChangeHandler else", formData);
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };


  //   validateInput(id, value);
  //   if (id === 'mobileNumber' && mobileNumberRegex.test(value)) {
  //     console.log('mobileNumber mobileNumberRegex.test(value)', value);
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [id]: value,
  //     }));
  //     console.log('mobileNumber onChangeHandler', formData);
  //   } else if (id !== 'mobileNumber') {
  //     console.log('mobileNumber onChangeHandler else', formData);
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [id]: value,
  //     }));
  //   }
  // };
  const onFormSubmit = () => {
    if (userData == null) {
      pushMessage(
        'Please login to apply for this service.'
      );
    }
    if (!formData?.name && !hideForm) {
      validateInput('name', '');
    } else if (!formData?.email && !hideForm) {
      validateInput('email', '');
    } else if (!formData?.mobileNumber && !hideForm) {
      validateInput('mobileNumber', '');
    } else if (!formData?.comment && !hideForm) {
      validateInput('comment', '');
    } else {
      onSubmit(formData);
    }
  };

  return (
    <div className="">
      <form onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit(e)
      }}>
        {!hideForm && (<>
          <Grid
            container
            columnSpacing={8}
            alignItems="start"
          >
            <Grid item md={6} lg={6} xs={12}>
              <FormField id="name" label="Name" value={formData?.name} disabled={userData ? true : false} type="text" placeholder="Enter name" onChange={onChangeHandler} validateInput={validateInput} errors={errors} />
            </Grid>
            <Grid item md={6} lg={6} xs={12}>
              <FormField id="email" label="Email ID" value={formData?.email} type="email" disabled={userData ? true : false} placeholder="Enter email" onChange={onChangeHandler} validateInput={validateInput} errors={errors} />
            </Grid>
            <Grid item md={6} lg={6} xs={12}>
              <FormField id="mobileNumber" value={formData?.mobileNumber} disabled={formData?.mobileNumber?.length == 10 && userData ? true : false} label="Phone No" type="tel" placeholder="Enter phone number" onChange={onChangeHandler} validateInput={validateInput} errors={errors} inputProps={{
                maxLength: 10,
              }} onKeyDown={(evt) => ["e", "E", "+", "-", "*", "-", "/"].includes(evt.key) && evt.preventDefault()} />
            </Grid>
            <Grid item md={6} lg={6} xs={12}>
              <FormField id="comment" label="Comment" type="text" placeholder="Enter comment" onChange={onChangeHandler} validateInput={validateInput} errors={errors} />
            </Grid>
          </Grid>
        </>)}

        {Responsive(
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
            {submitBtnText}
          </Button>,
          <Box className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="contained"
              className="btn-web-next"
            >
              {submitBtnText}
            </Button>
          </Box>
        )}





      </form>
    </div>
  );
};
FormService.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  validateInput: PropTypes.func,
  errors: PropTypes.object,
  mobile_number: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  submitBtnText: PropTypes.string,
  userData: PropTypes.object,
  pushMessage: PropTypes.func,
  hideForm: PropTypes.bool
};
export default FormService;
