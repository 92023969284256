import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material'; // Import FormHelperText

const FormField = ({
  id,
  label,
  type,
  value,
  placeholder,
  onChange,
  errors,
  disabled,
  inputProps,
  onKeyDown
}) => {
  return (
    <div className="mb-3">
      <InputLabel
        htmlFor={id}
        className="text-start fs-14 text-black font-skmodernist"
      >
        {label}
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <OutlinedInput
          id={id}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          fullWidth
          margin="none"
          onChange={onChange}
          defaultValue={value}
          inputProps={inputProps}
          onKeyDown={onKeyDown}
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id={`${id}-helper-text`}>
        {errors[id]}
      </FormHelperText>
    </div>
  );
};

FormField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.object, // Assuming errors is an object with keys corresponding to field IDs
  inputProps:PropTypes.object,
  onKeyDown:PropTypes.object
};

export default FormField;
