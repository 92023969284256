var OTPData = (function () {
	var otp = null;

	var getOtp = function () {
		return otp; // Or pull this from cookie/localStorage
	};

	var setOtp = function (newOtp) {
		otp = newOtp;
		// Also set this in cookie/localStorage
	};

	return {
		getOtp: getOtp,
		setOtp: setOtp,
	};
})();

export default OTPData;
