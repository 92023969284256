import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
	Container,
	Grid,
	Typography,
	Divider,
	Button,
	Autocomplete,
	TextField,
	TextareaAutosize,
	ListItemIcon,
	Radio,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import { ReactComponent as CategoryMediaIcon } from '../../assets/category-media-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
// import { ReactComponent as CrossIcon } from '../../assets/cross-chip-icon.svg';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { ReactComponent as ClockIcon } from '../../assets/clock-icon.svg';
import RequestCallBackModal from './RequestCallBackModal';
import { any, array, bool, func, string } from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { COMPANY_LOGO_BASE_URL } from '../../utils/APIs/api';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { loading } from '../../store/actions/rootActions';
import WebFooter from '../../components/Footer/WebFooter';
import { CLICKED, JOBLISTING_TITLE } from '../../utils/constants';
import { GAEvents } from '../../utils/helper-funtions';

const theme = createTheme();

const WebJobListingForm = ({
	// isLoading,
	// errors
	jobs,
	saveJobData,
	setJobID,
	userData,
	resetJobApplyData,
	sortArr,
	handleClick,
	allJCategory,
	handleCategoryToggle,
	selCategory,
	allJCity,
	selCity,
	allJMinExp,
	handleMinExpToggle,
	selMinExp,
	handleCityToggle,
	getJobListByTitle,
	searchJobText,
	setSelCategory,
	setFilterCategory,
	setSelCity,
	setFilterCity,
	setSelMinExp,
	setFilterMinExp,
	setSelectedSort,
	setFilterSort,
	selSort,
	openInNewTab,
	// handleShippingDelivery,
	handleTerms,
	handlePrivacy,
	// handleCancellationRefund,
	handleContactUs
}) => {
	const history = useHistory();

	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		// setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	// const sortArr = [{ label: 'Price - Low to High', value: 'price-low-to-high' }, { label: 'Price - High to Low', value: 'price-high-to-low' }, { label: 'Rating - High to Low', value: 'rating-High-to-low' }];
	// const [selSort, setSelectedSort] = useState();
	const handleSortChange = (value) => {
		console.log('values : ', value);
		setSelectedSort(value);
		// const currentIndex = selSort?.indexOf(value);
		// const newChecked = [...selSort];

		// if (currentIndex === -1) {
		//     newChecked.push(value);
		// } else {
		//     newChecked.splice(currentIndex, 1);
		// }
	};

	const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
	const toggleCallBackDrawer = () => {
		setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
	};

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			className="fs-14 text-gray font-gotham"
			href="/"
			onClick={handleClick}
		>
			Elynker
		</Link>,
		// <Link
		//     underline="hover"
		//     key="2"
		//     className="fs-14 text-gray font-gotham"
		//     href="/"
		//     onClick={handleClick}
		// >
		//     Business
		// </Link>,
		<Link
			underline="none"
			key="3"
			className="fs-14 text-breadcrumbs-active font-gotham"
		>
			Elynker Job-list
		</Link>,
	];

	function setFilterParam() {
		var params = '';
		if (selMinExp?.length > 0) {
			if (params !== '') {
				params += '&';
			}
			for (let i = 0; i < selMinExp.length; i++) {
				params += 'experiences=' + selMinExp[i];
				if (i !== selMinExp.length - 1) {
					params += ',';
				}
			}
		}

		if (selCategory?.length > 0) {
			if (params !== '') {
				params += '&';
			}
			for (let i = 0; i < selCategory.length; i++) {
				params += 'categories=' + selCategory[i];
				if (i !== selCategory.length - 1) {
					params += ',';
				}
			}
		}

		if (selCity?.length > 0) {
			if (params !== '') {
				params += '&';
			}
			for (let i = 0; i < selCity.length; i++) {
				params += 'locations=' + selCity[i];
				if (i !== selCity.length - 1) {
					params += '&';
				}
			}
		}

		if (selSort !== undefined && selSort !== null) {
			if (params !== '') {
				params += '&';
			}

			if (selSort?.value == 'salary-asc') {
				params += 'sortBy=salary';
			} else if (selSort?.value == 'salary-desc') {
				params += 'sortBy=salary';
				params += '&sortOrder=desc';
			} else if (selSort?.value == 'createdAt-asc') {
				params += 'sortBy=createdAt';
			} else if (selSort?.value == 'createdAt-desc') {
				params += 'sortBy=createdAt';
				params += '&sortOrder=desc';
			}
		}

		return params;
	}

	useEffect(() => {
		let rParams = setFilterParam();
		const fetchData = async () => {
			await getJobListByTitle(searchJobText, rParams);
		};
		// toggleFilterDrawer();
		loading(true);
		fetchData();
		loading(false);
	}, [selSort, selMinExp, selCategory, selCity]);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xl">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: '67vh' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2 d-flex align-items-center">
							<ArrowBackIosIcon
								onClick={() => {
									history.replace('/homepage');
								}}
							/>
							<ElynkerLogoComponent>
								<ElynkerLogo
									className="ms-3"
									width={'100px'}
									height={'30px'}
									onClick={() => {
										history.push('/homepage');
									}}
								/>
							</ElynkerLogoComponent>
						</div>
						<Divider
							component="div"
							className="main-divider mt-2 mb-3"
							role="presentation"
						/>

						<form style={{ marginTop: '0px' }}>
							<Breadcrumbs
								className="mb-3"
								separator={
									<NavigateNextIcon fontSize="small" className="text-gray" />
								}
								aria-label="breadcrumb"
							>
								{breadcrumbs}
							</Breadcrumbs>

							<Card className="job-listing-web-card mb-3">
								<CardContent className="job-listing-web-content">
									<div className="job-listing-web-content-tint" />
									<div
										className="d-flex align-items-center flex-content"
										style={{ zIndex: 2, position: 'relative' }}
									>
										<Typography
											variant="h5"
											className="job-listing-web-text text-offwhite fw-bold mb-3 fw-bold font-skmodernist"
											component="div"
											style={{ width: '50%' }}
										>
											People have booked this service <br></br>
											on elynker with 4.5 stars
										</Typography>
										<div className="">
											<Button
												variant="contained"
												className="post-requirement-btn py-2 px-4"
												onClick={()=>{
													GAEvents(JOBLISTING_TITLE, "Job Listing", CLICKED, {
														userId: userData?.id || sessionStorage.getItem('random_id'),
														reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
														title: "Request a Call Back"
													});
													handleOpenModal();
												}}
											>
												Request a Call Back
											</Button>
										</div>
									</div>
									{/* <Typography
                                        variant="h5"
                                        className="fs-16 text-white mb-3 fw-bold font-skmodernist"
                                        component="div"
                                    >
                                        Connect with 4 Mn+ <br></br> Businesses Across India
                                    </Typography>
                                    <Button variant="contained" className="post-requirement-btn " onClick={toggleCallBackDrawer}>
                                        Request a Call Back
                                    </Button> */}
								</CardContent>
							</Card>

							<RequestCallBackModal
								open={openModal}
								onClose={handleCloseModal}
							/>

							<Drawer
								xs={12}
								anchor="bottom"
								open={isCallBackDrawerOpen}
								onClose={toggleCallBackDrawer}
								sx={{
									'& .MuiDrawer-paper': {
										height: 'auto',
										borderTopLeftRadius: '20px',
										borderTopRightRadius: '20px',
									},
								}}
							>
								<div className="p-3">
									<div className="d-flex justify-content-between mb-3">
										<Typography
											variant="h5"
											align="left"
											alignItems="center"
											className="fs-18 mb-2 fw-bold font-skmodernist "
											gutterBottom
										>
											Request a Call Back
										</Typography>
										<CloseIcon onClick={toggleCallBackDrawer} />
									</div>

									<div className="mb-3">
										<InputLabel
											htmlFor="inputCompanyName"
											className="text-start fs-14 text-black font-skmodernist"
										>
											Name
										</InputLabel>
										<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
											<OutlinedInput
												id="inputName"
												// label="Mobile Number"
												type="text"
												placeholder="Enter name"
												required
												autoFocus
												fullWidth
												margin="none"
											/>
										</FormControl>
									</div>

									<div className="mb-3">
										<InputLabel
											htmlFor="inputEmailID"
											className="text-start fs-14 text-black font-skmodernist"
										>
											Email ID
										</InputLabel>
										<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
											<OutlinedInput
												id="inputName"
												// label="Mobile Number"
												type="text"
												placeholder="Enter email Id"
												required
												autoFocus
												fullWidth
												margin="none"
											/>
										</FormControl>
									</div>

									<div className="mb-3">
										<InputLabel
											htmlFor="inputPhone"
											className="text-start fs-14 text-black font-skmodernist"
										>
											Phone No.
										</InputLabel>
										<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
											<OutlinedInput
												id="inputName"
												// label="Mobile Number"
												type="text"
												placeholder="Enter phone no."
												required
												autoFocus
												fullWidth
												margin="none"
											/>
										</FormControl>
									</div>

									<div className=" mb-3">
										<InputLabel
											htmlFor="inputCompanyName"
											className="text-start fs-14 text-black font-skmodernist"
										>
											Comment
										</InputLabel>
										<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
											<TextareaAutosize
												id="inputComment"
												placeholder="Enter comment"
												minRows={3} // You can adjust the number of rows as needed
												maxRows={2} // You can adjust the number of rows as needed
											/>
										</FormControl>
									</div>

									<Button
										type="submit"
										fullWidth
										variant="contained"
										className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
										sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
									>
										Submit
									</Button>
								</div>
							</Drawer>

							<Grid container spacing={2} className="mb-4">
								<Grid item md={4} lg={4}>
									<Box
										sx={{
											border: '1px solid #e3e3e3 !important',
											borderRadius: '8px !important',
											padding: '14px',
										}}
									>
										<Box className="d-flex justify-content-between align-items-center">
											<Typography className="fs-22 fw-bold font-skmodernsit ">
												Filters
											</Typography>
											<Typography
												className="fs-15 fw-bold font-skmodernsit text-secondary"
												onClick={() => {
													GAEvents(JOBLISTING_TITLE, "Filters Clear All", CLICKED, {
														userId: userData?.id || sessionStorage.getItem('random_id'),
														reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
														title: "Clear All"
													});
													setSelCategory([]);
													setFilterCategory([]);
													setSelCity([]);
													setFilterCity([]);
													setSelMinExp([]);
													setFilterMinExp([]);
													setSelectedSort([]);
													setFilterSort([]);
												}}
											>
												Clear All
											</Typography>
										</Box>
										<Divider
											component="div"
											className="my-3"
											role="presentation"
										/>
										<Box>
											<Typography className="fs-18 fw-medium font-skmodernsit mb-2">
												Job Categories
											</Typography>
											<List
												class="filter-web-list"
												sx={{
													width: '100%',
													bgcolor: 'background.paper',
													padding: '0px',
												}}
											>
												{allJCategory?.map((jCat, index) => {
													const labelId = `checkbox-list-secondary-label-${index}`;
													return (
														<ListItem key={index} disablePadding>
															<ListItemButton sx={{ paddingLeft: '0px' }}>
																<ListItemIcon>
																	<Checkbox
																		edge="start"
																		onChange={handleCategoryToggle(jCat)}
																		checked={selCategory?.indexOf(jCat) !== -1}
																		inputProps={{
																			'aria-labelledby': labelId,
																		}}
																		sx={{
																			color: '#D4D4D4',
																			'&.Mui-checked': {
																				color: '#416AD4',
																			},
																		}}
																	/>
																</ListItemIcon>
																<ListItemText
																	id={labelId}
																	className="fs-15 fw-normal text-black font-skmodernist"
																	primary={jCat}
																/>
															</ListItemButton>
														</ListItem>
													);
												})}
											</List>
											<Divider
												component="div"
												className="my-3"
												role="presentation"
											/>
											<Typography className="fs-18 fw-medium font-skmodernsit mb-2">
												City
											</Typography>

											<Autocomplete
												fullWidth
												options={allJCity}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														placeholder="Search your city"
														InputProps={{
															startAdornment: (
																<SearchIcon
																	style={{ margin: '8px', width: '30px' }}
																/>
															),
														}}
													/>
												)}
											/>
											<List
												class="filter-web-list"
												sx={{
													width: '100%',
													bgcolor: 'background.paper',
													padding: '0px',
												}}
											>
												{allJCity?.map((loc, index) => {
													const labelId = `checkbox-list-secondary-label-${index}`;
													return (
														<ListItem key={index} disablePadding>
															<ListItemButton sx={{ paddingLeft: '0px' }}>
																<ListItemIcon>
																	<Checkbox
																		edge="start"
																		onChange={handleCityToggle(loc)}
																		checked={selCity?.indexOf(loc) !== -1}
																		inputProps={{
																			'aria-labelledby': labelId,
																		}}
																		sx={{
																			color: '#D4D4D4',
																			'&.Mui-checked': {
																				color: '#416AD4',
																			},
																		}}
																	/>
																</ListItemIcon>
																<ListItemText
																	id={labelId}
																	className="fs-15 fw-normal text-black font-skmodernist"
																	primary={loc}
																/>
															</ListItemButton>
														</ListItem>
													);
												})}
											</List>

											<Divider
												component="div"
												className="my-3"
												role="presentation"
											/>

											<Typography className="fs-18 fw-medium font-skmodernsit mb-2">
												Min. Experience
											</Typography>
											<List
												class="filter-web-list"
												sx={{
													width: '100%',
													bgcolor: 'background.paper',
													padding: '0px',
												}}
											>
												{allJMinExp?.map((exp, index) => {
													const labelId = `checkbox-list-secondary-label-${index}`;
													return (
														<ListItem key={index} disablePadding>
															<ListItemButton sx={{ paddingLeft: '0px' }}>
																<ListItemIcon>
																	<Checkbox
																		edge="start"
																		onChange={handleMinExpToggle(exp)}
																		checked={selMinExp?.indexOf(exp) !== -1}
																		inputProps={{
																			'aria-labelledby': labelId,
																		}}
																		sx={{
																			color: '#D4D4D4',
																			'&.Mui-checked': {
																				color: '#416AD4',
																			},
																		}}
																	/>
																</ListItemIcon>
																<ListItemText
																	id={labelId}
																	className="fs-15 fw-normal text-black font-skmodernist"
																	primary={exp}
																/>
															</ListItemButton>
														</ListItem>
													);
												})}
											</List>
											<Divider
												component="div"
												className="my-3"
												role="presentation"
											/>
											<Typography className="fs-18 fw-medium font-skmodernsit mb-2">
												Sort By
											</Typography>

											<List
												class="filter-web-sort-list"
												dense
												sx={{ width: '100%', bgcolor: 'background.paper' }}
											>
												{sortArr &&
													sortArr?.map((option, index) => {
														const labelId = `radio-list-secondary-label-${index}`;
														return (
															<ListItem key={index} disablePadding>
																<ListItemButton sx={{ paddingLeft: '0px' }}>
																	<ListItemIcon>
																		<Radio
																			edge="end"
																			onChange={() => handleSortChange(option)}
																			checked={selSort?.label === option?.label} //selRating.findIndex(x => x.label === rating.label) !== -1
																			inputProps={{
																				'aria-labelledby': labelId,
																			}}
																			sx={{
																				color: '#D4D4D4',
																				'&.Mui-checked': {
																					color: '#416AD4',
																				},
																			}}
																		/>
																	</ListItemIcon>
																	<ListItemText
																		id={labelId}
																		className="fs-15 fw-normal text-grey font-gotham"
																		primary={option.label}
																	/>
																</ListItemButton>
															</ListItem>
														);
													})}
											</List>
										</Box>
									</Box>
								</Grid>

								<Grid item md={8} lg={8}>
									<List className="pt-0">
										{jobs &&
											jobs?.map((item, index) => {
												let last_update = moment(item?.updatedAt)?.fromNow();
												let categoriesToShow = item?.categories?.map((item) => {
													return item?.title;
												});
												console.log('JobList useEffect');
												return (
													<ListItem
														className="job-list-web-item"
														key={index}
														onClick={() => {
															history.push('/job-detail');
														}}
													>
														<Card className="card">
															<div className="d-flex justify-content-between">
																<CardContent className="content p-0">
																	<div className="d-flex justify-content-between align-items-start w-100">
																		<Typography
																			variant="h5"
																			className="fs-22 text-black mb-1 fw-bold font-skmodernist"
																			component="div"
																		>
																			{item?.title}
																		</Typography>
																		<div>
																			<RightArrowIcon
																				onClick={() => {
																					GAEvents(JOBLISTING_TITLE, "Job Listing", CLICKED, {
																						userId: userData?.id || sessionStorage.getItem('random_id'),
																						reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
																						title: "Right Arrow",
																						text: item?.title || ''
																					});
																					saveJobData(item);
																					setJobID(item?.id);
																					history.push('/job-detail');
																				}}
																			/>
																		</div>
																	</div>

																	<Typography
																		variant="body2"
																		className="fs-15 text-grey mb-1 font-gotham"
																	>
																		{categoriesToShow?.flat()}
																	</Typography>

																	<div className="d-flex align-items-center">
																		<IconButton className="p-0 pe-2">
																			<ClockIcon />
																		</IconButton>
																		<Typography
																			variant="h5"
																			className="fs-14 text-gray fw-normal  font-gotham"
																			component="div"
																		>
																			{last_update !== 'Invalid date'
																				? last_update
																				: ''}
																		</Typography>
																	</div>
																</CardContent>
															</div>

															<Divider
																component="div"
																className="mt-2 mb-3"
																role="presentation"
															/>
															<div className="d-flex">
																<CardMedia
																	className="subcategory-media"
																	image={
																		item?.registration?.image_path
																			? `${COMPANY_LOGO_BASE_URL}${item?.registration?.image_path}`
																			: require('../../assets/elynker_default_image.jpg')
																	}
																	sx={{
																		padding: '1em 1em 0 1em',
																		objectFit: 'contain',
																	}}
																>
																	{/* <img  src={COMPANY_LOGO_BASE_URL + `/${item?.registration?.image_path}`} className='subcategory-media' /> */}
																</CardMedia>
																<CardContent className="content p-0">
																	<div className="d-flex justify-content-between align-items-start w-100">
																		<div>
																			<Typography
																				variant="h5"
																				className="fs-16 text-black mb-1 text-darkgrey  font-skmodernist"
																				component="div"
																			>
																				{item?.registration?.company_name}
																			</Typography>

																			<Typography
																				variant="body2"
																				className="fs-14 text-secondary mb-1 font-skmodernist"
																			>
																				{item?.job_location}
																			</Typography>
																		</div>

																		<div>
																			<Typography
																				variant="h5"
																				className="fs-14 text-darkgrey mb-0 font-skmodernist"
																			>
																				Upto
																			</Typography>

																			<Typography
																				variant="h5"
																				className="fs-16 text-secondary fw-bold mb-1 font-skmodernist"
																			>
																				₹ {item?.salary_offered}{' '}
																				<span className="fs-12 text-grey fw-normal">
																					/ Month
																				</span>
																			</Typography>
																		</div>

																		<div>
																			{item?.registration?.id !==
																			userData?.registration?.id ? (
																				<Button
																					variant="contained"
																					className="apply-btn px-4"
																					onClick={(e) => {
																						GAEvents(JOBLISTING_TITLE, "Job Listing", CLICKED, {
																							userId: userData?.id || sessionStorage.getItem('random_id'),
																							reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
																							title: "Apply",
																							text: item?.title || ''
																						});
																						e.stopPropagation();
																						resetJobApplyData();
																						saveJobData(item);
																						setJobID(item?.id);
																						history.push('/job-apply');
																					}}
																				>
																					Apply
																				</Button>
																			) : (
																				<div>This Job is posted by You.</div>
																			)}
																		</div>
																	</div>
																</CardContent>
															</div>
														</Card>
													</ListItem>
												);
											})}
									</List>
								</Grid>
							</Grid>
						</form>
						<div className="homepage-footer" style={{ marginTop: '100px' }}>
						<WebFooter
							handleTerms={handleTerms}
							handlePrivacy={handlePrivacy}
							openInNewTab={openInNewTab}
							handleContactUs={handleContactUs}
							userData={userData} />
						</div>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

WebJobListingForm.propTypes = {
	isLoading: bool,
	errors: any,
	jobs: array,
	saveJobData: func,
	setJobID: func,
	userData: any,
	resetJobApplyData: func,
	sortArr: array,
	handleClick: func,
	allJCategory: array,
	handleCategoryToggle: func,
	selCategory: array,
	allJCity: any,
	selCity: any,
	allJMinExp: any,
	handleMinExpToggle: any,
	selMinExp: any,
	handleCityToggle: func,
	getJobListByTitle: func,
	searchJobText: string,
	setSelCategory: func,
	setFilterCategory: func,
	setSelCity: func,
	setFilterCity: func,
	setSelMinExp: func,
	setFilterMinExp: func,
	setSelectedSort: func,
	setFilterSort: func,
	selSort: any,
	openInNewTab: func,
	handleShippingDelivery: func,
	handleTerms: func,
	handlePrivacy: func,
	handleCancellationRefund: func,
	handleContactUs: func
};

export default WebJobListingForm;
