import { UPDATE_PROFILE_DETAILS, RESET_UPDATE_PROFILE_DATA } from '../type';

const INITIAL_STATE = {
  name: '',
  email: '',
  // company_name: '',
  //   mobileNo: '',
  // gst: '',
  // pan: '',
  // cin: ''
  categories: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_DETAILS:
      return { ...state, ...action.payload };
    
    case RESET_UPDATE_PROFILE_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
