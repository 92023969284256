// import { generateUniqueId } from '../../../../../utils/genrateUniqId';
import {
  FRL_BIO,
  GET_BANNER_DATA,
  RESET_FRL_BIO_DATA,
  SAVE_PROJECT_DATA,
  REMOVE_BANNER_DATA,
  DELETED_PROJECT_DATA
} from '../types';

const INITIAL_STATE = {
  freelancerBio: '',
  projects: [],
  projectFile: [],
  deletedProjectFile: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FRL_BIO:
      return { ...state, freelancerBio: action.payload };

    case GET_BANNER_DATA:
      return { ...state, projects: action.payload };
    case REMOVE_BANNER_DATA: {
      const banner_image = action.payload;
      const _projects = state.projects.filter(item => item.banner_image !== banner_image);
      console.log("===REMOVE_BANNER_DATA finalProj", _projects);
      const finalProj = { ...state, projects: _projects };
      return finalProj;
    }

    case SAVE_PROJECT_DATA:
      {
        console.log("===state.projects56", state.projects);
        const finalProj = { ...state, projects: [...state.projects, action.payload] }
        console.log("===SAVE_PROJECT_DATA finalProj", finalProj);
        return finalProj;
      }
    case DELETED_PROJECT_DATA:
      {
        const finalProj = { ...state, deletedProjectFile: [...state.deletedProjectFile, action.payload] }
        console.log("===DELETED_PROJECT_DATA finalProj", finalProj);
        return finalProj;
      }

    case RESET_FRL_BIO_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
