/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import './complete-profile.css';
import '../../index.css';
import '../../components/ToggleButton/toggle-button.css';
import { FormHelperText } from '@mui/material';
import UserLogo from '../../assets/user-icon.svg';
import EmailLogo from '../../assets/email-icon.svg';
// import LocationLogo from '../../assets/location-icon.svg';
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Button,
  FormControlLabel,
  CircularProgress,
  Backdrop,
  TextField,
  Autocomplete,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import axios from 'axios';

// import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import { ReactComponent as LocatemeLogo } from '../../assets/locateme-icon.svg';
import ColorToggleButton from '../../components/ToggleButton/ToggleButton';
import PropTypes, { func } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  sendSignupData,
  setRoleData,
} from './redux/actions/start-connecting-action';
// import { ValidateEmail } from '../../utils/constants';
import { loading } from '../../store/actions/rootActions';
import useValidation from '../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../utils/validation';
import {
  updateUserDetails,
  updateUserData,
} from '../FreeLancer/FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { putUpdateUserDetails } from '../UpdateProfile/store/actions/profileActions';
// import useGeoLocation from '../../hooks/useGeolocation';
import WebCompleteProfile from './WebCompleteProfile ';
const theme = createTheme();
const customBlueColor = '#416AD4';
import responsive from '../../utils/responsive';
import { POSTOFFICE_CITY } from '../../utils/APIs/api';
import { B2C_ID, BUSINESS_ID, FREELANCER_ID } from '../../utils/constants';
import { GAEvents, sendDefaultPageEvent } from '../../utils/helper-funtions';
const toggleChoice1 = [
  { text: 'Business', value: 'B2B' },
  { text: 'Job Seeker', value: 'Freelancer' },
];

const CompleteProfileForm = (props) => {
  const { errors, validateInput } = useValidation();
  // const { loading: locLoading, error } = useGeoLocation();
  const { updateUserData, userData, putUpdateUserDetails, setRoleData } = props;
  const { name, city, email } = props;
  const [form, setFormData] = React.useState(name, city, email);
  // console.log('======city CompleteProfileForm', name, city, email);
  React.useEffect(() => {
    setFormData({
      name: name ?? '',
      city: city ?? '',
      email: email ?? '',
    });
  }, [name, city, email]);
  let history = useHistory();
  // const inputBoxRef = React.useRef(null);
  const isFromHomeScreen = history.location.state?.fromHomePage;
  // const { sendSignupData } = props
  const [isSeller, setIsSeller] = useState(false);
  const [registrationType, setRegistrationType] = useState(1);
  const [selIndex, setSelIndex] = useState(2);
  const searchLocInpRef = React.useRef(null);
  const [mapOtions, setMapOtions] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [locSearchVal, setLocSearchVal] = useState('');
  // const [location, setLocation] = React.useState();
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
  const enquiry = props.enquiry;
  useEffect(() => {
    const getData = setTimeout(async () => {
      if (locSearchVal && searchLoad) {
        try {
          const response = await axios.get(
            POSTOFFICE_CITY + locSearchVal
            // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}&types=city`
          );
          if (response.status === 200) {
            if (response?.data?.status_code === 200) {
              console.log('SEARCH RESULT', response.data);
              setMapOtions(response.data?.data);
              setSearchLoad(false);
            } else {
              console.log('SEARCH RESULT', response.data);
              setMapOtions([]);
              setSearchLoad(false);
            }
          } else {
            pushMessage('No City Found!!');
          }
        } catch (err) {
          pushMessage('Network Error!!');
        }
      }
    }, 250);

    return () => clearTimeout(getData);
  }, [locSearchVal, searchLoad]);

  useEffect(() => {
    sendDefaultPageEvent(
      null,
      'Signup Page',
      window.location.href,
      window.location.pathname
    );
  }, []);
  const handleNameChange = (e) => {
    const input = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(input)) {
      console.log('===handleNameChange', input);
      setFormData((prev) => ({ ...prev, name: input }));
      // setName(input);
      validateInput('name', input);
    }
  };
  const handleEmailChange = (e) => {
    const input = e.target.value.trim();
    console.log('===handleEmailChange');
    setFormData((prev) => ({ ...prev, email: input }));
    validateInput('email', input);
  };
  // const handleCityChange = (e) => {
  //   const input = e.target.value;
  //   const regex = /^[a-zA-Z0-9\s]*$/;
  //   if (regex.test(input)) {
  //     setCity(input);
  //   }
  // };
  const getAddress2DefaultVal = () => {
    const address2Val = props.userData?.registration?.address2;
    if (address2Val) {
      return address2Val;
    } else {
      return null;
    }
  };

  const updateUserDetailsSuccess = (data) => {
    props.loading(false);
    console.log('updateUserDetailsSuccess Success', data);
    if (registrationType === 2) {
      history.replace('/onboarding');
    } else if (registrationType === 3) {
      history.replace('/fl-onboarding');
    } else if (registrationType === 1) {
      history.replace('/homepage');
    }
  };
  const updateUserDetailsError = () => {
    console.log('updateUserDetailsSuccess error');
    props.loading(false);
  };
  const setRoleSuccess = () => {
    console.log('Set Role data success');
  };
  const setRoleError = () => {
    console.log('Set Role data error');
  };
  const putUpdateUserDetailsSuccess = () => {
    console.log('putUpdateUserDetailsSuccess success');
  };
  const putUpdateUserDetailsError = () => {
    console.log('putUpdateUserDetailsSuccess error');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newFormData = {};
    if (isFromHomeScreen) {
      if (form?.email) {
        newFormData.email = form?.email;
      }
      if (form?.name) {
        newFormData.name = form?.name;
      }
      if (form?.city) {
        newFormData.city = form?.city;
      }

      // console.log('registrationType', registrationType);

      if (form.email) {
        await putUpdateUserDetails(
          props.userData?.user?.id,
          { email: form.email },
          props.userData,
          putUpdateUserDetailsSuccess,
          putUpdateUserDetailsError
        );
      }
      if (props.userData?.roles?.find((itm) => itm.name === 'admin')) {
        await setRoleData(
          props.userData?.user?.id,
          props.userData,
          {
            roles:
              registrationType === 3
                ? ['Freelancer']
                : registrationType === 2
                  ? ['Business']
                  : ['B2C'],
          },
          setRoleSuccess,
          setRoleError
        );
      } else {
        await setRoleData(props.userData?.user?.id, props.userData, {
          roles:
            registrationType === 3
              ? ['Freelancer']
              : registrationType === 2
                ? ['Business']
                : ['B2C'],
        }),
          setRoleSuccess,
          setRoleError;
      }

      await updateUserData(
        props.userData,
        { registration_type: registrationType, ...newFormData },
        props.userData?.registration?.id,
        null,
        () => {
          let navTimer = setTimeout(() => {
            if (registrationType === 2) {
              history.replace('/onboarding');
            } else if (registrationType === 3) {
              history.replace('/fl-onboarding');
            } else if (registrationType === 1) {
              history.replace('/homepage');
            }
            clearTimeout(navTimer);
          }, 1000);
        },
        () => {
          console.log('UpdateUser Data Error : ');
        }
      );
    } else {
      // console.log('USERDATA===============', userData);
      // console.log('===handleSubmit error', errors, form);
      if (form?.name == '') {
        validateInput('name', name);
      }
      if (form?.email == '') {
        validateInput('email', email);
      }
      // if (form?.city == "") {
      //   validateInput('city', city);
      // }
      if (!hasAnyErrorPresent(errors)) {
        props.loading(true);
        let data = {
          mobile_number: props.mobile_number,
          country_code: props.country_code,
          registration_type: registrationType,
        };
        if (form?.name) {
          data.name = form.name;
        }
        if (form?.email) {
          data.email = form.email;
        }
        if (form?.city) {
          data.city = form.city;
        }
        // console.log('SIGN UP POST DATA', data);
        if (
          props.userData?.registration?.registration_type === null ||
          props.userData?.registration?.registration_type
        ) {
          if (form?.email) {
            newFormData.email = form?.email;
          }
          if (form?.name) {
            newFormData.name = form?.name;
          }
          if (form?.city) {
            newFormData.city = form?.city;
          }

          if (form.email) {
            await putUpdateUserDetails(
              props.userData?.user?.id,
              { email: form.email },
              props.userData,
              putUpdateUserDetailsSuccess,
              putUpdateUserDetailsError
            );
          }

          await setRoleData(
            props.userData?.user?.id,
            props.userData,
            {
              roles:
                registrationType === 3
                  ? ['Freelancer']
                  : registrationType === 2
                    ? ['Business']
                    : ['B2C'],
            },
            async (newUsrData) => {
              props.loading(false);
              await updateUserData(
                newUsrData,
                { registration_type: registrationType, ...newFormData },
                props.userData?.registration?.id,
                null,
                updateUserDetailsSuccess,
                updateUserDetailsError
              );
            },
            () => {
              console.log('UpdateUser Data Error : ');
            }
          );
        } else {
          let sData;
          if(data?.name){
            sData = { ...data, name: '<name>' };
          } else{
            sData = { ...data, name: '' };
          }
          if(data?.email){
            sData = { ...data, email: '<email>' };
          } else{
            sData = { ...data, email: '' };
          }
          if(data?.city){
            sData = { ...data, city: '<city>' };
          } else{
            sData = { ...data, city: '' };
          }
          if (data?.registration_type == B2C_ID) {
            sData = { ...data, registration_type: 'B2C' };
          } else if (data?.registration_type == BUSINESS_ID) {
            sData = { ...data, registration_type: 'B2B' };
          } else {
            sData = { ...data, registration_type: 'Freelancer' };
          }

          GAEvents('begin_signup', 'begin_signup', 'clicked', sData);
          const response = props.sendSignupData(data);
          response
            .then((res) => {
              props.loading(false);
              if (res.statusCode === 201) {
                // console.log(
                //   '=====enquiry object,enquiry?.isTrue',
                //   enquiry,
                //   enquiry?.isTrue
                // );
                if (
                  userData?.registration?.steps_completed == true &&
                  enquiry?.isTrue
                ) {
                  history.replace('/b2b-category');
                } else {
                  if (registrationType === BUSINESS_ID) {
                    history.replace('/onboarding');
                  } else if (registrationType === FREELANCER_ID) {
                    history.replace('/fl-onboarding');
                  } else if (registrationType === B2C_ID) {
                    history.replace('/homepage');
                  }
                }
              }
              if (res.statusCode === 400) {
                props.pushMessage(res?.data?.message);
              }
            })
            .catch((error) => {
              props.loading(false);
              if (error.statusCode === 404) {
                history.push('/complete-profile');
              }
            });
        }
      }
    }
  };
  const getLocationString = (option) => {
    console.log('===getLocationString option', option);
    const name = option;
    return name;
  };
  const onLocationChange = (e, value) => {
    console.log('===onLocationChange', e);
    console.log('===onLocationChange value', value);
    setFormData((prev) => ({
      ...prev,
      city: value,
    }));
    validateInput('city', value);
    console.log('===on change location', value);
    // if (value?.geometry?.length > 0) {
    //   setLocationCoords({
    //     latitude: value?.geometry?.coordinates[1],
    //     longitude: value?.geometry?.coordinates[0],
    //   });
    // }
    setSearchLoad(false);
  };
  const onLocationInputChange = (e, value) => {
    console.log('onLocationInputChange');
    setLocSearchVal(value);
    setSearchLoad(true);
  };

  const handleCheckChange = (e) => {
    const _name = form?.name ? '<name>' : '';
    const _email = form?.email ? '<email>' : '';
    const _city = form?.city ? '<city>' : '';
    GAEvents('Signup Page', 'Become a seller', 'clicked', {
      checked: e.target.checked,
      name: _name,
      email: _email,
      city: _city,
    });

    setIsSeller(e.target.checked);
    if (e.target.checked === false) {
      setRegistrationType(1);
    } else {
      if (selIndex === 2) {
        setRegistrationType(2);
      } else if (selIndex === 3) {
        setRegistrationType(3);
      }
      if (e.target.checked && selIndex == 2) {
        GAEvents('Signup Page', 'Type selected', 'clicked', {
          type: 'B2B',
        });
      }
    }
  };

  // useEffect(() => {
  //   if (locLoading) props.loading(true);
  //   else props.loading(false);
  //   if (error?.message) props.pushMessage(error?.message);
  // }, [locLoading, error]);

  const handleToggle = (arg) => {
    if (arg === 'B2B') {
      setRegistrationType(2);
      setSelIndex(2);
    }
    if (arg === 'Freelancer') {
      setRegistrationType(3);
      setSelIndex(3);
    }

    GAEvents('Signup Page', 'Type selected', 'clicked', {
      type: arg,
    });
  };
  return responsive(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {props.isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '85vh' }}
        >
          <Grid item xs={12}>
            <div className="d-flex align-items-center">
              <Typography
                variant="h4"
                align="left"
                alignItems="center"
                className="fs-26 mb-2 fw-bold font-skmodernist"
                gutterBottom
              >
                Complete your Profile
              </Typography>
            </div>

            <Typography
              variant="body1"
              align="left"
              className="fs-14 text-grey fw-normal font-gotham"
              gutterBottom
            >
              The more you share, the better we can serve you
            </Typography>
            <form
              style={{ marginTop: '60px' }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className=" mb-3">
                <InputLabel
                  htmlFor="inputMobile"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Name
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputMobile"
                    // label="Mobile Number"
                    type="text"
                    placeholder="Enter your name"
                    disabled={isFromHomeScreen && name ? true : false}
                    autoFocus
                    fullWidth
                    inputProps={{
                      maxLength: 50,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          src={UserLogo}
                          alt="user-logo"
                          className="user-logo"
                        />
                      </InputAdornment>
                    }
                    value={form?.name}
                    onChange={handleNameChange}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.name}
                </FormHelperText>
              </div>
              <div className=" mb-3">
                <InputLabel
                  htmlFor="inputEmail"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Email ID
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputEmail"
                    // label="Mobile Number"
                    type="text"
                    placeholder="Enter your email id"
                    disabled={isFromHomeScreen && email ? true : false}
                    fullWidth
                    value={form?.email}
                    inputProps={{
                      maxLength: 200,
                      autoCapitalize: 'none', // Specify autoCapitalize here
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          src={EmailLogo}
                          alt="email-logo"
                          className="email-logo"
                        />
                      </InputAdornment>
                    }
                    onChange={handleEmailChange}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.email}
                </FormHelperText>
              </div>
              <div className=" mb-3">
                <div className="">
                  <InputLabel
                    htmlFor="inputLocation"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Location
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    {/* <GooglePlacesAutocomplete
              apiKey="abcdefgh"
              debounce={1500}
              onLoadFailed={(error) =>
                console.error('Could not inject Google script', error)
              }
              selectProps={{
                value: address2,
                // onChange: handlePlacesValueChange,
                placeholder: 'Search your location',
                className: 'singleValue',
                styles: {
                  control: (base) => {
                    return {
                      ...base,
                      height: '49px !important',
                    };
                  },
                },
              }}
            /> */}
                    <Autocomplete
                      disablePortal
                      ref={searchLocInpRef}
                      id={'SearchLocation'}
                      options={mapOtions}
                      fullWidth
                      // value={form?.city}
                      inputValue={locSearchVal}
                      onInputChange={onLocationInputChange}
                      onChange={onLocationChange}
                      open={open}
                      onOpen={openPopper}
                      onClose={closePopper}
                      defaultValue={getAddress2DefaultVal()}
                      getOptionLabel={getLocationString}
                      isOptionEqualToValue={(option, value) =>
                        option?.properties?.name === value?.properties?.name
                      }
                      sx={{
                        '.MuiAutocomplete-input': {
                          paddingLeft: '14px !important',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          placeholder={form?.city ? form?.city : 'Search City'}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 200,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.city}
                  </FormHelperText>
                </div>
                {/* <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <OutlinedInput
              id="inputLocation"
              // label="Mobile Number"
              type="text"
              placeholder="Enter your city location"
              ref={inputBoxRef}
              value={city}
              autoFocus
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={LocationLogo}
                    alt="location-logo"
                    className="location-logo"
                  />
                </InputAdornment>
              }
              inputProps={{
                maxLength: 200,
              }}
              onChange={handleCityChange}
            />
          </FormControl> */}
                {/* <Grid container rowSpacing={0} alignItems="end">

                  <Grid item md={6} lg={6} xs={12} className="mb-3 d-flex">
                    <Button type="button" variant="text" className="ps-0" onClick={handleLocateMe}>
                      <LocatemeLogo />
                      <Typography
                        variant="body1"
                        align="left"
                        className="fs-14 text-primary fw-normal font-gotham ms-2"
                      >
                        Locate Me
                      </Typography>
                    </Button>
                  </Grid>
                </Grid> */}
              </div>
              <FormControlLabel
                className="d-flex align-items-center"
                control={
                  <Checkbox
                    id="customCheck1"
                    className="py-0"
                    sx={{
                      color: customBlueColor,
                      borderWidth: 1,
                      '& .MuiSvgIcon-root': {
                        borderWidth: 1,
                        borderRadius: 4,
                      },
                      '&.Mui-checked': {
                        color: customBlueColor,
                        borderRadius: 15,
                        borderWidth: 2,
                      },
                    }}
                    checked={isSeller}
                    onChange={handleCheckChange}
                  />
                }
                label={
                  <Typography className="text-start fs-13 font-gotham text-grey line-height">
                    Do you want to become a seller?
                  </Typography>
                }
              />
              {isSeller && (
                <>
                  <InputLabel
                    htmlFor="inputLocation"
                    className="text-start fs-15 text-black font-skmodernist mt-3 mb-2"
                  >
                    Who are you?
                  </InputLabel>
                  <ColorToggleButton
                    className="font-skmodernist"
                    data={toggleChoice1}
                    callback={(arg) => {
                      handleToggle(arg);
                    }}
                    selIndex={selIndex}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Get started
                  </Button>
                </>
              )}

              {!isSeller && (
                <>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Get started
                  </Button>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>,
    <WebCompleteProfile
      formData={form}
      locSearchVal={locSearchVal}
      errors={errors}
      handleSubmit={(e) => handleSubmit(e)}
      isSeller={isSeller}
      handleCheckChange={handleCheckChange}
      toggleData={toggleChoice1}
      handleToggle={handleToggle}
      selIndex={selIndex}
      isFromHomeScreen={isFromHomeScreen}
      handleNameChange={handleNameChange}
      onLocationInputChange={onLocationInputChange}
      handleEmailChange={handleEmailChange}
      email={email}
      onLocationChange={onLocationChange}
      mapOtions={mapOtions}
      searchLocInpRef={searchLocInpRef}
      locationAutoCompleteOpen={open}
      onLocationAutoCompleteOpen={openPopper}
      onLocationAutoCompleteClose={closePopper}
      getAddress2DefaultVal={getAddress2DefaultVal}
      getLocationString={getLocationString}
    />
  );
};

CompleteProfileForm.propTypes = {
  // Adjust the prop type based on your actual data type
  mobile_number: PropTypes.string,
  sendSignupData: PropTypes.func,
  country_code: PropTypes.string,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  userData: PropTypes.object,
  pushMessage: PropTypes.func,
  putUpdateUserDetails: PropTypes.func,
  enquiry: PropTypes.object,
  updateUserDetails: func,
  updateUserData: func,
  name: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  // addRoleData: PropTypes.func,
  setRoleData: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { enquiry } = state.b2bVendorlisting;

  console.log(' enquiry complete profile', enquiry);
  console.log('==userData 456', state.signin);
  const { email = '', city = '', name = '' } = userData?.user || {};

  return {
    mobile_number: state.mobile.mobile_number,
    country_code: state.mobile.country_code,
    isLoading: state.root.isLoading,
    userData,
    enquiry,
    name,
    city,
    email,
  };
};

export default connect(mapStateToProps, {
  sendSignupData,
  putUpdateUserDetails,
  loading,
  pushMessage,
  updateUserDetails,
  updateUserData,
  // addRoleData,
  setRoleData,
})(CompleteProfileForm);
