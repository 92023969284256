import {
  SAVE_PRODUCT_IMAGES,
  GET_PRODUCTS_DATA,
  SAVE_PRODUCT_DATA,
  PRODUCT_DESC,
  PRODUCT_DETAIL,
  PRODUCT_BUDGET,
  PRODUCT_MOQ,
  PRODUCT_SUB_CATEGORY,
  PAGE_ACTION_TYPE,
  SINGLE_PRODUCT,
  RESET_CATALOG_DATA,
  REMOVE_PROD_IMAGE,
  PRODUCT_UNIT,
  SET_DELETE_PROD_IMAGE,
  GET_ALL_UNIT,
} from '../types';
import {
  deleteSingleProductImage,
  getMiscellaneousType,
  postSingleProduct,
  putSingleProductUpdate,
} from '../../../../utils/APIs/endpoints';
import { PRODUCT_IMAGE_BASE_URL } from '../../../../utils/APIs/api';

const saveProductImage = (productImage) => {
  // console.log("==saveProductImage", productImage);
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_PRODUCT_IMAGES,
        payload: productImage,
      });
    } catch (error) {
      console.log('Error saving product_image', error);
    }
  };
};

export const getProductsList = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: GET_PRODUCTS_DATA,
      });
    } catch (error) {
      console.log('Error saving product_image', error);
    }
  };
};

export const saveProductData = (product) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_PRODUCT_DATA,
        payload: product,
      });
    } catch (error) {
      console.log('product data saving error ', error);
    }
  };
};

export const setProductDesc = (desc) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_DESC, payload: desc });
  };

export const setProductDetail = (detail) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_DETAIL, payload: detail });
  };

export const setProductBudget = (budget) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_BUDGET, payload: budget });
  };

export const setProductMOQ = (moq) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_MOQ, payload: moq });
  };
export const setProductSubCategory = (subCategory) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_SUB_CATEGORY, payload: subCategory });
  };

export const uploadProductData = (
  imageData,
  type,
  registrationId,
  title,
  description,
  budget,
  moq,
  subCategoryId,
  unit,
  accessToken,
  successCallback,
  errorCallback,
  customHeader
) =>
  async function () {
    if (!imageData) {
      console.log('[add-catalog-action] ABORTING... No Image Selected');

      return;
    }
    try {
      const formData = new FormData();
      Array.from(imageData).forEach((file) => {
        formData.append('images', file);
      });
      // const fileListAsArray = Array.from(imageData)
      // formData.append("images", fileListAsArray);
      formData.append('type', type);
      formData.append('registrationId', registrationId);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('budget', budget);
      if (moq) formData.append('moq', moq);
      formData.append('category_id', subCategoryId);
      if (unit) formData.append('unit', unit);

      const response = await postSingleProduct(
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Product Preogress - ', percentCompleted);
          // dispatch({
          //   type: SET_PRODUCT_UPLOAD_PROGRESS,
          //   payload: percentCompleted,
          // });
        },
        customHeader
      );
      if (response.status === 201) {
        console.log(
          '[add-catalog-action-action] uploadProductData Success',
          response.data
        );
        successCallback();
      }
    } catch (error) {
      console.log('[add-catalog-action-action] uploadProductData Error', error);
      errorCallback(error?.response?.data.error);
    }
  };

// Edit
export const editProductData = (
  sku,
  imageData,
  type,
  registrationId,
  title,
  description,
  budget,
  moq,
  subCategoryId,
  unit,
  accessToken,
  successCallback,
  errorCallback,
  customHeader
) =>
  async function () {
    // if (!imageData) {
    //   console.log(
    //     '[edit-catalog-action] ABORTING... No Image Selected'
    //   );
    //   return;
    // }
    try {
      const formData = new FormData();
      let productImages = '';
      Array.from(imageData).forEach((file) => {
        if (typeof file === 'string') {
          productImages += file.replace(PRODUCT_IMAGE_BASE_URL, '') + ',';
        } else {
          formData.append('images', file);
        }
      });
      if (productImages.length > 0) {
        productImages = productImages.substring(0, productImages.length - 1);
        formData.append('product_images', productImages);
      }
      // const fileListAsArray = Array.from(imageData)
      // formData.append("images", fileListAsArray);
      formData.append('type', type);
      formData.append('registrationId', registrationId);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('budget', budget);
      if (moq) formData.append('moq', moq);
      formData.append('category_id', subCategoryId);
      if (unit) formData.append('unit', unit);

      const response = await putSingleProductUpdate(
        sku,
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Product Preogress - ', percentCompleted);
          // dispatch({
          //   type: SET_PRODUCT_UPLOAD_PROGRESS,
          //   payload: percentCompleted,
          // });
        },
        customHeader
      );
      if (response.status === 200) {
        console.log(
          '[add-catalog-action-action] editProductData Success',
          response.data
        );
        successCallback();
      }
    } catch (error) {
      console.log('[add-catalog-action-action] editProductData Error', error);
      errorCallback(error?.response?.data.error);
    }
  };

export const setPageActionType = (type) =>
  function (dispatch) {
    dispatch({ type: PAGE_ACTION_TYPE, payload: type });
  };

export const setSingleData = (data) =>
  function (dispatch) {
    dispatch({ type: SINGLE_PRODUCT, payload: data });
  };

export const resetAddCatalogData = () =>
  function (dispatch) {
    dispatch({ type: RESET_CATALOG_DATA });
  };

export const removeProdImage = (
  index,
  prodId,
  imgName,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function () {
    try {
      console.log('removeProdImage imgName, prodId', imgName, prodId);
      const path = new URL(imgName).pathname;
      const segments = path.split('/');

      const cleanedSegments = segments.filter(
        (segment) =>
          segment &&
          segment !== 'uploads' &&
          segment !== 'products' &&
          segment !== 'products_images'
      );

      const cleanedPath = '/' + cleanedSegments.join('/');

      console.log('filename removeProdImage', imgName);
      const data = { data: { image_name: cleanedPath } };
      const response = await deleteSingleProductImage(prodId, data);
      if (response.status === 200) {
        console.log(
          '[add-catalog-action-action] removeProdImage Success',
          response.data
        );
        // dispatch({ type: REMOVE_PROD_IMAGE, payload: index });
        successCallback();
      } else {
        console.log(
          '[add-catalog-action-action] removeProdImage Error',
          response.data
        );
        errroCallback();
      }
    } catch (err) {
      console.log('[add-catalog-action-action] removeProdImage Error', err);
      errroCallback();
    }
  };

export const getMiscellaneousData = (
  type,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await getMiscellaneousType(type);
      if (response.status === 200) {
        console.log(
          '[add-catalog-action-action] getMiscellaneousData Success',
          response.data
        );
        dispatch(getStoreUnit(response.data?.module));
        successCallback();
      } else {
        console.log(
          '[add-catalog-action-action] removeProdImage Error',
          response.data
        );
        errroCallback();
      }
    } catch (err) {
      console.log('[add-catalog-action-action] removeProdImage Error', err);
      errroCallback();
    }
  };

export const removeAddProdImage = (index) =>
  function (dispatch) {
    dispatch({ type: REMOVE_PROD_IMAGE, payload: index });
  };

export const setProductUnit = (unit) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_UNIT, payload: unit });
  };

export const setDeleteProdImages = (imgArr) =>
  function (dispatch) {
    dispatch({ type: SET_DELETE_PROD_IMAGE, payload: imgArr });
  };
export const getStoreUnit = (units) =>
  function (dispatch) {
    dispatch({ type: GET_ALL_UNIT, payload: units });
  };

export default saveProductImage;
