import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { List, Paper, Grid, Button, Typography, CardMedia, Box, Divider } from '@mui/material';
// import { ReactComponent as SuccessImage } from '../../assets/success-banner.svg';
// import Divider from '@mui/material/Divider';
// import { Container, Box, ListItem, ListItemText, Divider } from '@mui/material';
// import { createTheme } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ProductImg1 } from '../../assets/catalog-product-img-1.svg';
import { ReactComponent as ProductImg2 } from '../../assets/catalog-product-img-2.svg';
import { ReactComponent as ProductImg3 } from '../../assets/catalog-product-img-3.svg';
import { ReactComponent as ProductImg4 } from '../../assets/catalog-product-img-4.svg';
import { useMediaQuery } from 'react-responsive';
import ProductImg5 from '../../assets/cat-product-1.png';
import ProductImg6 from '../../assets/cat-product-2.png';
import ProductImg7 from '../../assets/cat-product-3.png';
import ProductImg8 from '../../assets/cat-product-4.png';

// const theme = createTheme();



const ProductCatalogListForm = () => {
    // const history = useHistory();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)', });
    const isTabletOnly = useMediaQuery({
        query: '(min-width: 600px) and (max-width: 1023px)',
    });
    const isMobileOnly = useMediaQuery({ query: '(max-width: 600px)' });
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    const lamps = [
        {
            id: 1,
            title: 'Hanging Lamp',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard',
            price: '₹ 899.00',
            imageUrl: <ProductImg1 />, // Replace with actual image URLs
            imageMobileUrl: <img src={ProductImg5} />
        },
        {
            id: 2,
            title: 'Dayton Pendant',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard',
            price: '₹ 899.00',
            imageUrl: <ProductImg2 />, // Replace with actual image URLs
            imageMobileUrl: <img src={ProductImg6} />
        },
        {
            id: 3,
            title: 'Esfera Hanging Lamp',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard',
            price: '₹ 899.00',
            imageUrl: <ProductImg3 />, // Replace with actual image URLs
            imageMobileUrl: <img src={ProductImg7} />
        },
        {
            id: 4,
            title: 'Glass Hanging Light',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard',
            price: '₹ 899.00',
            imageUrl: <ProductImg4 />, // Replace with actual image URLs
            imageMobileUrl: <img src={ProductImg8} />
        },
    ];


    // const styles = {
    //     root: {
    //         width: '100%',
    //         maxWidth: 780,
    //         margin: '0 auto',
    //         backgroundColor: '#fff',
    //     },
    //     paper: {
    //         padding: 16,
    //         margin: 'auto',
    //         maxWidth: 780,
    //         boxShadow: 'none'
    //     },
    //     media: {
    //         width: 350,
    //         height: 274,
    //     },
    //     content: {
    //         border: '2px solid #dcdcdc',
    //         padding: '20px 32px',
    //         height: '273px'
    //     },
    //     price: {
    //         color: '#3f51b5',
    //     },
    //     button: {
    //         backgroundColor: '#864FFD',
    //         color: 'white',
    //         fontSize: '20px',
    //         fontFamily: 'Sk-Modernist',
    //         padding: '5px 20px'
    //     },
    // };

    return (
        <>
            {isDesktopOrLaptop && (<>
                <List className="product-catalog-list">
                    {lamps.map((lamp) => (
                        <Paper className='paper' key={lamp.id} >
                            <Grid container spacing={1}>
                                <Grid item xs={6}>

                                    <CardMedia className='media' component="div">
                                        {lamp.imageUrl}
                                    </CardMedia>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid direction="column" spacing={2}>
                                        <Box className="content" >
                                            <Typography className="fs-28 font-skmodernist fw-bold line-height-20 mb-3">
                                                {lamp.title}
                                            </Typography>
                                            <Typography className="fs-17 font-gotham text-darkgrey mb-3">
                                                {lamp.description}
                                            </Typography>
                                            <Button variant="contained" className='price-btn'>
                                                {lamp.price}
                                            </Button>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </List>
                <Box className="d-flex align-items-center py-3" sx={{
                    gap: '24px', position: 'sticky',
                    bottom: 0,
                    justifyContent: 'space-between',
                    background: "#fff",
                    maxWidth: "780px",
                    margin: "0 auto",
                    padding: "16px"
                }}>
                    <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography>
                    <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '55%' }} />
                    <Typography className='fs-18 font-skmodernist'>02</Typography>
                </Box>


            </>)
            }

            {isTabletOnly && (<>
                <List className="product-catalog-list">
                    {lamps.map((lamp) => (
                        <Paper className='paper' key={lamp.id} >
                            <Grid container spacing={1}>
                                <Grid item xs={6}>

                                    <CardMedia className='media' component="div">
                                        {lamp.imageUrl}
                                    </CardMedia>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid direction="column" spacing={2}>
                                        <Box className="content" >
                                            <Typography className="fs-28 font-skmodernist fw-bold line-height-20 mb-3">
                                                {lamp.title}
                                            </Typography>
                                            <Typography className="fs-17 font-gotham text-darkgrey mb-3">
                                                {lamp.description}
                                            </Typography>
                                            <Button variant="contained" className='price-btn'>
                                                {lamp.price}
                                            </Button>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </List>
                <Box className="d-flex align-items-center py-3" sx={{
                    gap: '24px', position: 'sticky',
                    bottom: 0,
                    justifyContent: 'space-between',
                    background: "#fff",
                    maxWidth: "780px",
                    margin: "0 auto",
                    padding: "16px"
                }}>
                    <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography>
                    <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '55%' }} />
                    <Typography className='fs-18 font-skmodernist'>02</Typography>
                </Box>
            </>)
            }

            {isMobileOnly && (<>
                <List className="product-catalog-mobile-list">
                    {lamps.map((lamp) => (
                        <Paper className='paper' key={lamp.id} >
                            <Grid container spacing={1}>
                                <Grid item xs={5}>

                                    <CardMedia className='media' component="div">
                                        {lamp.imageMobileUrl}
                                    </CardMedia>
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid direction="column" spacing={2}>
                                        <Box className="content" >
                                            <Typography className="fs-16 font-skmodernist fw-bold line-height-20 mb-2">
                                                {lamp.title}
                                            </Typography>
                                            <Typography className="fs-12 font-gotham text-darkgrey mb-2">
                                                {lamp.description}
                                            </Typography>
                                            <Button variant="contained" className='price-btn'>
                                                {lamp.price}
                                            </Button>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </List>
                <Box className="d-flex align-items-center py-3" sx={{
                    gap: '10px', position: 'sticky',
                    bottom: 0,
                    // left: 0,
                    justifyContent: 'space-between',
                    background: "#fff",
                    maxWidth: "400px",
                    margin: "0 auto",
                    padding: "16px"
                }}>
                    <Typography className='fs-14  font-skmodernist'>Powered by: www.elynker.com</Typography>
                    <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '30%' }} />
                    <Typography className='fs-18 font-skmodernist'>02</Typography>
                </Box>
            </>)
            }
        </>


    );


};

export default ProductCatalogListForm;