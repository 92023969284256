import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  circle: {
    stroke: 'url(#linearColors) !important',
    strokeLinecap: 'round !important',
  },
}));

export default function GradientCircularProgress({ value = 1, size = 90 }) {
  const classes = useStyles({});

  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#864FFD" />
          <stop offset="80%" stopColor="#1843A0" />
        </linearGradient>
      </svg>
      <CircularProgress
        variant="determinate"
        value={value}
        thickness={4}
        classes={{ circle: classes.circle }}
        size={size}
        sx={{
          '.MuiCircularProgress-circle > circle': {
            path: { stroke: `url(#linearColors)`, height: '100%' },
          },
        }}
      />
    </>
  );
}

GradientCircularProgress.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number,
};
