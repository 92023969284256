import { FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { string } from "prop-types";
import React from "react";
import { connect } from "react-redux";


const MobileNoWidget = ({mobile_number}) => {
    return(
      <Grid item md={6} lg={6} xs={12}>
        <InputLabel
          htmlFor="inputName"
          className="text-start fs-14 text-black font-skmodernist"
        >
          Mobile No.
        </InputLabel>
        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
          <OutlinedInput
            id="mobile_number"
            type="number"
            placeholder="Enter your mobile no."
            fullWidth
            value={mobile_number}
            disabled
            margin="none"
          />
        </FormControl>
      </Grid>
    );
  }

MobileNoWidget.propTypes = {
    mobile_number: string,
}
const mapStateToPropsMobile = (state) => {
    console.log('===global state', state);
    //regDetails
    const { mobile_number } = state?.signin?.userDataNew?.user || {mobile_number: ""};
    return {
      mobile_number,
    };
  };
export default connect(mapStateToPropsMobile, {})(MobileNoWidget)