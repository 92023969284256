import { SET_CHILDREN_WIDGET_DATA, SET_TNT_FOUR_DATA } from "../types"

const initialState = { 
    single_child_data : [],
    get_tnt_for_data : []
}


const personalServiceReducer = (state=initialState, action) => {
    switch(action.type){
        case SET_CHILDREN_WIDGET_DATA:
            return {
                ...state,
                single_child_data: action.payload
            }
        case SET_TNT_FOUR_DATA:
            return {
                ...state,
                get_tnt_for_data: action.payload
            }
        default:
            return state
    }

}


export default personalServiceReducer;