import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import {
    COMPANY_LOGO_BASE_URL
} from '../../utils/APIs/api';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';



const ShareBusinessCardModal = ({ open, onClose, userData, getMinimumPrice, allProducts }) => {


    const handleSave = () => {
        // Handle save logic here
        onClose();
    };

    const handleCancel = () => {
        onClose(true); // Close the modal
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="custom-border-header pb-0">
                <Box className="position-relative d-flex justify-content-between">
                    <Typography className="fs-22 fw-bold font-skmodernist" >
                        Share your E-Business Card
                    </Typography>
                    <Box >
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
            </DialogTitle>

            <DialogContent>
                <Card className="e-business-card mb-3">
                    <CardContent className="e-business-content">
                        <div className="d-flex">
                            <div>
                                <img
                                    src={
                                        userData?.registration?.image_path
                                            ? COMPANY_LOGO_BASE_URL +
                                            userData?.registration?.image_path
                                            : require('../../assets/profile-image.png')
                                    }
                                    width={'96px'}
                                    height={'96px'}
                                    style={{ borderRadius: 100 }}
                                ></img>
                            </div>
                            <div className="ms-3">
                                <Typography
                                    variant="h5"
                                    className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                                    component="div"
                                >
                                    {userData?.user?.name}
                                </Typography>
                                {/* <Typography
                            variant="body2"
                            className="fs-14 text-white mb-3 font-gotham"
                          >
                            {userData?.registration?.freelancer_bio}
                          </Typography> */}

                                <div className="d-flex align-items-center">
                                    <div className="d-flex me-4 mb-2">
                                        {/* <DeliveryIcon className="delivery-icon" /> */}
                                        <Typography
                                            variant="body2"
                                            className="fs-13 text-white fw-normal font-skmodernist"
                                        >
                                            {/* {allProducts?.length} deliveries */}
                                            {userData?.registration?.company_name} <br />
                                            {userData?.user?.email} <br />
                                            {userData?.user?.country_code}-
                                            {userData?.user?.mobile_number}
                                        </Typography>
                                    </div>

                                    {/* <div className="d-flex mb-2">
                              <RatingIcon className="rating-icon" />
                              <Typography
                                variant="body2"
                                className="fs-13 text-white  fw-normal font-skmodernist"
                              >
                                {userData?.registration?.rating}
                              </Typography>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        <Divider
                            component="div"
                            className="divider my-2"
                            role="presentation"
                        ></Divider>
                        <div className="d-flex justify-content-between align-items-center">
                            <ElynkerLogoComponent>
                                <ElynkerLogo width={'54px'} height={'16px'} />
                            </ElynkerLogoComponent>
                            {getMinimumPrice(allProducts) && (
                                <div>
                                    <Typography
                                        variant="body2"
                                        className="fs-12 text-white fw-normal font-skmodernist"
                                    >
                                        Starting at <br />
                                        <span className="text-primary-2 fw-bold fs-15">
                                            ₹{getMinimumPrice(allProducts)}/
                                        </span>
                                        Products
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions className="modal-footer">
                <Button onClick={handleCancel} className="btn-cancel me-2">
                    Cancel
                </Button>
                <Button onClick={handleSave} className="btn-save">
                    Share Card
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ShareBusinessCardModal.propTypes = {
    open: PropTypes.bool.isRequired, // 'open' prop should be a boolean and is required
    onClose: PropTypes.func.isRequired, // 'onClose' prop should be a function and is required
    userData: PropTypes.object,
    allProducts: PropTypes.array,
    getMinimumPrice: PropTypes.func


};

export default ShareBusinessCardModal;