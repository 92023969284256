import {
  ADD_FEEDBACK,
  CLEAR_FEEDBACK,
  REMOVE_FEEDBACK_IMAGE,
  SAVE_FEEDBACK,
} from '../types';

const INITIAL_STATE = {
  feedback: {
    images: [],
    deleteProdImgs: [],
  },
  feedbacks: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FEEDBACK: {
      if (action.payload?.image) {
        if (state.feedback.images !== null) {
          const allImgs = Array.from(action.payload.image).concat(
            Array.from(state.feedback.images)
          );
          return {
            ...state,
            feedback: { ...state.feedback, images: allImgs },
          };
        } else {
          return {
            ...state,
            feedback: { ...state.feedback, images: action.payload.image },
          };
        }
      } else {
        return {
          ...state,
          feedback: { ...state.feedback, ...action.payload },
        };
      }
    }
    case REMOVE_FEEDBACK_IMAGE: {
      const fileListArr = Array.isArray(state.feedback.images)
        ? [...state.feedback.images]
        : [];
      if (fileListArr.length > 0) {
        fileListArr.splice(action.payload, 1);
      }
      return {
        ...state,
        feedback: { ...state.feedback, images: fileListArr },
      };
    }

    case SAVE_FEEDBACK: {
      return {
        ...state,
        feedbacks: [...state.feedbacks, ...action.payload],
      };
    }
    case CLEAR_FEEDBACK:
      return INITIAL_STATE;
    default:
      return state;
  }
};
