import React from 'react';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';

const SimpleDropdown = ({ options, onSelect }) => {
    return (
        <FormControl variant="outlined">
            {options?.map((option, index) => (
                <MenuItem key={index} value={option.value} onClick={() => onSelect(option.value)}>
                    {option.value}
                </MenuItem>
            ))}
        </FormControl>
    );
};

SimpleDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default SimpleDropdown;
