import { loading } from '../../../../store/actions/rootActions';
import { getPageCMSbySearch } from '../../../../utils/APIs/endpoints';
import { RESET_TEMPLATE_DATA, SET_PAGE_DATA_FROM_KEY, SET_TEMPLATE_RENDER } from '../types';

export const setTemplateRender = (set_data) => {
	return async (dispatch) => {
		dispatch({ type: SET_TEMPLATE_RENDER, payload: set_data });
	};
};

export const getPageContentbySearch = (key, value) =>
	async function (dispatch) {
		try {
			dispatch(loading(true));
			const response = await getPageCMSbySearch(key, value);
			console.log('getPageContentbySearch RESPONSE', response.data);
			if (response.status === 200) {
				dispatch({
					type: SET_PAGE_DATA_FROM_KEY,
					payload: response.data?.data,
				});
			}
		} catch (error) {
			console.log('[template-action] getPageCMSbySearch Error', error);
		} finally {
			dispatch(loading(false));
		}
	};

	export const resetTemplateData = () => {
		return async (dispatch) => {
			dispatch({ type: RESET_TEMPLATE_DATA});
		};
	};