import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
// import PropTypes from 'prop-types';
import { Container, Grid, Button, AppBar, Toolbar, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import UserIcon from '../../assets/user-purple-icon.svg';
import EmailIcon from '../../assets/email-purple-icon.svg';
import CallIcon from '../../assets/call-purple-icon.svg';
import CityIcon from '../../assets/city-purple-icon.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { setB2CprofileData } from './redux/actions/b2c-profile-action';
// import { handleLogoutReset } from '../../utils/helper-funtions';
import { resetUserData } from '../Otp/redux/actions/submit-code-action';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import Responsive from '../../utils/responsive';


const theme = createTheme();

const B2CProfileForm = ({ userData, setB2CprofileData, resetUserData }) => {
    const history = useHistory();

    console.log("userData : ", userData);

    const handleReset = () => {
        resetUserData();
        history.replace('/homepage');
    }

    const handleEditProfile = (userData) => {
        let newUserData = { ...userData.user }
        newUserData.city = {
            "properties": {
                "name": userData?.user?.city,
            }
        }
        setB2CprofileData(newUserData);
        history.push("/b2c-edit-profile");
    }


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 'auto' }}
                >
                    <Grid item xs={12}>
                        <div >
                            <AppBar position="static">
                                <Toolbar>
                                    {/* <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon />
                                    </IconButton> */}
                                    <div style={{ flexGrow: 1 }}>
                                        <ElynkerLogoComponent>
                                            <ElynkerLogo width={'100px'} height={'30px'} />
                                        </ElynkerLogoComponent>
                                    </div>
                                    {
                                        <div>
                                            <Button
                                                variant="contained"
                                                className="edit-profile-btn"
                                                onClick={() => handleEditProfile(userData)}
                                            >
                                                Edit Profile
                                            </Button>
                                        </div>
                                    }
                                </Toolbar>
                            </AppBar>
                        </div>
                        <Divider
                            component="div"
                            className="main-divider mb-3"
                            role="presentation"
                        />


                        <form style={{ marginBottom: '100px' }}
                        >
                            <div className="d-flex align-items-end my-3">
                                <div>
                                    <img
                                        src={UserIcon}
                                        alt="user-logo"
                                        width={24}
                                        height={24}
                                        className="user-logo me-3 mb-2"
                                    />
                                </div>
                                <div>
                                    <Typography
                                        className="text-start fs-16 font-skmodernist text-black fw-bold line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        Name
                                    </Typography>
                                    <Typography
                                        className="text-start fs-14 font-gotham text-grey line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {userData?.user?.name}
                                    </Typography>
                                </div>
                            </div>
                            <Divider
                                component="div"
                                className="mb-3"
                                role="presentation"
                            />

                            <div className="d-flex align-items-end my-3">
                                <img
                                    src={EmailIcon}
                                    alt="user-logo"
                                    width={24}
                                    height={24}
                                    className="user-logo me-3 mb-2"
                                />
                                <div>
                                    <Typography
                                        className="text-start fs-16 font-skmodernist text-black fw-bold line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        Email
                                    </Typography>
                                    <Typography
                                        className="text-start fs-14 font-gotham text-grey line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {userData?.user?.email}
                                    </Typography>
                                </div>
                            </div>
                            <Divider
                                component="div"
                                className=" mb-3"
                                role="presentation"
                            />

                            <div className="d-flex align-items-end my-3">
                                <img
                                    src={CallIcon}
                                    alt="user-logo"
                                    width={24}
                                    height={24}
                                    className="user-logo me-3 mb-2"
                                />
                                <div>
                                    <Typography
                                        className="text-start fs-16 font-skmodernist text-black fw-bold line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        Phone
                                    </Typography>
                                    <Typography
                                        className="text-start fs-14 font-gotham text-grey line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {userData?.user?.mobile_number}
                                    </Typography>
                                </div>

                            </div>
                            <Divider
                                component="div"
                                className=" mb-3"
                                role="presentation"
                            />

                            <div className="d-flex align-items-end my-3">
                                <img
                                    src={CityIcon}
                                    alt="user-logo"
                                    width={24}
                                    height={24}
                                    className="user-logo me-3 mb-2"
                                />
                                <div>
                                    <Typography
                                        className="text-start fs-16 font-skmodernist text-black fw-bold line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        City
                                    </Typography>
                                    <Typography
                                        className="text-start fs-14 font-gotham text-grey line-height"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {userData?.user?.city}
                                    </Typography>
                                </div>
                            </div>
                            <Divider
                                component="div"
                                className=" mb-3"
                                role="presentation"
                            />
                        </form>



                        {Responsive(
                            <>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    className="mb-4 logout-btn"
                                    onClick={handleReset}
                                >
                                    Logout
                                </Button>
                            </>,
                            <>
                                <div className='d-flex justify-content-end mt-5'>
                                    <Button
                                        type="button"
                                        
                                        variant="contained"
                                        className="mb-4 logout-btn"
                                        sx={{paddingLeft: "20px !important", paddingRight: "20px !important"}}
                                        onClick={handleReset}
                                    >
                                        Logout
                                    </Button>
                                </div>
                            </>
                        )
                        }
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

// UpdateProfileForm.propTypes = {
//   b2bLvl1Categories: PropTypes.array,
//   selectedLvl1Categories: PropTypes.array,
// }


// const mapStateToProps = (state) => {
//   const { userData } = state.signin;
//   const {
//     b2bLvl1Categories,
//     selectedLvl1Categories,
//   } = state.offeredServices;

//   return {
//     b2bLvl1Categories,
//     selectedLvl1Categories,
//   };
// };

// export default connect(mapStateToProps, {

// })(UpdateProfileForm);
B2CProfileForm.propTypes = {
    // Adjust the prop type based on your actual data type
    userData: PropTypes.object,
    setB2CprofileData: PropTypes.func,
    resetUserData: PropTypes.func
};


const mapStateToProps = (state) => {
    const { userData } = state.signin;
    return {
        userData
    }
}

export default connect(mapStateToProps, { setB2CprofileData, resetUserData })(B2CProfileForm);