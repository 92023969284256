import {
  SAVE_PRODUCT_IMAGES,
  GET_PRODUCTS_DATA,
  SAVE_PRODUCT_DATA,
  PRODUCT_DESC,
  PRODUCT_DETAIL,
  PRODUCT_BUDGET,
  YEARS_OF_EXPERIENCE,
  PRODUCT_SUB_CATEGORY,
  SINGLE_PRODUCT,
  PAGE_ACTION_TYPE,
  RESET_FL_ADD_DATA,
  REMOVE_PROD_IMAGE,
  PORTFOLIO_LINK,
  SET_DELETE_PROD_IMAGE
} from '../types';

const INITIAL_STATE = {
  images: null,
  type: '',
  registrationId: 0,
  title: '',
  description: '',
  budget: "",
  yearOfExp: "",
  category_id: 0,
  subCategoryId: 0,
  singleProduct: {},
  pageActionType: 'Add',
  portfolioLink: '',
  deleteProdImgs: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_IMAGES: {
      if (state.images !== null) {
        const allImgs = Array.from(action.payload).concat(
          Array.from(state.images)
        );
        return {
          ...state,
          images: allImgs,
        };
      } else {
        return {
          ...state,
          images: action.payload,
        };
      }
      // return {
      //     ...state, images:action.payload
      // };
    }

    case GET_PRODUCTS_DATA:
      return state;

    case SAVE_PRODUCT_DATA:
      return {
        ...state,
        products: action.payload,
      };

    case PRODUCT_DESC:
      return {
        ...state,
        description: action.payload,
      };
    case PRODUCT_DETAIL:
      return {
        ...state,
        title: action.payload,
      };
    case PRODUCT_BUDGET:
      return {
        ...state,
        budget: action.payload,
      };
    case YEARS_OF_EXPERIENCE:
      return {
        ...state,
        yearOfExp: action.payload,
      };
    case PRODUCT_SUB_CATEGORY:
      return {
        ...state,
        subCategoryId: action.payload,
      };
    case SINGLE_PRODUCT:
      return {
        ...state,
        singleProduct: action.payload,
      };
    case PAGE_ACTION_TYPE:
      return {
        ...state,
        pageActionType: action.payload,
      };
    case REMOVE_PROD_IMAGE: {
      const fileListArr = Array.isArray(state.images) ? [...state.images] : [];
      fileListArr.splice(action.payload, 1);
      return {
        ...state,
        images: fileListArr,
      };
    }
    case PORTFOLIO_LINK:
      return {
        ...state,
        portfolioLink: action.payload,
      };

    case SET_DELETE_PROD_IMAGE:
      return { ...state, deleteProdImgs: action.payload };

    case RESET_FL_ADD_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
