import {
  RESET_CUSTOMER_PROFILE_DATA,
  SET_NEW_COMPANY_LOGO,
  SET_NEW_COVER_PHOTO,
  SET_TOP_LEVEL_PRODUCT_CATEGORIES,
} from '../types';

const INITIAL_STATE = {
  newSelectedCoverPic: '',
  newSelectedCompanyLogo: '',
  topLevelAddedCategories: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NEW_COVER_PHOTO:
      return { ...state, newSelectedCoverPic: action.payload };
    case SET_NEW_COMPANY_LOGO:
      return { ...state, newSelectedCompanyLogo: action.payload };
    case SET_TOP_LEVEL_PRODUCT_CATEGORIES:
      return { ...state, topLevelAddedCategories: action.payload };
    case RESET_CUSTOMER_PROFILE_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
