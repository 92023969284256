export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SINGLE_SUBSCRIPTION = 'GET_SINGLE_SUBSCRIPTION';
export const GET_ALL_SUBSCRIPTION_SERVICES = 'GET_ALL_SUBSCRIPTION_SERVICES';
export const CLEAR_ALL_SUBSCRIPTION_SERVICES =
	'CLEAR_ALL_SUBSCRIPTION_SERVICES';
export const GET_PREMIUM_PLAN = 'GET_PREMIUM_PLAN';
export const SET_SUBSCRIPTION_ORDER_DATA = 'SET_SUBSCRIPTION_ORDER_DATA';
export const SET_SUBSCRIPTION_DETAILS_DATA = 'SET_SUBSCRIPTION_DETAILS_DATA';
export const SET_CURRENT_SUBSCRIPTION_DETAIL =
	'SET_CURRENT_SUBSCRIPTION_DETAIL';
export const RESET_SUBSCRIPTION_DATA = 'RESET_SUBSCRIPTION_DATA';
