import html2canvas from 'html2canvas';

const downloadCanvasImage = async (cardContent) => {
  let filename = 'e-business-card.png';
  console.log('====downloadCanvasImage cardContent', cardContent);

  return html2canvas(cardContent, { useCORS: true }).then((canvas) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create blob'));
          return;
        }
        const file = new File([blob], filename, { type: 'image/png' });
        resolve(file);
      }, 'image/png');
    });
  });
};

export { downloadCanvasImage };
