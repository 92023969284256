import React, {createContext, useContext, useState} from 'react'
import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import ENV from '../env/env.json'
import { SET_USER } from '../store/types';
import PropTypes from 'prop-types';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState();

	const dispatch = useDispatch();

	const userLogin = async (creds) => {
		try {
			// const response = await axios.post(
			// 	ENV.API_URL + 'users/login',
			// 	creds
			// );
			setTimeout(() => {
				dispatch({ type: SET_USER, payload: creds });
				setUser(creds);
				return 'success';
			}, 2000)
		} catch (error) {
			console.log('Login Error', error);
			return 'failed';
		}
	};

	const userSignout = async () => {
		try {
			setTimeout(() => {
				dispatch({ type: SET_USER, payload: null });
				setUser(null);
				return 'success';
			}, 200);
		} catch (error) {
			console.log('Login Error', error);
			return 'failed';
		}
	};

	return (
		<AuthContext.Provider
			value={{
				userLogin,
				userSignout,
				user,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

const useAuth = () => {
	const auth = useContext(AuthContext);
	if (auth == null) {
		throw new Error('useAuth() called outside of a AuthProvider?');
	}
	return auth;
};

AuthProvider.propTypes = {
	children: PropTypes.element
}

export { AuthProvider, useAuth };