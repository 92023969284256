/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from 'react';
import '../../../common/fontsize.css';
// import './complete-profile.css';
import '../../../index.css';
import { Container, Grid, Typography, Button, Box, FormHelperText, Link, Backdrop, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import CallLogo from '../../assets/mobile-icon.svg';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../../assets/menu-black.svg';
import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { ReactComponent as ProfileIcon } from '../../../assets/user-black-icon.svg';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import CustomStepper from '../../components/CustomStepper/CustomStepper';
import Divider from '@mui/material/Divider';
// import { matchIsValidTel } from 'mui-tel-input';
import { MuiOtpInput } from 'mui-one-time-password-input'
// import { ReactComponent as MenuDesktopIcon } from '../../../assets/menu-dektop-icon.svg';
import { useMediaQuery } from 'react-responsive';
// import ElynkerLogoComponent from '../../../components/ElynkerLogo/ElynkerLogo';
import { bool, func, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { postBusinessRequirement, putBusinessRequirement, resetRequirementData, sendEnqCallbackMsg } from '../B2BSubmitRequirement/redux/actions/b2b-submit-requirement-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DEFAULT_REQ_CALLBACK_TIME_HRS, OTP_MIN, OTP_SEC } from '../../../utils/constants';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import { sendOTPForVerify } from '../../Otp/redux/actions/submit-code-action';
import { loading } from '../../../store/actions/rootActions';
import { sendMobForOTP } from '../../Login/redux/actions/submit-mobileno-action';

const theme = createTheme();

// const handleBTypeChange = (event) => {
//     setBusinessType(event.target.value);
//   };
// const customBlueColor = '#416AD4';

const B2BRequirementVerifyForm = ({
        postBusinessRequirement,
        userData,
        moq,
        businessType,
        name,
        category,
        location,
        description,
        budget,
        resetRequirementData,
        pageActionType,
        singleRequirement,
        putBusinessRequirement,
        sendEnqCallbackMsg,
        pushMessage,
        sendOTPForVerify,
        loading,
        sendMobForOTP,
        isLoading
    }) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)', });
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    // const [mobileNumber, setMobileNumber] = React.useState('+918793655054')
    // const [inputError, setInputError] = React.useState(false)

    // const handleChange = (newValue, info) => {
    //     console.log("test", matchIsValidTel(newValue))
    //     console.log(info)
    //     if (matchIsValidTel(newValue)) {
    //         setInputError(false)
    //     }
    //     else {
    //         setInputError(true)
    //     }
    //     setMobileNumber(newValue)
    // }
    const history = useHistory();
    const [otp, setOtp] = React.useState('');
    const [otpError, setotpError] = useState('');
    const [minutes, setMinutes] = useState(OTP_MIN);
    const [seconds, setSeconds] = useState(OTP_SEC);
    
    console.log("log data on location :", location);

    useEffect(() => {
        const interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
    
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(interval);
            } else {
              setSeconds(59);
              setMinutes(minutes - 1);
            }
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [seconds]);

    const handleOtpChange = (newValue) => {
        setotpError('');
        setOtp(newValue)
    }

    const successOTPCallback = async (data) => {
		// delete otp info from localstorage
		if (data?.success == true) {
			await submitRequirement();
		}

	};
	const errorOTPCallback = (message) => {
		console.log("Invalid otp");
		setotpError("Please Enter Correct OTP.")
		if (message){
			pushMessage(message);
		}
		// handleOpen();
	};
    const handleSubmit = (e) => {
		// e.preventDefault();
		const data = {
			phone: userData?.user?.mobile_number,
			userOtp: otp?.toString(),
		};
		sendOTPForVerify(data, successOTPCallback, errorOTPCallback);
	};
    const submitRequirement = async () =>{
        if (otp.length < 6) {
            // alert('Please Enter Correct OTP.');
            setotpError('Please Enter Correct OTP.');
            return;
          }
          loading(true);
        //create object
        let data = {
            "product_type" : Number(businessType),
            "product_service_name" : name,
            "category": category?.title,
            "location":location,
            "description": description,
            "registrationId": userData?.registration?.id
        }

        { businessType == "1" ?
           data = {...data, "order_quantity": Number(moq)} : data = {...data, "budget": budget}  }


        if(pageActionType == "Post"){
            // send Verify code
            await postBusinessRequirement(data, successCallback, errorCallback);
        }  else {
            //Edit
            await putBusinessRequirement(singleRequirement?.id, data, successCallback, errorCallback);
        }
    }
    const successCallback = () => {
        console.log("B2BRequirement success.");
        //clear reducer
        var data = { "phone" : Number(userData?.user?.mobile_number), "time": DEFAULT_REQ_CALLBACK_TIME_HRS};
        sendEnqCallbackMsg(data);
        resetRequirementData();
        loading(false);
        history.replace("/req-successful");
    }

    const errorCallback = () => {
        console.log("B2BRequirement Error");
        loading(false);
        resetRequirementData();
    }
	const successResendCallback = () => {
		loading(false);
	};
	const errorResendCallback = () => {
		loading(false);
	};

	const resendOTP = () => {
		// console.log("Resend button is clicked");
		setMinutes(OTP_MIN);
		setSeconds(OTP_SEC);

		const data = {
			phone: userData?.user?.mobile_number,
		};

		sendMobForOTP(data, successResendCallback, errorResendCallback);
	};

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg">
            {isLoading && (
                    <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={isLoading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 'auto' }}>
                    <Grid item xs={12}>
                    <div className='my-3'>
                            <ArrowBackIosIcon onClick={() => {
                                resetRequirementData();
                                history.push("b2b-requirement-product-category")}}/>
                            <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" />

                        {/* Include Custom Stepper here * /}
                        //Stepper Labels -> Details - Product Category - Verify (if radio selected is product)
                        //Stepper Labels -> Details - Service Category - Verify (if radio selected is service)
                        {/* <CustomStepper  /> */}

                        <form style={{ marginTop: '40px' }} onSubmit={(e)=>{
                                e.preventDefault();
                                handleSubmit();
                            }}>
                            <Typography
                                variant="h4"
                                align="start"
                                alignItems="center"
                                className="fs-22 mt-4 mb-1 fw-bold font-skmodernist"
                                gutterBottom>
                                Verify
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                className="fs-14 text-darkgrey mb-4 fw-normal font-gotham"
                                gutterBottom>
                                We sent it to the number{' '}
                                <span style={{ color: '#864FFD' }}>
                                    {userData?.user?.country_code} {userData?.user?.mobile_number}
                                </span>
                            </Typography>

                            {/* <Grid md={5} lg={5} xs={12} className=" mb-3">
                                <InputLabel
                                    htmlFor="inputMobile"
                                    className="text-start fs-14 text-black fw-normal font-skmodernist">
                                    Mobile Number
                                </InputLabel>
                                <div className="position-relative mb-2">
                                    <MuiTelInput
                                        forceCallingCode
                                        focusOnSelectCountry
                                        onlyCountries={['IN', 'AU']}
                                        defaultCountry="IN"
                                        fullWidth
                                        sx={{ my: 1 }}
                                        value={mobileNumber}
                                        disableDropdown
                                        onChange={handleChange}

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <div>
                                                        10/10
                                                        // {mobileNumber.length}/{mobileNumberLength}
                                                    </div>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </div>

                                <div>
                                    {
                                        inputError && 'Not Valid'
                                    }
                                </div>
                            </Grid> */}

                            <Grid md={5} lg={5} xs={12} className="mb-3">
                                <MuiOtpInput
                                    id="req-otp-id"
                                    value={otp}
                                    autoFocus
                                    itemType=""
                                    length={6}
                                    gap={2}
                                    TextFieldsProps={{
                                        type: 'text', // Change to 'text'
                                        inputMode: 'numeric',
                                        // Add the pattern and maxLength for better control
                                        pattern: "[0-9]",
                                        maxLength: 1, // Ensures only one digit per input
                                    }}
                                    validateChar={(val) => /^\d$/.test(val)} // Only allow single digit (0-9)
                                    onChange={handleOtpChange}
                                    onKeyDown={(evt) => {
                                        // Prevent non-numeric input
                                        if (['e', 'E', '+', '-', 'ArrowLeft', 'ArrowRight'].includes(evt.key)) {
                                        evt.preventDefault();
                                        }
                                    }}
                                    />
                                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                                    {otpError}
                                </FormHelperText>
                            </Grid>

                            <Grid md={5} lg={5} xs={12} className="d-flex justify-content-between mt-3 mb-3">
                            {seconds ? (
                                <Typography
                                    variant="body1"
                                    align="left"
                                    className="fs-14 text-grey fw-normal font-gotham"
                                    gutterBottom>
                                    Waiting for OTP:{' '}
                                    <span style={{ color: '#416AD4' }}>
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </span>
                                </Typography>
                                ) : (
                                <Link
                                    component="button"
                                    variant="body1"
                                    align="right"
                                    underline="none"
                                    onClick={resendOTP}
                                    className="fs-14 fw-normal font-gotham"
                                    gutterBottom
                                    style={{
                                        color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#416AD4',
                                      }}
                                      disabled={seconds > 0 || minutes > 0}
                                >
                                    Resend OTP?
                                </Link>
                                )}
                            </Grid>
                            {/* <Grid md={5} lg={5} xs={12}>
                                <Typography className="my-3 text-grey fs-13 font-gotham">
                                    By signing up, you agree to <b>Terms.</b>  See how we use your data in
                                    our <b>Privacy Policy.</b>
                                </Typography>

                            </Grid> */}


                            {isTabletOrMobile && (<>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                                >
                                    Submit
                                </Button>

                                {/* <Button
                                    // onClick={(e) => handleBackButton(e)}
                                    variant="text"
                                    type="button"
                                    fullWidth
                                    className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                                >
                                    Back
                                </Button> */}
                            </>)}

                            {isDesktopOrLaptop && (<>
                                <Box className="d-flex justify-content-between align-items-center my-4">
                                    <Button
                                        // onClick={(e) => handleBackButton(e)}
                                        onClick={() => {
                                            resetRequirementData();
                                            history.push("b2b-requirement-product-category")
                                        }}
                                        variant="text"
                                        type="button"
                                        className="btn-web-back"
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="btn-web-next"
                                    >
                                        Submit
                                    </Button>
                                </Box>

                            </>)}
                        </form>




                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider >
    );
};

B2BRequirementVerifyForm.propTypes = {
    postBusinessRequirement: func,
    userData: object,
    moq: number,
    businessType: number,
    name: string,
    category: object,
    location: string,
    description: string,
    budget: number,
    resetRequirementData: func,
    pageActionType: string,
    singleRequirement: object,
    putBusinessRequirement: func,
    sendEnqCallbackMsg: func,
    pushMessage: func,
    sendOTPForVerify: func,
    loading: func,
    sendMobForOTP: func,
    isLoading: bool
}

const mapStateToProps = (state) => {
    const {
        moq,
        businessType,
        name,
        category,
        location,
        description,
        budget,
        pageActionType,
        singleRequirement
    } = state.b2bRequirement;
    const { userData } = state.signin;
    const { isLoading } = state.root;
    return {
        userData,
        moq,
        businessType,
        name,
        category,
        location,
        description,
        budget,
        pageActionType,
        singleRequirement,
        isLoading
    }
}
export default connect( mapStateToProps, { postBusinessRequirement, 
    resetRequirementData, 
    putBusinessRequirement, 
    sendEnqCallbackMsg,
    pushMessage,
    sendOTPForVerify,
    loading,
    sendMobForOTP
})(B2BRequirementVerifyForm);
