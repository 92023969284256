import { B2C_PROFILE_EDIT } from "../types";

const initial_state = {
    b2cProfileObj : {}
}


const b2cProfileReducer = (state=initial_state, action) => {
    switch (action.type){
        case B2C_PROFILE_EDIT:
            return {
                ...state,
                b2cProfileObj: action.payload
            }
        default:
            return state;
    }
}



export default b2cProfileReducer;