import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Box, FormControl, InputLabel, OutlinedInput, TextareaAutosize, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {ReactComponent as CloseIcon} from '../../assets/close-icon.svg';



const RequestCallBackModal = ({ open, onClose }) => {


    const handleSave = () => {
        // Handle save logic here
        onClose();
    };

    const handleCancel = () => {
        onClose(true); // Close the modal
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            

            <DialogTitle className="custom-border-header pb-0">
                <Box className="position-relative d-flex justify-content-between">
                    <Typography className="fs-22 fw-bold font-skmodernist" >
                    Request a Call Back
                    </Typography>
                    <Box >
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="row">
                    <div className="col-md-6 mb-1">
                        <InputLabel
                            htmlFor="inputCompanyName"
                            className="text-start fs-14 text-black font-skmodernist">
                            Name
                        </InputLabel>
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">

                            <OutlinedInput
                                id="inputName"
                                // label="Mobile Number"
                                type="text"
                                placeholder="Enter name"
                                required
                                autoFocus
                                fullWidth
                                margin='none'
                            />
                        </FormControl>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 mb-1">
                        <InputLabel
                            htmlFor="inputEmailID"
                            className="text-start fs-14 text-black font-skmodernist">
                            Email ID
                        </InputLabel>
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">

                            <OutlinedInput
                                id="inputName"
                                // label="Mobile Number"
                                type="text"
                                placeholder="Enter email Id"
                                required
                                autoFocus
                                fullWidth
                                margin='none'
                            />
                        </FormControl>
                    </div>

                    <div className="col-md-6 mb-1">
                        <InputLabel
                            htmlFor="inputPhone"
                            className="text-start fs-14 text-black font-skmodernist">
                            Phone No.
                        </InputLabel>
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">

                            <OutlinedInput
                                id="inputName"
                                // label="Mobile Number"
                                type="text"
                                placeholder="Enter phone no."
                                required
                                autoFocus
                                fullWidth
                                margin='none'
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mb-2">
                        <InputLabel
                            htmlFor="inputCompanyName"
                            className="text-start fs-14 text-black font-skmodernist">
                            Comment
                        </InputLabel>
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                            <TextareaAutosize
                                id="inputComment"
                                placeholder="Enter comment"
                                minRows={3} // You can adjust the number of rows as needed
                                maxRows={2} // You can adjust the number of rows as needed
                            />
                        </FormControl>
                    </div>
                </div>






            </DialogContent>
            <DialogActions className="modal-footer">
                <Button onClick={handleCancel} className="btn-cancel me-2">
                    Cancel
                </Button>
                <Button onClick={handleSave} className="btn-save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RequestCallBackModal.propTypes = {
    open: PropTypes.bool.isRequired, // 'open' prop should be a boolean and is required
    onClose: PropTypes.func.isRequired, // 'onClose' prop should be a function and is required
};

export default RequestCallBackModal;