import { GET_REQUIREMENT, RESET_REQUIREMENT_LIST } from "../types";

const INITIAL_STATE = {
requirements:[],
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case GET_REQUIREMENT:
            return {
                ...state,
                requirements: action.payload
            }
        case RESET_REQUIREMENT_LIST:
            return INITIAL_STATE;
        default:
            return state
    }
}