import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import '../../index.css';
import { MuiOtpInput } from 'mui-one-time-password-input';
import PropTypes from 'prop-types';
// import { ModalStyle } from '../../utils/constants';
import { connect } from 'react-redux';
import { GAEvents } from '../../utils/helper-funtions';

function WebOtp({
  resendOTP,
  otp = '',
  otpError,
  handleSubmit,
  isLoading,
  maskFirstFiveCharacters,
  mobile_number,
  country_code,
  minutes,
  seconds,
  // open,
  // handleClose,
  handleChange,
  outReachMobile,
}) {
  return (
    <Container maxWidth="xl" className="p-0">
      <Grid container className="root" style={{ height: '100vh' }}>
        {' '}
        {/* Apply class name from CSS */}
        {/* The image half */}
        <Grid item md={7} lg={7} className="bgImage">
          {' '}
          {/* Apply class name from CSS */}
        </Grid>
        {/* The content half */}
        <Grid
          item
          md={5}
          lg={5}
          xs={12}
          className="bgMain"
          sx={{ margin: 'auto' }}
        >
          {' '}
          {/* Apply class name from CSS */}
          <div className="login">
            {/* Demo content */}
            <Container>
              {isLoading && (
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              {/* <Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={ModalStyle}>
								<Typography id="modal-modal-title" variant="h6" component="h2">
									Elynker
								</Typography>
								<Typography id="modal-modal-description" sx={{ mt: 2 }}>
									Invalid verification code provided, please try again.
								</Typography>
								<div style={{ float: 'right' }}>
									<Button
										onClick={() => {
											handleClose();
										}}
									>
										OK
									</Button>
								</div>
							</Box>
						</Modal> */}
              <Grid
                justifyContent="center"
                display={'flex'}
                alignContent={'center'}
              >
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <div className="mb-2">
                    <Typography
                      variant="h4"
                      align="left"
                      alignItems="center"
                      className="fs-34 mb-0 me-2 fw-bold text-black font-skmodernist"
                      gutterBottom
                    >
                      Verify your phone number
                    </Typography>
                  </div>

                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-15 text-darkgrey fw-normal font-gotham"
                    gutterBottom
                  >
                    A code has been sent to{' '}
                    <span style={{ color: '#864FFD' }}>
                      {country_code}{' '}
                      {maskFirstFiveCharacters(
                        outReachMobile ? outReachMobile : mobile_number
                      )}
                    </span>{' '}
                    , Enter it below to continue
                  </Typography>

                  <Typography className="fs-15 text-decoration-underline fw-normal font-gotham">
                    <a href="/login" onClick={
                      ()=>{
                        const isOTP = otp?.toString() ? `<OTP>` : '';
                        const isMobileNo = outReachMobile ? `<mobile_no>` : '';
                        GAEvents(
                          'Change Mobile Number',
                          'OTP',
                          'clicked',
                          {
                            phone: isMobileNo,
                            userOtp: isOTP,
                          }
                        );
                      }}
                    className="text-primary">
                      Change Mobile Number
                    </a>
                  </Typography>

                  <form
                    style={{ marginTop: '50px' }}
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div>
                      <MuiOtpInput
                        id="otp-id"
                        value={otp}
                        autoFocus
                        itemType=""
                        length={6}
                        gap={2}
                        TextFieldsProps={{
                          type: 'text', // Change to 'text'
                          inputMode: 'numeric',
                          // Add the pattern and maxLength for better control
                          pattern: "[0-9]",
                          maxLength: 1, // Ensures only one digit per input
                        }}
                        validateChar={(val) => /^\d$/.test(val)} // Only allow single digit (0-9)
                        onChange={handleChange}
                        onKeyDown={(evt) => {
                          // Prevent non-numeric input
                          if (['e', 'E', '+', '-', 'ArrowLeft', 'ArrowRight'].includes(evt.key)) {
                            evt.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {otpError}
                    </FormHelperText>

                    <div className="d-flex justify-content-between mt-4">
                      {seconds ? (
                        <Typography
                          variant="body1"
                          align="left"
                          className="fs-14 text-darkgrey fw-normal font-gotham"
                          gutterBottom
                        >
                          Waiting for OTP:{' '}
                          <span style={{ color: '#416AD4' }}>
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        </Typography>
                      ) : (
                        <Button
                          sx={{ padding: '0px', textTransform: 'capitalize' }}
                          component="button"
                          variant="body1"
                          align="right"
                          underline="none"
                          onClick={resendOTP}
                          className="fs-14 fw-normal font-gotham"
                          gutterBottom
                          style={{
                            color:
                              seconds > 0 || minutes > 0
                                ? '#DFE3E8'
                                : '#416AD4',
                          }}
                          disabled={seconds > 0 || minutes > 0}
                        >
                          Resend OTP
                        </Button>
                      )}
                    </div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      Submit
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

WebOtp.propTypes = {
  otp: PropTypes.string,
  otpError: PropTypes.string,
  resendOTP: PropTypes.func,
  submitOTPData: PropTypes.func,
  successResendCallback: PropTypes.func,
  errorResendCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  maskFirstFiveCharacters: PropTypes.func,
  mobile_number: PropTypes.string,
  isLoading: PropTypes.bool,
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  country_code: PropTypes.func,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  outReachMobile: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { userData, postLoginRouteName } = state.signin;
  const { mobile_number, country_code, country_code_name } = state.mobile;
  const { isLoading } = state.root;
  const { enquiry } = state.b2bVendorlisting;

  return {
    mobile_number,
    country_code,
    userData,
    postLoginRouteName,
    isLoading,
    country_code_name,
    enquiry,
  };
};

export default connect(mapStateToProps, {})(WebOtp);
