import { useEffect, useState } from 'react';
import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
// import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Button,
  AppBar,
  Toolbar,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { setB2CprofileData } from './redux/actions/b2c-profile-action';
import { loading } from '../../store/actions/rootActions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { putUpdateUserDetails } from '../UpdateProfile/store/actions/profileActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useValidation from '../../hooks/useValidation';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import Responsive from '../../utils/responsive';
import { POSTOFFICE_CITY } from '../../utils/APIs/api';

const theme = createTheme();

const B2CEditProfileForm = ({
  b2cProfileObj,
  setB2CprofileData,
  userData,
  putUpdateUserDetails,
  pushMessage,
  isLoading,
  loading,
}) => {
  useEffect(() => {}, []);

  // useEffect(() => {
  //     console.log("userData =====", userData)
  // }, []);

  const history = useHistory();
  const { validateInput, errors } = useValidation();

  const searchLocInpRef = React.useRef(null);
  // const [form, setFormData] = React.useState(name, city, email);
  const [mapOtions, setMapOtions] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [locSearchVal, setLocSearchVal] = useState('');

  // useEffect(()=> {
  //     setLocSearchVal(b2cProfileObj?.city);
  // },[b2cProfileObj?.city])
  // const [location, setLocation] = React.useState();
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
  React.useEffect(() => {
    const getData = setTimeout(async () => {
      console.log('localSearxh :', locSearchVal);
      console.log('searchload :   ', searchLoad);
      if (locSearchVal && searchLoad) {
        try {
          const response = await axios.get(
            // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}&types=city`
            POSTOFFICE_CITY + locSearchVal
          );
          if (response.status === 200) {
            if (response?.data?.status_code === 200) {
              console.log('SEARCH RESULT', response.data);
              setMapOtions(response.data?.data);
              setSearchLoad(false);
              loading(false);
            } else {
              loading(false);
              console.log('SEARCH RESULT', response.data);
              setMapOtions([]);
              setSearchLoad(false);
            }
          } else {
            loading(false);
            pushMessage('No City Found!!');
          }
        } catch (err) {
          loading(false);
          pushMessage('Network Error!!');
        }
      }
    }, 250);

    return () => clearTimeout(getData);
  }, [locSearchVal, searchLoad]);
  // const [city, setCity] = useState('');
  // const handleChange = (event) => {
  //   setCity(event.target.value);
  // };

  const getAddress2DefaultVal = () => {
    const address2Val = userData?.user?.city;
    console.log('adreessval :', address2Val);
    if (address2Val) {
      return address2Val;
    } else {
      return '';
    }
  };

  const getLocationString = (option) => {
    console.log('===getLocationString option', option);
    // const fulladdress = option?.properties?.full_address
    //   ? `${option?.properties?.full_address}`
    //   : '';
    // const address = option?.properties?.address
    //   ? `${option?.properties?.address}, `
    //   : '';
    let name = '';
    if (typeof option == 'string') {
      name = option;
    } else {
      name = option?.properties?.name || '';
    }

    console.log('City name :', name);
    // const place_formatted = option?.properties?.place_formatted
    //   ? `${option?.properties?.place_formatted}, `
    //   : '';
    // return fulladdress || address + name + place_formatted;
    return name;
  };

  const handleNameChange = (e) => {
    let obj = { ...b2cProfileObj };
    obj.name = e.target.value;
    validateInput('name', obj.name);
    setB2CprofileData(obj);
  };

  const handleEmailChange = (e) => {
    let obj = { ...b2cProfileObj };
    obj.email = e.target.value.trim();
    validateInput('email', obj.email);
    setB2CprofileData(obj);
  };

  const handlePhoneChange = (e) => {
    let obj = { ...b2cProfileObj };
    obj.mobile_number = e.target.value;
    setB2CprofileData(obj);
  };

  //   const handleDuplicate = (e) => {
  //     let obj = { ...socialMediaObj }
  //     obj.media_name = e.target.value;
  //     setsocialMediaObj(obj)
  //   }

  const handleSelectCity = (e, value) => {
    console.log('values', value);
    let obj = { ...b2cProfileObj };
    obj.city = value;
    validateInput('cityEdit', value);
    setB2CprofileData(obj);
  };

  const handleUpdate = () => {
    console.log('under handle Update', b2cProfileObj?.id);
    console.log('city', b2cProfileObj?.city?.properties?.name);
    if (b2cProfileObj?.name === '') {
      validateInput('name', b2cProfileObj?.name);
      return;
    }
    if (b2cProfileObj?.email === '') {
      validateInput('email', b2cProfileObj?.email);
      return;
    }
    if (b2cProfileObj?.city?.properties?.name === '') {
      validateInput('cityEdit', b2cProfileObj?.city);
    }
    const jsonData = {
      name: b2cProfileObj?.name,
      // username: userMasterObj?.username,
      email: b2cProfileObj?.email,
      mobile_number: b2cProfileObj?.mobile_number,
      city: b2cProfileObj?.city,
    };

    console.log('JSon Data ', jsonData);
    loading(true);
    putUpdateUserDetails(
      b2cProfileObj?.id,
      jsonData,
      userData,
      () => {
        pushMessage('Profile Edit Successfull');
        history.push('/b2c-profile');
        loading(false);
      },
      (error) => {
        console.log('error', error);
        loading(false);
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div>
              <AppBar position="static">
                <Toolbar>
                  {/* <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon />
                                    </IconButton> */}
                  <div style={{ flexGrow: 1 }}>
                    <ElynkerLogoComponent>
                      <ElynkerLogo width={'100px'} height={'30px'} />
                    </ElynkerLogoComponent>
                  </div>
                  {/* {
                                        <div>

                                            <IconButton
                                                size="medium"
                                                aria-label="user icon"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                color="inherit"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </div>
                                    } */}
                </Toolbar>
              </AppBar>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '20px' }}>
              <Grid container rowSpacing={0} columnSpacing={10} className="">
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Name
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputName"
                      type="text"
                      placeholder="Enter your name"
                      required
                      autoFocus
                      fullWidth
                      margin="none"
                      value={b2cProfileObj?.name}
                      onChange={handleNameChange}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="b2c-helper-text">
                    {errors.name}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputEmail"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Email ID
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputEmail"
                      type="text"
                      placeholder="Enter your email id"
                      required
                      autoFocus
                      fullWidth
                      margin="none"
                      onChange={handleEmailChange}
                      value={b2cProfileObj?.email}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="b2c-helper-text">
                    {errors.email}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompany"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Phone
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompany"
                      type="number"
                      placeholder="Enter your phone"
                      required
                      disabled
                      autoFocus
                      fullWidth
                      margin="none"
                      value={b2cProfileObj?.mobile_number}
                      onChange={handlePhoneChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputEmail"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    City
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <Autocomplete
                      disablePortal
                      ref={searchLocInpRef}
                      id={'SearchLocation'}
                      options={mapOtions}
                      fullWidth
                      value={b2cProfileObj?.city || null}
                      inputValue={locSearchVal}
                      onInputChange={(e, value) => {
                        console.log('onInputChange : ', value);
                        setLocSearchVal(value);
                        setSearchLoad(true);
                      }}
                      onChange={handleSelectCity}
                      // onChange={(e, value) => {
                      //     setFormData(prev => ({ ...prev, city: value?.properties?.name }))
                      //     console.log("===on change location", value);
                      //     if (value?.geometry?.length > 0) {
                      //         // setLocationCoords({
                      //         //   latitude: value?.geometry?.coordinates[1],
                      //         //   longitude: value?.geometry?.coordinates[0],
                      //         // });
                      //     }
                      //     setSearchLoad(false);
                      // }}
                      open={open}
                      onOpen={openPopper}
                      onClose={closePopper}
                      defaultValue={getAddress2DefaultVal()}
                      getOptionLabel={getLocationString}
                      isOptionEqualToValue={(option, value) =>
                        option?.properties?.name === value?.properties?.name
                      }
                      sx={{
                        '.MuiAutocomplete-input': {
                          paddingLeft: '14px !important',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          placeholder={'Select City'}
                          // value={locSearchVal}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 200,
                          }}
                        />
                      )}
                    />
                    {/* <Select
                                        value={b2cProfileObj?.city}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value={10}>Mumbai </MenuItem>
                                        <MenuItem value={20}>Pune</MenuItem>
                                        <MenuItem value={30}>Bangalore</MenuItem>
                                        <MenuItem value={40}>Delhi</MenuItem>
                                    </Select> */}
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="b2c-helper-text">
                    {errors.cityEdit}
                  </FormHelperText>
                </Grid>
              </Grid>

              {Responsive(
                <>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="mt-5 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Save Profile Details
                  </Button>
                </>,
                <>
                  <div className="d-flex justify-content-end mt-5">
                    <Button
                      type="button"
                      variant="contained"
                      className="btn-web-next"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                      onClick={() => {
                        handleUpdate();
                      }}
                    >
                      Save Profile Details
                    </Button>
                  </div>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

B2CEditProfileForm.propTypes = {
  b2cProfileObj: PropTypes.object,
  setB2CprofileData: PropTypes.func,
  userData: PropTypes.object,
  loading: PropTypes.func,
  pushMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  putUpdateUserDetails: PropTypes.func,
};

// const mapStateToProps = (state) => {
//   const { userData } = state.signin;
//   const {
//     b2bLvl1Categories,
//     selectedLvl1Categories,
//   } = state.offeredServices;

//   return {
//     b2bLvl1Categories,
//     selectedLvl1Categories,
//   };
// };

// export default connect(mapStateToProps, {

// })(UpdateProfileForm);
const mapStateToProps = (state) => {
  const { b2cProfileObj } = state.b2cData;
  const { userData } = state.signin;
  const { isLoading } = state.root;
  return {
    b2cProfileObj,
    userData,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  setB2CprofileData,
  putUpdateUserDetails,
  pushMessage,
  loading,
})(B2CEditProfileForm);
