import { loading } from "../../../../store/actions/rootActions";
import { getEnquiryCheck } from "../../../../utils/APIs/endpoints";
import { SET_CHILDREN_WIDGET_DATA, SET_TNT_FOUR_DATA } from "../types";

export const setChildWidgetData = (data) => {
    return (dispatch) => {
    dispatch({type:SET_CHILDREN_WIDGET_DATA, payload: data})
    }
}


export const getTwentyFourData = (regId) => {
    return async(dispatch) => {
        dispatch(loading(true));
        try {
            const response = await getEnquiryCheck(regId);
            console.log("fetching the getenquiry check data :", response.data)
            if (response.status === 200){
                dispatch({type: SET_TNT_FOUR_DATA, payload: response?.data?.data});
                
            }
            else{
                dispatch(loading(false));
            }
        } catch (error) {
            console.log("error on getenquiry check", error);

        }
        finally {
            dispatch(loading(false));
        }
    }
}