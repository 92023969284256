import { string, array, number, mixed, date } from 'yup';
const REGEX_PORTFOLIO_LINK =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\/\S*)?$|^$/i;

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// const nameRegex = /^[a-zA-Z\s]*$/;

const nameValidation = string().max(50).optional();
const bioNameValidation = string().required('Bio is required').max(50).min(3);
const feedbackDetail = string().required('Detail is required').min(3);
const feedbackRating = string().required('Feedback rating is required');

const companyNameValidation = string()
  .required('Company name is required')
  .max(100)
  .min(3);

const checkedCount = number()
  .required('Company name is required')
  .min(1, 'At least one product must be selected');

const buisnessTypeValidation = string().required('buisness type is required');

const comment = string().required('comment is required').max(50).min(3);
const dobValidation = date()
  .required('Date of birth is required')
  .max(new Date(), 'Date of birth cannot be in the future')
  .min(
    new Date('1900-01-01'),
    'Date of birth cannot be earlier than 1900-01-01'
  );

const companyAboutValidation = string()
  .required('About company is required')
  .max(5000, 'About company cannot be more than 5000 characters')
  .min(3, 'About company must be at least 3 characters long');
const about = string()
  .required('About you is required')
  .min(3, 'About you must be at least 3 characters long')
  .max(5000, 'About company cannot be more than 5000 characters');

const validateCIN = (cin) => {
  console.log('==validateCIN', cin);
  const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
  return cin !== '' ? cinRegex.test(cin) : true;
};

const companCINValidation = string()
  .max(21, 'CIN number must be exactly 21 characters long')
  .test('is-valid-cin', 'Invalid CIN format', validateCIN);

const companPanValidation = string()
  .test(
    'is-valid-pan',
    'PAN number must be exactly 10 characters long and in the format: first 5 letters, next 4 digits, and last letter',
    (value) => value === '' || /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(value)
  )
  .nullable()
  .notRequired();

const companGstValidation = string().test(
  'is-valid-gst',
  'Invalid GST number format',
  (value) => {
    console.log('companGstValidation', value);

    return (
      value === '' ||
      !value ||
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/.test(value)
    );
  }
);

const companGstReasonValidation = string()
  .required('Gst reson is required')
  .max(50);

const emailValidation = string()
  // matches(emailRegex, 'Invalid email address').
  // .required("Please enter valid emailId")
  .max(200)
  // .nullable()
  // .optional()
  .test({
    name: 'Valid-email',
    message: 'Invalid email address',
    test: (value) => {
      if (!value || value.trim() === '') {
        // If the value is empty or null, it's considered valid
        return true;
      }
      return emailRegex.test(value);
    },
  });
const outReachEmail = string()
  // matches(emailRegex, 'Invalid email address').
  // .required("Please enter valid emailId")
  .max(200)
  // .nullable()
  // .optional()
  .test({
    name: 'Valid-email',
    message: 'Invalid email address',
    test: (value) => {
      return emailRegex.test(value);
    },
  });
const languageValidation = string()
  .required('Language is required')
  .max(200)
  .min(2);
const cityValidation = string().max(50).min(2);
const addressValidation = string().required('Address is required');
const mobileNumberValidation = string()
  .required('Mobile number is required')
  .min(10, 'Phone number must be 10 digits.');

const whatsappNumberValidation = string()
  .notRequired()
  .test(
    'min-length-or-empty',
    'Please enter the valid mobile number',
    (value) => !value || value.length >= 10
  );

const locationValidation = mixed().test(
  'is-location',
  'Location is required',
  (value) => {
    if (typeof value === 'string') {
      return value.trim().length > 0;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      return true;
    }
    return false;
  }
);

const productPhotosValidation = array()
  .required('At least one photo is required')
  .min(1, 'At least one photo is required');

const subDomainUrlValidation = string()
  .min(4, 'Minimum should be four characters')
  .max(14, 'maximum should be 14')
  .required('Domain Url is required');
const client_name = string().required('Client name is required');
const designation = string().required('Designation is required');
const testimonial = string().required('Testimonial is required');
const subDomainValidation = string().required('Sub Domain is required');
const socialLinkValidation = array()
  .required('At least one social link is required')
  .min(1);
const flProtLink = string()
  // .nullable()
  // .matches(REGEX_PORTFOLIO_LINK, 'Invalid URL format. Must be a valid HTTP or HTTPS URL.');
  .test(
    'is-url-valid',
    'Invalid URL format. Must be a valid HTTP or HTTPS URL.',
    (value) => {
      // Check if the value matches the regex pattern
      return REGEX_PORTFOLIO_LINK.test(value || '');
    }
  );
const logoPhotoValidation = array().required('Logo image is required').min(1);
const coverPhotoValidation = array().required('Cover photo is required');
const productNameValidation = string()
  .required('Product name is required')
  .max(100)
  .min(3);
const stateValidation = string().required('State  is required').max(50).min(3);
const subCategoryValidation = string()
  .required('Subcategory is required')
  .min(1, 'At least one subcategory is required');

const productDescriptionValidation = string()
  .required('Product description is required')
  .min(2, 'Product description must be at least 2 characters');

const priceValidation = string().required('Price is required').min(1);

const unitValidation = string()
  .required('Unit is required')
  .max(10, 'Unit must be at most 10 characters');

const orderQuantityValidation = number()
  .required('Order quantity is required')
  .positive('Quantity must be positive');

// const hasAnyErrorPresent = (obj) => {
//     for (const key in obj) {
//         if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
//             return true; // Return true if any key has a non-empty value
//         }
//     }
//     return false; // Return false if all keys have empty values
// }
const hasAnyErrorPresent = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== '') {
      return true; // Return true if any key has a non-empty value
    }
  }
  return false; // Return false if all keys have empty values
};

const profileRoleValidation = string()
  .required('Role is required')
  .max(50)
  .min(3);

const hrRateValidation = string()
  .required('Hourly rate is required')
  .min(1)
  .max(11, 'Hourly rate must be at most 9 digits');

// const hrRateDecimalValidation = object().shape({
//     hrRate: number().test(
//         'is-decimal',
//         'invalid decimal',
//         value => (value + "").match(/^\s*-?(\d{1,7}(\.\d{1,2})?)\s*$/),
//     ),
//     });
const hrRateDecimalValidation = string()
  .required('Price is required')
  .min(1)
  .max(11, 'Price must be at most 9 digits');

const flSerNameValidation = string()
  .required('Service name is required')
  .min(3)
  .max(100);

const flSerCategoryValidation = string().required(
  'Service category is required'
);

const flSerDescValidation = number().required(
  'Service description is required'
);

const flPriceValidation = string().required('Price/hour is required').min(1);

const flEducationValidation = string().required('Education is required');

const flAddress1Validation = string().required('Street address is required');

const flAddress2Validation = string().required('Location is required');

const addCityValidation = string().required('Please add city');

const flResumeUploadValidation = number().max(
  2000000,
  'File maximum size of 2 MB'
);

const flWorkUploadValidation = number().max(
  2000000,
  'File maximum size of 2 MB'
);

const flSkillsValidation = number()
  .required('Skills is Required')
  .min(0, 'Atleast 1 Skill is required')
  .max(5, 'You can add upto 5 Skills.');

const jobTitleValidation = string().required('Please add job title');
const jobCategoryValidation = string().required(
  'Please select at least 1 job category'
);
const jobDescValidation = string()
  .required('Job description is required')
  .min(2, 'Job description must be at least 2 characters');

const jobExpValidation = string().required('Experience is Required');

const jobLocValidation = mixed().test(
  'is-location',
  'Location is required',
  (value) => {
    if (typeof value === 'string') {
      return value.trim().length > 0;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      return true;
    }
    return false;
  }
);

// const jobSalaryValidation = number()
//   .required('Salary amount is required')
//   .min(0, 'Salary amount is required')
//   .max(11, 'Salary amount must be at most 9 digits')
//   .nullable(true)
//   .transform((_, val) => val === Number(val) ? val : null)

const jobSalaryValidation = string().required('Price is required').min(1);

const ProdNameValidation = string().required(' name is required').max(100);

const orderQuantValidation = string().required('order quantity is required');

const estimatedBudgetValidation = string().required(
  'Estimated Budget is required'
);

const JobApplicantNameValidation = string()
  .required('Applicant name is required')
  .max(100)
  .min(3);

const pincodeValidation = string()
  .required('Pincode is required')
  .matches(
    /^[1-9][0-9]{5}$/,
    'Invalid pincode. It should be a 6-digit number starting with a non-zero digit'
  );
const websiteTestimonial = number().required('Atleast one Testimonial is required').min(1, 'Atleast one Testimonial is required');
export {
  nameValidation,
  emailValidation,
  cityValidation,
  companGstReasonValidation,
  addressValidation,
  companyNameValidation,
  companyAboutValidation,
  companCINValidation,
  companPanValidation,
  productPhotosValidation,
  productNameValidation,
  subCategoryValidation,
  productDescriptionValidation,
  priceValidation,
  unitValidation,
  orderQuantityValidation,
  logoPhotoValidation,
  coverPhotoValidation,
  locationValidation,
  mobileNumberValidation,
  socialLinkValidation,
  stateValidation,
  hasAnyErrorPresent,
  companGstValidation,
  profileRoleValidation,
  hrRateValidation,
  hrRateDecimalValidation,
  flSerNameValidation,
  flSerCategoryValidation,
  flSerDescValidation,
  flPriceValidation,
  flEducationValidation,
  flAddress1Validation,
  flAddress2Validation,
  bioNameValidation,
  dobValidation,
  languageValidation,
  flProtLink,
  addCityValidation,
  about,
  comment,
  feedbackDetail,
  feedbackRating,
  whatsappNumberValidation,
  flResumeUploadValidation,
  flSkillsValidation,
  flWorkUploadValidation,
  jobTitleValidation,
  jobCategoryValidation,
  jobDescValidation,
  jobExpValidation,
  jobLocValidation,
  jobSalaryValidation,
  ProdNameValidation,
  orderQuantValidation,
  estimatedBudgetValidation,
  JobApplicantNameValidation,
  pincodeValidation,
  subDomainUrlValidation,
  subDomainValidation,
  client_name,
  designation,
  testimonial,
  checkedCount,
  buisnessTypeValidation,
  outReachEmail,
  websiteTestimonial
};
