import { loading } from '../../../../store/actions/rootActions';
import {
  getSubscriptionData,
  getSubscriptionById,
  postPaymentResponse,
  postSubscriptionOrder,
  postSubscriptionDetails,
  getOrderById,
  getSubscriptionDetailsBySearch,
  getOrderBySearch,
  postPaymentDataForHash,
  postPaymentOrder,
  putSubscriptionOrder,
} from '../../../../utils/APIs/endpoints';
import {
  GET_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTION_SERVICES,
  CLEAR_ALL_SUBSCRIPTION_SERVICES,
  GET_SINGLE_SUBSCRIPTION,
  GET_PREMIUM_PLAN,
  SET_SUBSCRIPTION_ORDER_DATA,
  SET_SUBSCRIPTION_DETAILS_DATA,
  SET_CURRENT_SUBSCRIPTION_DETAIL,
  RESET_SUBSCRIPTION_DATA,
} from '../types';

export const getAllSubscriptionList = (sucessCallBack, loading) =>
  async function (dispatch) {
    try {
      loading(true);
      const response = await getSubscriptionData();
      console.log('=== response.data', response.data);
      if (response.status === 200) {
        await dispatch({ type: GET_SUBSCRIPTION, payload: response.data.data });
        sucessCallBack(response.data.data);
      }
    } catch (error) {
      console.log('Error fetching updated registration data', error);
    } finally {
      loading(false);
    }
  };

export const getAllSubscriptionServices = (subscription) =>
  async function (dispatch) {
    try {
      // console.log('==getAllSubscriptionServices subscription', subscription);
      const response = await getSubscriptionById(subscription.id);
      console.log(
        '=== getAllSubscriptionServices  response.data',
        response.data
      );
      if (response.status === 200) {
        // GET_SINGLE_SUBSCRIPTION
        if (subscription.name === 'Premium Plan') {
          // console.log(
          //   '==response.data.data[0].subs Premium',
          //   response.data.data[0]
          // );
          dispatch({
            type: GET_PREMIUM_PLAN,
            payload: response.data.data[0],
          });
          dispatch({
            type: GET_SINGLE_SUBSCRIPTION,
            payload: response.data.data[0].subs,
          });
        }

        const newObj = response.data.data[0].subs;
        newObj.map((res) => {
          res.services.split(',').map(async (r) => {
            if (subscription.name === 'Free Plan') {
              await dispatch({
                type: GET_ALL_SUBSCRIPTION_SERVICES,
                payload: {
                  name: r,
                  free: true,
                },
              });
            } else {
              await dispatch({
                type: GET_ALL_SUBSCRIPTION_SERVICES,
                payload: {
                  name: r,
                  premium: true,
                },
              });
            }
            if (res.plan_type === 'annually') {
              await dispatch({
                type: GET_ALL_SUBSCRIPTION_SERVICES,
                payload: {
                  name: r,
                  annually: true,
                },
              });
            } else {
              await dispatch({
                type: GET_ALL_SUBSCRIPTION_SERVICES,
                payload: {
                  name: r,
                  monthly: true,
                },
              });
            }
          });
        });
      }
    } catch (error) {
      console.log('Error fetching updated registration data', error);
    }
  };

export const clearSubscription = () =>
  async function (dispatch) {
    try {
      dispatch({
        type: CLEAR_ALL_SUBSCRIPTION_SERVICES,
      });
    } catch (error) {
      console.log('Error fetching updated registration data', error);
    }
  };

export const resetSubscription = () =>
  async function (dispatch) {
    try {
      dispatch({
        type: RESET_SUBSCRIPTION_DATA,
      });
    } catch (error) {
      console.log('Error fetching updated registration data', error);
    }
  };

export const createSubscriptionOrder = (
  body,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch(loading(true));
      const response = await postSubscriptionOrder(body);
      console.log('createSubscriptionOrder STATUS', response.status);
      console.log('createSubscriptionOrder RESPONSE', response.data);
      if (response.status === 201) {
        successCallback(response.data);
      } else {
        errorCallback(response.data);
      }
    } catch (error) {
      console.log('[subscription.action] Error createSubscriptionOrder', error);
      errorCallback(error);
    } finally {
      dispatch(loading(false));
    }
  };

export const sendPaymentResponse = (
  body,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch(loading(true));
      const response = await postPaymentResponse(body);
      console.log('sendPaymentResponse STATUS', response.status);
      console.log('sendPaymentResponse RESPONSE', response.data);
      if (response.status === 201) {
        successCallback(response.data, response.request);
      } else {
        errorCallback(response.data, response.request);
      }
    } catch (error) {
      console.log('[subscription.action] Error sendPaymentResponse', error);
      errorCallback(error?.response, error?.request);
    } finally {
      dispatch(loading(false));
    }
  };

export const sendSubscriptionDetails = (
  body,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch(loading(true));
      const response = await postSubscriptionDetails(body);
      console.log('sendSubscriptionDetails STATUS', response.status);
      console.log('sendSubscriptionDetails RESPONSE', response.data);
      if (response.status === 201) {
        successCallback(response.data, response.request);
      } else {
        errorCallback(response.data, response.request);
      }
    } catch (error) {
      console.log('[subscription.action] Error sendSubscriptionDetails', error);
      errorCallback(error?.response, error?.request);
    } finally {
      dispatch(loading(false));
    }
  };

export const getSubscriptionDetails = (
  key = 'registration_id',
  value,
  sCall = () => {},
  fCall = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await getSubscriptionDetailsBySearch(key, value);
      console.log('getSubscriptionDetails RESPONSE', response.data);
      if (response.status === 200) {
        dispatch({
          type: SET_SUBSCRIPTION_DETAILS_DATA,
          payload: response.data?.data?.slice(-1)[0] || null,
        });
        sCall(response.data);
      } else {
        fCall(response.data);
      }
    } catch (error) {
      console.log(
        '[subscription.action] Error getSubscriptionDetailsBySearch',
        error
      );
      fCall(error?.response?.data || error);
    }
  };

export const getSubscriptionOrderBySearch = (value, key) =>
  async function (dispatch) {
    try {
      const response = await getOrderBySearch(value, key);
      console.log('getSubscriptionOrderBySearch RESPONSE', response.data);
      if (response.status === 200) {
        dispatch({
          type: SET_SUBSCRIPTION_ORDER_DATA,
          payload: response.data?.data || null,
        });
        return response.data?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(
        '[subscription.action] Error getSubscriptionDetailsBySearch',
        error
      );
      return null;
    }
  };

export const getUserSubscriptionOrderById = (id) =>
  async function (dispatch) {
    try {
      const response = await getOrderById(id);
      if (response.status === 200) {
        dispatch({
          type: SET_SUBSCRIPTION_ORDER_DATA,
          payload: response.data?.data,
        });
      }
    } catch (error) {
      console.log('[subscription.action] Error sendSubscriptionDetails', error);
    }
  };

export const setCurrentSubscriptionDetail = (data) =>
  function (dispatch) {
    dispatch({ type: SET_CURRENT_SUBSCRIPTION_DETAIL, payload: data });
  };

export const reqPayDataHash = (body, sCall = () => {}, fCall = () => {}) =>
  async function (dispatch) {
    try {
      dispatch(loading(true));
      const response = await postPaymentDataForHash(body);
      console.log('reqPayDataHash RESPONSE', response.data);
      if (response.status === 200) {
        sCall(response.data);
      } else {
        fCall(response.data);
      }
    } catch (error) {
      console.log('[subscription.action] Error sendSubscriptionDetails', error);
      fCall(error?.response?.data);
    } finally {
      dispatch(loading(true));
    }
  };

export const getRapOrderForPG = (body, sCall = () => {}, fCall = () => {}) =>
  async function (dispatch) {
    try {
      dispatch(loading(true));
      const response = await postPaymentOrder(body);
      if (response.status === 200) {
        sCall(response.data);
      } else {
        fCall(response.data);
      }
    } catch (error) {
      console.log('[subscription.action] Error getRapOrderForPG', error);
      fCall(error?.response?.data || error);
    } finally {
      dispatch(loading(false));
    }
  };

export const updateSubscriptionOrder = (
  id,
  body,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch(loading(true));
      const response = await putSubscriptionOrder(id, body);
      console.log('updateSubscriptionOrder STATUS', response.status);
      console.log('updateSubscriptionOrder RESPONSE', response.data);
      if (response.status === 201) {
        successCallback(response.data);
      } else {
        errorCallback(response.data);
      }
    } catch (error) {
      console.log('[subscription.action] Error updateSubscriptionOrder', error);
      errorCallback(error);
    } finally {
      dispatch(loading(false));
    }
  };
