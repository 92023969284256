import { COMPANY_NAME, RESET_COMPANY_DATA, ABOUT_COMPANY, SET_ADDITIONAL_DETAILS1, SET_ADDITIONAL_DETAILS2, SET_ADDITIONAL_DETAILS3 } from '../types';

export const setCompanyName = (companyName) =>
  function (dispatch) {
    dispatch({ type: COMPANY_NAME, payload: companyName });
  };

export const setAboutCompany = (aboutCompany) =>
  function (dispatch) {
    dispatch({ type: ABOUT_COMPANY, payload: aboutCompany });
  };

export const resetComapanyData = () =>
  function (dispatch) {
    dispatch({ type: RESET_COMPANY_DATA });
  };
export const setAdditioanlDetails1 = (details1) =>
  function (dispatch) {
    dispatch({ type: SET_ADDITIONAL_DETAILS1, payload: details1 });
  };
export const setAdditioanlDetails2 = (details2) =>
  function (dispatch) {
    dispatch({ type: SET_ADDITIONAL_DETAILS2, payload: details2 });
  };
export const setAdditioanlDetails3 = (details3) =>
  function (dispatch) {
    dispatch({ type: SET_ADDITIONAL_DETAILS3, payload: details3 });
  };

