import { deleteJOBApi, getProdCategories, postJOBApi, putJOBApi } from '../../../../utils/APIs/endpoints';
import { FREELANCER } from '../../../../utils/constants';
import { ALL_JOB_CATEGORY,
  PUT_POST_JOB_RESULT,
  SAVE_JOB_DESCRIPTION,
  SAVE_JOB_EXP,
  SAVE_JOB_LOCATION,
  SAVE_JOB_SALARY,
  SAVE_JOB_TITLE,
  SET_JOB_LOCATION_COORDS,
  RESET_CREATE_JOB_DATA, 
  JOB_ACTION_TYPE,
  SAVE_SINGLE_JOB_DATA,
  SELECTED_JOB_CATEGORY,
  JOB_LOC_SEARCH_VAL} from '../types';

export const saveJobTitle = (title) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_JOB_TITLE,
        payload: title,
      });
    } catch (err) {
      console.log('Error in saving title', err);
    }
  };
};

export const saveJobDesc = (desc) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_JOB_DESCRIPTION,
        payload: desc,
      });
    } catch (err) {
      console.log('Error in saving description', err);
    }
  };
};

export const saveJobExp = (desc) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_JOB_EXP,
        payload: desc,
      });
    } catch (err) {
      console.log('Error in saving experience', err);
    }
  };
};

export const saveJobSalary = (desc) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_JOB_SALARY,
        payload: desc,
      });
    } catch (err) {
      console.log('Error in saving salary', err);
    }
  };
};

export const postJob =
  (payload, successCallback = () => {}, errorCallback = () => {}) =>
  async () => {
    try {
      const response = await postJOBApi(payload);
      if (response.status === 201) {
        successCallback(response);
      } else {
        errorCallback(response);
      }
    } catch (error) {
      return errorCallback(error);
    }
  };

export const putJOBPosting =
  (id, data, successCallback = () => {}, errorCallback = () => {}) =>
  async (dispatch) => {
    try {
      const response = await putJOBApi(
        id,
        data,
        successCallback,
        errorCallback
      );
      if (response.status === 200) {
        console.log('create job PUT', response.data);
        //dispatch
        dispatch({ type: PUT_POST_JOB_RESULT, payload: data });
        successCallback();
      } else {
        errorCallback();
      }
    } catch (error) {
      console.log('Update job requirement PUT');
    }
  };

export const deleteJOBData = (jobId,
    successCallback = () => { },
    errroCallback = () => { }) =>
    async function () {
        try {
        const response = await deleteJOBApi(jobId);
        if (response.status === 200) {
            console.log(
            '[delete-jobs-action] deleting Job Success',
            response.data
            );
            // dispatch({ type: , payload: response.data.data.id })
            successCallback(response.data);
        } else {
            console.log(
            '[delete-jobs-action]  Job delete Error',
            response.data
            );
            errroCallback();
        }
        } catch (err) {
        console.log(
            '[delete-jobs-action] Job delete Error',
            err
        );
        errroCallback();
    }
};


export const getFreelancerCategories = (type=FREELANCER, successCallback = () => {},
errorCallback = () => {}) =>
async function (dispatch) {
    try {
        const response = await getProdCategories(type);
        if (response.status === 200) {
            await dispatch({
                type: ALL_JOB_CATEGORY,
                payload: response.data.data,
            });
            successCallback(FREELANCER);
        }
    } catch (error) {
        console.log(
            '[create-job-action] getFreelancerCategories Error',
            error
        );
        errorCallback(error);
    }
};

export const setJobLocationCoords = (locationCoord) =>
  function (dispatch) {
    dispatch({
      type: SET_JOB_LOCATION_COORDS,
      payload: locationCoord,
    });
  };

export const saveJobLocation = (location) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_JOB_LOCATION,
        payload: location,
      });
    } catch (err) {
      console.log('Error in saving job location', err);
    }
  };
};

export const resetCreateJobData = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CREATE_JOB_DATA });
  }
}

export const setJobActionType = (type) =>
  function (dispatch) {
    dispatch({ type: JOB_ACTION_TYPE, payload: type });
  };

export const setSingleJobData = (data) =>
  function (dispatch) {
    dispatch({ type: SAVE_SINGLE_JOB_DATA, payload: data });
  };

export const saveSelCategory = (data) => 
  function (dispatch) {
    dispatch({ type: SELECTED_JOB_CATEGORY, payload: data });
  };

export const saveSelLocation = (data) => 
function (dispatch) {
  dispatch({ type: JOB_LOC_SEARCH_VAL, payload: data });
};