import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as FailedImage } from '../../assets/failed-banner.svg';
import Divider from '@mui/material/Divider';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import PropTypes from 'prop-types';
import {
	useHistory,
	useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';

const theme = createTheme();

const FailedForm = ({
	title = 'Payment Failed!',
	subtitle = 'Oops! Something went wrong!',
	btnText = 'Try Again',
}) => {
	const history = useHistory();
	let location = useLocation();
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2">
							<ElynkerLogoComponent>
								<ElynkerLogo width={'100px'} height={'30px'} />
							</ElynkerLogoComponent>
						</div>
						<Divider
							component="div"
							className="main-divider mb-3"
							role="presentation"
						></Divider>

						<form
							onSubmit={(e) => {
								e.preventDefault();
								history.replace(location?.state?.to ?? '/subscription');
							}}
							style={{ marginTop: '60px' }}
						>
							<div className="mb-4 text-center">
								<FailedImage />
							</div>

							<Typography
								variant="h4"
								align="center"
								alignItems="center"
								className="fs-28 mb-3  fw-bold text-black font-skmodernist"
								gutterBottom
							>
								{location?.state?.title ?? title}
							</Typography>

							<Typography className="fs-16 text-center font-gotham text-grey mb-2">
								{location?.state?.subtitle ?? subtitle}
							</Typography>

							{isTabletOrMobile && (
								<>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
										sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
									>
										{location?.state?.btnText ?? btnText}
									</Button>
								</>
							)}

							{isDesktopOrLaptop && (
								<>
									<Box className="text-center my-5">
										<Button
											type="submit"
											variant="contained"
											className="btn-web-next"
										>
											{location?.state?.btnText ?? btnText}
										</Button>
									</Box>
								</>
							)}
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

FailedForm.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	btnText: PropTypes.string,
};

export default FailedForm;

