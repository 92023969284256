import { deleteBusinessRequirementApi, getBusinessRequirementApi } from "../../../../utils/APIs/endpoints";
import { GET_REQUIREMENT, RESET_REQUIREMENT_LIST } from "../types";

export const getRequirements = (params, successCallback = () => {},
errorCallback = () => {}) =>
  async function (dispatch) {
    try {
      const response = await getBusinessRequirementApi(params);
      if (response.status == 200) {
       
            await dispatch({
                type: GET_REQUIREMENT,
                payload: response?.data?.data,
              });
              successCallback();
        
      }else{
        errorCallback();
      }
    } catch (error) {
      if(error?.response?.status === 404){
        await dispatch({
          type: GET_REQUIREMENT,
          payload: [],
        }); 
      }
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
      errorCallback(error);
    }
  };




  export const deleteRequirement = (reqId, successCallback = () => {}, errroCallback = () => { }
  ) =>
    async function () {
      try {
  const response = await deleteBusinessRequirementApi(reqId);
  if (response.status === 200) {
    console.log(
      '[Requirement Success',
      response.data
    );
    // dispatch({ type: REMOVE_PROD_IMAGE, payload: index });
    successCallback();
  } else {
    console.log(
      '[Requirement Error',
      response.data
    );
    errroCallback();
  }
} catch (err) {
  console.log('[add-catalog-action-action] removeProdImage Error', err);
  errroCallback();
}
};

export const resetRequirementList = () => 
async function (dispatch) {
  dispatch({ type: RESET_REQUIREMENT_LIST });
};
