import React, { useEffect } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import {
  setActiveStep,
  setStepperData,
} from './redux/actions/fl-onboarding-steps-action';
import { loading } from '../../../store/actions/rootActions';
import FLUploadResumeForm from '../FL-UploadResume/FL-UploadResume';
import CustomFLStepper from '../../../components/CustomFLStepper/CustomFLStepper';
import FLAddPProfessionalRole from '../FLAddProfessionalRole/FL-AddProfessionalRole';
import FLAddBioForm from '../FL-AddBio/FL-AddBio';
import FLSetRateForm from '../FL-SetRate/FL-SetRate';
import FLUploadServiceCatalogForm from '../FL-UploadServiceCatalog/FL-UploadServiceCatalog';
import FLProfileDetailsForm from '../FL-Profile-Details/FL-Profile-Details';





const theme = createTheme();

const FLOnboardingSteps = ({
  activeStep,
  setActiveStep,
  stepperData,
  setStepperData,
  loading,
}) => {
  const onNextHandler = async () => {
    if (activeStep < stepperData.length - 1) {
      let steps = [...stepperData];
      if (activeStep + 1 <= stepperData.length - 1) {
        // changing current step flags
        steps[activeStep].completed = true;
        steps[activeStep].active = false;
        steps[activeStep].error = false;
        steps[activeStep].icon = 1;

        // makeing next step to be current and active step
        steps[activeStep + 1].completed = false;
        steps[activeStep + 1].active = true;
        steps[activeStep + 1].error = false;
        steps[activeStep + 1].icon = 2;
        setActiveStep(activeStep + 1);
      }
      setStepperData(steps);
      loading(false);
    }
  };

  const onBackHandler = async () => {
    if (activeStep > 0) {
      let steps = [...stepperData];
      steps[activeStep].completed = false;
      steps[activeStep].active = false;
      steps[activeStep].error = false;
      steps[activeStep].icon = 3;

      // makeing prev step to be current and active step
      steps[activeStep - 1].completed = false;
      steps[activeStep - 1].active = true;
      steps[activeStep - 1].error = false;
      steps[activeStep - 1].icon = 2;
      setActiveStep(activeStep - 1);
      setStepperData(steps);
      loading(false);
    }
  };
  useEffect(() => {
    {
      activeStep === 0 && (
        <FLUploadResumeForm
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
        />
      )
    }
    {
      activeStep === 1 && (
        <FLAddPProfessionalRole
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
        />
      )
    }
    {
      activeStep === 2 && (
        <FLAddBioForm
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
        />
      )
    }
    {
      activeStep === 3 && (
        <FLSetRateForm
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
        />
      )
    }
    {
      activeStep === 4 && (
        <FLUploadServiceCatalogForm
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
        />
      )
    }
    {
      activeStep === 5 && (
        <FLProfileDetailsForm
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
        />
      )
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg" className="px-0">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2">
              <ElynkerLogo width={'100px'} height={'30px'} />
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            ></Divider>
            <CustomFLStepper activeStep={activeStep} stepperData={stepperData} />
            {activeStep === 0 && (
              <FLUploadResumeForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
            {activeStep === 1 && (
              <FLAddPProfessionalRole
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
            {activeStep === 2 && (
              <FLAddBioForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
            {/* {activeStep === 3 && (
              <FLSetRateForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )} */}
            {activeStep === 3 && (
              <FLUploadServiceCatalogForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
            {activeStep === 4 && (
              <FLProfileDetailsForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

FLOnboardingSteps.propTypes = {
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  stepperData: PropTypes.array,
  setStepperData: PropTypes.func,
  loading: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { activeStep, stepperData } = state.flOnboardingStep;
  // console.log("====stepperData", stepperData);
  return { activeStep, stepperData };
};

export default connect(mapStateToProps, { setActiveStep, setStepperData, loading })(
  FLOnboardingSteps
);
