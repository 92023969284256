import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { CLICKED, MANAGEWEBSITE_TITLE } from '../../utils/constants';
import { GAEvents } from '../../utils/helper-funtions';

const AddTestimonialModal = ({
  open,
  onClose,
  testimonialWebsiteData,
  onTestimonialChange,
  onTestimonialSave,
  errors,
  userData
}) => {
  const handleCancel = () => {
    onClose(true);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="custom-border-header pb-0">
        <Box className="position-relative d-flex justify-content-between">
          <Typography className="fs-22 fw-bold font-skmodernist">
            Testimonials
          </Typography>
          <Box>
            <CloseIcon onClick={()=>{
              GAEvents(MANAGEWEBSITE_TITLE, "Add Testimonial", CLICKED, {
                userId: userData?.id || sessionStorage.getItem('random_id'),
                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                title: "Close"
              });
              handleCancel();
              }} style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-md-6 mb-2">
            <InputLabel
              htmlFor="inputEducation"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Client Name <span className="text-mandatory">*</span>
            </InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="client_name"
                name="client_name"
                // label="Mobile Number"
                type="text"
                placeholder="Enter client name"
                onChange={onTestimonialChange}
                fullWidth
                margin="none"
                value={testimonialWebsiteData.client_name}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FormControl>
            <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
              {errors?.client_name}
            </FormHelperText>
          </div>

          <div className="col-md-6 mb-2">
            <InputLabel
              htmlFor="inputEducation"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Designation <span className="text-mandatory">*</span>
            </InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="designation"
                name="designation"
                value={testimonialWebsiteData.designation}
                // label="Mobile Number"
                type="text"
                onChange={onTestimonialChange}
                placeholder="Enter designation"
                fullWidth
                margin="none"
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FormControl>
            <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
              {errors?.designation}
            </FormHelperText>
          </div>
        </div>

        <div className="mb-2">
          <InputLabel
            htmlFor="inputCompanyName"
            className="text-start fs-14 text-black font-skmodernist"
          >
            Testimonial <span className="text-mandatory">*</span>
          </InputLabel>

          <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <TextareaAutosize
              id="testimonial"
              name="testimonial"
              value={testimonialWebsiteData.testimonial}
              onChange={onTestimonialChange}
              placeholder="Enter testimonial"
              minRows={3} // You can adjust the number of rows as needed
              maxRows={2} // You can adjust the number of rows as needed
              maxLength={500}
            />
          </FormControl>
          <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
            {errors?.testimonial}
          </FormHelperText>
        </div>
      </DialogContent>
      <DialogActions className="modal-footer">
        <Button onClick={()=>{
          GAEvents(MANAGEWEBSITE_TITLE, "Add Testimonial", CLICKED, {
            userId: userData?.id || sessionStorage.getItem('random_id'),
            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
            title: "Cancel"
          });
          handleCancel();}} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button onClick={()=>{
          GAEvents(MANAGEWEBSITE_TITLE, "Add Testimonial", CLICKED, {
            userId: userData?.id || sessionStorage.getItem('random_id'),
            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
            title: "Save"
          });
          onTestimonialSave();
          }} className="btn-save">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddTestimonialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  testimonialWebsiteData: PropTypes.any,
  setTestimonialWebsiteData: PropTypes.func,
  registrationId: PropTypes.any,
  onTestimonialChange: PropTypes.func,
  onTestimonialSave: PropTypes.func,
  errors: PropTypes.any,
  userData: PropTypes.object
};

export default AddTestimonialModal;
