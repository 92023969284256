import React from 'react';
import {
  Drawer,
  Typography,
} from '@mui/material'; // Import Typography
import CloseIcon from '@mui/icons-material/Close';
import Form from './Form';
import PropTypes from 'prop-types';

const DrawerForm = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  validateInput,
  errors,
  mobile_number,
  name,
  email,
}) => {

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          height: 'auto',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        },
      }}
    >
      <div className="d-flex justify-content-between p-4">
        <Typography
          variant="h5"
          align="left"
          alignItems="center"
          className="fs-18 mb-2 fw-bold font-skmodernist "
          gutterBottom
        >
          {title}
        </Typography>
        <CloseIcon onClick={onClose} />
      </div>
      <Form
        onSubmit={onSubmit}
        validateInput={validateInput}
        errors={errors}
        mobile_number={mobile_number}
        name={name}
        email={email}
      />
    </Drawer>
  );
};

DrawerForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  validateInput: PropTypes.func,
  errors: PropTypes.object,
  mobile_number: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
};

export default DrawerForm;
