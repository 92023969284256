import { JOB_APPLICANT_EMAIL, JOB_APPLICANT_MOBILE_NO, JOB_APPLICANT_NAME, JOB_FORM_RESET_DATA, JOB_RESET_DATA, SAVE_JOB_COVERLETTER_DATA, SAVE_JOB_ID, SAVE_JOB_RES, UPDATE_JOB_UPLOADED_RESUME } from "../types";

const INITIAL_STATE = {
    applyJobs: {},
    name: '',
    phoneNumber: '',
    emailId: '',
    resume: null,
    coverLetter: null,
    jobId: null

  };

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_JOB_RES :
            return {
                ...state,
                applyJobs: action.payload
            };
        case UPDATE_JOB_UPLOADED_RESUME:
            return {
              ...state,
              resume: action.payload
            };
        case SAVE_JOB_COVERLETTER_DATA:
          return {
            ...state,
            coverLetter: action.payload
          };
        case JOB_APPLICANT_EMAIL:
          return {
            ...state,
            emailId: action.payload
          };
        case JOB_APPLICANT_MOBILE_NO:
          return {
            ...state,
            phoneNumber: action.payload
          };
        case JOB_APPLICANT_NAME:
          return {
            ...state,
            name: action.payload
          };
        case SAVE_JOB_ID:
          return {
            ...state,
            jobId: action.payload
          };
        case JOB_RESET_DATA:
          return INITIAL_STATE;
        
        case JOB_FORM_RESET_DATA:
          return {
            ...state,
            applyJobs: {},
            name: '',
            phoneNumber: '',
            emailId: '',
            resume: null,
            coverLetter: null
          }

        default:
            return state;
    }
}

