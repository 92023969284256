import { UPLOAD_RESUME, GET_RESUME_DATA, RESET_RESUME_DATA, REMOVE_RESUME, STORE_DELETED_RESUME, UPDATE_UPLOADED_RESUME, DELETE_RESUME } from "../types"
// import { generateUniqueId } from '../../../../../utils/genrateUniqId'
const INITIAL_STATE = {
    resume: [],
    resumeData: [],
    deletedResumeData: [],

}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case UPLOAD_RESUME: {
            if (state.images !== null) {
                const allImgs = Array.from(action.payload).concat(Array.from(state.images));
                return {
                    ...state, images: allImgs
                };
            } else {
                return {
                    ...state, images: action.payload
                };
            }
            // return {
            //     ...state, images:action.payload
            // };
        }
        case UPDATE_UPLOADED_RESUME: {
            // const uniqId = generateUniqueId()
            // let payloadWithId;
            // if (action.payload?.id) {
            //     payloadWithId = { ...action.payload };
            // } else {
            //     payloadWithId = { ...action.payload, id: uniqId };
            // }
            // console.log("====UPDATE_UPLOADED_RESUME payloadWithId", payloadWithId);
            let resumes = {
                ...state,
                resume: [action.payload]
            };
            console.log("====UPDATE_UPLOADED_RESUME state", resumes);
            return resumes;
        }
        case DELETE_RESUME: {
            const name = action.payload
            console.log("====DELETE_RESUME payload", action.payload);
            let resumes = {
                ...state,
                resume: state.resume.filter(r => r.name !== name)
            };
            console.log("====DELETE_RESUME state", resumes);
            return resumes;
        }
        case STORE_DELETED_RESUME: {
            console.log("====DELETE_RESUME payload", action.payload);
            let deletedResumeData = {
                ...state,
                deletedResumeData: [...state.deletedResumeData, action.payload]
            };
            console.log("====DELETE_RESUME state", deletedResumeData);
            return deletedResumeData;
        }
        case GET_RESUME_DATA:
            return {
                ...state, resume: action.payload, resumeData: action.payload
            };

        case REMOVE_RESUME:
            return {
                ...state, resumeData: [], resume: null
            };

        case RESET_RESUME_DATA:
            return INITIAL_STATE;

        default:
            return state;
    }
}