/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Autocomplete,
  TextField,
  ButtonGroup,
  Radio,
  Backdrop,
  CircularProgress,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as FiltersIcon } from '../../assets/filters-icon.svg';
import { ReactComponent as NearMeIcon } from '../../assets/nearme-icon.svg';
import { ReactComponent as NearMeWhiteIcon } from '../../assets/NearMeWhiteIcon.svg';
import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';
import { ReactComponent as OrdersIcon } from '../../assets/orders-icon.svg';
import { ReactComponent as DurationIcon } from '../../assets/duration-icon.svg';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import DropdownIcon from '@mui/icons-material/KeyboardArrowDown';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import OutlinedInput from '@mui/material/OutlinedInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import { ReactComponent as SellerProfileIcon } from '../../assets/seller-profile-icon.svg';
import { handleLocationPermission } from '../../utils/helper-funtions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { connect, useDispatch } from 'react-redux';
import {
  getVendorList,
  postEnquiry,
  getEnquiryById,
  setFilterVCity,
  saveFLDetails,
  getFreelancerVendorDetails,
  getVendorDetails,
  postMemberContacted,
  setFilterCategory,
  setFilterSort,
  setFilterRating,
  setVendorFilterCity,
  setFilterNearMe,
  setNearByClickState,
} from '../B2BCategory/redux/actions/b2b-category-action';
import defaultProfileImage from '../../assets/defaultProfileImage.png';

import { loading } from '../../store/actions/rootActions';
import { COMPANY_LOGO_BASE_URL, WHATSAPP_URL } from '../../utils/APIs/api';

import {
  getCityMaster,
  setFilterCity,
  setLocationCoords,
} from '../ProfileDetails/redux/actions/profile-details-form-action';
import DrawerForm from '../../components/DrawerForm';
import useValidation from '../../hooks/useValidation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import { SET_ENQUIRY } from '../B2BCategory/redux/types';
import {
  CANCELLATION_REFUND,
  CONTACT_US,
  FREELANCER_ID,
  LANG,
  MAPBOX_ACC,
  ModalStyle,
  PRIVACY_POLICY,
  SHIPPING_DELIVERY,
  TERMS,
  PRIV_POL_SLUG,
  T_AND_C_SLUG,
  SHIP_DEL_SLUG,
  CAN_REF_SLUG,
  CON_US_SLUG,
  DIALOG_NOT_LOGIN_MSG,
  DIALOG_TITLE,
  CALLBACK_TYPE_GENERIC,
  DIALOG_VENDOR_DETAIL_SCRN__MSG,
} from '../../utils/constants';
import axios from 'axios';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
// import useGeoLocation from '../../hooks/useGeolocation';
import Rating from '@mui/material/Rating';
import { searchVendors } from '../Search/redux/actions/search-action';
import Responsive from '../../utils/responsive';

import {
  resetTemplateData,
  setTemplateRender,
} from '../Templates/redux/action.js/template-action';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import WebFLCategoryForm from './Web-FL-Category';

function findMinimumBudget(data) {
  if (data.length === 0) return null; // Handle empty array case
  let minBudget = parseFloat(data[0].budget);
  for (let i = 1; i < data.length; i++) {
    const budget = parseFloat(data[i].budget);
    if (budget < minBudget) {
      minBudget = budget;
    }
  }
  return minBudget;
}
const theme = createTheme();
export function isMoreThan24HoursAgo(createdAt) {
  if (!createdAt) {
    return false; // or handle this case as per your requirement
  }

  const createdDate = moment(createdAt);
  if (!createdDate.isValid()) {
    return false; // or handle this case as per your requirement
  }

  const differenceInHours = moment().diff(createdDate, 'hours');
  return differenceInHours > 24;
}

const FLFreelanceCategoryForm = ({
  getVendorList,
  vendors,
  loading,
  isLoading,
  getCityMaster,
  allCity,
  b2bprodCategories,
  filterCity,
  setFilterCity,
  selectedVCategory,
  userData,
  postEnquiry,
  enquiry,
  pushMessage,
  getEnquiryById,
  allVCity,
  setFilterVCity,
  filterVCity,
  saveFLDetails,
  getFreelancerVendorDetails,
  getVendorDetails,
  postMemberContacted,
  setFilterCategory,
  setFilterSort,
  setVendorFilterCity,
  setFilterRating,
  saveFilterRating,
  saveFilterCategory,
  saveFilterCity,
  saveFilterNearMe,
  saveFilterSort,
  setNearByClickState,
  markNearByClick,
  setFilterNearMe,
  searchVendors,
  searchVendor,
  setTemplateRender,
  resetTemplateData,
}) => {
  var params = '';
  const history = useHistory();
  const ratingArr = [
    { label: '1.0 and above', value: 1 },
    { label: '2.0 and above', value: 2 },
    { label: '3.0 and above', value: 3 },
    { label: '4.0 and above', value: 4 },
  ];
  const sortArr = [
    { label: 'Price - Low to High', value: 'price-low-to-high' },
    { label: 'Price - High to Low', value: 'price-high-to-low' },
    { label: 'Rating - High to Low', value: 'rating-High-to-low' },
  ];

  const { user } = userData || {};
  const { mobile_number = '', name = '', email = '' } = user || {};

  // Vendor SearchBox States
  const [vendorSearchVal, setVendorSearchVal] = useState('');
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);

  const [openCallModal, setOpenCallModal] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);
  const [selectedNearBy, setSelectedNearBy] = useState(false);
  const nearRef = useRef();
  const nearMeRef = useRef(true);

  const [categoriesTree, setCategoriesTree] = useState([]);
  function handleClickHome(event) {
    event.preventDefault();
    history.replace('/homepage');
    console.info('You clicked a breadcrumb.');
  }

  function handleClickCategory(e) {
    e.preventDefault();
    history.replace('/fl-freelance');
  }

  const { validateInput, errors } = useValidation();
  const [isCityDrawerOpen, setIsCityDrawerOpen] = useState(false);
  const toggleCityDrawer = () => {
    setIsCityDrawerOpen(!isCityDrawerOpen);
  };
  const dispatch = useDispatch();
  // Checkbox
  // const [checked, setChecked] = useState([1]);
  const [selRating, setSelRating] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [selCity, setSelCity] = useState([]);
  const [selSort, setSelectedSort] = useState();
  const [searchText, setSearchText] = useState('');
  const [mapOtions, setMapOtions] = useState([]);
  // const { loading: locLoading, error, coords } = useGeoLocation();
  const [nearByCity, setNearByCity] = useState('');

  function filterAllCategory(list) {
    let roots = list?.filter((item) => {
      return item.parent_id == selectedVCategory?.id;
    });
    return roots;
  }

  const handleCallNow = (event, phoneNumber, countryCode, id) => {
    event.stopPropagation(); // Prevents the click of card
    if (userData?.registration?.id !== undefined) {
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ''
      ) {
        postMemberContacted(id, phoneNumber);
        window.location.href = `tel:${countryCode}${phoneNumber}`;
      } else {
        pushMessage('Phone number not found.');
      }
    } else {
      // show dialog msg and navigate to login screen
      handleOpenCallModal();
    }
  };

  const handleWhatsapp = (event, phoneNumber, countryCode, id) => {
    console.log('country code :', countryCode);
    event.stopPropagation();
    if (userData?.registration?.id !== undefined) {
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ''
      ) {
        postMemberContacted(id, phoneNumber);
        window.open(`${WHATSAPP_URL}${countryCode}${phoneNumber}`, '_blank');
      } else {
        pushMessage('WhatsApp number not found.');
      }
    } else {
      // show dialog msg and navigate to login screen
      handleOpenCallModal();
    }
  };

  const handleRatingToggle = (value) => () => {
    // const currentIndex = selRating.indexOf(value);
    console.log('handleRatingToggle--selRating', value);
    const currentIndex = selRating.map((x) => x.label).indexOf(value.label);
    const newChecked = [...selRating];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelRating(newChecked);
    setFilterRating(newChecked);
    console.log('handleRatingToggle--selRating', selRating);
  };

  const handleCategoryToggle = (value) => () => {
    const currentIndex = selCategory.indexOf(value);
    const newChecked = [...selCategory];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelCategory(newChecked);
    setFilterCategory(newChecked);
  };

  const handleCityToggle = (value) => () => {
    const currentIndex = selCity.indexOf(value);
    const newChecked = [...selCity];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelCity(newChecked);
  };
  const clearFilter = () => {
    setSelCategory([]);
    setSelRating([]);
  };
  const filterListData = (e) => {
    // const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    const searchTerm = e.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm);
  };
  const findNearestLocation = () => {
    // console.log('===findNearestLocation coords', coords);
    // if (coords == undefined) {
    //   getCurrentLocation();
    // }

    const fetchData = async () => {
      await getVendorList(FREELANCER_ID, selectedVCategory?.id, params);
    };
    loading(true);
    fetchData();
    loading(false);
    nearMeRef.current = !nearMeRef.current;
    setNearByClickState(!markNearByClick);
    setSelectedNearBy(!selectedNearBy);

    console.log('Near me loc FL: ', nearByCity);
  };

  // const handleLocateMe = async () => {
  //   const res = await handleLocationPermission(pushMessage);
  //   if (res.result) {
  //     if (res.result.state === 'granted') {
  //       getCurrentLocation();
  //     } else {
  //       pushMessage('Location Permission not granted.');
  //     }
  //   }
  // };
  useEffect(() => {
    const regId = userData?.registration.id;
    getEnquiryById(regId, CALLBACK_TYPE_GENERIC);
    // if(enquiry)
    if (enquiry?.isTrue == true) {
      setIsCallBackDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    // const regId = userData?.registration.id;
    // getEnquiryById(regId);
    // if(enquiry)
    setIsCallBackDrawerOpen(false);
  }, []);

  // useEffect(() => {
  //   if (locLoading) loading(true);
  //   else loading(false);
  //   if (error?.message) pushMessage(error?.message);
  // }, [locLoading, error]);

  async function getCurrentLocation() {
    if ('geolocation' in navigator) {
      loading(true);
      navigator.geolocation.getCurrentPosition(async function (position) {
        validateInput('location', position);
        try {
          const response = await axios.get(
            encodeURI(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${position.coords.longitude},${position.coords.latitude}.json?access_token=${MAPBOX_ACC}`
            )
          );
          if (response.status === 200) {
            console.log('REVERSE SEARCH RES', response.data);
            // setMapOtions(response.data?.features);
            // setOpen(true);
            if (response.data?.features?.length > 0) {
              for (const value of response.data.features) {
                console.log('value', value);
                if (value?.id?.includes('place') && value?.text) {
                  setNearByCity(value?.text);
                  setFilterNearMe(value?.text);
                  break;
                }
              }
            }
          }
        } catch (error) {
          console.log('Error fetch address by coordinates', error);
        } finally {
          loading(false);
        }
      });
    } else {
      console.log('Geolocation is not available in your browser.');
    }
  }
  // Checkbox

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
  const RequestCallBackComponent = React.useCallback(() => {}, [
    isCallBackDrawerOpen,
  ]);
  // React.useEffect(() => {
  // 	console.log(
  // 		'===useEffect freelancer isCallBackDrawerOpen',
  // 		isCallBackDrawerOpen
  // 	);
  // }, [isCallBackDrawerOpen]);

  const toggleCallBackDrawer = () => {
    if (isMoreThan24HoursAgo(enquiry?.createdAt)) {
      if (!userData) {
        dispatch({
          type: SET_ENQUIRY,
          payload: { isTrue: true, redirect_to: '/b2b-category' },
        });
        history.push('/login');
      } else {
        setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
      }
    } else if (enquiry && Object.keys(enquiry)?.length == 0) {
      if (!userData) {
        dispatch({
          type: SET_ENQUIRY,
          payload: { isTrue: true, redirect_to: '/b2b-category' },
        });
        handleOpenCallModal();
      } else {
        setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
      }
    } else {
      pushMessage(
        'We already received your enquiry. Admin will get back to you soon'
      );
    }
  };

  const [isSortByDrawerOpen, setIsSortByDrawerOpen] = useState(false);
  const toggleSortByDrawer = () => {
    setIsSortByDrawerOpen(!isSortByDrawerOpen);
  };

  const handleSortChange = (value) => {
    console.log('values : ', value);
    setSelectedSort(value);
    setFilterSort(value);
  };

  const requestCallBackSubmit = async (formData) => {
    // const{email,name,mobile_number}=userData
    const registrationId = userData?.registration?.id;
    if (registrationId) {
      const { mobileNumber, comment, ...rest } = formData;
      const details = {
        ...rest,
        comments: comment,
        phone_number: mobileNumber,
        registrationId,
      };
      // const postResponse = await postEnquiry(registrationId, details);
      const postResponse = await postEnquiry(registrationId, details);
      pushMessage(postResponse.message);
    }
    setIsCallBackDrawerOpen(false);
  };

  // Filters section starts

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function setFilterParam() {
    var params = '';
    if (selRating?.length > 0) {
      for (let i = 0; i < selRating.length; i++) {
        params += 'rating=' + selRating[i].value;
        if (i !== selRating.length - 1) {
          params += '&';
        }
      }
    }

    if (selCategory?.length > 0) {
      if (selRating?.length > 0) {
        params += '&';
      }
      for (let i = 0; i < selCategory.length; i++) {
        params += 'categoryId=' + selCategory[i].id;
        if (i !== selCategory.length - 1) {
          params += '&';
        }
      }
    }

    if (selCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < selCity.length; i++) {
        params += 'location=' + selCity[i];
        if (i !== selCity.length - 1) {
          params += '&';
        }
      }
    }

    if (selSort !== undefined && selSort !== null) {
      if (params !== '') {
        params += '&';
      }

      params += 'sortBy=' + selSort?.value;
    }

    if (markNearByClick) {
      if (nearByCity && nearByCity !== '') {
        if (params !== '') {
          params += '&';
        }
        params += 'location=' + nearByCity;
        nearRef.current.style.color = '#ff0000';
      }
    } else {
      nearRef.current.style.color = '#1843a0';
    }

    return params;
  }
  // Filter section ends

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickHome}
    >
      Elynker
    </Link>,
    <Link
      underline="hover"
      key="2"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickCategory}
    >
      Freelance
    </Link>,
    <Link
      underline="none"
      key="3"
      className="fs-14 text-breadcrumbs-active font-gotham"
    >
      {selectedVCategory?.title || ''}
    </Link>,
  ];

  const handleRatingAndCategoryFilter = () => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(FREELANCER_ID, selectedVCategory?.id, rParams);
    };
    toggleFilterDrawer();
    loading(true);
    fetchData();
    loading(false);
  };

  const clearRatingData = () => {
    if (value == 0) {
      setSelRating([]);
      setFilterRating([]);
    }
    if (value == 1) {
      setSelCategory([]);
      setFilterCategory([]);
    }
  };

  const [ratingValue, setRatingValue] = React.useState();

  const clearAllCity = () => {
    setSelCity([]);
    setFilterCity([]);
  };

  const handleSelectedCity = () => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(FREELANCER_ID, selectedVCategory?.id, rParams);
    };
    loading(true);
    setVendorFilterCity(selCity);
    fetchData();
    loading(false);
    toggleCityDrawer();
  };

  const clearSortData = () => {
    setSelectedSort(null);
    setFilterSort(null);
  };

  const handleSelectedSort = () => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getVendorList(FREELANCER_ID, selectedVCategory?.id, rParams);
    };
    loading(true);
    fetchData();
    loading(false);
    toggleSortByDrawer();
  };

  useEffect(() => {
    if (saveFilterCategory?.length > 0) {
      setSelCategory(saveFilterCategory);
    }
    if (saveFilterRating?.length > 0) {
      setSelRating(saveFilterRating);
    }
    if (saveFilterCity?.length > 0) {
      setSelCity(saveFilterCity);
    }
    if (saveFilterSort && 'value' in saveFilterSort) {
      setSelectedSort(saveFilterSort);
    }
    if (saveFilterNearMe !== null && saveFilterNearMe !== '') {
      setNearByCity(saveFilterNearMe);
      setSelectedNearBy(markNearByClick);
      nearMeRef.current = markNearByClick;
    }
    var params = '';
    if (saveFilterRating?.length > 0) {
      for (let i = 0; i < saveFilterRating?.length; i++) {
        params += 'rating=' + saveFilterRating[i].value;
        if (i !== saveFilterRating?.length - 1) {
          params += '&';
        }
      }
    }

    if (saveFilterCategory?.length > 0) {
      if (saveFilterRating?.length > 0) {
        params += '&';
      }
      for (let i = 0; i < saveFilterCategory?.length; i++) {
        params += 'categoryId=' + saveFilterCategory[i]?.id;
        if (i !== saveFilterCategory?.length - 1) {
          params += '&';
        }
      }
    }

    // call API
    if (saveFilterCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < saveFilterCity?.length; i++) {
        params += 'location=' + saveFilterCity[i];
        if (i !== saveFilterCity?.length - 1) {
          params += '&';
        }
      }
    }

    if (saveFilterSort && 'value' in saveFilterSort) {
      if (params !== '') {
        params += '&';
      }

      params += 'sortBy=' + saveFilterSort?.value;
    }

    if (markNearByClick) {
      nearRef.current.style.color = '#ff0000';
      if (saveFilterNearMe && saveFilterNearMe !== '') {
        if (params !== '') {
          params += '&';
        }
        params += 'location=' + saveFilterNearMe;
      }
    } else {
      nearRef.current.style.color = '#1843a0';
    }

    const fetchData = async () => {
      // await getCurrentLocation();
      await getVendorList(FREELANCER_ID, selectedVCategory?.id, params);
    };
    loading(true);
    fetchData();
    loading(false);
  }, []);

  useEffect(() => {
    if (b2bprodCategories?.length > 0) {
      const categoryList = filterAllCategory(b2bprodCategories);
      setCategoriesTree(categoryList);
    }
  }, [b2bprodCategories]);

  useEffect(() => {
    if (searchText === '') {
      setFilterVCity(allVCity);
    } else {
      const filteredItems = allVCity?.filter((item) =>
        item?.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilterVCity(filteredItems);
    }
  }, [allVCity, searchText]);

  useEffect(() => {
    const vCardList = searchVendor?.map((item) => {
      item['card_type'] = 'VendorCard';
      return item;
    });
    console.log('res : ', vCardList);
    if (vCardList !== undefined && vCardList?.length > 0) {
      setSearchOptions(vCardList);
    } else {
      console.log('called empty search options');
      setSearchOptions([]);
    }

    return () => {};
  }, [searchVendor]);

  const saveFreelancerDetail = async (details) => {
    console.log('===saveFreelancerDetail details', details);
    await getFreelancerVendorDetails(details.id);
    if (details.uuid) {
      history.push(`/freelancer-detail/${details.uuid}`);
    } else {
      pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
    }
  };

  // const handleVendorSearchInput = (e) => {
  // 	if (!open) {
  // 		setOpen(true);
  // 	}
  // 	setVendorSearchVal(e.target.value);
  // 	searchVendors(e.target.value);
  // };

  // const handleClick = () => {
  // 	setOpen(!open);
  // };

  const handleShippingDelivery = () => {
    setTemplateRender(SHIPPING_DELIVERY);
    history.push(`/${SHIP_DEL_SLUG}`);
    resetTemplateData();
  };

  const handleTerms = () => {
    setTemplateRender(TERMS);
    history.push(`/${T_AND_C_SLUG}`);
    resetTemplateData();
  };

  const handlePrivacy = () => {
    setTemplateRender(PRIVACY_POLICY);
    history.push(`/${PRIV_POL_SLUG}`);
    resetTemplateData();
  };

  const handleCancellationRefund = () => {
    setTemplateRender(CANCELLATION_REFUND);
    history.push(`/${CAN_REF_SLUG}`);
    resetTemplateData();
  };

  const handleContactUs = () => {
    setTemplateRender(CONTACT_US);
    history.push(`/${CON_US_SLUG}`);
    resetTemplateData();
  };

  return (
    <>
      <DrawerForm
        title="Request a Call Back"
        isOpen={isCallBackDrawerOpen}
        onClose={() => {
          setIsCallBackDrawerOpen(false);
        }}
        mobile_number={mobile_number}
        name={name}
        email={email}
        validateInput={validateInput}
        onSubmit={requestCallBackSubmit}
        errors={errors}
        BuisnessType={false}
      />
      <Dialog
        open={openCallModal}
        onClose={handleCloseCallModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        fullWidth
      >
        {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
        <DialogTitle className="custom-border-header pb-0">
          <Box className="position-relative d-flex justify-content-between">
            <Typography
              className="fs-22 fw-bold font-skmodernist"
              id="alert-dialog-title"
            >
              {DIALOG_TITLE}
            </Typography>
            <Box>
              <CloseIcon
                onClick={() => {
                  handleCloseCallModal();
                  history.push('/login');
                }}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {DIALOG_NOT_LOGIN_MSG}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="modal-footer">
          <Button className="btn-cancel me-2" onClick={handleCloseCallModal}>
            No
          </Button>
          <Button
            onClick={() => {
              handleCloseCallModal();
              history.push('/login');
            }}
            className="btn-save"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {Responsive(
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            {isLoading && (
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: '67vh' }}
            >
              <Grid item xs={12}>
                <div className="my-3 ms-2 d-flex align-items-center">
                  <ArrowBackIosIcon
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <ElynkerLogoComponent>
                      <ElynkerLogo
                        className="ms-3"
                        width={'100px'}
                        height={'30px'}
                      />
                    </ElynkerLogoComponent>
                    <div>
                      {/* <IconButton
                        className="p-0"
                        size="medium"
                        aria-label="user icon"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => {
                          //   setShowSearch((val) => !val);
                          history.push('/search');
                        }}
                      >
                        <SearchIcon />
                      </IconButton> */}
                    </div>
                  </div>
                </div>
                {/* <div>
									{ showSearch ? 
													<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
														<OutlinedInput
															id="subCategoryInput"
															type="text"
															placeholder="Search here..."
															// required
															// disabled={categoriesTreeFilter.length === 0}
															fullWidth
															margin="none"
															value={vendorSearchVal}
															onChange={handleVendorSearchInput}
															onClick={() => {
																console.log('===OutlinedInput onClick');
																handleClick();
															}}
															// onFocus={handleClick}
															startAdornment={
																<SearchIcon style={{ margin: '8px', width: '30px' }} />
															}
														/>
														{open && (
															<div
																style={{
																	position: 'absolute',
																	boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
																	top: '50px',
																	background: 'white',
																	zIndex: 1,
																	borderRadius: 4,
																	width: '100%',
																}}
															>
																{searchOptions.length > 0 &&
																	searchOptions?.map((option, vnIdx) => {
																		return (
																			<Box
												style={{
												marginRight:'10px',
												marginLeft: '10px'
												}}
																				key={vnIdx}
																				onClick={() => {
																					if (option?.registration_type[0] == 2) {
																						console.log('renderOption clicked b2b');
																						getVendorDetails(option.id);
																						history.push('/b2b-detail');
																					} else {
																						//registration_type == 2
																						console.log('renderOption clicked FL');
																						getVendorDetails(option.id);
																						history.push('/freelancer-detail');
																					}
																				}}
																			>
																				<div>
																					{option?.registration_type[0] == 2
																						? option?.company_name
																						: option?.name}
																				</div>
																				<div>{option?.city ? option.city : ''}</div>
																				<br />
																			</Box>
																		);
																	})}
															</div>
														)}
													</FormControl>
									: null }
									</div> */}
                <Divider
                  component="div"
                  className="main-divider mt-2 mb-3"
                  role="presentation"
                />

                <form style={{ marginTop: '0px' }}>
                  <Card className="category-card mb-3">
                    <CardContent className="freelance-category-content">
                      <Typography
                        variant="h5"
                        className=" text-white mb-3 fw-bold font-skmodernist freelance-category-text"
                        component="div"
                      >
                        Gain insights and advice from <br></br>
                        industry professionals who <br></br>
                        understand your challenges! <br></br>
                      </Typography>
                      <Button
                        variant="contained"
                        className="post-requirement-btn freelance-category-button"
                        onClick={toggleCallBackDrawer}
                      >
                        Request a Call Back
                      </Button>
                    </CardContent>
                  </Card>
                  {/* <DrawerForm
                    title="Request a Call Back"
                    isOpen={isCallBackDrawerOpen}
                    onClose={() => {
                      setIsCallBackDrawerOpen(false);
                    }}
                    mobile_number={mobile_number}
                    name={name}
                    email={email}
                    validateInput={validateInput}
                    onSubmit={requestCallBackSubmit}
                    errors={errors}
                  /> */}

                  {/* <div className="my-4 b2b-business">
										<Stack direction="row" spacing={1.5} useFlexGap flexWrap="wrap">
										<Chip label="25 Mn+ Business" />
										<Chip label="30 Cr Business Done" />
										<Chip label="4.5 Star" />
										</Stack>
									</div> */}

                  <Breadcrumbs
                    className="mb-3"
                    separator={
                      <NavigateNextIcon
                        fontSize="small"
                        className="text-gray"
                      />
                    }
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>

                  <div
                    className={
                      selectedNearBy
                        ? 'my-4 b2b-filter-saved-section b2b-filter-section'
                        : 'my-4 b2b-filter-section'
                    }
                  >
                    <Stack
                      className="b2b-filter-slider"
                      direction="row"
                      spacing={1.5}
                      useFlexGap
                      flexWrap="nowrap"
                      overflow={'auto'}
                    >
                      <Chip
                        icon={<FiltersIcon />}
                        label="Filters"
                        clickable
                        onClick={toggleFilterDrawer}
                      />
                      <Chip
                        ref={nearRef}
                        icon={
                          !selectedNearBy ? <NearMeIcon /> : <NearMeWhiteIcon />
                        }
                        label="Near Me"
                        clickable
                        className={selectedNearBy ? 'MuiChip-saved-root' : ''}
                        onClick={findNearestLocation}
                      />
                      <Chip
                        label="City"
                        onDelete={() => {
                          toggleCityDrawer();
                        }}
                        onClick={toggleCityDrawer}
                        deleteIcon={
                          <DropdownIcon
                            fontSize="medium"
                            className="dropdown-icon"
                          />
                        }
                        clickable
                      />
                      <Chip
                        label="Sort By"
                        onDelete={() => {
                          toggleSortByDrawer();
                        }}
                        deleteIcon={
                          <DropdownIcon
                            fontSize="medium"
                            className="dropdown-icon"
                          />
                        }
                        onClick={toggleSortByDrawer}
                        clickable
                      />
                    </Stack>
                  </div>
                  {/* Filter drawer starts*/}
                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isFilterDrawerOpen}
                    onClose={toggleFilterDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: 'auto',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          Filters
                        </Typography>
                        <CloseIcon onClick={toggleFilterDrawer} />
                      </div>

                      <Box
                        sx={{
                          flexGrow: 1,
                          bgcolor: 'background.paper',
                          display: 'flex',
                          height: 250,
                          margin: '0 -16px',
                          borderTop: '1px solid #D9D9D9',
                          borderBottom: '1px solid #D9D9D9',
                        }}
                      >
                        <Tabs
                          orientation="vertical"
                          variant="scrollable"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          className="filter-tabs"
                          sx={{
                            borderRight: 1,
                            borderColor: 'divider',
                            background: '#F4F4F4',
                            minWidth: '30%',
                          }}
                        >
                          <Tab
                            label="Rating"
                            sx={{ borderBottom: '1px solid #E3E3E3' }}
                            className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0 text-start"
                            {...a11yProps(0)}
                          />
                          <Tab
                            label="Category"
                            sx={{ borderBottom: '1px solid #E3E3E3' }}
                            className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                        <TabPanel
                          value={value}
                          index={0}
                          className="filter-tabpanel"
                        >
                          <List
                            class="filter-tabpanel-list"
                            sx={{
                              width: '100%',
                              bgcolor: 'background.paper',
                              padding: '0px',
                            }}
                          >
                            {ratingArr &&
                              ratingArr?.map((rating, index) => {
                                const labelId = `checkbox-list-secondary-label-${index}`;
                                return (
                                  <ListItem
                                    key={index}
                                    secondaryAction={
                                      <Checkbox
                                        edge="end"
                                        onChange={handleRatingToggle(rating)}
                                        checked={
                                          selRating.findIndex(
                                            (x) => x.label === rating.label
                                          ) !== -1
                                        }
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                        sx={{
                                          color: '#D4D4D4',
                                          '&.Mui-checked': {
                                            color: '#416AD4',
                                          },
                                        }}
                                      />
                                    }
                                    disablePadding
                                  >
                                    <ListItemButton
                                      sx={{ paddingLeft: '0px' }}
                                      onClick={handleRatingToggle(rating)}
                                    >
                                      <ListItemText
                                        id={labelId}
                                        className="fs-15 fw-normal text-black font-skmodernist"
                                        primary={rating?.label}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </TabPanel>
                        <TabPanel
                          value={value}
                          index={1}
                          className="filter-tabpanel"
                        >
                          <List
                            class="filter-tabpanel-list"
                            sx={{
                              width: '100%',
                              bgcolor: 'background.paper',
                              padding: '0px',
                            }}
                          >
                            {categoriesTree?.map((category, index) => {
                              const labelId = `checkbox-list-secondary-label-${index}`;
                              return (
                                <ListItem
                                  key={index}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      onChange={handleCategoryToggle(category)}
                                      checked={
                                        selCategory.findIndex(
                                          (x) => x.id === category.id
                                        ) !== -1
                                      }
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemButton sx={{ paddingLeft: '0px' }}>
                                    <ListItemText
                                      id={labelId}
                                      className="fs-15 fw-normal text-black font-skmodernist"
                                      primary={category?.title}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        </TabPanel>
                      </Box>

                      <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                        <Button
                          variant="contained"
                          className="call-now-btn"
                          onClick={clearRatingData}
                        >
                          Clear All
                        </Button>
                        <Button
                          variant="contained"
                          className="whatsapp-btn"
                          onClick={handleRatingAndCategoryFilter}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Drawer>
                  {/* Filter drawer ends*/}

                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isCityDrawerOpen}
                    onClose={toggleCityDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: 'auto',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          Select City
                        </Typography>
                        <CloseIcon onClick={toggleCityDrawer} />
                      </div>
                      <div className="mb-3">
                        <Autocomplete
                          freeSolo
                          fullWidth
                          options={filterVCity}
                          onInputChange={(e, value) => {
                            filterListData(value);
                          }}
                          onClose={() => {
                            setFilterCity(allVCity);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search your city"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <SearchIcon
                                    style={{ margin: '8px', width: '30px' }}
                                  />
                                ),
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      </div>
                      <Typography
                        variant="h4"
                        align="left"
                        alignItems="center"
                        className="fs-14 text-grey fw-normal font-gotham"
                      >
                        Popular
                      </Typography>
                      <List
                        dense
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          height: '220px',
                          overflowY: 'scroll',
                        }}
                      >
                        {filterVCity &&
                          filterVCity?.map((city, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                              <ListItem
                                key={index}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={handleCityToggle(city)}
                                    checked={selCity.indexOf(city) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={handleCityToggle(city)}
                                >
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={city}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>

                      <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                        <Button
                          variant="contained"
                          className="call-now-btn"
                          onClick={clearAllCity}
                        >
                          Clear All
                        </Button>
                        <Button
                          variant="contained"
                          className="whatsapp-btn"
                          onClick={handleSelectedCity}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Drawer>

                  <Drawer
                    xs={12}
                    anchor="bottom"
                    open={isSortByDrawerOpen}
                    onClose={toggleSortByDrawer}
                    sx={{
                      '& .MuiDrawer-paper': {
                        height: 'auto',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      },
                    }}
                  >
                    <div className="p-3">
                      <div className="d-flex justify-content-between mb-3">
                        <Typography
                          variant="h5"
                          align="left"
                          alignItems="center"
                          className="fs-18 mb-2 fw-bold font-skmodernist "
                          gutterBottom
                        >
                          Sort By
                        </Typography>
                        <CloseIcon onClick={toggleSortByDrawer} />
                      </div>

                      <List
                        dense
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                      >
                        {sortArr &&
                          sortArr?.map((option, index) => {
                            const labelId = `radio-list-secondary-label-${index}`;
                            return (
                              <ListItem
                                key={index}
                                secondaryAction={
                                  <Radio
                                    edge="end"
                                    onChange={() => handleSortChange(option)}
                                    checked={selSort?.label === option?.label}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={() => handleSortChange(option)}
                                >
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={option.label}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>

                      <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                        <Button
                          variant="contained"
                          className="call-now-btn"
                          onClick={clearSortData}
                        >
                          Clear All
                        </Button>
                        <Button
                          variant="contained"
                          className="whatsapp-btn"
                          onClick={handleSelectedSort}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Drawer>

                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-20 text-black mb-3 fw-bold font-skmodernist"
                  >
                    Wholesale and Suppliers
                  </Typography>

                  <List>
                    {vendors &&
                      vendors?.map((item, index) => {
                        let last_update = moment(item?.last_login)?.fromNow();

                        return (
                          <ListItem className="freelance-list-item" key={index}>
                            <Card
                              className="card"
                              onClick={() => saveFreelancerDetail(item)}
                            >
                              <div className="d-flex">
                                <CardMedia
                                  className="category-media"
                                  image={
                                    item?.image_path
                                      ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                      : defaultProfileImage
                                  }
                                  sx={{
                                    padding: '1em 1em 0 1em',
                                    objectFit: 'contain',
                                  }}
                                />
                                <CardContent className="content p-0">
                                  <div className="d-flex justify-content-between align-items-center mb-1 w-100">
                                    <Typography
                                      variant="h5"
                                      className="fs-16 text-black mb-0 fw-bold font-skmodernist"
                                      component="div"
                                    >
                                      {item?.name || ''}
                                    </Typography>

                                    <div
                                      onClick={() => {
                                        getFreelancerVendorDetails(
                                          item.id,
                                          (r) => {
                                            if (item?.uuid) {
                                              history.push(
                                                `/freelancer-detail/${item.uuid}`
                                              );
                                            } else {
                                              pushMessage(
                                                DIALOG_VENDOR_DETAIL_SCRN__MSG
                                              );
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      <RightArrowIcon />
                                    </div>
                                  </div>
                                  <Typography className="fs-13 fw-normal text-secondary font-skmodernist mb-1">
                                    {item?.freelancer_role || ''}
                                  </Typography>

                                  <Typography
                                    variant="h5"
                                    className="fs-13 text-grey fw-normal font-skmodernist mb-1"
                                    component="div"
                                  >
                                    Last seen{' '}
                                    {last_update !== 'Invalid date'
                                      ? last_update
                                      : ''}
                                  </Typography>

                                  <Box>
                                    <Rating
                                      name="read-only"
                                      value={item?.rating}
                                      onChange={(event, newValue) => {
                                        setRatingValue(newValue);
                                      }}
                                      size="small"
                                      precision={0.1}
                                      readOnly
                                    />
                                  </Box>
                                </CardContent>
                              </div>

                              <CardContent className="p-0 d-flex justify-content-between mt-1">
                                <div className="d-flex flex-column">
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                  >
                                    Members
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-secondary mb-1 font-skmodernist"
                                  >
                                    {item?.member_count || 0}
                                  </Typography>
                                </div>
                                <div className="d-flex flex-column">
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                  >
                                    Services
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-secondary mb-1 font-skmodernist"
                                  >
                                    {item?.product_count || 0}
                                  </Typography>
                                </div>
                                <div className="d-flex flex-column">
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                  >
                                    Starting at
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className="fs-15 fw-bold text-secondary mb-1 font-skmodernist"
                                  >
                                    ₹{item?.hourly_rate || 0}/{' '}
                                    <span className="fs-13 text-lightgrey fw-normal">
                                      Hour
                                    </span>
                                  </Typography>
                                </div>
                              </CardContent>

                              <Divider
                                component="div"
                                className="mt-2 mb-3"
                                role="presentation"
                              />
                              <CardContent className="p-0 d-flex justify-content-between">
                                <Button
                                  variant="contained"
                                  className="call-now-btn "
                                  onClick={(e) =>
                                    handleCallNow(
                                      e,
                                      item?.mobile_number,
                                      item?.country_code
                                        ? `+${item?.country_code}`
                                        : ''
                                    )
                                  }
                                >
                                  <CallNowIcon className="me-2" />
                                  Call Now
                                </Button>
                                <Button
                                  variant="contained"
                                  className="whatsapp-btn "
                                  onClick={(e) =>
                                    handleWhatsapp(
                                      e,
                                      item?.whatsapp_number,
                                      item?.country_code
                                        ? `+${item?.country_code}`
                                        : ''
                                    )
                                  }
                                >
                                  <WhatsappIcon className="me-2" />
                                  WhatsApp
                                </Button>
                              </CardContent>
                            </Card>
                          </ListItem>
                        );
                      })}
                  </List>
                </form>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>,
        <WebFLCategoryForm
          nearRef={nearRef}
          isLoading={isLoading}
          toggleCallBackDrawer={toggleCallBackDrawer}
          errors={errors}
          categoriesTree={categoriesTree}
          handleCategoryToggle={handleCategoryToggle}
          selCategory={selCategory}
          clearRatingData={clearRatingData}
          handleRatingAndCategoryFilter={handleRatingAndCategoryFilter}
          ratingArr={ratingArr}
          sortArr={sortArr}
          handleRatingToggle={handleRatingToggle}
          selRating={selRating}
          vendors={vendors}
          handleCallNow={handleCallNow}
          handleWhatsapp={handleWhatsapp}
          clearFilter={clearFilter}
          selectedVCategory={selectedVCategory}
          handleTerms={handleTerms}
          handlePrivacy={handlePrivacy}
          handleShippingDelivery={handleShippingDelivery}
          handleCancellationRefund={handleCancellationRefund}
          handleContactUs={handleContactUs}
          handleClickHome={handleClickHome}
          handleClickCategory={handleClickCategory}
          getFreelancerVendorDetails={getFreelancerVendorDetails}
          selCity={selCity}
          setSelCity={setSelCity}
          nearByCity={nearByCity}
          setSelRating={setSelRating}
          setSelCategory={setSelCategory}
          selSort={selSort}
          setSelectedSort={setSelectedSort}
          getVendorList={getVendorList}
          handleCityToggle={handleCityToggle}
          filterVCity={filterVCity}
          allVCity={allVCity}
          setFilterVCity={setFilterVCity}
          clearSortData={clearSortData}
          clearAllCity={clearAllCity}
          pushMessage={pushMessage}
          userData={userData}
        />
      )}
    </>
  );
};

FLFreelanceCategoryForm.propTypes = {
  userData: PropTypes.object,
  vendors: PropTypes.array,
  getVendorList: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  getCityMaster: PropTypes.func,
  allCity: PropTypes.array,
  b2bprodCategories: PropTypes.array,
  filterCity: PropTypes.array,
  setFilterCity: PropTypes.func,
  selectedVCategory: PropTypes.object,
  postEnquiry: PropTypes.func,
  enquiry: PropTypes.object,
  pushMessage: PropTypes.func,
  getEnquiryById: PropTypes.func,
  allVCity: PropTypes.array,
  setFilterVCity: PropTypes.func,
  filterVCity: PropTypes.array,
  saveFLDetails: PropTypes.func,
  getFreelancerVendorDetails: PropTypes.func,
  getVendorDetails: PropTypes.func,
  postMemberContacted: PropTypes.func,
  setFilterCategory: PropTypes.func,
  setFilterSort: PropTypes.func,
  setVendorFilterCity: PropTypes.func,
  setFilterRating: PropTypes.func,
  saveFilterCity: PropTypes.array,
  saveFilterRating: PropTypes.array,
  saveFilterCategory: PropTypes.array,
  saveFilterSort: PropTypes.object,
  saveFilterNearMe: PropTypes.string,
  setFilterNearMe: PropTypes.func,
  setNearByClickState: PropTypes.func,
  markNearByClick: PropTypes.bool,
  searchVendors: PropTypes.func,
  searchVendor: PropTypes.array,
  handleShippingDelivery: PropTypes.func,
  handleCancellationRefund: PropTypes.func,
  handleContactUs: PropTypes.func,
  handleTerms: PropTypes.any,
  handlePrivacy: PropTypes.any,
  setTemplateRender: PropTypes.func,
  resetTemplateData: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const {
    vendors,
    enquiry,
    allVCity,
    filterVCity,
    saveFilterRating,
    saveFilterCategory,
    saveFilterCity,
    saveFilterNearMe,
    saveFilterSort,
    markNearByClick,
  } = state.b2bVendorlisting;
  const { isLoading } = state.root;
  const { allCity, filterCity } = state.profileDetails;
  const { b2bprodCategories } = state.offeredServices;
  const { selectedVCategory } = state.b2bBusinessReducer;
  const { searchVendor } = state.solrSearch;

  return {
    userData,
    vendors,
    isLoading,
    allCity,
    filterCity,
    b2bprodCategories,
    selectedVCategory,
    enquiry,
    allVCity,
    filterVCity,
    saveFilterRating,
    saveFilterCategory,
    saveFilterCity,
    saveFilterNearMe,
    saveFilterSort,
    markNearByClick,
    searchVendor,
  };
};

export default connect(mapStateToProps, {
  getVendorList,
  getVendorDetails,
  getFreelancerVendorDetails,
  loading,
  postEnquiry,
  pushMessage,
  getEnquiryById,
  getCityMaster,
  saveFLDetails,
  setFilterCity,
  setFilterVCity,
  postMemberContacted,
  setFilterCategory,
  setFilterSort,
  setFilterRating,
  setVendorFilterCity,
  setFilterNearMe,
  setNearByClickState,
  searchVendors,
  setTemplateRender,
  resetTemplateData,
})(FLFreelanceCategoryForm);
