import { loading } from "../../../../store/actions/rootActions";
import { getElynkerTools } from "../../../../utils/APIs/endpoints";
import { SHOW_TOOLS_DATA } from "../types";

export const setElynkerToolsData = (tools) => function (dispatch) {
    dispatch({type: SHOW_TOOLS_DATA, payload: tools})
}

export const getElynkerToolsData = () => {
    return async(dispatch) => {
        dispatch(loading(true));
        try {
            const response = await getElynkerTools();
            if (response.status === 200){
                let arr = [];
                if(response.data?.data?.length > 0) {
                    arr = response.data?.data?.map(itm => ({...itm, viewMore: false}))
                }
                dispatch({type: SHOW_TOOLS_DATA, payload: arr});
            }
        } catch (error) {
            console.log("error on elynker tools", error);

        }
        finally {
            dispatch(loading(false));
        }
    }
}