// Form.js
import React from 'react';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import FormField from './FormField';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loading } from '../../store/actions/rootActions';
import useValidation from '../../hooks/useValidation';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const mobileNumberRegex = /^\d{0,10}$/;

const Form = ({
  mobile_number,
  name,
  email,
  onSubmit,
  // validateInput,
  // errors,
  submitBtnText = 'Submit',
}) => {
  const { validateInput, errors, setErrors } = useValidation();
  const [formData, setFormData] = React.useState({
    mobileNumber: mobile_number,
    name: name,
    email: email,
    comment: '',
  });
  const [diaLoading, setDiaLOading] = React.useState(false);

  const onChangeHandler = (e) => {
    const { value, id } = e.target;

    validateInput(id, value);
    if (id === 'mobileNumber' && mobileNumberRegex.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
      console.log('mobileNumber onChangeHandler', formData);
    } else if (id !== 'mobileNumber') {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const onFormSubmit = () => {
    console.log('onFormSubmit', formData);
    if (!formData?.name) {
      setErrors((prevVal) => ({ ...prevVal, name: 'Name is required' }));
      setDiaLOading(false);
    } else if (formData?.name?.length < 3) {
      setErrors((prevVal) => ({
        ...prevVal,
        name: 'Name should be atleast 3 characters',
      }));
      setDiaLOading(false);
    }
    if (!formData?.email) {
      setErrors((prevVal) => ({ ...prevVal, email: 'Email Id is required' }));
      setDiaLOading(false);
    } else if(!emailRegex.test(formData?.email)) {
      setErrors((prevVal) => ({ ...prevVal, email: 'Invalid email' }));
      setDiaLOading(false);
    }
    if (!formData?.mobileNumber) {
      setErrors((prevVal) => ({
        ...prevVal,
        mobileNumber: 'Phone number is required',
      }));
      setDiaLOading(false);
    }
    if (!formData?.comment) {
      setErrors((prevVal) => ({ ...prevVal, comment: 'Comment is required' }));
      setDiaLOading(false);
    }

    if (
      !formData?.name ||
      formData?.name?.length < 3 ||
      !formData?.email ||
      !emailRegex.test(formData?.email) ||
      !formData?.mobileNumber ||
      !formData?.comment
    ) {
      setDiaLOading(false);
    } else {
      onSubmit(formData);
      setDiaLOading(false);
    }
  };

  return (
    <div className="p-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setDiaLOading(true);
          onFormSubmit();
        }}
      >
        {diaLoading && (
          <Backdrop
            sx={{
              color: '#f00',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={diaLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <FormField
          id="name"
          label="Name"
          value={formData?.name}
          // disabled={name ? true : false}
          type="text"
          placeholder="Enter name"
          onChange={onChangeHandler}
          errors={errors}
        />
        <FormField
          id="email"
          label="Email ID"
          value={formData?.email}
          type="email"
          // disabled={email ? true : false}
          placeholder="Enter email"
          onChange={onChangeHandler}
          errors={errors}
        />
        <FormField
          id="mobileNumber"
          value={formData?.mobileNumber}
          disabled={mobile_number ? true : false}
          label="Phone No"
          type="number"
          placeholder="Enter phone number"
          onChange={onChangeHandler}
          errors={errors}
        />
        <FormField
          id="comment"
          label="Comment"
          type="text"
          placeholder="Enter comment"
          onChange={onChangeHandler}
          errors={errors}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
          sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
        >
          {submitBtnText}
        </Button>
      </form>
    </div>
  );
};
Form.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  validateInput: PropTypes.func,
  errors: PropTypes.object,
  mobile_number: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  submitBtnText: PropTypes.string,
  userData: PropTypes.object,
  isLoading: PropTypes.bool,
  loading: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { isLoading } = state.root;

  return {
    userData,
    isLoading,
  };
};
// export default Form;
export default connect(mapStateToProps, {
  loading,
})(Form);
