import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import {
	Container,
	Grid,
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	TableHead,
	Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { Margin, usePDF } from 'react-to-pdf';
import { connect } from 'react-redux';
import getProductList from '../MyCatalog/redux/actions/mycatalog-action';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';

const theme = createTheme();

const ProductCatalogForm = ({ getProductList, allProducts, userData }) => {
	const [hideEle, setHideEle] = useState(false);
	const { toPDF, targetRef } = usePDF({
		filename: 'page.pdf',
		// resolution: Resolution.HIGH,
		page: { margin: Margin.MEDIUM },
		overrides: {
			canvas: {
				useCORS: true,
			},
		},
	});
	// const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	// const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	useEffect(() => {
		getProductList(userData.registration.id);
	}, [userData]);

	useEffect(() => {
		console.log('All Products :', allProducts);
	}, [allProducts]);

	return (
		<ThemeProvider theme={theme}>
			<Container
				ref={targetRef}
				component="main"
				maxWidth="lg"
				style={{ width: 1080 }}
			>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<div className="mt-4 mb-2">
							<ElynkerLogoComponent>
								<ElynkerLogo width={'100px'} height={'30px'} />
							</ElynkerLogoComponent>
						</div>

						<Box className="divider-date">
							<div className="product-divider"></div>
							<Typography className="fs-16 text-slategrey font-skmodernist">
								<span className="fw-bold text-black">Date:</span>{' '}
								{moment().format('DD/MM/YYYY')}
							</Typography>
						</Box>

						<Button
							hidden={hideEle}
							onClick={() => {
								setHideEle(true);
								setTimeout(() => {
									toPDF();
									setHideEle(false);
								}, 3000);
							}}
						>
							DownLoad PDF
						</Button>
						<form style={{ marginTop: '0px' }}>
							{/* {isTabletOrMobile && (
								<>
									<Container className="app-container">
										<List className="fixture-main-list">
											{fixtures.map((fixture, index) => (
												<ListItem key={index} className="fixture-list-item">
													<Card className="fixture-card">
														<CardMedia className="fixture-image">
															{fixture.image}
														</CardMedia>

														<CardContent className="fixture-details">
															<Typography className="fs-16 fw-bold text-darkgrey font-skmodernist mb-2">
																{fixture.title}
															</Typography>
															<Typography className="fs-14 fw-nomal text-darkgrey font-gotham mb-2">
																{fixture.description}
															</Typography>
															<Typography className="fs-15 fw-bold text-secondary font-skmodernist">
																₹ {fixture.price}
															</Typography>
														</CardContent>
													</Card>
												</ListItem>
											))}
										</List>
										<Typography className="fs-16 font-skmodernist fixtures-footer">
											❤️ Thank you for your business!
										</Typography>
									</Container>
								</>
							)} */}

							{/* {isDesktopOrLaptop && ( */}
							<>
								<Box sx={{ marginTop: '50px' }}>
									<TableContainer component={Paper}>
										<Table className="product-table-bordered">
											<TableHead>
												<TableRow>
													{/* <TableCell>Serial No.</TableCell> */}
													<TableCell>Image</TableCell>
													<TableCell>Name</TableCell>
													<TableCell>Description</TableCell>
													<TableCell>Price</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{allProducts.map((fixture) => (
													<TableRow key={fixture?.id}>
														{/* <TableCell>{fixture.serialNo}</TableCell> */}
														<TableCell>
															<img
																src={`${PRODUCT_IMAGE_BASE_URL}${fixture.default_image}`}
																alt={`${PRODUCT_IMAGE_BASE_URL}${fixture.default_image}`}
																width={136}
																height={122}
															/>
														</TableCell>
														<TableCell className="fw-semibold">
															{fixture.title}
														</TableCell>
														<TableCell>{fixture.description}</TableCell>
														<TableCell className="fw-semibold text-secondary">
															₹ {fixture.budget}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>

								<Typography className="fs-16 font-skmodernist fixtures-footer">
									❤️ Thank you for your business!
								</Typography>
							</>
							{/* )} */}
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

ProductCatalogForm.propTypes = {
	allProducts: PropTypes.array,
	userData: PropTypes.object,
	getProductList: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { allProducts } = state.mycatalog;
	return {
		userData,
		allProducts,
	};
};

export default connect(mapStateToProps, { getProductList })(ProductCatalogForm);
