/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as FailedImage } from '../../assets/failed-banner.svg';
import Divider from '@mui/material/Divider';
import {
	Container,
	Grid,
	Typography,
	Button,
	Box,
	CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	createSubscriptionOrder,
	getRapOrderForPG,
	getSubscriptionDetails,
} from '../Subscription/redux/action/susbcription-action';
import {
	useHistory,
	useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
	DEFAULT_EMAIL_FOR_PG,
	DEFAULT_NAME_FOR_PG,
} from '../../utils/constants';
import { ELYNKER_LOGO_URL } from '../../utils/APIs/api';

const theme = createTheme();

const ProcessingForm = ({
	userData,
	premiumPlan,
	createSubscriptionOrder,
	getRapOrderForPG,
	getSubscriptionDetails,
	single_subscription,
}) => {
	// const history = useHistory();
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
	const history = useHistory();
	let location = useLocation();

	const prefRp = useRef();

	const [paymentResponse, setPaymentResponse] = useState({});
	const [tSubOrdId, setTSubOrdId] = useState('');
	const [tPayOrdID, setTPayOrdID] = useState('');
	const [tAmt, setTAmt] = useState('');

	useEffect(() => {
		console.log('window.location.href', window.location.href);
		const fetchPaymentStatus = async () => {
			const queryParams = new URLSearchParams(location.search);
			const paymentResponseData = {};
			queryParams.forEach((value, key) => {
				paymentResponseData[key] = value;
			});
			console.log('paymentResponseData', paymentResponseData);

			// Process and store the response data as needed
			setPaymentResponse(paymentResponseData);
		};

		fetchPaymentStatus();
	}, [location?.search]);

	function successNav() {
		history.replace('/successful', {
			title: 'Payment Successful!',
			subtitle: 'Welcome to the family! Let’s boost your business now!',
			from: '/processing',
			to: '/profile',
		});
	}

	function failureNav() {
		history.replace('/failed', {
			title: 'Payment Failed!',
			subtitle: 'Oops! Something went wrong!',
			from: '/processing',
			to: '/subscription',
		});
	}

	useEffect(() => {
		const registrationId = userData?.registration?.id;
		console.log('paymentResponse', paymentResponse);
		let processResDeb = setTimeout(() => {
			if (
				Object.keys(paymentResponse).length > 0 &&
				registrationId &&
				premiumPlan?.subsDetails
			) {
				if (
					paymentResponse?.payment_status === 'captured' &&
					paymentResponse?.customer_subscription
				) {
					getSubscriptionDetails(
						'id',
						paymentResponse?.customer_subscription,
						successNav(),
						successNav()
					);
				} else {
					failureNav();
				}
			}
		}, 1500);

		return () => clearTimeout(processResDeb);
	}, [
		paymentResponse,
		userData,
		premiumPlan?.subsDetails,
		single_subscription,
	]);

	const sendSubscriptionRequest = async (ignore) => {
		if (ignore || !location?.state?.selectedPlan || !premiumPlan) {
			return;
		}
		console.log('selectedPlan', location?.state?.selectedPlan);
		console.log('premiumPlan', premiumPlan);
		if (
			userData &&
			location?.state?.selectedPlan &&
			premiumPlan?.subsDetails?.name &&
			location?.state?.selectedPlan?.price &&
			userData?.user?.mobile_number
		) {
			const registrationId = userData?.registration?.id;

			const orderBody = {
				subscription_id: location?.state?.selectedPlan?.id,
				registration_id: registrationId,
				subscriber_name: userData?.registration?.name,
				subscription_type: location?.state?.selectedPlan?.plan_type,
				subscription_name: premiumPlan?.subsDetails?.name,
				requests: null,
				response: null,
				orders_status: 'created',
				created_by: userData?.user?.id,
				updated_by: userData?.user?.id,
				txn_id: null,
			};

			console.log('createSubscriptionOrder Body', orderBody);

			await createSubscriptionOrder(
				orderBody,
				async function successCallback(subOrderRes) {
					if (!subOrderRes?.data) {
						return;
					}

					const subOrderData = subOrderRes?.data;

					setTSubOrdId(subOrderData?.id);

					const reqPayDataBody = {
						amount: Number(location?.state?.selectedPlan?.total_amount)
							.toFixed(2)
							.toString(),
						currency: 'INR',
						notes: {
							order_id: subOrderData?.id,
							redirect_url: window.location.href,
						},
					};

					await getRapOrderForPG(
						reqPayDataBody,
						async function sCall(payOrderResponse) {
							console.log('payOrderResponse', payOrderResponse);
							if (!payOrderResponse?.data) {
								return;
							}
							const payData = payOrderResponse.data;

							setTPayOrdID(payData?.id);
							setTAmt(payData?.amount);

							let formSubmitT = setTimeout(() => {
								/////////////////////////
								// WHEN ORDER IS RECIEVED WAIT FOR ORDER FIELDS TO SET AND OPEN PAYMENT GATEWAY
								prefRp.current?.submit();
								clearTimeout(formSubmitT);
								/////////////////////////
							}, 1000);
						}
					);
				},
				function errorCallback() {}
			);
		}
	};

	useEffect(() => {
		let subReqDeb = setTimeout(() => {
			if (!location?.search?.includes('payment_status'))
				sendSubscriptionRequest(false);
		}, 1500);

		return () => clearTimeout(subReqDeb);
	}, [location?.search, userData, premiumPlan]);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2">
							<ElynkerLogoComponent>
								<ElynkerLogo width={'100px'} height={'30px'} />
							</ElynkerLogoComponent>
						</div>
						<Divider
							component="div"
							className="main-divider mb-3"
							role="presentation"
						></Divider>

						<form
							onSubmit={(e) => {
								e.preventDefault();
								// prefRp.current?.submit();
							}}
							style={{ marginTop: '100px' }}
						>
							<Box display="flex" flexDirection="column" alignItems="center">
								<Box display="inline-flex" className="mb-5 position-relative">
									<CircularProgress
										variant="indeterminate"
										value={75}
										size={118}
										thickness={1}
										sx={{ color: '#FECC00', animationDuration: '3s' }}
									/>
								</Box>

								<Typography
									variant="h4"
									align="center"
									className="fs-28 mb-3 fw-bold text-black font-skmodernist"
									gutterBottom
								>
									Payment Processing...
								</Typography>

								<Typography className="fs-16 text-center font-gotham text-grey mb-2">
									PLEASE DO NOT CLOSE OR REFRESH THIS PAGE
								</Typography>

								{/* {isTabletOrMobile && (
									<>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											className="mt-5 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
											sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
										>
											Refresh
										</Button>
									</>
								)}

								{isDesktopOrLaptop && (
									<>
										<Box className="text-center my-5">
											<Button
												type="submit"
												variant="contained"
												className="btn-web-next"
											>
												Refresh
											</Button>
										</Box>
									</>
								)} */}
							</Box>
						</form>
						<form
							ref={prefRp}
							action="https://api.razorpay.com/v1/checkout/embedded"
							method="post"
						>
							<input
								type="hidden"
								name="key_id"
								value={process.env.REACT_APP_RAZORPAY_KEY}
							/>
							<input type="hidden" name="amount" value={tAmt} />
							<input type="hidden" name="order_id" value={tPayOrdID} />
							<input type="hidden" name="name" value="Elynker" />
							<input
								type="hidden"
								name="description"
								value={premiumPlan?.subsDetails?.name || 'Premium Plan'}
							/>
							<input
								type="hidden"
								name="image"
								value={window.location.origin + '/elynker_logo_bg.jpg'}
							/>
							<input
								type="hidden"
								name="prefill[name]"
								value={
									userData?.registration?.company_name ||
									userData?.registration?.name ||
									userData?.user?.name ||
									DEFAULT_NAME_FOR_PG
								}
							/>
							<input
								type="hidden"
								name="prefill[contact]"
								value={userData?.user?.mobile_number || ''}
							/>
							<input
								type="hidden"
								name="prefill[email]"
								value={userData?.user?.email || DEFAULT_EMAIL_FOR_PG}
							/>
							<input type="hidden" name="notes[order_id]" value={tSubOrdId} />
							<input
								type="hidden"
								name="notes[redirect_url]"
								value={window.location.href}
							/>
							<input
								type="hidden"
								name="callback_url"
								value={
									process.env.REACT_APP_BASE_URL + '/razorpay/payment-verify'
								}
							/>
							<input
								type="hidden"
								name="cancel_url"
								value={`${window.location.href}`.replace(
									'processing',
									'failed'
								)}
							/>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

ProcessingForm.propTypes = {
	single_subscription: PropTypes.any,
	subscription: PropTypes.any,
	userData: PropTypes.any,
	premiumPlan: PropTypes.object,
	createSubscriptionOrder: PropTypes.func,
	reqPayDataHash: PropTypes.func,
	getRapOrderForPG: PropTypes.func,
	getSubscriptionDetails: PropTypes.func,
};

const mapStateToProps = ({ Subscription, signin }) => {
	return {
		userData: signin?.userData,
		single_subscription: Subscription.single_subscription,
		subscription: Subscription.subscription,
		premiumPlan: Subscription?.premiumPlan,
	};
};

export default connect(mapStateToProps, {
	createSubscriptionOrder,
	getRapOrderForPG,
	getSubscriptionDetails,
})(ProcessingForm);

