import { getAllCatalogProducts } from '../../../../../utils/APIs/endpoints';
import { GET_ALL_SERVICE, RESET_ALL_SERVICE_DATA } from '../types';

export const setProfessionalRole = (role) =>
  function (dispatch) {
    dispatch({ type: GET_ALL_SERVICE, payload: role });
  };

export const getAllService = (id) =>
  async function (dispatch) {
    try {
      const response = await getAllCatalogProducts(id);
      if (response.status === 200) {
        // console.log('Service ' + response.data);

        // dispatch data into state
        dispatch({ type: GET_ALL_SERVICE, payload: response.data });
      }
    } catch (error) {
      console.log('[upload-services-form-action] getAllService Error', error);
      dispatch({ type: GET_ALL_SERVICE, payload: [] });
    }
  };

export const resetFLUploadServiceData = () =>
  function (dispatch) {
    dispatch({ type: RESET_ALL_SERVICE_DATA });
  };
