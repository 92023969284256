export const SET_ALL_JOBS = 'SET_ALL_JOBS';
export const SET_JOBS_DETAILS = 'SET_JOBS_DETAILS';
export const SEARCH_JOBS = 'SEARCH_JOBS';
export const SET_JOBS_ALL_CITY = 'SET_JOBS_ALL_CITY';
export const SET_FILTER_JCITY = 'SET_FILTER_JCITY';
export const SET_FILTER_JEXP = 'SET_FILTER_JEXP';
export const SET_JOBS_ALL_CATEGORY = 'SET_JOBS_ALL_CATEGORY';
export const SET_FILTER_JCATEGORY = 'SET_FILTER_JCATEGORY';
export const SET_JOB_FILTER_CITY = 'SET_JOB_FILTER_CITY';
export const SET_FILTER_JSORT = 'SET_FILTER_JSORT';
export const RESET_JOB_CITY_FILTER = 'RESET_JOB_CITY_FILTER';
export const RESET_ALL_JOBS_DATA = 'RESET_ALL_JOBS_DATA';
export const SAVE_JOB_SERCH_TEXT = 'SAVE_JOB_SERCH_TEXT';
export const SET_JOBS_ALL_EXP = "SET_JOBS_ALL_EXP";
