import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import PropTypes from 'prop-types';




const ConnectWithModal = ({ open, onClose, company_name, handleWhatsappCall, handleCall }) => {
    


    // const handleSave = () => {
    //     // Handle save logic here
    //     onClose();
    // };

    const handleCancel = () => {
        onClose(true); // Close the modal
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            {/* <DialogTitle className="custom-border-header"></DialogTitle> */}
            <DialogTitle className="custom-border-header p-0" sx={{ padding: '20px 24px 0px 24px !important' }}>
                <Box className="position-relative d-flex justify-content-between">
                    <Typography className="fs-22 fw-bold font-skmodernist" >
                        Connect with {company_name}
                    </Typography>
                    <Box >
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer' }} />
                    </Box>

                </Box>
            </DialogTitle>
            <DialogContent>

                <Typography className="fs-15 fw-normal font-gotham text-gray mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin felis a eget eget urna.{' '}
                </Typography>

            </DialogContent>
            <DialogActions className="modal-footer">
                <Button
                    type="submit"

                    variant="contained"
                    className="mb-3 py-3 send-req-btn"
                    onClick={handleWhatsappCall}
                    rel="noopener noreferrer"
                >
                    Send Requirement on WhatsApp
                </Button>

                <Button
                    type="submit"

                    variant="contained"
                    onClick={handleCall}
                    className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                >
                    Call Now
                </Button>


            </DialogActions>
        </Dialog>
    );
};

ConnectWithModal.propTypes = {
    open: PropTypes.bool.isRequired, // 'open' prop should be a boolean and is required
    onClose: PropTypes.func.isRequired, // 'onClose' prop should be a function and is required
    company_name: PropTypes.string,
    handleCall: PropTypes.func,
    handleWhatsappCall: PropTypes.func,
};

export default ConnectWithModal;