import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CompanyDetailsForm from '../CompanyDetails/CompanyDetails';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import CustomStepper from '../../components/CustomStepper/CustomStepper';
import Divider from '@mui/material/Divider';
import OfferedServicesForm from '../OfferedServices/OfferedServices';
import ProfileDetailsForm from '../ProfileDetails/ProfileDetails';
import { connect } from 'react-redux';
import {
  setActiveStep,
  setStepperData,
} from './redux/actions/b2b-onboarding-steps-action';
import { loading } from '../../store/actions/rootActions';


const theme = createTheme();

const OnboardingSteps = ({
  activeStep,
  setActiveStep,
  stepperData,
  setStepperData,
  loading,
}) => {
  const onNextHandler = async () => {
    if (activeStep < stepperData.length - 1) {
      let steps = [...stepperData];
      if (activeStep + 1 <= stepperData.length - 1) {
        // changing current step flags
        steps[activeStep].completed = true;
        steps[activeStep].active = false;
        steps[activeStep].error = false;
        steps[activeStep].icon = 1;

        // makeing next step to be current and active step
        steps[activeStep + 1].completed = false;
        steps[activeStep + 1].active = true;
        steps[activeStep + 1].error = false;
        steps[activeStep + 1].icon = 2;
        setActiveStep(activeStep + 1);
      }
      setStepperData(steps);
      loading(false);
    }
  };

  const onBackHandler = async () => {
    if (activeStep > 0) {
      let steps = [...stepperData];
      steps[activeStep].completed = false;
      steps[activeStep].active = false;
      steps[activeStep].error = false;
      steps[activeStep].icon = 3;

      // makeing prev step to be current and active step
      steps[activeStep - 1].completed = false;
      steps[activeStep - 1].active = true;
      steps[activeStep - 1].error = false;
      steps[activeStep - 1].icon = 2;
      setActiveStep(activeStep - 1);
      setStepperData(steps);
      loading(false);
    }
  };
  useEffect(() => {
    if (activeStep === 1) {
      let steps = [...stepperData];
      steps[activeStep - 1].completed = true;
      steps[activeStep - 1].active = false;
      steps[activeStep - 1].error = false;
      steps[activeStep - 1].icon = 1;

      steps[activeStep].completed = false;
      steps[activeStep].active = true;
      steps[activeStep].error = false;
      steps[activeStep].icon = 2;

      // makeing next step to be current and active step
      steps[activeStep + 1].completed = false;
      steps[activeStep + 1].active = false;
      steps[activeStep + 1].error = false;
      steps[activeStep + 1].icon = 3;
      setStepperData(steps);
    }
    if (activeStep === 2) {
      let steps = [...stepperData];
      steps[activeStep - 2].completed = true;
      steps[activeStep - 2].active = false;
      steps[activeStep - 2].error = false;
      steps[activeStep - 2].icon = 1;

      steps[activeStep - 1].completed = true;
      steps[activeStep - 1].active = false;
      steps[activeStep - 1].error = false;
      steps[activeStep - 1].icon = 1;

      // makeing next step to be current and active step
      steps[activeStep].completed = false;
      steps[activeStep].active = true;
      steps[activeStep].error = false;
      steps[activeStep].icon = 2;

      setStepperData(steps);
    }

  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2">
              <ElynkerLogo width={'100px'} height={'30px'} />
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            ></Divider>
            <CustomStepper activeStep={activeStep} stepperData={stepperData} />
            {activeStep === 0 && (
              <CompanyDetailsForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
            {activeStep === 1 && (
              <ProfileDetailsForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}
            {activeStep === 2 && (
              <OfferedServicesForm
                onNextHandler={onNextHandler}
                onBackHandler={onBackHandler}
              />
            )}

          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

OnboardingSteps.propTypes = {
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  stepperData: PropTypes.array,
  setStepperData: PropTypes.func,
  loading: PropTypes.func,

};

const mapStateToProps = (state) => {
  const { activeStep, stepperData } = state.b2bOnboardStep;

  return { activeStep, stepperData };
};

export default connect(mapStateToProps, { setActiveStep, setStepperData, loading })(
  OnboardingSteps
);
