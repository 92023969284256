import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ColorToggleButton({ data, callback, selIndex }) {
  const [alignment, setAlignment] = React.useState(data[selIndex - 2].value);

  const handleChange = (event, newAlignment) => {
    if (!newAlignment) return;
    setAlignment(newAlignment);
    callback(newAlignment);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#416AD4;',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ToggleButtonGroup
        // color='primary'
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {data.map((item, index) => {
          return (
            <ToggleButton
              className="custom-toggle font-skmodernist"
              key={index}
              value={item.value}
            >
              {item.text}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </ThemeProvider>
  );
}

ColorToggleButton.defaultProps = {
  data: [],
};

ColorToggleButton.propTypes = {
  data: PropTypes.array,
  callback: PropTypes.func,
  selIndex: PropTypes.number,
};
