let totalForm = 3; //update after adding new form
let totalFLForm = 5;
export function calculateFilledPercentage(data, userType = 'B2B') {
  let filledBoxes = 0;
  let totalBoxes = 0;

  // Iterate over the object properties
  for (const key in data) {
    // Check if the property is a direct property of the object
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      totalBoxes++; // Increment total boxes count

      // Check if the value is not null and not an empty string
      if (data[key] !== null && data[key] !== '') {
        // Increment filled boxes count
        // Additional check for arrays to handle empty arrays
        if (Array.isArray(data[key]) && data[key].length === 0) {
          continue; // Skip empty arrays
        }
        filledBoxes++;
      }
    }
  }

  // Calculate the percentage
  const noOfForms = userType == 'B2B' ? totalForm : totalFLForm;
  const percentageFilled = (filledBoxes / totalBoxes) * (100 / noOfForms) || 0;

  return percentageFilled;
}
export const getCurrentProfileProgress = (
  regDetails,
  allProducts,
  businessDocuments,
  socialLinks
) => {
  let totalPercentage =
    updateCompanyCompletionPercentage(regDetails, businessDocuments) +
    updateOfferedServicesPercentage(allProducts, 'B2B') +
    updateProfileCompletionPercentage(regDetails, socialLinks);
  return totalPercentage;
};

//create seprate method
const updateCompanyCompletionPercentage = (regDetails, businessDocuments) => {
  const { company_name, about_company } = regDetails;
  function getDocumentNameAndReason(data) {
    const resultArray = [];
    for (const obj of data) {
      if (obj.no_document_reason === '' || obj.no_document_reason === null) {
        resultArray.push({
          document_name: obj.document_name,
          no_document_reason: null,
        });
      }
      // else if (obj.no_document_reason) {
      //     resultArray.push(null);
      // } else {
      //     resultArray.push(null);
      // }
    }
    return resultArray;
  }
  let companyDocument = businessDocuments
    ? getDocumentNameAndReason(businessDocuments)
    : [];
  const filledBoxDetails = { company_name, about_company, ...companyDocument };
  const companyDetailsPercentage = calculateFilledPercentage(filledBoxDetails);
  return companyDetailsPercentage;
};
const updateOfferedServicesPercentage = (allProducts, userType) => {
  let offeredServicesFilledObj = {
    allProducts: allProducts?.length > 0 ? 'yes' : '',
  };
  let calculatofferedServicesFilledObjPercentage = calculateFilledPercentage(
    offeredServicesFilledObj,
    userType
  );

  return calculatofferedServicesFilledObjPercentage;
};
const updateProfileCompletionPercentage = (regDetails, socialLinks) => {
  const { cover_image, image_path, address1, address2, city } = regDetails;

  let newSocials =
    socialLinks.length > 0 ? { socialLinks: 'true' } : { socialLinks: '' };
  const filledBoxDetails = {
    image_path,
    cover_image,
    ...newSocials,
    address1,
    address2,
    city,
    // state,
  };
  const submitProfilePercentage = calculateFilledPercentage(filledBoxDetails);
  return submitProfilePercentage;
};

export const getFLCurrentProfileProgress = (
  registration,
  uploadResume,
  uploadProject,
  allService,
  certificates
) => {
  let totalPercentage =
    uploadResumePercentage(uploadResume) +
    uploadProfRolePercentage(registration) +
    uploadAddBioPercentage(registration, uploadProject) +
    // uploadHourlyRatePercentage(registration) +
    updateOfferedServicesPercentage(allService, 'Freelancer') +
    updateFLProfileCompletionPercentage(registration, certificates);

  return totalPercentage;
};

const uploadResumePercentage = (uploadResume) => {
  let uploadResumeFilledObj = {
    uploadResume: uploadResume?.length > 0 ? 'yes' : '',
  };
  let calculateUploadResumeFilledObjPercentage = calculateFilledPercentage(
    uploadResumeFilledObj,
    'Freelancer'
  );

  return calculateUploadResumeFilledObjPercentage;
};
const uploadProfRolePercentage = (regDetails) => {
  const { freelancer_role } = regDetails;
  const filledBoxDetails = { freelancer_role };
  const submitProfilePercentage = calculateFilledPercentage(
    filledBoxDetails,
    'Freelancer'
  );
  return submitProfilePercentage;
};
const uploadAddBioPercentage = (regDetails, uploadProject) => {
  const { freelancer_bio } = regDetails;
  const filledBoxDetails = {
    freelancer_bio,
    uploadProject: uploadProject?.length > 0 ? 'yes' : '',
  };
  const submitFlBioPercentage = calculateFilledPercentage(
    filledBoxDetails,
    'Freelancer'
  );

  return submitFlBioPercentage;
};

const updateFLProfileCompletionPercentage = (regDetails, certificates) => {
  const { image_path, dob, education, language, address1, address2 } =
    regDetails;

  const filledBoxDetails = {
    image_path,
    dob,
    education,
    language,
    address1,
    address2,
    certificates: certificates?.length > 0 ? 'yes' : '',
  };
  const submitProfPercentage = calculateFilledPercentage(
    filledBoxDetails,
    'Freelancer'
  );
  return submitProfPercentage;
};
