import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Container, Grid, Typography, Divider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-icon-black.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled } from '@mui/system';
import { filterAllCategory } from '../../utils/helper-funtions';
import { getProductCategories } from '../OfferedServices/redux/actions/offered-services-form-action';
import { setSelectedVCategory } from './redux/actions/fl-business-action';
import { func, number, object, oneOfType, bool, array } from 'prop-types';

import e_business_bg from '../../assets/elynker_app_icon.svg';
import elynker_default_image from '../../assets/elynker_default_image.jpg';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import ImageProfile from '../../components/CustomImageView/ImgProfile';
import { CATEGORY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { useHistory } from 'react-router-dom';
import { loading } from '../../store/actions/rootActions';
import { connect } from 'react-redux';
import { FREELANCER } from '../../utils/constants';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useMediaQuery } from 'react-responsive';

const theme = createTheme();

const FLFreelanceForm = ({
	getProductCategories,
	flancerLvl1Categories,
	setSelectedVCategory,
}) => {
	let history = useHistory();
	const [categoriesTree, setCategoriesTree] = useState([]);

	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOnly = useMediaQuery({
		query: '(min-width: 600px) and (max-width: 1023px)',
	});
	const isMobileOnly = useMediaQuery({ query: '(max-width: 600px)' });
	// const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

	function handleClickHome(event) {
		event.preventDefault();
		history.replace('/homepage');
	}

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			className="fs-14 text-gray font-gotham"
			href="/"
			onClick={handleClickHome}
		>
			Elynker
		</Link>,
		// <Link
		//     underline="hover"
		//     key="2"
		//     className="fs-14 text-gray font-gotham"
		// >
		//     Freelance
		// </Link>,
		<Link
			underline="none"
			key="3"
			className="fs-14 text-breadcrumbs-active font-gotham"
		>
			All Categories
		</Link>,
	];

	const StyledImageContainer = styled('div')({
		border: '1px solid #E3E3E3',
		padding: '10px',
		textAlign: 'left',
		borderRadius: '8px',
		height: '100%',
	});

	useEffect(() => {
		getProductCategories(FREELANCER);
	}, []);

	useEffect(() => {
		if (flancerLvl1Categories?.length > 0) {
			const categoryList = filterAllCategory(flancerLvl1Categories);
			setCategoriesTree(categoryList);
			console.log('LT : ', categoryList);
		}
	}, [flancerLvl1Categories]);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xl">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: '67vh' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2 d-flex align-items-center">
							<ArrowBackIosIcon
								onClick={() => {
									history.goBack();
								}}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									alignItems: 'center',
								}}
							>
								<ElynkerLogoComponent>
									<ElynkerLogo
										className="ms-3"
										width={'100px'}
										height={'30px'}
									/>
								</ElynkerLogoComponent>
								<div>
									{/* <IconButton
										className="p-0"
										size="medium"
										aria-label="user icon"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="inherit"
										onClick={() => {
											//   setShowSearch((val) => !val);
											history.push('/search');
										}}
									>
										<SearchIcon />
									</IconButton> */}
								</div>
							</div>
						</div>
						<Divider
							component="div"
							className="main-divider mt-2 mb-3"
							role="presentation"
						/>

						<form style={{ marginTop: '0px' }}>
							{isDesktopOrLaptop && (
								<>
									<Card className="freelance-testimonial-web-card mb-3">
										<CardContent className="freelance-testimonial-web-content">
											<div className="freelance-testimonial-web-content-tint" />
											<div style={{ zIndex: 2, position: 'relative' }}>
												<Typography
													variant="h5"
													className="text-white mb-2 fw-bold font-skmodernist freelance-testimonial-web-text "
													component="div"
												>
													Gain insights and advice from
													industry professionals who
													understand your challenges!

												</Typography>
											</div>
										</CardContent>
									</Card>

									{/* <div className="d-flex justify-content-between align-items-center mt-5 mb-4"> */}
									{/* <div className=" b2b-business">
											<Stack
												direction="row"
												spacing={1.5}
												useFlexGap
												flexWrap="wrap"
											>
												<Chip label="25 Mn+ Business" />
												<Chip label="30 Cr Business Done" />
												<Chip label="4.5 Star" />
											</Stack>
										</div> */}
									<Breadcrumbs
										className="mb-3"
										separator={
											<NavigateNextIcon
												fontSize="small"
												className="text-gray"
											/>
										}
										aria-label="breadcrumb"
									>
										{breadcrumbs}
									</Breadcrumbs>

									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-20 text-black mb-3 fw-bold font-skmodernist"
									>
										Select From Below Services
									</Typography>
									{/* </div> */}
								</>
							)}

							{isTabletOnly && (
								<>
									<Card className="freelance-testimonial-web-card mb-3">
										<CardContent className="freelance-testimonial-web-content">
											<div className="freelance-testimonial-web-content-tint" />
											<div style={{ zIndex: 2, position: 'relative' }}>
												<Typography
													variant="h5"
													className="text-white mb-2 fw-bold font-skmodernist freelance-testimonial-web-text "
													component="div"
												>
													Gain insights and advice from
													industry professionals who
													understand your challenges!

												</Typography>
											</div>
										</CardContent>
									</Card>

									{/* <div className="d-flex justify-content-between align-items-center mt-5 mb-4"> */}
									{/* <div className=" b2b-business">
											<Stack
												direction="row"
												spacing={1.5}
												useFlexGap
												flexWrap="wrap"
											>
												<Chip label="25 Mn+ Business" />
												<Chip label="30 Cr Business Done" />
												<Chip label="4.5 Star" />
											</Stack>
										</div> */}
									<Breadcrumbs
										className="mb-3"
										separator={
											<NavigateNextIcon
												fontSize="small"
												className="text-gray"
											/>
										}
										aria-label="breadcrumb"
									>
										{breadcrumbs}
									</Breadcrumbs>

									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-20 text-black mb-3 fw-bold font-skmodernist"
									>
										Select From Below Services
									</Typography>
									{/* </div> */}
								</>
							)}

							{isMobileOnly && (
								<>
									<Card className="freelance-testimonial-card mb-3">
										<CardContent className="freelance-testimonial-content">
											<div className="freelance-testimonial-content-tint" />
											<div style={{ zIndex: 2, position: 'relative' }}>
												<Typography
													variant="h5"
													className="fs-16 text-white mb-2 fw-bold font-skmodernist freelance-testimonial-text"
													component="div"
												>
													Gain insights and advice from<br></br>
													industry professionals who<br></br>
													understand your challenges!

												</Typography>
											</div>
										</CardContent>
									</Card>

									{/* <div className="my-4 b2b-business">
                                    <Stack direction="row" spacing={1.5} useFlexGap flexWrap="wrap">
                                        <Chip label="25 Mn+ Business" />
                                        <Chip label="30 Cr Business Done" />
                                        <Chip label="4.5 Star" />
                                    </Stack>
                                </div> */}
									<Breadcrumbs
										className="mb-3"
										separator={
											<NavigateNextIcon
												fontSize="small"
												className="text-gray"
											/>
										}
										aria-label="breadcrumb"
									>
										{breadcrumbs}
									</Breadcrumbs>
									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-20 text-black mb-3 fw-bold font-skmodernist"
									>
										Select From Below Services
									</Typography>
								</>
							)}

							<Grid container spacing={2} className="mb-4">
								{categoriesTree?.map((item) => (
									<Grid item xs={6} md={3} key={item.img}>
										<StyledImageContainer
											onClick={() => {
												setSelectedVCategory(item);
												history.push('/fl-freelance-category');
											}}
										>
											{/* <img
                                                srcSet={`${item.img}`}
                                                src={`${item.img}`}
                                                alt={item.title}
                                                loading="lazy"
                                                style={{ marginBottom: '8px', width: '100%', height: '130px', borderRadius: '8px' }}
                                            /> */}
											<ImageProfile
												path={CATEGORY_LOGO_BASE_URL + item.banner_image}
												placeholder={e_business_bg}
												loadError={elynker_default_image}
												style={{
													marginBottom: '8px',
													width: '100%',
													height: '130px',
													borderRadius: '8px',
													objectFit: 'contain',
												}}
												loading="lazy"
											/>
											<Typography className="fs-14 text-darkgrey text-center">
												{item.title}
											</Typography>
										</StyledImageContainer>
									</Grid>
								))}
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

FLFreelanceForm.propTypes = {
	getProductCategories: func,
	isLoading: bool,
	loading: func,
	userData: oneOfType([object, number]),
	pushMessage: func,
	flancerLvl1Categories: array,
	setSelectedVCategory: func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { isLoading } = state.root;
	const { flancerLvl1Categories } = state.offeredServices;
	return {
		userData,
		isLoading,
		flancerLvl1Categories,
	};
};

export default connect(mapStateToProps, {
	getProductCategories,
	loading,
	pushMessage,
	setSelectedVCategory,
})(FLFreelanceForm);

