import { GET_PRODUCT_LIST, RESET_MY_CATALOG_DATA } from '../types';

const INITIAL_STATE = {
  allProducts: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return { ...state, allProducts: action.payload };
    case RESET_MY_CATALOG_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
