import { Box } from '@mui/material'
import PropTypes from 'prop-types';
import React from 'react'
import { useMediaQuery } from 'react-responsive';

function ProfileCardWrapper({ children }) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    if (isDesktopOrLaptop

    ) {
        return (
            <Box sx={{ border: "1px solid #E2E2E2", borderRadius: "8px", padding: "20px" }}>
                {children}
            </Box>
        )
    } else {
        return (
            <Box>
                {children}
            </Box>
        )
    }

}

ProfileCardWrapper.propTypes = {
    children: PropTypes.node
}

export default ProfileCardWrapper