import * as React from 'react';
// import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { func, object } from 'prop-types';
import { Box, Grid, Link, Typography } from '@mui/material';
import { GAEvents } from '../../utils/helper-funtions';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter'; // For X
function WebFooter({
  handleTerms,
  handlePrivacy,
  openInNewTab,
  handleContactUs,
  userData,
}) {
  return (
    <>
      <Grid
        container
        className="homepage-footer-content mb-4"
        sx={{
          width: '70%',
          margin: '0 auto',
          justifyContent: 'space-evenly',
        }}
      >
        <Box sx={{ marginTop: '4px', marginBottom: '10px' }}>
          <Typography
            variant="body1"
            className="fs-20 text-white font-skmodernist fw-bold mb-4"
          >
            Get In Touch
          </Typography>
          <Link className="support-link" underline="always" color="#FFF">
            {'support@elynker.com'}
          </Link>
          <Typography className="fs-14 font-gotham text-light-white mt-2">
            +91 9820596537
          </Typography>
          <Typography className="fs-14 font-gotham text-light-white">
            {'Remember us with ease -  '}
            <Link
              component="button"
              underline="none"
              variant="body2"
              color="#FFF"
              onClick={() => {
                window.location.href = 'tel:+919820596537';
              }}
            >
              9820 LYNKER
            </Link>
          </Typography>
        </Box>

        <Box className="usefullink-container">
          <Typography
            variant="body1"
            className="fs-20 text-white font-skmodernist fw-bold mb-4"
          >
            Useful Links
          </Typography>

          <Box>
            <Typography
              className="fs-14 font-gotham text-light-white mt-3 mb-1"
              onClick={() => {
                GAEvents(
                  'btn_privacy_policy',
                  'btn_privacy_policy',
                  'clicked',
                  {
                    user_id:
                      userData?.id || sessionStorage.getItem('random_id'),
                    reg_uuid:
                      userData?.registration?.uuid ||
                      sessionStorage.getItem('random_id'),
                  }
                );
                handlePrivacy();
              }}
              sx={{ cursor: 'pointer' }}
            >
              Privacy Policy
            </Typography>
          </Box>
          <Box>
            <Typography
              className="fs-14 font-gotham text-light-white mt-3 mb-1"
              onClick={() => {
                GAEvents('btn_terms_of_use', 'btn_terms_of_use', 'clicked', {
                  user_id: userData?.id || sessionStorage.getItem('random_id'),
                  reg_uuid:
                    userData?.registration?.uuid ||
                    sessionStorage.getItem('random_id'),
                });
                handleTerms();
              }}
              sx={{ cursor: 'pointer' }}
            >
              Terms of Use
            </Typography>
          </Box>
          <Box>
            <Typography
              className="fs-14 font-gotham text-light-white mt-3 mb-1"
              onClick={() => {
                GAEvents('btn_contact_us', 'btn_contact_us', 'clicked', {
                  user_id: userData?.id || sessionStorage.getItem('random_id'),
                  reg_uuid:
                    userData?.registration?.uuid ||
                    sessionStorage.getItem('random_id'),
                });
                handleContactUs();
              }}
              sx={{ cursor: 'pointer' }}
            >
              Contact Us
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '16px', mt: 3 }}>
            <Link
              href="https://www.instagram.com/elynker_growth"
              underline="none"
              color="inherit"
              target="_blank"
            >
              <InstagramIcon sx={{ color: '#FFF', fontSize: 32 }} />
            </Link>
            <Link
              href="https://www.facebook.com/profile.php?id=61560971700927&mibextid=ZbWKwL"
              underline="none"
              color="inherit"
              target="_blank"
            >
              <FacebookIcon sx={{ color: '#FFF', fontSize: 32 }} />
            </Link>
            <Link
              href="https://www.linkedin.com/company/elynker/"
              underline="none"
              color="inherit"
              target="_blank"
            >
              <LinkedInIcon sx={{ color: '#FFF', fontSize: 32 }} />
            </Link>
            <Link
              href="https://x.com/Elynker_Growth"
              underline="none"
              color="inherit"
              target="_blank"
            >
              <TwitterIcon sx={{ color: '#FFF', fontSize: 32 }} />
            </Link>
          </Box>
        </Box>
      </Grid>
      <Box className="d-flex justify-content-around">
        <Typography
          className="fs-12 font-gotham text-light-white mb-4"
          sx={{ marginTop: '30px', cursor: 'pointer' }}
          onClick={() => openInNewTab('https://www.elgraces.com/')}
        >
          © El Graces Aggregators Private Limited, 2024
        </Typography>
      </Box>
    </>
  );
}

WebFooter.propTypes = {
  handleTerms: func,
  handlePrivacy: func,
  openInNewTab: func,
  handleContactUs: func,
  userData: object,
};

export default WebFooter;
