import { loading } from '../../../../store/actions/rootActions';
import { getHomepagewidget } from '../../../../utils/APIs/endpoints';
import { SET_HOMEPAGE_WIDGETS } from '../types';

export const getHomepageWidgetDetails = () => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const response = await getHomepagewidget();
      if (response.status === 200) {
        dispatch({ type: SET_HOMEPAGE_WIDGETS, payload: response?.data?.data });
      }
    } catch (error) {
      console.log('error on homepageWidget', error);
    } finally {
      dispatch(loading(false));
    }
  };
};
