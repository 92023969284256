/*

  INDIA - Component for Business Documents

*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  hasGSTNumber,
  setCINNumber,
  setGSTNumber,
  setGSTReason,
  setPANNumber,
} from './redux/actions/in-bussiness-document-form-action';
import { useMediaQuery } from 'react-responsive';
import { cinRegex, gstRegex, panRegex } from '../../../utils/regex';
// import { REGEX_CIN, REGEX_GST, REGEX_PAN } from '../../../utils/constants';
// import useValidation from '../../../hooks/useValidation';

export const MENU_ITEM = [
  { value: '0', text: 'Select your reason' },
  { value: '1', text: 'I dont remember GST' },
  { value: '2', text: 'I dont have GST' },
  { value: '3', text: 'I will add later' },
  //   { value: 4, text: 'I have GST number' },
];

const INBussinessDocumentForm = ({
  setBusinessDocuments,
  setGSTReason,
  setCINNumber,
  setGSTNumber,
  setPANNumber,
  companyName,
  gstReason,
  cin,
  gst,
  pan,
  businessDocuments,
  hasGSTNumber,
  hasGST,
  validateInput,
  errors,
  // setCompletedFields
}) => {
  // const { errors, validateInput } = useValidation();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  const handleChangeCIN = (event) => {
    const input = event.target.value;
    console.log('===handleChangeCIN', input);
    if (cinRegex.test(input)) {
      validateInput('cin', input);
      setCINNumber(input);
    }
  };

  const handleChangeGSTReason = (event) => {
    setGSTReason(event.target.value);
    if (event.target.value !== '0') {
      validateInput('gst_reason', event.target.value);
    }
    // validateInput("gst_reason",event.target.value)
    // setGSTNumber('');
  };

  const handleChangeGST = (event) => {
    const input = event.target.value;
    console.log('handleChangeGST', input);

    if (gstRegex.test(input)) {
      validateInput('gst', event.target.value);
      setGSTNumber(event.target.value);
    }
  };

  const handleChangePAN = (event) => {
    const input = event.target.value;
    if (panRegex.test(input)) {
      // validateInput('pan', event.target.value);
      setPANNumber(event.target.value);
    }
  };

  // const selectGSTOption = (event) => {
  //   console.log('===selectGSTOption', event.target.value);
  //   hasGSTNumber(event.target.value);
  //   if (event.target.value == '1') {
  //     validateInput('gst', 'gstno');
  //   } else {
  //     validateInput('gst', '');
  //   }
  //   // if (event.target.value == '0') {
  //   //   setGSTNumber('');
  //   // }
  // };
  // const totalBoxes = 3;

  useEffect(() => {
    let businessDoc = [];
    let CINIdx = -1;
    let GSTIdx = -1;
    let PANIdx = -1;
    if (businessDocuments) {
      CINIdx = businessDocuments?.findIndex(
        (val) => val.document_name === 'CIN'
      );
      GSTIdx = businessDocuments?.findIndex(
        (val) => val.document_name === 'GST'
      );
      PANIdx = businessDocuments?.findIndex(
        (val) => val.document_name === 'PAN'
      );
    }

    // if (cin) {
    let cinObj = {};
    if (CINIdx !== -1) {
      let existingCIN = { ...businessDocuments[CINIdx] };
      existingCIN.company_name = companyName;
      existingCIN.document_number = cin;
      cinObj = existingCIN;
    } else {
      cinObj = {
        company_name: companyName,
        document: 'N/A',
        is_active: 1,
        document_name: 'CIN',
        document_number: cin,
        file_location: '',
        file_name: '',
        is_provided: cin ? true : false,
        no_document_reason: '',
      };
    }
    businessDoc.push(cinObj);
    // }

    // if (hasGST === '1' && gst) {
    let gstObj = {};
    if (GSTIdx !== -1) {
      let existingGST = { ...businessDocuments[GSTIdx] };
      existingGST.company_name = companyName;
      existingGST.document_number = gst;
      if (hasGST === '1') {
        existingGST.is_provided = true;
        existingGST.no_document_reason = existingGST.no_document_reason
          ? existingGST.no_document_reason
          : '';
      } else {
        existingGST.is_provided = false;
        existingGST.no_document_reason = gstReason
          ? MENU_ITEM[gstReason].text
          : existingGST.no_document_reason
            ? existingGST.no_document_reason
            : MENU_ITEM[2].text;
      }
      gstObj = existingGST;
    } else {
      gstObj = {
        company_name: companyName,
        document: 'N/A',
        is_active: 1,
        document_name: 'GST',
        document_number: gst,
        file_location: '',
        file_name: '',
        is_provided: hasGST === '1' && gst ? true : false,
        no_document_reason: gstReason
          ? MENU_ITEM[gstReason].text
          : MENU_ITEM[2].text,
      };
    }
    businessDoc.push(gstObj);
    // }

    // if (pan) {
    let panObj = {};
    if (PANIdx !== -1) {
      let existingPAN = { ...businessDocuments[PANIdx] };
      existingPAN.company_name = companyName;
      existingPAN.document_number = pan;
      panObj = existingPAN;
    } else {
      panObj = {
        company_name: companyName,
        document: 'N/A',
        is_active: 1,
        document_name: 'PAN',
        document_number: pan,
        file_location: '',
        file_name: '',
        is_provided: pan ? true : false,
        no_document_reason: '',
      };
    }
    businessDoc.push(panObj);
    // }
    console.log('BUSINESS DOC', businessDoc);
    if (businessDoc.length > 0) {
      setBusinessDocuments(businessDoc);
    } else {
      setBusinessDocuments(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cin,
    gst,
    pan,
    gstReason,
    setBusinessDocuments,
    companyName,
    businessDocuments,
    hasGST,
  ]);

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <Grid container columnSpacing={8} alignItems="start">
            <Grid item md={6} lg={6} className=" mb-3">
              <InputLabel
                htmlFor="inputCIN"
                className="text-start fs-14 text-black font-skmodernist"
              >
                CIN Number
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="cin"
                  type="text"
                  placeholder="Enter CIN"
                  fullWidth
                  inputProps={{
                    maxLength: 21,
                  }}
                  margin="none"
                  value={cin}
                  onChange={handleChangeCIN}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                {errors?.cin}
              </FormHelperText>
            </Grid>

            <Grid item md={6} lg={6} className=" mb-3">
              <InputLabel
                htmlFor="inputPAN"
                className="text-start fs-14 text-black font-skmodernist"
              >
                PAN Number
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="pan"
                  type="text"
                  placeholder="Enter PAN"
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth
                  margin="none"
                  value={pan}
                  onChange={handleChangePAN}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                {errors?.pan}
              </FormHelperText>
            </Grid>
          </Grid>
        </>
      )}
      {isTabletOrMobile && (
        <div className=" mb-3 ">
          <InputLabel
            htmlFor="inputCIN"
            className="text-start fs-14 text-black font-skmodernist"
          >
            CIN Number:
          </InputLabel>
          <OutlinedInput
            id="cin"
            type="text"
            sx={{ my: 1 }}
            placeholder="Enter CIN"
            fullWidth
            inputProps={{
              maxLength: 21,
            }}
            margin="none"
            value={cin}
            onChange={handleChangeCIN}
          />

          <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
            {errors?.cin}
          </FormHelperText>
        </div>
      )}
      <div className="mb-3">
        <InputLabel
          htmlFor="inputCIN"
          className="text-start fs-14 text-black font-skmodernist"
        >
          Do you want to share your GST number?
        </InputLabel>
        <Divider
          component="div"
          className="divider mt-3 mb-1"
          role="presentation"
        ></Divider>
        <RadioGroup
          row
          aria-labelledby="gst-radio"
          name="gst-radio"
          value={hasGST}
          onChange={(e) => {
            if (hasGST !== '0') {
              setGSTNumber('');
              validateInput('gst');
            }
            hasGSTNumber(e.target.value);
          }}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Yes"
            className="me-5"
          />
          <FormControlLabel value="0" control={<Radio />} label="No" />
        </RadioGroup>
        <Divider
          component="div"
          className="divider mb-3 mt-1"
          role="presentation"
        ></Divider>
        {hasGST === '0' && (
          <>
            <FormControl fullWidth sx={{ my: 1 }}>
              <Select
                id="gst_reason"
                value={gstReason}
                onChange={handleChangeGSTReason}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                placeholder="Select your reason"
              >
                {MENU_ITEM.map((menuItem, menuIdx) => (
                  <MenuItem
                    key={menuItem.value}
                    disabled={menuIdx === 0}
                    value={menuItem.value}
                  >
                    {menuItem.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
              {errors?.gst_reason}
            </FormHelperText>
          </>
        )}

        {hasGST === '1' && (
          <>
            <Grid container columnSpacing={8} alignItems="start">
              <Grid item md={6} lg={6} xs={12}>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="gst"
                    type="text"
                    placeholder="Enter GST number"
                    fullWidth
                    margin="none"
                    value={gst}
                    inputProps={{
                      maxLength: 15,
                    }}
                    onChange={handleChangeGST}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors?.gst}
                </FormHelperText>
              </Grid>
            </Grid>
          </>
        )}
      </div>

      {isTabletOrMobile && (
        <div className=" mb-3">
          <InputLabel
            htmlFor="inputPAN"
            className="text-start fs-14 text-black font-skmodernist"
          >
            PAN Number:
          </InputLabel>
          <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <OutlinedInput
              id="pan"
              type="text"
              placeholder="Enter PAN"
              inputProps={{
                maxLength: 10,
              }}
              fullWidth
              margin="none"
              value={pan}
              onChange={handleChangePAN}
            />
          </FormControl>
          <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
            {errors?.pan}
          </FormHelperText>
        </div>
      )}
    </>
  );
};

INBussinessDocumentForm.propTypes = {
  setBusinessDocuments: PropTypes.func,
  // setCompletedFields: PropTypes.func,
  companyName: PropTypes.string,
  gstReason: PropTypes.string,
  cin: PropTypes.string,
  gst: PropTypes.string,
  pan: PropTypes.string,
  setGSTReason: PropTypes.func,
  setCINNumber: PropTypes.func,
  setGSTNumber: PropTypes.func,
  setPANNumber: PropTypes.func,
  businessDocuments: PropTypes.array,
  hasGSTNumber: PropTypes.func,
  hasGST: PropTypes.string,
  validateInput: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  // validateInput
};

const mapStateToProps = (state) => {
  const { companyName } = state.companyDetails;
  const { gstReason, cin, gst, pan, hasGST } = state.INBusinessDoc;
  const { businessDocuments } = state.b2bOnboardStep;

  return {
    companyName,
    gstReason,
    cin,
    gst,
    pan,
    businessDocuments,
    hasGST,
  };
};

export default connect(mapStateToProps, {
  setCINNumber,
  setGSTReason,
  setGSTNumber,
  setPANNumber,
  hasGSTNumber,
})(INBussinessDocumentForm);
