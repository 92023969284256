import {
  SAVE_PRODUCT_IMAGES,
  GET_PRODUCTS_DATA,
  SAVE_PRODUCT_DATA,
  PRODUCT_DESC,
  PRODUCT_DETAIL,
  PRODUCT_BUDGET,
  PORTFOLIO_LINK,
  PRODUCT_SUB_CATEGORY,
  PAGE_ACTION_TYPE,
  SINGLE_PRODUCT,
  RESET_FL_ADD_DATA,
  REMOVE_PROD_IMAGE,
  YEARS_OF_EXPERIENCE,
  SET_DELETE_PROD_IMAGE,
} from '../types';
import {
  deleteSingleProductImage,
  postSingleProduct,
  putSingleProductUpdate,
} from '../../../../../utils/APIs/endpoints';
import { PRODUCT_IMAGE_BASE_URL } from '../../../../../utils/APIs/api';

const saveServiceImage = (productImage) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_PRODUCT_IMAGES,
        payload: productImage,
      });
    } catch (error) {
      console.log('Error saving product_image', error);
    }
  };
};

export const getServicesList = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: GET_PRODUCTS_DATA,
      });
    } catch (error) {
      console.log('Error saving product_image', error);
    }
  };
};

export const saveServiceData = (product) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_PRODUCT_DATA,
        payload: product,
      });
    } catch (error) {
      console.log('product data saving error ', error);
    }
  };
};

export const setServiceDesc = (desc) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_DESC, payload: desc });
  };

export const setServiceDetail = (detail) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_DETAIL, payload: detail });
  };

export const setServiceBudget = (budget) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_BUDGET, payload: budget });
  };

export const setServicePortfolioLink = (pLink) =>
  function (dispatch) {
    dispatch({ type: PORTFOLIO_LINK, payload: pLink });
  };
export const setServiceSubCategory = (subCategory) =>
  function (dispatch) {
    dispatch({ type: PRODUCT_SUB_CATEGORY, payload: subCategory });
  };

export const setServiceYOfExp = (yoExp) =>
  function (dispatch) {
    dispatch({ type: YEARS_OF_EXPERIENCE, payload: yoExp });
  };

export const uploadServiceData = (
  imageData,
  type,
  registrationId,
  title,
  description,
  budget,
  portfolioLink,
  yearOfExp,
  subCategoryId,
  accessToken,
  successCallback,
  errorCallback,
  customHeader
) =>
  async function () {
    if (!imageData) {
      console.log('[add-catalog-action] ABORTING... No Image Selected');

      return;
    }
    try {
      const formData = new FormData();
      Array.from(imageData).forEach((file) => {
        formData.append('images', file);
      });
      // const fileListAsArray = Array.from(imageData)
      // formData.append("images", fileListAsArray);
      formData.append('type', type);
      formData.append('registrationId', registrationId);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('budget', budget);
      formData.append('portfolio_link', portfolioLink);
      formData.append('year_of_exp', yearOfExp);
      formData.append('category_id', subCategoryId);

      const response = await postSingleProduct(
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Product Preogress - ', percentCompleted);
          // dispatch({
          //   type: SET_PRODUCT_UPLOAD_PROGRESS,
          //   payload: percentCompleted,
          // });
        },
        customHeader
      );
      if (response.status === 201) {
        console.log(
          '[offered-services-form-action] uploadCoverImage Success',
          response.data
        );
        successCallback();
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] uploadCoverImage Error',
        error
      );
      errorCallback(error?.response?.data.error);
    }
  };

// Edit
export const editProductData = (
  sku,
  imageData,
  type,
  registrationId,
  title,
  description,
  budget,
  portfolioLink,
  yearOfExp,
  subCategoryId,
  accessToken,
  successCallback,
  errorCallback,
  customHeader
) =>
  async function () {
    // if (!imageData) {
    //   console.log(
    //     '[edit-catalog-action] ABORTING... No Image Selected'
    //   );
    //   return;
    // }
    try {
      const formData = new FormData();
      Array.from(imageData).forEach((file) => {
        formData.append('images', file);
      });
      formData.append('title', title);
      formData.append('category_id', subCategoryId);
      formData.append('description', description);
      formData.append('budget', budget);
      formData.append('portfolio_link', portfolioLink);
      formData.append('year_of_exp', yearOfExp);
      formData.append('type', type);
      formData.append('registrationId', registrationId);

      const response = await putSingleProductUpdate(
        sku,
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Product Preogress - ', percentCompleted);
          // dispatch({
          //   type: SET_PRODUCT_UPLOAD_PROGRESS,
          //   payload: percentCompleted,
          // });
        },
        customHeader
      );
      if (response.status === 200) {
        console.log(
          '[offered-services-form-action] uploadCoverImage Success',
          response.data
        );
        successCallback();
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] uploadCoverImage Error',
        error
      );
      errorCallback(error?.response?.data.error);
    }
  };

export const setPageActionType = (type) =>
  function (dispatch) {
    dispatch({ type: PAGE_ACTION_TYPE, payload: type });
  };

export const setSingleData = (data) =>
  function (dispatch) {
    dispatch({ type: SINGLE_PRODUCT, payload: data });
  };

export const resetFLAddServiceData = () =>
  function (dispatch) {
    dispatch({ type: RESET_FL_ADD_DATA });
  };

export const removeServiceImage = (
  index,
  prodId,
  imgName,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function () {
    try {
      // const filename = imgName?.substring(imgName?.lastIndexOf('/') + 1);
      const filename = imgName?.substring(
        PRODUCT_IMAGE_BASE_URL?.length,
        imgName?.length
      );
      console.log('Filename images :', filename);
      const data = { data: { image_name: filename } };
      const response = await deleteSingleProductImage(prodId, data);
      if (response.status === 200) {
        console.log(
          '[add-catalog-action-action] removeServiceImage Success',
          response.data
        );
        // dispatch({ type: REMOVE_PROD_IMAGE, payload: index });
        successCallback();
      } else {
        console.log(
          '[add-catalog-action-action] removeServiceImage Error',
          response.data
        );
        errroCallback();
      }
    } catch (err) {
      console.log('[add-catalog-action-action] removeServiceImage Error', err);
      errroCallback();
    }
  };

export const removeAddServiceImage = (index) =>
  function (dispatch) {
    dispatch({ type: REMOVE_PROD_IMAGE, payload: index });
  };

export const setDeleteProdImages = (imgArr) =>
  function (dispatch) {
    dispatch({ type: SET_DELETE_PROD_IMAGE, payload: imgArr });
  };

export default saveServiceImage;
