import {
  SAVE_FL_BUSINESS_DOCUMENTS,
  SET_FL_ACTIVE_STEP,
  SET_FL_STEPPER_DATA,
  RESET_FL_DATA
} from '../types';
import { SAVE_SIGNIN_DATA, UPDATE_USER_DETAILS } from '../../../../Otp/redux/types';
import {
  postBusinessDocuments,
  putUpdateUser,
} from '../../../../../utils/APIs/endpoints';

export const setActiveStep = (newStep) =>
  function (dispatch) {
    dispatch({ type: SET_FL_ACTIVE_STEP, payload: newStep });
  };

export const saveBusinessDocuments = (businessDoc) =>
  function (dispatch) {
    dispatch({ type: SAVE_FL_BUSINESS_DOCUMENTS, payload: businessDoc });
  };

export const submitBusinessDocuments = (
  id = null,
  accessToken = null,
  businessDocuments = null,
  successCallback = () => { },
  errroCallback = () => { }
) =>
  async function (dispatch) {
    console.log('[b2b-onboarding-steps-action] id', id);
    console.log('[b2b-onboarding-steps-action] documents', businessDocuments);
    if (id !== null && accessToken !== null && businessDocuments !== null) {
      console.log(
        '[b2b-onboarding-steps-action] exec.. submitBusinessDocuments'
      );
      try {
        const response = await postBusinessDocuments(id, businessDocuments);

        console.log(
          '[b2b-onboarding-steps-action] submitBusinessDocuments response ',
          response.data
        );
        if (response.status === 200) {
          await dispatch({
            type: SAVE_FL_BUSINESS_DOCUMENTS,
            payload: businessDocuments,
          });
          successCallback();
        }
      } catch (error) {
        console.log(
          '[b2b-onboarding-steps-action]  submitBusinessDocuments Error',
          JSON.stringify(error, null, 2)
        );
        errroCallback();
      }
    }
  };



export const setStepperData = (stepperData) =>
  function (dispatch) {
    dispatch({ type: SET_FL_STEPPER_DATA, payload: stepperData });
  };

export const updateUserData = (
  olduserData,
  newUserData,
  userId,
  accessToken,
  successCallback = () => { },
  errroCallback = () => { }
) =>
  async function (dispatch) {
    try {
      const response = await putUpdateUser(userId, newUserData);
      if (response.status === 200) {
        let newUsrData = JSON.parse(JSON.stringify(olduserData));
        delete newUserData?.registration_type
        newUsrData.user = {...olduserData.user, ...newUserData}
        newUsrData.registration = response.data?.updatedRegistration;
        console.log("===updateUserData,newUsrData", newUsrData);
        await dispatch({ type: SAVE_SIGNIN_DATA, payload: newUsrData });
        successCallback(newUsrData);
      } else {
        console.log(
          '[fl-onboarding-steps-action] updateUserData ERROR',
          response.data
        );
        errroCallback(response?.data);
      }
    } catch (error) {
      console.log('[fl-onboarding-steps-action] updateUserData ERROR', error);
      errroCallback(error);
    }
  };

export const resetFLStepData = () =>
  function (dispatch) {
    dispatch({ type: RESET_FL_DATA });
  };

export const updateUserDetails = (detail) => dispatch => {
  dispatch({ type: UPDATE_USER_DETAILS, payload: detail })
}
