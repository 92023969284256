import {
  SET_SUBDOMAIN,
  SET_MANAGE_WEBSITE_DATA,
  DELETE_TESTIMONIAL_WEBSITE_DATA,
  EDIT_TESTIMONIAL_WEBSITE_DATA,
  SET_TESTIMONIAL_WEBSITE_DATA,
  SET_PRODUCT_DATA,
  MARK_SELECTED_PRODUCT_DATA,
  SET_SUB_DOMAINS,
  SET_ALL_TESTIMONIAL_WEBSITE_DATA,
  SET_DELETED_TESTIMONIAL_WEBSITE_DATA,
  RESET_TESTIMONIAL_WEBSITE_DATA,
  RESET_MANAGED_WEBSITE_DATA,
} from '../type';

const INITIAL_STATE = {
  searchSubDomain: null,
  manageWebsiteData: {},
  testimonialWebsiteData: [],
  all_testimonialWebsiteData: [],
  productData: [],
  markedProductData: '',
  subDomains: [],
  deleted_testimonialWebsiteData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SUBDOMAIN:
      return { ...state, searchSubDomain: action.payload };
    case SET_MANAGE_WEBSITE_DATA:
      return {
        ...state,
        manageWebsiteData: { ...state.manageWebsiteData, ...action.payload },
      };
    case EDIT_TESTIMONIAL_WEBSITE_DATA:
      return {
        ...state,
        testimonialWebsiteData: [
          ...state.testimonialWebsiteData.filter(
            (r) => r.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case SET_TESTIMONIAL_WEBSITE_DATA:
      return {
        ...state,
        testimonialWebsiteData: {
          ...state.testimonialWebsiteData,
          ...action.payload,
        },
      };
    case SET_ALL_TESTIMONIAL_WEBSITE_DATA:
      return {
        ...state,
        all_testimonialWebsiteData: action.payload,
      };
    case SET_DELETED_TESTIMONIAL_WEBSITE_DATA:
      return {
        ...state,
        deleted_testimonialWebsiteData: [
          ...state.deleted_testimonialWebsiteData,
          action.payload,
        ],
      };
    case DELETE_TESTIMONIAL_WEBSITE_DATA:
      return {
        ...state,
        testimonialWebsiteData: state.testimonialWebsiteData.filter(
          (r) => r.id !== action.payload.id
        ),
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload,
      };
    case MARK_SELECTED_PRODUCT_DATA:
      return {
        ...state,
        markedProductData: action.payload,
      };
    case SET_SUB_DOMAINS:
      return {
        ...state,
        subDomains: action.payload,
      };
    case RESET_TESTIMONIAL_WEBSITE_DATA:
      return {
        ...state,
        testimonialWebsiteData: {},
      };
    case RESET_MANAGED_WEBSITE_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
