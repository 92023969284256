import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
// import { ReactComponent as GraphicImg } from '../../assets/graphic-designer-img.svg';
// import { ReactComponent as CustomerServiceImg } from '../../assets/customer-service-img.svg';
import { ReactComponent as LocationIcon } from '../../assets/location-pin-icon.svg';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getRequirements } from './redux/actions/active-requirement-action';
import { connect } from 'react-redux';
import { array, bool, func, object } from 'prop-types';
import { DeleteConfirmationPopup } from '../../components/ActionPopup';
import { loading } from '../../store/actions/rootActions';
import {
  putBusinessRequirement,
  setPageActionType,
  setSingleData,
} from '../B2BRequirement/B2BSubmitRequirement/redux/actions/b2b-submit-requirement-action';
import { ACTIVEREQUIREMENTS_TITLE, REQUIREMENT_GET_PARAMS } from '../../utils/constants';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { GAEvents } from '../../utils/helper-funtions';

// import { PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';
const theme = createTheme();
const categoryText = 'Active Requirements';

const ActiveRequirementsForm = ({
  userData,
  getRequirements,
  requirements,
  // deleteRequirement,
  setPageActionType,
  setSingleData,
  putBusinessRequirement,
  loading,
  isLoading,
  pushMessage,
}) => {
  const history = useHistory();

  useEffect(() => {
    GAEvents('Active_Requirements_Load', 'Active_Requirements', 'load', {
      page_title: 'Active_Requirements',
      page_location: window.location.href,
      page_path: window.location.pathname,
      userId: userData?.id || sessionStorage.getItem('random_id')
    });
    let params = REQUIREMENT_GET_PARAMS + userData?.registration?.id;
    loading(true);
    getRequirements(params);
    loading(false);
  }, []);

  const [openPopup, setOpenPopup] = React.useState(false);
  const [rId, setRId] = React.useState();
  const handleDelete = async () => {
    //  console.log('Item deleted');
    try {
      loading(true);
      let data = { status: 'deleted' };
      await putBusinessRequirement(rId, data);
      pushMessage('Requirement Deleted Successfully');
      let params = REQUIREMENT_GET_PARAMS + userData?.registration?.id;
      await getRequirements(params);
      loading(false);
      setOpenPopup(false);
    } catch (err) {
      // console.log(err);
      loading(false);
      setOpenPopup(false);
    }
  };

  async function deleteRequirementById(id) {
    setRId(id);
    setOpenPopup(true);
  }

  function editProduct(data) {
    if (typeof data?.subCategoryId === 'string') {
      data.category_id = Number(data?.category_id);
    }
    setPageActionType('Edit');
    setSingleData(data);
    history.push('/b2b-requirement-product-category');
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <DeleteConfirmationPopup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          onConfirm={handleDelete}
          message={'Are you sure you want to delete the requirement?'}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => history.replace('/profile')} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>
            <Card className="active-requirements-card mb-3">
              <CardContent className="active-requirements-content">
                <Typography
                  variant="h5"
                  className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                  component="div"
                >
                  Add a new Requirement
                </Typography>
                <Typography
                  variant="body2"
                  className="fs-14 text-white mb-3 font-gotham"
                >
                  Easily add your requirements and get discovered by
                  freelancers!
                </Typography>
                <Button
                  variant="contained"
                  className="add-product-btn"
                  onClick={() => {
                    GAEvents(ACTIVEREQUIREMENTS_TITLE, 'Active_Requirements', 'clicked', {
                      title: 'Add a Requirement Button',
                      userId: userData?.id || sessionStorage.getItem('random_id')
                    });
                    setPageActionType('Post');
                    history.push('/b2b-requirement-product-category');
                  }}
                >
                  Add a Requirement
                </Button>
              </CardContent>
            </Card>
            <List>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={1.5}
                alignItems="stretch"
              >
                {requirements &&
                  requirements.map((item, index) => {
                    return (
                      <Grid
                        item
                        md={6}
                        lg={6}
                        xs={12}
                        key={item.id}
                        sx={{ display: 'grid !important' }}
                      >
                        <ListItem
                          className="active-req-list-item active-req"
                          key={index}
                          onClick={() => {
                            GAEvents('Active_Requirements', 'Edit', 'click', {
                              title: item?.product_service_name,
                              userId: userData?.id || sessionStorage.getItem('random_id')
                            });
                            editProduct(item);
                          }}
                        >
                          <Card className="card d-flex align-items-stretch">
                            {/* <CardMedia
                                        className="media">
                                        <GraphicImg className="media-img" />
                                    </CardMedia> */}
                            <CardContent className="content w-100">
                              <div className="d-flex justify-content-between align-items-start w-100">
                                <Typography
                                  variant="h5"
                                  className="fs-16 text-black mb-2 fw-bold font-skmodernist"
                                  component="div"
                                  style={{ width: '80%' }}
                                >
                                  {item?.product_service_name}
                                </Typography>
                                {(item?.status === 'pending' ||
                                  item?.status === 'hold') && (
                                    <div className="actions mt-0">
                                      <IconButton
                                        className="cat-edit-icon p-0"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          GAEvents(ACTIVEREQUIREMENTS_TITLE, 'Edit', 'click', {
                                            page_title: 'Active Requirements',
                                            page_location: window.location.href,
                                            page_path: window.location.pathname,
                                            title: item?.product_service_name,
                                            userId: userData?.id || sessionStorage.getItem('random_id')
                                          });
                                          editProduct(item);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        className="cat-delete-icon p-0"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          GAEvents(ACTIVEREQUIREMENTS_TITLE, 'Delete', 'click', {
                                            page_title: 'Active Requirements',
                                            page_location: window.location.href,
                                            page_path: window.location.pathname,
                                            title: item?.product_service_name,
                                            userId: userData?.id || sessionStorage.getItem('random_id')
                                          });
                                          deleteRequirementById(item?.id);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  )}
                              </div>
                              <Typography
                                variant="body2"
                                className="fs-13 text-grey mb-3 font-gotham"
                              >
                                {item?.description}
                              </Typography>
                              <Typography className="fs-14 font-gotham text-darkgrey">
                                Type:{' '}
                                <span>
                                  {item?.product_type === 1
                                    ? 'Product'
                                    : 'Service'}
                                </span>
                              </Typography>

                              {/* <Typography
                                variant="body2"
                                className="fs-13 text-gray2 fw-normal mb-1 font-skmodernist"
                              >
                                Full-Time
                              </Typography> */}

                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <LocationIcon />
                                  <Typography
                                    variant="h5"
                                    className="fs-13 text-darkgrey mb-0 fw-normal font-skmodernist"
                                  >
                                    {item?.location}
                                  </Typography>
                                </div>
                                {/* <Typography className={row.status.toLowerCase() === 'pending' ? 'text-status-inactive' : (row.status.toLowerCase() === 'closed' ? 'text-status-contacted' : 'text-status-pending')}>
                                                {row.status}
                                            </Typography> */}

                                {/* <Typography
                                                variant="h5"
                                                className="fs-13 text-hold fw-bold mb-0 font-skmodernist"
                                            >
                                                Hold
                                            </Typography> */}

                                {/* <Typography
                                                variant="h5"
                                                className="fs-13 text-pending fw-bold mb-0 font-skmodernist"
                                            >
                                                Pending
                                            </Typography> */}

                                <Typography
                                  variant="h5"
                                  // className="fs-13 text-fulfilled fw-bold mb-0 font-skmodernist"
                                  className={
                                    item?.status?.toLowerCase() === 'pending'
                                      ? 'fs-13 text-pending fw-bold mb-0 font-skmodernist'
                                      : item?.status.toLowerCase() ===
                                        'fulfilled'
                                        ? 'fs-13 text-fulfilled fw-bold mb-0 font-skmodernist'
                                        : item?.status.toLowerCase() === 'hold'
                                          ? 'fs-13 text-hold fw-bold mb-0 font-skmodernist'
                                          : 'fs-13 text-cancelled fw-bold mb-0 font-skmodernist'
                                  }
                                >
                                  {item?.status}
                                </Typography>
                              </div>
                            </CardContent>
                          </Card>
                        </ListItem>
                      </Grid>
                    );
                  })}
                {/* <ListItem className="active-req-list-item">
                                <Card className="card d-flex">
                                    <CardMedia
                                        className="media">
                                        <CustomerServiceImg className="media-img" />
                                    </CardMedia>
                                    <CardContent className="content">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <Typography
                                                variant="h5"
                                                className="fs-16 text-black mb-2 fw-bold font-skmodernist"
                                                component="div"
                                            >
                                                Customer Service
                                            </Typography>
                                            <div className="actions mt-0">
                                                <IconButton
                                                    className="cat-edit-icon p-0"
                                                // onClick={() => editProduct(item)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    className="cat-delete-icon p-0"
                                                // onClick={() => deleteProduct(item.rId)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Typography
                                            variant="body2"
                                            className="fs-13 text-grey mb-2 font-gotham"
                                        >
                                            Manage inbound calls and <br></br>  emails for...
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            className="fs-13 text-gray2 fw-normal mb-1 font-skmodernist"
                                        >
                                            Full-Time
                                        </Typography>

                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex">
                                                <LocationIcon />
                                                <Typography
                                                    variant="h5"
                                                    className="fs-13 text-darkgrey mb-0 fw-normal font-skmodernist"
                                                >
                                                    Andheri
                                                </Typography>
                                            </div>

                                            <Typography
                                                variant="h5"
                                                className="fs-13 text-secondary fw-bold mb-0 font-skmodernist"
                                            >
                                                42 Applications
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </ListItem> */}
              </Grid>
            </List>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

ActiveRequirementsForm.propTypes = {
  getRequirements: func,
  userData: object,
  requirements: array,
  loading: func,
  // deleteRequirement: func,
  setPageActionType: func,
  setSingleData: func,
  putBusinessRequirement: func,
  isLoading: bool,
  pushMessage: func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { requirements } = state.b2bRequirementList;
  const { isLoading } = state.root;
  return {
    userData,
    requirements,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  getRequirements,
  loading,
  setPageActionType,
  setSingleData,
  putBusinessRequirement,
  pushMessage,
})(ActiveRequirementsForm);
