const countryData = {
  countries: [
    {
      name: 'United States',
      code: '+1',
      alpha2Code: 'US',
      mobileNumberLength: 10,
    },
    {
      name: 'United Kingdom',
      code: '+44',
      alpha2Code: 'GB',
      mobileNumberLength: 11,
    },
    {
      name: 'India',
      code: '+91',
      alpha2Code: 'IN',
      mobileNumberLength: 10,
    },
    {
      name: 'Australia',
      code: '+61',
      alpha2Code: 'AU',
      mobileNumberLength: 10,
    },
    {
      name: 'Canada',
      code: '+1',
      alpha2Code: 'CA',
      mobileNumberLength: 10,
    },
    {
      name: 'Germany',
      code: '+49',
      alpha2Code: 'DE',
      mobileNumberLength: 11,
    },
    {
      name: 'France',
      code: '+33',
      alpha2Code: 'FR',
      mobileNumberLength: 10,
    },
    {
      name: 'Japan',
      code: '+81',
      alpha2Code: 'JP',
      mobileNumberLength: 10,
    },
    {
      name: 'China',
      code: '+86',
      alpha2Code: 'CN',
      mobileNumberLength: 11,
    },
    {
      name: 'Brazil',
      code: '+55',
      alpha2Code: 'BR',
      mobileNumberLength: 11,
    },
    {
      name: 'Russia',
      code: '+7',
      alpha2Code: 'RU',
      mobileNumberLength: 10,
    },
    {
      name: 'South Africa',
      code: '+27',
      alpha2Code: 'ZA',
      mobileNumberLength: 9,
    },
    {
      name: 'Mexico',
      code: '+52',
      alpha2Code: 'MX',
      mobileNumberLength: 10,
    },
    {
      name: 'Italy',
      code: '+39',
      alpha2Code: 'IT',
      mobileNumberLength: 10,
    },
    {
      name: 'Spain',
      code: '+34',
      alpha2Code: 'ES',
      mobileNumberLength: 9,
    },
    {
      name: 'Argentina',
      code: '+54',
      alpha2Code: 'AR',
      mobileNumberLength: 10,
    },
    {
      name: 'South Korea',
      code: '+82',
      alpha2Code: 'KR',
      mobileNumberLength: 10,
    },
    {
      name: 'Sweden',
      code: '+46',
      alpha2Code: 'SE',
      mobileNumberLength: 10,
    },
    {
      name: 'Netherlands',
      code: '+31',
      alpha2Code: 'NL',
      mobileNumberLength: 10,
    },
    {
      name: 'Switzerland',
      code: '+41',
      alpha2Code: 'CH',
      mobileNumberLength: 9,
    },
    // Add more countries as needed
  ],
};

function isValidMobileNumber(countryCode, mobileNumber) {
  const country = countryData.countries.find(
    (c) => c.alpha2Code === countryCode
  );

  if (country) {
    const expectedLength = country.mobileNumberLength;
    const actualLength = mobileNumber.replace(/\D/g, '').length;
    return actualLength === expectedLength;
  } else {
    return false; // Country code not found
  }
}
function maxMobileLength(countryCode) {
  const country = countryData.countries.find(
    (c) => c.alpha2Code === countryCode
  );
  if (country) {
    return country.mobileNumberLength;
  } else {
    return false; // Country code not found
  }
}

function maxMobileLengthObj(countryCode) {
  const country = countryData.countries.find(
    (c) => c.alpha2Code === countryCode
  );
  if (country) {
    return country;
  } else {
    return false; // Country code not found
  }
}

export { isValidMobileNumber, maxMobileLength, maxMobileLengthObj };
