import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import './login.css';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import LoadingButton from '@mui/lab/LoadingButton';
// import SaveIcon from '@mui/icons-material/Save';
// import { FormHelperText } from '@mui/material';

import {
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Backdrop,
  Box,
  // FormControlLabel,
} from '@mui/material';
// import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { MuiTelInput } from 'mui-tel-input';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import saveMobileNo, {
  getTemplateDataPage,
  saveCountryCode,
  saveCountryCodeName,
  sendMobForOTP,
  checkUserActiveStatus,
} from './redux/actions/submit-mobileno-action';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import {
  isValidMobileNumber,
  maxMobileLengthObj,
} from '../../utils/countryCode';
import {
  resetUserData,
  sendSigninData,
} from '../Otp/redux/actions/submit-code-action';
import {
  getSocialLinks,
  getSocialMediaMasterData,
  resetProfileData,
} from '../ProfileDetails/redux/actions/profile-details-form-action';
import { resetOfferedServiceData } from '../OfferedServices/redux/actions/offered-services-form-action';
import { resetComapanyData } from '../CompanyDetails/redux/actions/company-details-form-action';
import {
  getExistingBusinessDocuments,
  resetB2BStepData,
} from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { resetMyCatalogData } from '../MyCatalog/redux/actions/mycatalog-action';
import { resetAddCatalogData } from '../AddCatalog/redux/actions/add-catalog-action';
import { loading } from '../../store/actions/rootActions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { resetMyServiceData } from '../MyServices/redux/actions/mycatalog-action';
import { resetUpdateProfileeData } from '../UpdateProfile/store/actions/profileActions';
import { resetFLAddBioData } from '../FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import { resetFLProfileData } from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { resetHourlyRate } from '../FreeLancer/FL-SetRate/redux/actions/fl-set-rate-action';
import { resetFLResumeData } from '../FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
import { resetFLUploadServiceData } from '../FreeLancer/FL-UploadServiceCatalog/redux/actions/fl-upload-service-action';
import { resetFLUProfRoleData } from '../FreeLancer/FLAddProfessionalRole/redux/actions/fl-add-profession-action';
import { resetFLAddServiceData } from '../FreeLancer/FLAddService/redux/actions/add-service-action';
import { resetCompleteProfileData } from '../CompleteProfile/redux/actions/start-connecting-action';
import { resetFLStepData } from '../FreeLancer/FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import responsive from '../../utils/responsive';
import WebLogin from './WebLogin';
import {
  CLICKED,
  LOAD,
  LOGIN,
  PRIV_POL_SLUG,
  PRIVACY_POLICY,
  SEO_FAV_ICON_URL,
  SEO_LOGIN_DESC,
  SEO_LOGIN_KEYWORDS,
  SEO_LOGIN_TITLE,
  SEO_LOGIN_URL,
  T_AND_C_SLUG,
  TERMS,
} from '../../utils/constants';
import {
  resetTemplateData,
  setTemplateRender,
} from '../Templates/redux/action.js/template-action';
import Checkbox from '@mui/material/Checkbox';
import {
  clearSubscription,
  resetSubscription,
} from '../Subscription/redux/action/susbcription-action';
import { GAEvents, sendDefaultPageEvent } from '../../utils/helper-funtions';
import { Helmet } from 'react-helmet';

const theme = createTheme();

const LoginForm = (props) => {
  const {
    country_code,
    saveCountryCode,
    saveCountryCodeName,
    mobile_number,
    saveMobileNo,
    resetUserData,
    resetComapanyData,
    resetProfileData,
    resetB2BStepData,
    country_code_name,
    loading,
    isLoading,
    pushMessage,
    resetAddCatalogData,
    resetMyCatalogData,
    resetMyServiceData,
    resetOfferedServiceData,
    resetUpdateProfileeData,
    resetFLAddBioData,
    resetFLProfileData,
    resetHourlyRate,
    resetFLResumeData,
    resetFLUploadServiceData,
    resetFLUProfRoleData,
    resetFLAddServiceData,
    resetCompleteProfileData,
    resetFLStepData,
    getTemplateDataPage,
    all_template,
    setTemplateRender,
    sendSigninData,
    getExistingBusinessDocuments,
    getSocialLinks,
    getSocialMediaMasterData,
    enquiry,
    sendMobForOTP,
    clearSubscription,
    resetSubscription,
    resetTemplateData,
    checkUserActiveStatus,
  } = props;
  let history = useHistory();
  const [mobileNumber, setMobileNumber] = useState(mobile_number || '');
  const [mobileNumberLength, setMobileNumberLength] = useState(10);
  const [errorMsg, setErrorMsg] = useState();
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = React.useState(true);
  // const [loader, setLoader] = useState(false);
  const handlePrivacyPolicyCheckbox = (event) => {
    // console.log(
    //   'handlePrivacyPolicyCheckbox',
    //   event.target.checked,
    //   privacyPolicyChecked
    // );

    const isMobileNo = mobileNumber ? `<mobile_no>` : '';
    GAEvents('Login checkbox', 'Login checkbox', 'click', {
      mobile_no: isMobileNo,
      item_name: 'checkbox',
    });
    setPrivacyPolicyChecked(event.target.checked);
  };

  const handleChange = (newValue, info) => {
    setErrorMsg('');
    saveCountryCode(`+${info.countryCallingCode}`);
    if (info.countryCode) {
      saveCountryCodeName(info.countryCode);
    }
    if (info?.nationalNumber?.length > mobileNumberLength) {
      pushMessage(`Only ${mobileNumberLength} digits are allowed.`);
      return;
    }
    // saveMobileNo(info.nationalNumber);
    setMobileNumber(info.nationalNumber);
  };

  /* eslint-disable no-unused-vars */
  const submitOTPData = async () => {
    loading(true);
    let data = {
      mobile_number: mobileNumber,
      country_code: country_code,
    };
    console.log('==submitOTPData');
    await sendSigninData(
      data,
      history,
      async (usrData) => {
        if (usrData) {
          await getExistingBusinessDocuments(
            usrData?.registration?.id,
            country_code_name
          );
          await getSocialLinks(usrData?.registration?.id, usrData?.accessToken);
          await getSocialMediaMasterData();
        }
      },
      enquiry
    );
    loading(false);
  };
  /* eslint-enable no-unused-vars */

  const successCallback = () => {
    loading(false);
    history.push('/otp');
  };

  const errorCallback = (message) => {
    loading(false);
    if (message) {
      pushMessage(message);
    }
    // pushMessage('Network Error while sending OTP.');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (privacyPolicyChecked) {
      loading(true);
      let isValidMobile = isValidMobileNumber(country_code_name, mobileNumber);
      saveMobileNo(mobileNumber);
      // setLoader(true);
      localStorage.removeItem(mobileNumber);
      if (mobile_number !== mobileNumber) {
        console.log('Resetting States');
        resetUserData();
        resetB2BStepData();
        resetComapanyData();
        resetProfileData();
        resetAddCatalogData();
        resetMyCatalogData();
        resetMyServiceData();
        resetOfferedServiceData();
        resetUpdateProfileeData();
        resetFLAddBioData();
        resetFLProfileData();
        resetHourlyRate();
        resetFLResumeData();
        resetFLUploadServiceData();
        resetFLUProfRoleData();
        resetFLAddServiceData();
        resetCompleteProfileData();
        resetFLStepData();
        clearSubscription();
        resetSubscription();
      }
      if (mobileNumber?.length === 0) {
        setErrorMsg('Please enter the mobile number.');
        // alert('Please enter the mobile number.');
        loading(false);
        return;
      }
      if (!isValidMobile) {
        setErrorMsg('Please enter the valid mobile number.');
        // alert('Please enter the valid mobile number.');
        loading(false);
        return;
      }
      // loading(false);
      var data = { phone: mobileNumber };
      sendMobForOTP(data, successCallback, errorCallback);
      const isMobileNo = mobileNumber ? `<mobile_no>` : '';
      GAEvents('Login', 'Login', 'click', {
        mobile_no: isMobileNo,
      });
      // history.push('/otp');
      // submitOTPData();
    } else {
      pushMessage('Please Accept Privacy Policy');
    }
  };

  useEffect(() => {
    const numberLen = maxMobileLengthObj(country_code).mobileNumberLength;
    setMobileNumberLength(numberLen || 10);
  }, [country_code]);

  useEffect(() => {}, []);

  useEffect(() => {
    GAEvents(LOGIN, 'Login', LOAD, {
      page_title: 'Login',
      page_location: window.location.href,
      page_path: window.location.pathname,
      userId: sessionStorage.getItem('random_id'),
      reg_uuid: sessionStorage.getItem('random_id'),
    });
    getTemplateDataPage();
  }, []);

  useEffect(() => {
    sendDefaultPageEvent(
      null,
      'Login Page',
      window.location.href,
      window.location.pathname
    );
    console.log('all_template :', all_template);
    loading(false);
  }, []);

  const handleTerms = () => {
    setTemplateRender(TERMS);
    history.push(`/${T_AND_C_SLUG}`);
    resetTemplateData();
  };

  const handlePrivacy = () => {
    setTemplateRender(PRIVACY_POLICY);
    history.push(`/${PRIV_POL_SLUG}`);
    resetTemplateData();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SEO_LOGIN_TITLE}</title>
        <link rel="canonical" href={SEO_LOGIN_URL} />
        <meta name="description" content={SEO_LOGIN_DESC} />
        <meta property="og:title" content={SEO_LOGIN_TITLE} />
        <meta property="og:description" content={SEO_LOGIN_DESC} />
        <meta property="og:url" content={SEO_LOGIN_URL} />
        <meta property="og:image" content={SEO_FAV_ICON_URL} />
        <meta property="og:type" content="website" />
        <meta name="keywords" content={SEO_LOGIN_KEYWORDS} />
      </Helmet>
      {responsive(
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            {isLoading && (
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: '60vh' }}
            >
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-26 mb-2 me-1 fw-bold text-black font-skmodernist"
                    gutterBottom
                  >
                    Welcome to
                  </Typography>
                  <ElynkerLogoComponent>
                    <ElynkerLogo />
                  </ElynkerLogoComponent>
                </div>

                <Typography
                  variant="body1"
                  align="left"
                  className="fs-14 text-grey fw-normal font-gotham"
                  gutterBottom
                >
                  Login to get started
                </Typography>
                <form
                  style={{ marginTop: '80px' }}
                  onSubmit={async (e) => {
                    e.preventDefault();
                    GAEvents(LOGIN, 'Login', CLICKED, {
                      userId: sessionStorage.getItem('random_id'),
                      reg_uuid: sessionStorage.getItem('random_id'),
                      title: 'Login',
                      phone: mobileNumber ? '<mobile_no>' : '',
                    });
                    try {
                      const status = await checkUserActiveStatus(mobileNumber);
                      if (status === 'Active') {
                        handleSubmit(e);
                      } else if (status === 'normal') {
                        handleSubmit(e);
                      } else {
                        pushMessage('Account is Inactive please contact Admin');
                      }
                    } catch (error) {
                      console.log('error', error);
                    }
                    console.log('form submit status', status);
                  }}
                >
                  <InputLabel
                    htmlFor="inputMobile"
                    className="text-start fs-14 text-black fw-normal font-skmodernist"
                  >
                    Mobile Number <span className="text-mandatory">*</span>
                  </InputLabel>
                  <div className="position-relative mb-0">
                    <MuiTelInput
                      forceCallingCode
                      focusOnSelectCountry
                      color={'error'}
                      onlyCountries={['IN', 'AU']}
                      defaultCountry={country_code_name}
                      fullWidth
                      sx={{ my: 1 }}
                      value={mobileNumber}
                      disableDropdown
                      error={errorMsg}
                      helperText={errorMsg}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div>
                              {mobileNumber.length}/{mobileNumberLength}
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {/* <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errorMsg}
                </FormHelperText> */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      marginTop: 3,
                    }}
                  >
                    <Checkbox
                      checked={privacyPolicyChecked}
                      onChange={handlePrivacyPolicyCheckbox}
                      inputProps={{ 'aria-label': 'controlled' }}
                      className="p-0"
                    />
                    <Typography
                      className="text-grey font-gotham"
                      sx={{ marginLeft: 1 }}
                    >
                      {/* <Typography className="d-flex align-items-start">
                  By signing up, you agree to{' '}
                  <span className="fw-bold">Terms</span>. See how we use your data in our{' '}
                  <span className="fw-bold"> Privacy Policy</span>.
                </Typography> */}
                      By signing up, you agree to{' '}
                      <Button
                        title="Terms"
                        className="p-0"
                        sx={{
                          textTransform: 'capitalize !important',
                          padding: '0px !important',
                          minWidth: '0px !important',
                          fontSize: '16px !important',
                        }}
                        onClick={() => {
                          const isMobileNo = mobileNumber ? `<mobile_no>` : '';
                          GAEvents(LOGIN, 'Login terms', 'click', {
                            mobile_no: isMobileNo,
                            item_name: 'Terms',
                          });
                          handleTerms();
                        }}
                      >
                        Terms.
                      </Button>{' '}
                      See how we use your data in our{' '}
                      <Button
                        className="p-0"
                        sx={{
                          textTransform: 'capitalize !important',
                          padding: '0px !important',
                          minWidth: '0px !important',
                          fontSize: '16px !important',
                        }}
                        onClick={() => {
                          const isMobileNo = mobileNumber ? `<mobile_no>` : '';
                          GAEvents(LOGIN, 'Login policy', 'click', {
                            mobile_no: isMobileNo,
                            item_name: 'Policy',
                          });
                          handlePrivacy();
                        }}
                      >
                        Privacy Policy.
                      </Button>
                    </Typography>
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="mt-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Login
                  </Button>

                  {/* <LoadingButton
										loading={loader}
										// startIcon={<SaveIcon />}
										loadingPosition='start'
										type="submit"
										fullWidth
										variant="contained"
										className="mt-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
										sx={{ borderRadius: '6px', textTransform: 'capitalize', color: '#fff !important' }}
									>
										Login
									</LoadingButton> */}
                  {/* <Typography className="d-flex align-items-start">
                    By signing up, you agree to{' '}
                    <span className="fw-bold">Terms</span>. See how we use your
                    data in our <span className="fw-bold"> Privacy Policy</span>
                    .
                  </Typography> */}
                </form>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>,
        <WebLogin
          errorMsg={errorMsg}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          mobileNumber={mobileNumber}
          privacyPolicyChecked={privacyPolicyChecked}
          handlePrivacyPolicyCheckbox={handlePrivacyPolicyCheckbox}
          // loader={loader}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

LoginForm.propTypes = {
  // Adjust the prop type based on your actual data type
  mobile_number: PropTypes.string,
  saveMobileNo: PropTypes.func,
  saveCountryCode: PropTypes.func,
  country_code: PropTypes.string,
  country_code_name: PropTypes.string,
  saveCountryCodeName: PropTypes.func,
  resetUserData: PropTypes.func,
  resetProfileData: PropTypes.func,
  resetOfferedServiceData: PropTypes.func,
  resetComapanyData: PropTypes.func,
  resetB2BStepData: PropTypes.func,
  resetMyCatalogData: PropTypes.func,
  resetAddCatalogData: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  pushMessage: PropTypes.func,
  resetMyServiceData: PropTypes.func,
  resetUpdateProfileeData: PropTypes.func,
  resetFLAddBioData: PropTypes.func,
  resetFLProfileData: PropTypes.func,
  resetHourlyRate: PropTypes.func,
  resetFLResumeData: PropTypes.func,
  resetFLUploadServiceData: PropTypes.func,
  resetFLUProfRoleData: PropTypes.func,
  resetFLAddServiceData: PropTypes.func,
  resetCompleteProfileData: PropTypes.func,
  resetFLStepData: PropTypes.func,
  getTemplateDataPage: PropTypes.func,
  all_template: PropTypes.object,
  setTemplateRender: PropTypes.func,
  // set_template: PropTypes.string
  sendSigninData: PropTypes.func,
  getExistingBusinessDocuments: PropTypes.func,
  getSocialLinks: PropTypes.func,
  getSocialMediaMasterData: PropTypes.func,
  enquiry: PropTypes.object,
  sendMobForOTP: PropTypes.func,
  clearSubscription: PropTypes.func,
  resetSubscription: PropTypes.func,
  resetTemplateData: PropTypes.func,
  checkUserActiveStatus: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    mobile_number: state.mobile.mobile_number,
    country_code: state.mobile.country_code,
    country_code_name: state.mobile.country_code_name,
    isLoading: state.root.isLoading,
    all_template: state.mobile.all_template,
    // set_template: state.template.set_template
    enquiry: state.b2bVendorlisting,
  };
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         saveMobileNo: (mobileNumber) => dispatch(saveMobileNo(mobileNumber))
//     }
// };

export default connect(mapStateToProps, {
  saveMobileNo,
  saveCountryCode,
  saveCountryCodeName,
  resetUserData,
  resetProfileData,
  resetOfferedServiceData,
  resetComapanyData,
  resetB2BStepData,
  resetMyCatalogData,
  resetAddCatalogData,
  loading,
  pushMessage,
  resetMyServiceData,
  resetUpdateProfileeData,
  resetFLAddBioData,
  resetFLProfileData,
  resetHourlyRate,
  resetFLResumeData,
  resetFLUploadServiceData,
  resetFLUProfRoleData,
  resetFLAddServiceData,
  resetCompleteProfileData,
  resetFLStepData,
  getTemplateDataPage,
  setTemplateRender,
  sendSigninData,
  getExistingBusinessDocuments,
  getSocialLinks,
  getSocialMediaMasterData,
  sendMobForOTP,
  clearSubscription,
  resetSubscription,
  resetTemplateData,
  checkUserActiveStatus,
})(LoginForm);
