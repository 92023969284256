import {
  CIN_NUMBER,
  GST_NUMBER,
  GST_REASON,
  PAN_NUMBER,
  HAS_GST_NO,
  RESET_IN_DOC_DATA,
} from '../types';

const INITIAL_STATE = {
  gstReason: 0,
  cin: '',
  gst: '',
  pan: '',
  hasGST: '1',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GST_REASON:
      return { ...state, gstReason: action.payload };
    case CIN_NUMBER:
      return { ...state, cin: action.payload };
    case GST_NUMBER:
      return { ...state, gst: action.payload };
    case PAN_NUMBER:
      return { ...state, pan: action.payload };
    case HAS_GST_NO:
      return { ...state, hasGST: action.payload };
    case RESET_IN_DOC_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
