import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as EditIcon } from '../../assets/tick-circle-icon.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/cross-circle-icon.svg';
import { ReactComponent as CallIcon } from '../../assets/call-purple-icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/email-purple-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { getApplicantDataDetails } from './redux/actions/job-application-action';
import PropTypes from 'prop-types';
import Responsive from '../../utils/responsive';
import { saveAs } from 'file-saver';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { CLICKED, JOBAPPLICATION_TITLE, LOAD } from '../../utils/constants';
import { GAEvents } from '../../utils/helper-funtions';
export const UPLOAD_BASE_URL = process.env.REACT_APP_UPLOAD_BASE_URL


const theme = createTheme();

// const data = [
//     {
//         id: 1,
//         name: 'Rohan Das',
//         mobile: '+91 845982270',
//         email: 'rohan@support.com',
//     },
//     {
//         id: 2,
//         name: 'John Doe',
//         mobile: '+91 845982465',
//         email: 'john@support.com',
//     },
//     {
//         id: 2,
//         name: 'Will Smith',
//         mobile: '+91 814982135',
//         email: 'will@support.com',
//     },
//     // Add more items as needed
// ];


const JobApplicationsForm = ({
    all_applicant_data,
    getApplicantDataDetails,
    userData,
    pushMessage
}) => {

    let location = useLocation();

    const history = useHistory();

    useEffect(() => {
        console.log('state', location?.state);
    }, [location?.state])


    useEffect(() => {
        GAEvents(JOBAPPLICATION_TITLE, "Job Application", LOAD, {
			page_title: 'Job Application',
			page_location: window.location.href,
			page_path: window.location.pathname,
			user_id: userData?.id || sessionStorage.getItem('random_id'),
			reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id')
		});
        getApplicantDataDetails(location?.state?.jobData?.id)
    }, [])

    useEffect(() => {
        console.log("Details data : ", all_applicant_data);
    }, [])



    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '67vh' }}>
                    <Grid item xs={12}>
                        <div className='my-3'>
                            <ArrowBackIosIcon onClick={() => { history.push("/job-posted") }} />
                            <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} onClick={() => { history.push("/homepage") }} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" />
                        <Typography
                            variant="h4"
                            align="left"
                            alignItems="center"
                            className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
                            gutterBottom style={{ whiteSpace: 'pre-line' }}>
                            Job Applications
                        </Typography>

                        <List >
                            <Grid
                                container
                                columnSpacing={3}
                                rowSpacing={1.5}
                                alignItems="start"
                            >
                                {all_applicant_data.map((item) => (
                                    <Grid item md={6} lg={6} xs={12} key={item.id} >
                                        <ListItem className="job-list-item">
                                            <Card className="card d-flex">
                                                <CardContent className="content">
                                                    <div className="d-flex justify-content-between align-items-start">
                                                        <Typography variant="h5" className="fs-16 text-black mb-2 fw-bold font-skmodernist" component="div">
                                                            {item.name}
                                                        </Typography>

                                                        <div className="actions mt-0">
                                                            <IconButton className="cat-edit-icon p-0" onClick={() => {
                                                                GAEvents(JOBAPPLICATION_TITLE, "Job Application", CLICKED, {
                                                                    userId: userData?.id || sessionStorage.getItem('random_id'),
                                                                    reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                                                    title: "Call Icon",
                                                                    job_title: item?.name || ''
                                                                });
                                                                window.location.href = `tel:${userData?.user?.country_code} + ${item?.phone_number}`;
                                                            }}>
                                                                <CallIcon width={'20px'} height={'20px'} />
                                                            </IconButton>
                                                            <IconButton className="cat-delete-icon p-0"
                                                                onClick={() => {
                                                                    GAEvents(JOBAPPLICATION_TITLE, "Job Application", CLICKED, {
                                                                        userId: userData?.id || sessionStorage.getItem('random_id'),
                                                                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                                                        title: "Email Icon",
                                                                        job_title: item?.email || ''
                                                                    });
                                                            }}>
                                                                <EmailIcon width={'20px'} height={'20px'} />
                                                            </IconButton>
                                                        </div>
                                                    </div>

                                                    <Typography variant="body2" className="fs-13 text-grey mb-1 font-gotham">
                                                        Mobile No. {item.phone_number}
                                                    </Typography>

                                                    <Typography variant="body2" className="fs-13 text-grey mb-3 font-gotham">
                                                        Email: {item.email}
                                                    </Typography>

                                                    <Typography variant="h5" className="fs-13 text-darkgrey mb-2 font-skmodernist">
                                                        {item.startingAt}
                                                    </Typography>

                                                    <div className="d-flex">
                                                        <div className="d-flex align-items-center me-5">
                                                            <Typography className="fs-13 text-secondary fw-bold font-skmodernist me-3">Resume</Typography>
                                                            {/* <DownloadIcon /> */}
                                                            <IconButton
                                                                className="fs-12"
                                                                onClick={() => {
                                                                    GAEvents(JOBAPPLICATION_TITLE, "Job Application", CLICKED, {
                                                                        userId: userData?.id || sessionStorage.getItem('random_id'),
                                                                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                                                        title: "Download Icon",
                                                                        job_title: item?.email || ''
                                                                    });
                                                                    if (item?.resume !== null) {
                                                                        saveAs(
                                                                            UPLOAD_BASE_URL + "/uploads/applications/resumes/" + item?.resume,
                                                                            item?.resume
                                                                        );
                                                                    }
                                                                    else {
                                                                        pushMessage("Resume not Uploaded")
                                                                    }

                                                                }}
                                                            >
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <Typography className="fs-13 text-secondary fw-bold font-skmodernist me-3">Cover Letter</Typography>
                                                            <IconButton
                                                                className="fs-12"
                                                                onClick={() => {
                                                                    GAEvents(JOBAPPLICATION_TITLE, "Job Application", CLICKED, {
                                                                        userId: userData?.id || sessionStorage.getItem('random_id'),
                                                                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                                                        title: "Download Icon",
                                                                        job_title: item?.email || ''
                                                                    });
                                                                    if (item?.cover_letter !== null) {
                                                                        saveAs(
                                                                            UPLOAD_BASE_URL + "/uploads/applications/resumes/" + item?.cover_letter,
                                                                            item?.cover_letter
                                                                        );
                                                                    }
                                                                    else {
                                                                        pushMessage("Cover letter not Uploaded")
                                                                    }

                                                                }}
                                                            >
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </ListItem>
                                    </Grid>
                                ))}
                            </Grid>
                        </List>


                        {
                            Responsive(
                                <></>,
                                <>
                                    <Box sx={{ marginTop: '80px' }}>
                                        <Button
                                            // onClick={(e) => handleBackButton(e)}
                                            variant="text"
                                            type="button"
                                            className="btn-web-back"
                                        >
                                            Back
                                        </Button>
                                    </Box>

                                </>

                            )
                        }

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider >
    );
};

JobApplicationsForm.propTypes = {
    // Adjust the prop type based on your actual data type
    all_applicant_data: PropTypes.array,
    getApplicantDataDetails: PropTypes.func,
    userData: PropTypes.object,
    pushMessage: PropTypes.object
};


const mapStateToProps = (state) => {
    const { all_applicant_data } = state.jobApplication;
    const { userData } = state.signin;
    return {
        all_applicant_data,
        userData
    }
}


export default connect(mapStateToProps, { getApplicantDataDetails, pushMessage })(JobApplicationsForm);