export const FL_STEP_1_ACTIVE = [
  {
    id: 0,
    name: '',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  {
    id: 1,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 2,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 3,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 4,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  // {
  //   id: 5,
  //   name: '',
  //   completed: false,
  //   active: false,
  //   error: false,
  //   icon: 3,
  // },
];

export const FL_STEP_2_ACTIVE = [
  {
    id: 0,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 1,
    name: '',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  {
    id: 2,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 3,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 4,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  // {
  //   id: 5,
  //   name: '',
  //   completed: false,
  //   active: false,
  //   error: false,
  //   icon: 3,
  // },
];

export const FL_STEP_3_ACTIVE = [
  {
    id: 0,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 1,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 2,
    name: '',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  {
    id: 3,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 4,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  // {
  //   id: 5,
  //   name: '',
  //   completed: false,
  //   active: false,
  //   error: false,
  //   icon: 3,
  // },
];

export const FL_STEP_4_ACTIVE = [
  {
    id: 0,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 1,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 2,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 3,
    name: '',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  {
    id: 4,
    name: '',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  // {
  //   id: 5,
  //   name: '',
  //   completed: false,
  //   active: false,
  //   error: false,
  //   icon: 3,
  // },
];

export const FL_STEP_5_ACTIVE = [
  {
    id: 0,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 1,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 2,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 3,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 4,
    name: '',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  // {
  //   id: 5,
  //   name: '',
  //   completed: false,
  //   active: false,
  //   error: false,
  //   icon: 3,
  // },
];

export const FL_STEP_6_ACTIVE = [
  {
    id: 0,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 1,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 2,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 3,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  {
    id: 4,
    name: '',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
  // {
  //   id: 5,
  //   name: '',
  //   completed: false,
  //   active: true,
  //   error: false,
  //   icon: 2,
  // },
];
