/*eslint-disable no-useless-escape */
// B2B PROFILE DETAILS FORM TYPES
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const SET_LOCATION_COORDS = 'SET_LOCATION_COORDS';
export const SET_SOCIAL_LINKS = 'SET_SOCIAL_LINKS';
export const SET_COVER_IMG_UPLOAD_PROGRESS = 'SET_COVER_IMG_UPLOAD_PROGRESS';
export const SET_SELECTED_COVER_IMAGE = 'SET_SELECTED_COVER_IMAGE';
export const SET_COMPANY_LOGO_UPLOAD_PROGRESS =
  'SET_COMPANY_LOGO_UPLOAD_PROGRESS';
export const SET_ALL_CITY = 'SET_ALL_CITY';
export const SET_ALL_STATE = 'SET_ALL_STATE';
export const SET_PROFILE_DETAILS_ADDRESS_1 = 'SET_PROFILE_DETAILS_ADDRESS_1';
export const SET_PROFILE_DETAILS_ADDRESS_2 = 'SET_PROFILE_DETAILS_ADDRESS_2';
export const SET_PROFILE_DETAILS_CITY = 'SET_PROFILE_DETAILS_CITY';
export const SET_PROFILE_DETAILS_STATE = 'SET_PROFILE_DETAILS_STATE';
export const SET_SOCIAL_DATA_OBJECT = 'SET_SOCIAL_DATA_OBJECT';
export const RESET_PROFILE_DETAILS_DATA = 'RESET_PROFILE_DETAILS_DATA';
export const SET_SOCIAL_NAME = 'SET_SOCIAL_NAME';
export const SET_SOCIAL_MEDIA_MASTER = 'SET_SOCIAL_MEDIA_MASTER';
export const FILTERED_CITY_DATA = 'FILTERED_CITY_DATA';
export const SET_SELECTED_WHATSAPP = 'SET_SELECTED_WHATSAPP';
export const SET_PROFILE_DETAILS_AREANAME = 'SET_PROFILE_DETAILS_AREANAME';
export const SET_PROFILE_DETAILS_PINCODE = 'SET_PROFILE_DETAILS_PINCODE';
export const SET_DELETED_SOCIAL_LINK = 'SET_DELETED_SOCIAL_LINK';
export const RESET_DELETED_SOCIAL_LINK = 'RESET_DELETED_SOCIAL_LINK';
export const URL_REGEX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const URL_REGEX2 =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const URL_REGEX3 =
  /^((?:(https?):\/\/)?((?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9]))|(?:(?:(?:\w+\.){1,2}[\w]{2,3})))(?::(\d+))?((?:\/[\w]+)*)(?:\/|(\/[\w]+\.[\w]{3,4})|(\?(?:([\w]+=[\w]+)&)*([\w]+=[\w]+))?|\?(?:(wsdl|wadl))))$/g;
