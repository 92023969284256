import { LOADING } from "../../../Login/redux/types";
import { RESET_SIGNUP_DATA } from "../types";

const INITIAL_STATE = {
    otp: "",
    loading: false,
    error:"",    
    userData: null
  };

  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
       case LOADING:
         return {
              ...state,
              loading: action.payload,
              error: "",
          };
        case RESET_SIGNUP_DATA:
          return INITIAL_STATE;
    
      default:
        return state;
    }
  };