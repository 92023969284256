import {
  GET_RESUME_DATA, REMOVE_RESUME, RESET_RESUME_DATA, UPDATE_UPLOADED_RESUME
} from '../types';

import { getResume, uploadResume, deleteSingleResume } from '../../../../../utils/APIs/endpoints';
import { STATUS_CODE_200, STATUS_CODE_201, STATUS_CODE_400, STATUS_CODE_500 } from '../../../../../utils/constants';


export const uploadResumeData = (
  resume,
  registrationId,
  successCallback,
  errorCallback
) =>
  async function (dispatch) {
    if (!resume) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('resume', resume);
      const response = await uploadResume(registrationId, formData, (progressEvent) => {
        /* eslint-disable no-unused-vars */
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
       /* eslint-enable no-unused-vars */
      });

      if (response.status === STATUS_CODE_201) {
        dispatch({
          type: UPDATE_UPLOADED_RESUME,
          payload: response.data?.data,
        });
        successCallback();
      }
      else if (response.status === STATUS_CODE_400) {
        successCallback("Resume must be maximum of 2 mb");
      }
    } catch (error) {
      if (error?.response?.data?.error)
        errorCallback(error?.response?.data?.error);
      errorCallback();
    }
  };


export const getResumeData = (regId) =>
  async function (dispatch) {
    try {
      const response = await getResume(regId);

      if (response.status === 200) {
        dispatch({ type: GET_RESUME_DATA, payload: response?.data?.data });
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === STATUS_CODE_500) {
        console.log(
          '[upload-resume-action] getResume Error code 500',
          error
        );
      }
      console.log(
        '[upload-resume-action] getResume Error',
        error
      );
    }
  };

export const deleteResume = (resId,
  successCallback = () => { },
  errroCallback = () => { }) =>
  async function (dispatch) {
    try {
      const response = await deleteSingleResume(resId);
      if (response.status === STATUS_CODE_200) {
        dispatch({ type: REMOVE_RESUME });
        successCallback(response.data);
      } else {
        errroCallback();
      }
    } catch (err) {
      errroCallback();
    }
  };

export const resetFLResumeData = () =>
  function (dispatch) {
    dispatch({ type: RESET_RESUME_DATA });
  };