import { loading } from "../../../../../store/actions/rootActions";
import { getProdCategories, postBusinessRequirementApi, postSendEnquiryMsg, putBusinessRequirementApi } from "../../../../../utils/APIs/endpoints";
import { BUSINESS, FREELANCER,  } from "../../../../../utils/constants";
import { SAVE_MOQ, SAVE_NAME, SAVE_CATEGORY, SAVE_BTYPE, SAVE_DESCRIPTION, SAVE_LOCATION, SAVE_FL_CATEGORY, SAVE_B2B_CATEGORY, SAVE_BUDGET, RESET_REQUIREMENT_STATE, PAGE_ACTION_TYPE, SINGLE_REQUIREMENT, REQ_SUB_CATEGORY } from "../types";

export const postBusinessRequirement = (data, successCallback=()=>{}, errorCallback=()=>{}) =>  async () => {
    try{
        const response = await postBusinessRequirementApi(data, successCallback, errorCallback);
        if(response.status === 201){
            console.log("b2b-post-request ", response.data);
            successCallback();
        } else{
            errorCallback();
        }
    } catch(error){
        console.log("post business requirement")
    }
}

export const setRequestMOQ = (data) => async (dispatch) => {
    dispatch({type: SAVE_MOQ, payload: data});
}

export const setRequestName = (data) => async (dispatch) => {
    dispatch({type: SAVE_NAME, payload: data});
}

export const setRequestCategory = (data) => async (dispatch) => {
    dispatch({type: SAVE_CATEGORY, payload: data});
}

export const setRequestBType = (data) => async (dispatch) => {
    dispatch({type: SAVE_BTYPE, payload: data});
}

export const setRequestDesc = (data) => async (dispatch) => {
    dispatch({type: SAVE_DESCRIPTION, payload: data});
}

export const setRequestLocation = (data) => async (dispatch) => {
    dispatch({type: SAVE_LOCATION, payload: data});
}

export const getProductCategories = (type=BUSINESS, successCallback = () => {},
errorCallback = () => {}) =>
  async function (dispatch) {
    try {
      const response = await getProdCategories(type);
      if (response.status === 200) {

        if(type == BUSINESS){
            await dispatch({
                type: SAVE_B2B_CATEGORY,
                payload: response.data.data,
              });
              successCallback(BUSINESS);
        } else {
            await dispatch({
                type: SAVE_FL_CATEGORY,
                payload: response.data.data,
              });
              successCallback(FREELANCER);
        }
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
      errorCallback(error);
    }
  };

  export const setBudget = (data) => async (dispatch) => {
      dispatch({type: SAVE_BUDGET, payload: data});
  }

  export const resetRequirementData = () => async (dispatch) => {
    dispatch({type: RESET_REQUIREMENT_STATE});
}


export const setPageActionType = (type) =>
  function (dispatch) {
    dispatch({ type: PAGE_ACTION_TYPE, payload: type });
  };

export const setSingleData = (data) =>
  function (dispatch) {
    dispatch({ type: SINGLE_REQUIREMENT, payload: data });
  };

  export const putBusinessRequirement = (id, data, successCallback=()=>{}, errorCallback=()=>{}) =>  async () => {
    try{
        const response = await putBusinessRequirementApi(id, data, successCallback, errorCallback);
        if(response.status === 200){
            console.log("b2b-post-request Edit", response.data);
            successCallback();
        } else{
            errorCallback();
        }
    } catch(error){
        console.log("post business requirement Edit")
    }
}


export const sendEnqCallbackMsg = (data, successCallback = () => {}, errorCallback = () =>{}) => {
  return async(dispatch) => {
    dispatch(loading(true));
    try{
      const response = await postSendEnquiryMsg(data);
      if(response?.status === 200){
      //  let otpdata = response?.data?.data;
      //  localStorage.setItem(mobileNo?.phone, JSON.stringify(otpdata));
       dispatch(loading(false));
       successCallback();
      }else{
        dispatch(loading(false));
        errorCallback();
      }
    } catch(error){
      errorCallback();
      dispatch(loading(false));
    }

  }
}

export const setReqSubCategory = (subCategory) =>
  function (dispatch) {
    dispatch({ type: REQ_SUB_CATEGORY, payload: subCategory });
  };