import * as React from 'react';
// import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import {
    // func,
    // object,
    // bool,
    // array,
    // any,
    node
    // string
  } from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


function ElynkerLogoComponent({children}) {

    const history = useHistory();

    const handleBackHome = () =>  {
        history.push("/homepage")
    }

    return (
        // <ElynkerLogo width={'100px'} height={'30px'} />
        <div onClick={handleBackHome} style={{cursor:"pointer"}}>
            {children}
        </div>
    )
}

ElynkerLogoComponent.propTypes = {
children: node
}



export default ElynkerLogoComponent;