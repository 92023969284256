import {
  SAVE_FL_BUSINESS_DOCUMENTS,
  SET_FL_ACTIVE_STEP,
  SET_FL_STEPPER_DATA,
  RESET_FL_DATA
} from '../types';

const INITIAL_STATE = {
  activeStep: 0,
  businessDocuments: null,
  stepperData: [
    {
      id: 0,
      name: '',
      completed: false,
      active: true,
      error: false,
      icon: 2,
    },
    {
      id: 1,
      name: '',
      completed: false,
      active: false,
      error: false,
      icon: 3,
    },
    {
      id: 2,
      name: '',
      completed: false,
      active: false,
      error: false,
      icon: 3,
    },
    {
      id: 3,
      name: '',
      completed: false,
      active: false,
      error: false,
      icon: 3,
    },
    {
      id: 4,
      name: '',
      completed: false,
      active: false,
      error: false,
      icon: 3,
    },
    // {
    //   id: 5,
    //   name: '',
    //   completed: false,
    //   active: false,
    //   error: false,
    //   icon: 3,
    // },
  ],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FL_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case SAVE_FL_BUSINESS_DOCUMENTS:
      return { ...state, businessDocuments: action.payload };
    case SET_FL_STEPPER_DATA:
      return { ...state, stepperData: action.payload };
    case RESET_FL_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
