import { postApplyJob } from "../../../../utils/APIs/endpoints";
import { JOB_APPLICANT_EMAIL, JOB_APPLICANT_MOBILE_NO, JOB_APPLICANT_NAME, JOB_FORM_RESET_DATA, JOB_RESET_DATA, SAVE_JOB_ID } from "../types";

export const postJobApply = (
    name,
    phone_number,
    emailId,
    resume,
    cover_letter,
    jobId,
    successCallback,
    errorCallback,
  ) =>
    async function () {
      
      if (!resume) {
        console.log('[job-post-apply-action] ABORTING... No Resume file selected');
        return;
      }
      if(!cover_letter) {
        console.log('[job-post-apply-action] ABORTING... No cover_letter file selected');
      }
      try {
        console.log("====banner?.type.", resume?.type);  
  
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone_number', phone_number);
        formData.append('email', emailId);
        formData.append('resume', resume);
        if(cover_letter){
          formData.append('cover_letter', cover_letter);
        }
        formData.append('jobId', jobId);

        const response = await postApplyJob(formData, (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Resume uploading Preogress - ', percentCompleted);
        });
        if (response.status === 201) {
          //
          //
          console.log(
            '[upload-banner-action] Uploading banner Successfully',
            response.data
          );
          // if (type !== "banner") { dispatch({ type: SAVE_PROJECT_DATA, payload: response.data.data }); }
          successCallback(response.data);
        }
      } catch (error) {
        //error
        // dispatch({ type: PRODUCT_UNIT, payload: unit });
        if (error?.response?.data?.error)
          errorCallback(error?.response?.data?.error);
        console.log(
          '[upload-banner-action] Uploading banner Error',
          error
        );
      }
    };

export const setApplicantName = (name) =>
    function (dispatch) {
        dispatch({ type: JOB_APPLICANT_NAME, payload: name });
};

export const setApplicantEmail = (email) =>
    function (dispatch) {
        dispatch({ type: JOB_APPLICANT_EMAIL, payload: email });
};

export const setApplicantMobileNo = (email) =>
    function (dispatch) {
        dispatch({ type: JOB_APPLICANT_MOBILE_NO, payload: email });
};

export const resetJobApplyData = () =>
    function (dispatch) {
        dispatch({ type: JOB_RESET_DATA });
};

export const setJobID = (id) =>
    function (dispatch) {
        dispatch({ type: SAVE_JOB_ID, payload: id });
};

export const resetJobFormApplyData = () =>
    function (dispatch) {
        dispatch({ type: JOB_FORM_RESET_DATA });
};
