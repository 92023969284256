import { putUserData } from "../../../../utils/APIs/endpoints"
import { B2C_PROFILE_EDIT } from "../types"

export const setB2CprofileData = (edit_data) => {
    return async(dispatch) => {
        dispatch({type:B2C_PROFILE_EDIT, payload:edit_data})
    }
}



export const updateB2CprofileData = (id, data, successCallback=() => {}, errorCallback=() => {}) => {
    return async() => {
        try {
            const response = await putUserData(id, data);
            console.log("response_data : ", response);
            if (response.status === 200){
                successCallback(response?.data)
                // dispatch({type:, payload: false});
            }
            else{
                errorCallback(response?.data)
            }
        } catch (error) {
            console.log('putUpdateUserDetails Error', error);
            errorCallback(error);
        }
    }
}


// export const putUpdateUserDetails = (
//     userId,
//     userData,
//     oldUserData,
//     successCallback = () => { },
//     errorCallback = () => { }
//   ) =>
//     async function (dispatch) {
//       try {
//         const response = await putUserData(userId, userData);
//         if (response.status === 200) {
//           console.log(
//             '[user-action] putUpdateUserDetails Success',
//             response.data.data,
//             userData
//           );
//           dispatch({ type: UPDATE_PROFILE_DETAILS, payload: userData });
//           let newUsrData = { ...oldUserData };
//           newUsrData.user = { ...oldUserData.user, ...response.data };
//           await dispatch({
//             type: SAVE_SIGNIN_DATA,
//             payload: newUsrData,
//           });
  
//           successCallback(newUsrData);
//         } else {
//           console.log(
//             '[user-action] putUpdateUserDetails Error',
//             response.data.data
//           );
//           errorCallback(response?.data?.message);
//         }
//       } catch (err) {
//         console.log('[user-action] putUpdateUserDetails Error', err);
//         errorCallback(err);
//       }
//     };