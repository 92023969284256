import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

const Responsive = (MobileUI, DesktopUI) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
    // console.log("isDesktopOrLaptop isTabletOrMobile", isDesktopOrLaptop, isTabletOrMobile);
    return !isDesktopOrLaptop && isTabletOrMobile ? MobileUI : DesktopUI 
};

Responsive.propTypes = {
    DesktopUI: PropTypes.elementType.isRequired,
    MobileUI: PropTypes.elementType.isRequired,
};

export default Responsive;
