import { JOB_APPLICANT_DATA } from "../types";


const INITIAL_STATE = {
    all_applicant_data: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case JOB_APPLICANT_DATA:
            return {
                ...state,
                all_applicant_data: action.payload
            }
        default:
            return state;
    }
}