import React from 'react';
import PropTypes from 'prop-types';
// import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Dialog, DialogContent } from '@mui/material';
import ProductCloseIcon from '../../assets/product-close-icon.svg';
import ResponsiveImageCarousel from '../ImageSlider';

const ImageDialog = (props) => {
	const { open, onClose, image } = props;
	// console.log("===children", props);
	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg">
			{/* <DialogTitle>
                <Typography variant="h6">Image Preview</Typography>
            </DialogTitle> */}
			<DialogContent style={{ maxWidth: '800px' }} className="catalog-carousel">
				<img
					src={ProductCloseIcon}
					alt="product-close-icon"
					className="product-close-icon"
					onClick={onClose}
					style={{
						objectFit: 'inherit',
						position: 'absolute',
						top: '10px',
						right: '10px',
						width: '25px',
						height: '25px',
						zIndex: '1',
						cursor: 'pointer',
					}}
				/>

				{image && typeof image === 'string' ? (
					<img
						src={image}
						alt="Preview"
						style={{ maxWidth: '100%', height: 'auto' }}
					/>
				) : (
					<ResponsiveImageCarousel images={image} />
				)}
			</DialogContent>
		</Dialog>
	);
};

ImageDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	image: PropTypes.string.isRequired,
	children: PropTypes.any,
	carosel: PropTypes.any,
};

export default ImageDialog;

