import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { URL_REGEX } from '../../pages/ProfileDetails/redux/types';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
// import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
// import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
// import { ReactComponent as EditIcon } from "../../assets/cat-edit-icon.svg";
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import { SOCIAL_MEDIA_IMAGE_PATH } from '../../utils/APIs/api';
import _ from 'lodash';

function SocialLinks({
  socialLinks,
  setSocialLinks,
  socialName,
  newSocialLinkData,
  socialData,
  setSocialName,
  setSocialData,
  socialUrlValidateError,
  setSocialUrlValidateError,
  handleAddSocialLinkBtn,
  registrationId,
  socialMediaMaster,
  id,
  delSocialLinkArr
}) {
  const renderSocialImage = (link) => {
    let socialMasteridx = socialMediaMaster.findIndex(
      (itm) => itm.id === link.socialmedia_id
    );
    if (socialMasteridx !== -1) {
      return (
        <img
          src={
            SOCIAL_MEDIA_IMAGE_PATH +
            socialMediaMaster[socialMasteridx].media_image_path
          }
          height={'25px'}
          width={'25px'}
        />
      );
    } else {
      return <></>;
    }
  };
  const addButtonRef = React.useRef(null);
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2} alignItems="end">
        <Grid item md={4} lg={4} xs={12}>
          <InputLabel
            htmlFor="socialInput"
            className="text-start fs-14 text-black font-skmodernist"
          >
            Add Social Links
          </InputLabel>
          <FormControl fullWidth sx={{ my: 1 }}>
            <CustomDropdown
              id="socialInput"
              value={socialName}
              options={newSocialLinkData}
              inputPlaceholder="Select Social Link"
              // disabled={newSocialLinkData?.length === 0}
              onChange={(e, value) => {
                // console.log('Value',value);
                let obj = { ...socialData };
                obj.social_name = value?.media_name;
                obj.socialmedia_id = value?.id;
                obj.registrationId = registrationId;
                setSocialName(value);
                setSocialData(obj);
              }}
              optionKey="media_name"
              labelKey="media_name"
            />
          </FormControl>
        </Grid>
        <Grid item md={4} lg={4} xs={12} className="d-flex align-items-center">
          <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <OutlinedInput
              id={id}
              type="text"
              placeholder="https://"
              // required
              fullWidth
              margin="none"
              disabled={newSocialLinkData?.length === 0}
              value={socialData.social_url}
              onChange={(e) => {
                let obj = { ...socialData };
                obj.social_url = e.target.value;
                setSocialData(obj);
                if (e.target.value === '') {
                  setSocialUrlValidateError('');
                  addButtonRef.current.disabled = true;
                } else if (!URL_REGEX.test(e.target.value)) {
                  setSocialUrlValidateError('Invalid URL');
                  addButtonRef.current.disabled = true;
                } else {
                  setSocialUrlValidateError('');
                  addButtonRef.current.disabled = false;
                }
              }}
            />
          </FormControl>
          <Button
            type="button"
            ref={addButtonRef}
            fullWidth
            variant="outlined"
            className="social-add-button fs-15 font-skmodernist fw-bold"
            sx={{
              borderRadius: '6px',
              textTransform: 'capitalize',
            }}
            disabled={newSocialLinkData?.length === 0}
            onClick={handleAddSocialLinkBtn}
          >
            <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
          </Button>
        </Grid>
      </Grid>
      {socialUrlValidateError && (
        <FormHelperText sx={{ color: 'red' }} id="social-helper-text">
          {socialUrlValidateError}
        </FormHelperText>
      )}
      {socialLinks &&
        _.map(socialLinks, (link, linkIdx) => {
          return (
            <Grid
              item
              md={8}
              lg={8}
              xs={12}
              key={linkIdx}
              className="d-flex justify-content-between align-items-center my-2"
              onClick={() => console.log(link)}
            >
              <div className="d-flex align-items-center">
                {/* {link.social_name.toLowerCase() === 'facebook' && (
                  <FacebookIcon />
                )}
                {link.social_name.toLowerCase() === 'instagram' && (
                  <InstagramIcon />
                )} */}
                {renderSocialImage(link)}
                <Typography
                  variant="body1"
                  align="left"
                  className="fs-14 text-darkgrey fw-normal mb-0 ms-3 font-skmodernist"
                  gutterBottom
                >
                  {String(link.social_url).length > 50
                    ? String(link.social_url).substring(0, 50) + '...'
                    : link.social_url}
                </Typography>
              </div>
              <div className="d-flex">
                {/* <IconButton
                  className="cat-edit-icon"
                  onClick={() => {
                    setSocialName(link);
                    setSocialData(link);
                    let socialLink = [...socialLinks];
                    let socialIdx = socialLink.findIndex(
                      (el) => el.social_name === link.social_name
                    );
                    socialLink.splice(socialIdx, 1);
                    setSocialLinks(socialLink);
                  }}
                >
                  <EditIcon />
                </IconButton> */}
                <IconButton
                  className="cat-delete-icon"
                  onClick={() => {
                    let socialLink = [...socialLinks];
                    let socialIdx = socialLink.findIndex(
                      (el) => el.social_name === link.social_name
                    );
                    socialLink.splice(socialIdx, 1);
                    setSocialLinks(socialLink);
                    delSocialLinkArr(link?.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Grid>
          );
        })}
    </>
  );
}

SocialLinks.propTypes = {
  socialLinks: PropTypes.array,
  setSocialLinks: PropTypes.func,
  socialName: PropTypes.object,
  newSocialLinkData: PropTypes.array,
  socialData: PropTypes.object,
  setSocialName: PropTypes.func,
  setSocialData: PropTypes.func,
  socialUrlValidateError: PropTypes.string,
  id: PropTypes.string,
  setSocialUrlValidateError: PropTypes.func,
  handleAddSocialLinkBtn: PropTypes.func,
  registrationId: PropTypes.number,
  socialMediaMaster: PropTypes.array,
  delSocialLinkArr: PropTypes.func
};

export default SocialLinks;
