// get manage website data

import { loading } from '../../../../store/actions/rootActions';
import {
  getSubDomainApi,
  getTetimonialById,
  // getTetimonialByTestimonialId,
  isSubDomainAvlApi,
  postTetimonial,
  updateTetimonialApi,
  deleteTetimonialApi,
  getProductsByRegistration,
  postManagedData,
  getSellerWebsiteDataByRegistration,
  // getSellerWebsiteDataById,
  updateManagedDataApi,
} from '../../../../utils/APIs/endpoints';
import { STATUS_CODE_200 } from '../../../../utils/constants';
import {
  SET_MANAGE_WEBSITE_DATA,
  DELETE_TESTIMONIAL_WEBSITE_DATA,
  EDIT_TESTIMONIAL_WEBSITE_DATA,
  MARK_SELECTED_PRODUCT_DATA,
  SET_PRODUCT_DATA,
  SET_SUB_DOMAINS,
  SET_TESTIMONIAL_WEBSITE_DATA,
  SET_ALL_TESTIMONIAL_WEBSITE_DATA,
  RESET_TESTIMONIAL_WEBSITE_DATA,
  SET_DELETED_TESTIMONIAL_WEBSITE_DATA,
  RESET_MANAGED_WEBSITE_DATA,
} from '../type';

//API to validate user name exist or not
// isSubDomainAvlApi
export const setManageWebsiteData = (data) => async (dispatch) =>
  dispatch({ type: SET_MANAGE_WEBSITE_DATA, payload: data });

export const editTestimonialWebsiteData = (id) => async (dispatch) =>
  dispatch({ type: EDIT_TESTIMONIAL_WEBSITE_DATA, payload: id });

export const setTestimonialWebsiteData = (data) => async (dispatch) =>
  dispatch({ type: SET_TESTIMONIAL_WEBSITE_DATA, payload: data });
export const resetTestimonialWebsiteData = () => async (dispatch) =>
  dispatch({ type: RESET_TESTIMONIAL_WEBSITE_DATA });
export const deleteTestimonialWebsiteData = (id) => async (dispatch) =>
  dispatch({ type: DELETE_TESTIMONIAL_WEBSITE_DATA, payload: id });
export const setProductData = (data) => async (dispatch) =>
  dispatch({ type: SET_PRODUCT_DATA, payload: data });
export const markProductData = (prods) => async (dispatch) =>
  dispatch({ type: MARK_SELECTED_PRODUCT_DATA, payload: prods });

export const checkSubDomainAvl = async (
  value,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  try {
    const res = await isSubDomainAvlApi(value);
    if (res?.data?.data == STATUS_CODE_200) {
      successCallback(res);
    } else {
      errorCallback(res);
    }
  } catch (error) {
    errorCallback(error);
  }
};

export const getSubDomains = (
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    // dispatch(loading(true));
    try {
      const res = await getSubDomainApi();
      // console.log('getSubDomains', res?.data.module);
      if (res?.status === 200) {
        successCallback(res);
        dispatch({ type: SET_SUB_DOMAINS, payload: res?.data.module });
      } else {
        errorCallback(res);
        dispatch({ type: SET_SUB_DOMAINS, payload: [] });
      }
    } catch (error) {
      dispatch({ type: SET_SUB_DOMAINS, payload: [] });
      errorCallback(error);
    }
  };
};
export const setLocalTestimonial = (data, all_testimonialWebsiteData) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
      payload: [...all_testimonialWebsiteData, data],
    });
  };
};
export const setTestinomial = (
  data,
  // all_testimonialWebsiteData,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await postTetimonial(data);
      console.log('setTestinomial response', res?.status);
      if (res?.status === 200 || res?.status === 201) {
        successCallback(res.data.message);
        // dispatch({
        //   type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        //   payload: [...all_testimonialWebsiteData, res.data.data],
        // });
      } else {
        errorCallback(res.data.message);
      }
    } catch (error) {
      errorCallback(error?.response?.data?.error);
    } finally {
      dispatch(loading(false));
    }
  };
};
export const localUpdateTestimonialData =
  (id, data, allData) => async (dispatch) => {
    dispatch({
      type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
      payload: [...allData.filter((r) => r.id !== id), data],
    });
  };
export const updateTestinomial = (
  id,
  data,
  // allData,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await updateTetimonialApi(id, data);
      console.log('updateTestinomial api', res?.status);
      if (res?.status === 200) {
        successCallback(res.data.message);
        // dispatch({
        //   type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        //   payload: [...allData.filter((r) => r.id !== id), data],
        // });
      } else {
        errorCallback(res.data.message);
      }
    } catch (error) {
      console.log('updateTestinomial error', error);

      errorCallback(error?.response?.data?.error);
    } finally {
      dispatch(loading(false));
    }
  };
};
export const deleteLocalTestinomial =
  (id, local_id, allData) => async (dispatch) => {
    console.log('deleteLocalTestinomial', id, allData);
    if (local_id) {
      dispatch({
        type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        payload: [...allData.filter((r) => r.local_id !== local_id)],
      });
    } else {
      dispatch({
        type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        payload: [...allData.filter((r) => r.id !== id)],
      });
    }
    dispatch({
      type: SET_DELETED_TESTIMONIAL_WEBSITE_DATA,
      payload: id,
    });
  };
export const deleteTestinomial = (
  id,
  // allData
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await deleteTetimonialApi(id);
      if (res?.status === 200) {
        successCallback(res);
        // dispatch({
        //   type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        //   payload: [...allData.filter((r) => r.id !== id)],
        // });
      } else {
        errorCallback(res);
      }
    } catch (error) {
      errorCallback(error);
    }
  };
};

export const getTestinomial = (
  id,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await getTetimonialById(id);
      console.log('getTestinomial1233', res);
      if (res?.status === 200) {
        successCallback(res);
        dispatch({
          type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
          payload: res.data.data,
        });
      } else {
        errorCallback(res);
        dispatch({ type: SET_ALL_TESTIMONIAL_WEBSITE_DATA, payload: [] });
      }
    } catch (error) {
      errorCallback(error);
      dispatch({ type: SET_ALL_TESTIMONIAL_WEBSITE_DATA, payload: [] });
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getUserProduct = (
  id,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await getProductsByRegistration(id);
      console.log('getUserProduct1222', res);
      if (res?.status === 200) {
        successCallback(res.data.data);
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: res.data.data,
        });
      } else {
        errorCallback(res);
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: [],
        });
      }
    } catch (error) {
      errorCallback(error);
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: [],
      });
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getSellerWebsiteData = (
  id,
  productData,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await getSellerWebsiteDataByRegistration(id);
      if (res?.status === 200) {
        console.log(
          'getSellerWebsiteData11112333222',
          res.data.data[0]?.url,
          id,
          productData
        );
        const productIds = res.data.data[0].product_ids
          .split(',')
          .map((id) => id.trim());

        // Update the product data based on the response
        const updatedProductData = productData.map((product) => {
          if (productIds.includes(product.id.toString())) {
            return {
              ...product,
              checked: true,
            };
          }
          return product;
        });

        console.log('updatedProductData122', updatedProductData);

        // Trigger the success callback and dispatch actions
        successCallback(res);
        dispatch({
          type: SET_MANAGE_WEBSITE_DATA,
          payload: { domain: res.data.data[0].domain },
        });
        dispatch({
          type: SET_MANAGE_WEBSITE_DATA,
          payload: { subDomainUrl: res.data.data[0].subdomain },
        });
        dispatch({
          type: SET_MANAGE_WEBSITE_DATA,
          payload: { products: updatedProductData },
        });
        dispatch({
          type: SET_MANAGE_WEBSITE_DATA,
          payload: { id: res.data.data[0].id },
        });
        if (res.data.data[0]?.url)
          dispatch({
            type: SET_MANAGE_WEBSITE_DATA,
            payload: { url: res.data.data[0]?.url },
          });
        dispatch({ type: SET_PRODUCT_DATA, payload: updatedProductData });
        return updatedProductData;
      } else {
        errorCallback(res);
      }
    } catch (error) {
      // dispatch({ type: SET_PRODUCT_DATA, payload: productData });
      errorCallback(error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const addManageData = (
  data,
  successCallback = () => {},
  errorCallback = () => {},
  productData
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await postManagedData(data);
      console.log('addManageData1233', res);
      if (res?.status === 200) {
        successCallback(res.data.message);
        dispatch(getSellerWebsiteData(res.data?.id, productData));
        // dispatch({
        //   type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        //   payload: res.data.data,
        // });
      } else {
        errorCallback(res?.data?.message);
        // dispatch({ type: SET_ALL_TESTIMONIAL_WEBSITE_DATA, payload: [] });
      }
    } catch (error) {
      console.log('addManageData error', error);

      errorCallback(error?.response?.data?.error);
      // dispatch({ type: SET_ALL_TESTIMONIAL_WEBSITE_DATA, payload: [] });
    } finally {
      dispatch(loading(false));
    }
  };
};

export const updateManageData = (
  data,
  id,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch(loading(true));
    try {
      const res = await updateManagedDataApi(data, id);
      console.log('updateManageData123', res);
      if (res?.status === 200) {
        successCallback(res.data.message);

        // dispatch({
        //   type: SET_ALL_TESTIMONIAL_WEBSITE_DATA,
        //   payload: res.data.data,
        // });
      } else {
        errorCallback(res?.data?.message);
        // dispatch({ type: SET_ALL_TESTIMONIAL_WEBSITE_DATA, payload: [] });
      }
    } catch (error) {
      console.log('addManageData error', error);
      errorCallback(error?.response?.data?.error);
      // dispatch({ type: SET_ALL_TESTIMONIAL_WEBSITE_DATA, payload: [] });
    } finally {
      dispatch(loading(false));
    }
  };
};

export const resetManageWesiteData = () => async (dispatch) =>
  dispatch({ type: RESET_MANAGED_WEBSITE_DATA });
