import { SHOW_TOOLS_DATA } from "../types"

const initialState = {
    tools: []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case SHOW_TOOLS_DATA:
            return {
                ...state,
                tools: action.payload
            }

        default:
            return state
    }
}