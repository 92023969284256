import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  CardMedia,
  Box,
  Snackbar,
  Alert,
  List,
  ListItem,
  Link,
  FormControl,
  OutlinedInput,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter'; // For X
import { createTheme, ThemeProvider } from '@mui/material/styles';
import elynker_default_image from '../../assets/elynker_default_image.jpg';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-white-logo.svg';
import e_business_bg from '../../assets/elynker_app_icon.svg';
import responsive from '../../utils/responsive';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import Divider from '@mui/material/Divider';
// import InputLabel from '@mui/material/InputLabel';
// import TextField from '@mui/material/TextField';
import { getProductCategories } from '../OfferedServices/redux/actions/offered-services-form-action';
import {
  setSelectedVCategory,
  setSelectedVCategoryUUID,
} from '../FLFreelance/redux/actions/fl-business-action';

// import Autocomplete from '@mui/material/Autocomplete';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as UserIcon } from '../../assets/user-white-icon.svg';
import { ReactComponent as ShopIcon } from '../../assets/shop-icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/profile-icon.svg';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ReactComponent as MoreIcon } from '../../assets/more-icon.svg';
import { ReactComponent as MoreBlueIcon } from '../../assets/more-blue-icon.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
// import CircleIcon from '@mui/icons-material/Circle';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getAllProducts } from '../OfferedServices/redux/actions/offered-services-form-action';
import { ReactComponent as QuotesIcon } from '../../assets/quotes-icon.svg';
// import CircularProgress from '@mui/material/CircularProgress';
import GradientCircularProgress from '../../components/ProgressCircle/ProgressCircle';
// import { ReactComponent as InsightsIcon } from '../../assets/insights-icon.svg';
// import { ReactComponent as InsightsGainArrowIcon } from '../../assets/insight-gain-arrow.svg';
import { ReactComponent as PersonBanner } from '../../assets/mobile-homepage-person-banner.svg';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { getUpdatedRegistrationData } from '../Profile/redux/actions/user-profile-actions';
import {
  getCurrentProfileProgress,
  getFLCurrentProfileProgress,
} from '../../utils/percent';
import { connect, useDispatch } from 'react-redux';
import { getCertificateData } from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { getBannerData } from '../FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import { getAllService } from '../FreeLancer/FL-UploadServiceCatalog/redux/actions/fl-upload-service-action';
import { getResumeData } from '../FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
import { ReactComponent as BusinessServiceImg } from '../../assets/business-service-img.svg';
import WebHomepage from './WebHomepage';
import {
  BUSINESS,
  FREELANCER,
  // BUSINESS_CATEGORY_SECTION,
  // CARD_BANNER_SECTION,
  // ELYNKER_COMPLETION_SECTION,
  // ELYNKER_SERVICES_SECTION,
  // ELYNKER_TOOLS_SECTION,
  HOME_PAGE_FL_COUNT,
  // FREELANCER_CATEGORY_SECTION,
  // homePageWidgetsIdx,
  // CARDS,
  // TILES,
  ELYNKER_COMPLETION,
  FREELANCER_CATEGORY,
  // ELYNKER_SERVICES,
  // FREELANCER_CATEGORY,
  BUSINESS_CATEGORY,
  // CARDS,
  ELYNKER_TOOLS,
  // TILES,
  // ELYNKER_TOOLS,
  // CARDS,
  // TILES,
  // TILES,
  HOME_SEARCH,
  // CREDIT_SERVICES,
  TERMS,
  PRIVACY_POLICY,
  JOBLIST,
  T_AND_C_SLUG,
  PRIV_POL_SLUG,
  SHIPPING_DELIVERY,
  CANCELLATION_REFUND,
  CONTACT_US,
  SHIP_DEL_SLUG,
  CAN_REF_SLUG,
  CON_US_SLUG,
  POSTJOB,
  POSTJOB_TITLE,
  POSTJOB_SUBTITLE,
  POSTJOB_BUTTON,
  POSTJOB_REDIRECT,
  DIALOG_TITLE,
  DIALOG_NOT_LOGIN_MSG,
  B2C_ID,
  FREELANCER_ID,
  BUSINESS_ID,
  HOMEPAGE,
  CLICKED,
  SEO_HOMEPAGE_TITLE,
  SEO_HOMEPAGE_URL,
  SEO_HOMEPAGE_DESC,
  SEO_FAV_ICON_URL,
  SEO_HOMEPAGE_KEYWORDS,
  HOME_PREREGISTER_MODAL,
} from '../../utils/constants';
import {
  CATEGORY_LOGO_BASE_URL,
  WIDGET_IMAGE_BASE_URL,
} from '../../utils/APIs/api';
import ImageProfile from '../../components/CustomImageView/ImgProfile';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { getHomepageWidgetDetails } from './redux/actions/homepage-action';
import { array, number, object } from 'prop-types';
import {
  getVendorDetails,
  resetAllVendorData,
} from '../B2BCategory/redux/actions/b2b-category-action';
import { getElynkerToolsData } from './redux/actions/homepage-tools-action';
import { ReactComponent as ViewMoreIcon } from '../../assets/double-right-arrow-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';

// import packageJson from '../../../package.json';
import {
  resetSearchVendors,
  searchVendors,
} from '../Search/redux/actions/search-action';
import { setSearchFrom } from '../../store/actions/rootActions';
import { saveChildWidget } from '../CreditVASLanding/redux/actions/credit-landing-action';
// import { setToolDataSerices } from '../ElynkerServices/redux/actions/elynker-tools-action';
import {
  resetTemplateData,
  setTemplateRender,
} from '../Templates/redux/action.js/template-action';
import { setChildWidgetData } from '../PersonalLoanLanding/redux/actions/personal-service-action';
import { getAllJobs } from '../JobPosted/redux/actions/create-jobposted-action';
import { saveJobSearchText } from '../JobListing/redux/actions/job-listing-action';
import { getHomepageJobs } from './redux/actions/homepage-job-actions';
// import { connect } from 'react-redux';
import { saveJobData } from '../JobPostDetail/redux/actions/job-post-details-action';
import { setJobID } from '../JobPostApply/redux/actions/job-post-apply-action';
import { ConfirmationDialog } from '../../components/ActionPopup';
import { GAEvents, sendDefaultPageEvent } from '../../utils/helper-funtions';
import PreRegisterModal from './PreRegisterModal';
import { sendSignupData } from '../CompleteProfile/redux/actions/start-connecting-action';
import { SET_ENQUIRY } from '../B2BCategory/redux/types';
import { Helmet } from 'react-helmet';
import { getUpdatedUserData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
// import CryptoJS from 'crypto-js';

// import Responsive from '../../utils/responsive';
// import { ReactComponent as FacebookLogo } from '../../assets/facebook.svg';
// import { ReactComponent as InstagramLogo } from '../../assets/instagram.svg';
// import { ReactComponent as WhatsappLogo } from '../../assets/whatsapp.svg';
// import { ReactComponent as TelegramLogo } from '../../assets/telegram.svg';
// import { LinkedIn, Reddit, Twitter } from '@mui/icons-material';
const theme = createTheme();
const categoryText =
  "Grow Your Business, Multiply Your Profits"
const StyledImageContainer = styled('div')({
  border: '1px solid #E3E3E3',
  padding: '10px',
  textAlign: 'center',
  borderRadius: '8px',
  width: '60px',
});
const HomepageForm = ({
  getResumeData,
  getBannerData,
  getAllService,
  getAllProducts,
  getCertificateData,
  getProductCategories,
  getAllJobs,
  getUpdatedRegistrationData,
  getHomepageJobs,
  getHomepageWidgetDetails,
  getElynkerToolsData,
  saveChildWidget,
  setChildWidgetData,
  searchVendors,
  setSearchFrom,
  setTemplateRender,
  saveJobSearchText,
  resetAllVendorData,
  setSelectedVCategory,
  // getVendorDetails,
  resetSearchVendors,
  userData,
  businessDocuments,
  socialLinks,
  allProducts,
  flancerLvl1Categories,
  b2bLvl1Categories,
  registrationData,
  homepageJobsActive,
  resumeData,
  projects,
  allServices,
  certificates,
  widgets,
  tools,
  searchVendor,
  // allJobs,
  saveJobData,
  setJobID,
  resetTemplateData,
  userType,
  enquiry,
  waEnquiry,
  setSelectedVCategoryUUID,
  sendSignupData,
  getUpdatedUserData,
}) => {
  const history = useHistory();
  const urlLocation = useLocation();
  const [sbOpen, setSBOpen] = React.useState(false);
  // const [searchTerm, setSearchTerm] = useState('');
  const [profileProgress, setProfileProgress] = useState(0);
  const [userStatus, setUserStatus] = React.useState('Complete Your Profile');
  const [searchOptions, setSearchOptions] = useState([]);
  // const secretKey = process.env.REACT_APP_HOMEURL_PARAM_SECRET;
  /* eslint-disable-next-line no-unused-vars */
  const [width, setWidth] = useState(window.innerWidth);
  // Vendor SearchBox States
  const [vendorSearchVal, setVendorSearchVal] = useState('');
  const [open, setOpen] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [openPreRegisterModal, setOpenPreRegisterModal] = useState(false);

  const queryParams = new URLSearchParams(urlLocation.search);
  console.log('queryParams : ', queryParams);
  // const deNameParam = decodeURIComponent(queryParams.get('name')); // null if params not present in url
  // const deMobileNoParam = decodeURIComponent(queryParams.get('mobile'));
  // const deEmailParam = decodeURIComponent(queryParams.get('email'));
  // const deTypeParam = decodeURIComponent(queryParams.get('type'));

  const nameParam = decodeURIComponent(queryParams.get('name')); // null if params not present in url
  const mobileNoParam = decodeURIComponent(queryParams.get('mobile'));
  const emailParam = decodeURIComponent(queryParams.get('email'));
  const typeParam = decodeURIComponent(queryParams.get('type'));

  const [openSuccCallModal, setOpenSuccCallModal] = useState(false);
  const handleOpenSuccCallModal = () => setOpenSuccCallModal(true);
  const handleCloseSuccCallModal = () => setOpenSuccCallModal(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  //remove back button
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      history.go(1);
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [history]);

  let registration_type, status;
  if (registrationData) {
    ({ registration_type, status } = registrationData);
  }
  React.useEffect(() => {
    // console.log('userData1211', userData);
    if (userData?.user?.id) getUpdatedUserData(userData?.user?.id);
  }, []);
  useEffect(() => {
    if (queryParams?.size >= 1) {
      setOpenPreRegisterModal(true);
    }
    sendDefaultPageEvent(
      userData,
      'Home Page',
      window.location.href,
      window.location.pathname
    );
    // sendPageData('Home Page', window.location.href, window.location.pathname);
    // ReactGA.send({
    //   hitType: 'pageview',
    //   page: '/',
    //   category: 'page_home',
    //   title: 'Mobile Home Page Open',
    // });
    if (
      userData?.registration.registration_type == BUSINESS_ID &&
      enquiry &&
      enquiry?.isTrue
    ) {
      history.push(enquiry?.redirect_to);
    }
    if (userData && waEnquiry && waEnquiry.isTrue) {
      history.push(waEnquiry?.redirect_to + waEnquiry?.vendor_slug);
    }
  }, []);

  useEffect(() => {
    getUpdatedRegistrationData(userData?.user?.id, userData, pushMessage);
    getHomepageJobs();
    getProductCategories(FREELANCER);
    getHomepageWidgetDetails();
    getElynkerToolsData();
    getResumeData(userData?.registration?.id);
    getBannerData(userData?.registration?.id);
    getAllService(userData?.registration?.id);
    getAllProducts(userData?.registration?.id);
    getCertificateData(userData?.registration?.id);
    getProductCategories(BUSINESS);
    getAllJobs();
    // dispatch(setToolDataSerices());
    const loggedInUserData = localStorage.getItem('user_loggedIn');
    if (status) setUserStatus(status);
    if (userData?.registration?.registration_type != 1) {
      if (loggedInUserData && userData?.registration?.status == 'pending') {
        setSBOpen(true);
      } else {
        setSBOpen(false);
      }
    }
  }, []);

  // const clearHistory = () => {
  //   // history.replace('/homepage');
  //   history.go(0);
  // };
  // clearHistory();

  const handleViewTools = () => {
    history.push('/elynker-services');
  };

  // Function to handle window resizing
  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  // Adding event listener for resize on component mount and cleanup on unmount
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if the device width is mobile or desktop
  // const isMobile = width < 768;  // Adjust based on your needs (e.g., 768px for mobile)

  function startOnboardingProcess() {
    // call signup API call

    GAEvents(HOME_PREREGISTER_MODAL, 'Start Onboarding', CLICKED, {
      title: 'Start Onboarding',
      name: nameParam ? nameParam : '',
      phone: mobileNoParam ? '<mobile_no>' : '',
      email: emailParam ? '<email' : '',
      business_type: typeParam ? typeParam : '',
    });
    if (queryParams?.size > 0 && queryParams?.size < 4) {
      history.replace('/login');
      return;
    }

    let data = {
      name: nameParam,
      mobile_number: mobileNoParam,
      email: emailParam,
      registration_type: Number(typeParam),
    };
    const response = sendSignupData(data);
    response
      .then((res) => {
        if (res.statusCode === 201) {
          window.history.pushState({}, '', '/homepage');
          setOpenPreRegisterModal(false);
          if (Number(typeParam) === BUSINESS_ID) {
            history.replace('/onboarding');
          } else if (Number(typeParam) === FREELANCER_ID) {
            history.replace('/fl-onboarding');
          } else if (Number(typeParam) === B2C_ID) {
            history.replace('/homepage');
          }
        }
        if (res.statusCode === 400) {
          // pushMessage(res?.data?.message);
          setErrorMsg('You are already registered, Please Login.');
          handleOpenSuccCallModal();
        }
      })
      .catch((error) => {
        if (error.statusCode === 400) {
          // pushMessage(res?.data?.message);
          setErrorMsg('You are already registered, Please Login.');
          handleOpenSuccCallModal();
        }
        if (error.statusCode === 404) {
          history.push('/complete-profile');
        }
      });
  }

  function startSubscritptionProcess(e) {
    e.preventDefault();
    GAEvents(HOME_PREREGISTER_MODAL, 'Explore our packages', CLICKED, {
      title: 'Explore our packages',
      name: nameParam ? nameParam : '',
      phone: mobileNoParam ? '<mobile_no>' : '',
      email: emailParam ? '<email' : '',
      business_type: typeParam ? typeParam : '',
    });

    if (
      queryParams?.size > 0 &&
      queryParams?.size < 4 &&
      typeParam !== 'null'
    ) {
      history.replace('/login');
      return;
    }
    // call signup API call
    let data = {
      name: nameParam,
      mobile_number: mobileNoParam,
      email: emailParam,
      registration_type: Number(typeParam),
    };
    const response = sendSignupData(data);
    response
      .then((res) => {
        if (res.statusCode === 201) {
          window.history.pushState({}, '', '/homepage');
          setOpenPreRegisterModal(false);
          history.replace('/subscription');
          // {isMobile ? history.replace('/subscription-new') :
          //  history.replace('/web-subscription-new')}
        }
        if (res.statusCode === 400) {
          // pushMessage(res?.data?.message);
          dispatch({
            type: SET_ENQUIRY,
            payload: {
              isTrue: true,
              redirect_to: '/subscription',
            },
          });
          history.replace('/subscription');
          // setErrorMsg('You are already registered, Please Login.');
          handleOpenSuccCallModal();
        }
      })
      .catch((error) => {
        if (error.statusCode === 400) {
          // pushMessage(res?.data?.message);
          // setErrorMsg('You are already registered, Please Login.');
          history.replace('/subscription');
          handleOpenSuccCallModal();
        }
        if (error.statusCode === 404) {
          // setErrorMsg(error?.message);
          handleOpenSuccCallModal();
        }
      });
  }

  useEffect(() => {
    // dispatch(getTopLevelProductCategoriesByUser(userData?.registration?.id, pushMessage));
    // setProfileProgress(getCurrentProfileProgress());

    if (userData?.registration?.registration_type == 2) {
      let progress = getCurrentProfileProgress(
        userData?.registration,
        allProducts,
        businessDocuments,
        socialLinks
      );
      setProfileProgress(Math.round(progress));
    }

    if (userData?.registration?.registration_type == 3) {
      let flProgress = getFLCurrentProfileProgress(
        userData?.registration,
        resumeData,
        projects,
        allServices,
        certificates
      );
      setProfileProgress(Math.round(flProgress));
    }
  }, [resumeData, projects, allServices, certificates]);

  // const handleSearchChange = (event, value) => {
  //   // setSearchTerm(value);
  //   // You can perform your search logic here, such as making an API call
  //   // to fetch search results based on the entered value.
  //   dispatch(saveSearchText(value));
  //   dispatch(searchVendors(value));
  //   history.push('/search');
  // };

  // const handleInputChange = (e, v) => {
  //   // setSearchTerm(v);
  //   // Your autocomplete logic here, e.g., fetching suggestions based on the entered value
  //   dispatch(saveSearchText(v));
  //   dispatch(searchVendors(v));
  // };

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 0,
    boxShadow: 'none',
  }));

  const handleMultipleServices = (data) => {
    if (data.title == 'Credit Services') {
      saveChildWidget(data);
      setChildWidgetData(data?.children[1] || {});
      // history.push('/credit-vas-landing');
      history.push('/personal-loan-landing');
    } else {
      saveChildWidget(data);
      setChildWidgetData(data?.children[0]);
      history.push('/personal-loan-landing');
    }
  };

  // const getOptionLabel = (option) => option;

  // const getOptionSelected = (option, value) => option === value;

  // Slider
  // const sliderRef = useRef(null);
  // // const scrollAmount = 100;
  // const images = [
  //   {
  //     id: 1,
  //     text: 'Home cleaning',
  //     url: 'https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80',
  //   },
  //   {
  //     id: 2,
  //     text: 'Painters',
  //     url: 'https://source.unsplash.com/300x300/?west-australia',
  //   },
  //   {
  //     id: 3,
  //     text: 'Home cleaning',
  //     url: 'https://source.unsplash.com/300x300/?perth',
  //   },
  //   {
  //     id: 4,
  //     text: 'Painters',
  //     url: 'https://source.unsplash.com/300x300/?quokka,perth',
  //   },
  //   {
  //     id: 5,
  //     text: 'Home cleaning',
  //     url: 'https://source.unsplash.com/300x300/?margaretriver,australia',
  //   },
  //   {
  //     id: 6,
  //     text: 'Painters',
  //     url: 'https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80',
  //   },
  // ];

  // const growtools = [
  //   { title: 'WhatsApp Business', image: 'https://source.unsplash.com/300x300/?margaretriver,australia' },
  //   { title: 'Google Business Profile', image: 'https://source.unsplash.com/300x300/?quokka,perth' },
  //   { title: 'Job Posting', image: 'https://source.unsplash.com/300x300/?west-australia' },
  //   { title: 'Advisory Service', image: 'https://source.unsplash.com/300x300/?west-australia' },
  //   { title: 'Concierge Service', image: 'https://source.unsplash.com/300x300/?quokka,perth' },
  //   { title: 'Digital Catalog', image: 'https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80' },
  // ];

  // Slider

  // Carousel
  const carouselItems = [
    {
      id: 1,
      paragraph:
        "I wanted to take a moment to commend you on the fantastic job you did on explaining the products and made me understand ' What is Elynker is all about' . Your dedication, attention to detail, and excellent execution were truly impressive.Keep up the great work! Your efforts do not go unnoticed and are highly appreciated.",
      text1: 'Shailesh Dhuri',
      text2: 'Mumbai',
    },
    {
      id: 2,
      paragraph:
        'Hi Elynker Team, \n I had an excellent experience with your service and product. The quality and customer support were outstanding. Keep up the great work!',
      text1: 'Kunal Pandey',
      text2: 'Jharkhand',
    },
    {
      id: 3,
      paragraph:
        'I am completely satisfied with my experience with Elynker. The product quality, service, and customer care executives were all excellent. They were really helpful in creating the entire profile for my Business. Thank you!',
      text1: 'Ashwin Deshpande',
      text2: 'Pune',
    },
    // Add more items as needed
  ];

  const handleVendorSearchInput = (e) => {
    e.preventDefault();
    if (!open) {
      setOpen(true);
    }
    setVendorSearchVal(e.target.value);
    searchVendors(e.target.value);
  };

  const handleClick = (e) => {
    // setOpen(!open);
    e.preventDefault();
    setSearchFrom(HOME_SEARCH);
    history.push('/search');
  };

  useEffect(() => {
    const vCardList = searchVendor?.map((item) => {
      item['card_type'] = 'VendorCard';
      return item;
    });
    if (vCardList !== undefined && vCardList?.length > 0) {
      setSearchOptions(vCardList);
    } else {
      setSearchOptions([]);
    }

    return () => { };
  }, [searchVendor]);
  // Carousel
  // useEffect(() => {
  //   // var newData = [];
  //   const vpList = searchVendor?.map((item) => {
  //     let vp = item?.vendor_products?.map((p) => {
  //       let splicedata = p.split('::')[0];
  //       // return {"label": splicedata, "value": splicedata};
  //       return splicedata;
  //     });
  //     return vp;
  //     // newData.concate(vp);
  //     // return obj;
  //   });
  //   // let data = vpList?.flat(1) || [];

  //   var flat = [];

  //   for (let i = 0; i < vpList.length; i++) {

  //     flat = flat.concat(vpList[i]);
  //   }

  //   function isempty(x) {
  //     if (x !== '') return true;
  //   }
  //   var resData = flat.filter(isempty);
  //   var res = resData.filter(function (element) {
  //     return element !== undefined;
  //   });

  //   if (res !== undefined && res !== null) {
  //     setSearchOptions(res);
  //   } else {
  //     setSearchOptions([]);
  //   }
  // }, [searchVendor]);

  // const handleClickOnDetail = (data) => {
  //   // setToolDataSerices(data);
  //   dispatch(setToolDataSerices(data));
  //   history.push("/gbp-landing")
  // }

  // const handleOpenPreRegisterModal = () => {
  //   setOpenPreRegisterModal(true);
  // };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleShippingDelivery = () => {
    setTemplateRender(SHIPPING_DELIVERY);
    history.push(`/${SHIP_DEL_SLUG}`);
    resetTemplateData();
  };

  const handleTerms = () => {
    setTemplateRender(TERMS);
    history.push(`/${T_AND_C_SLUG}`);
    resetTemplateData();
  };

  const handlePrivacy = () => {
    setTemplateRender(PRIVACY_POLICY);
    history.push(`/${PRIV_POL_SLUG}`);
    resetTemplateData();
  };

  const handleCancellationRefund = () => {
    setTemplateRender(CANCELLATION_REFUND);
    history.push(`/${CAN_REF_SLUG}`);
    resetTemplateData();
  };

  const handleContactUs = () => {
    setTemplateRender(CONTACT_US);
    history.push(`/${CON_US_SLUG}`);
    resetTemplateData();
  };

  const handleCloseSnackBar = (event, reason) => {
    // localStorage.clear();
    localStorage.removeItem('user_loggedIn');
    if (reason === 'clickaway') {
      return;
    }

    setSBOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function list_to_tree(list) {
    let map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent_id !== null) {
        // if you have dangling branches check that map[node.parent_id] exists
        list[map[node.parent_id]]?.children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  useEffect(() => {
    const services = tools
      ?.filter((itm) => itm?.service_type === 'Service')
      ?.map((itm) => {
        if (itm.parent_id == 0) {
          return { ...itm, parent_id: null };
        } else {
          return itm;
        }
      });
    if (services.length > 0) {
      const servicesTree = list_to_tree(services);
      setServicesData(servicesTree);
    }
  }, [tools]);

  const CreditServices = ({ itm, idx }) => {
    console.log(itm, idx);
    return (
      <>
        <Grid
          container
          columnSpacing={3}
          justifyContent={'center'}
          className="mt-4"
        >
          {servicesData?.map((service_parent) => (
            <Grid item xs={12} md={5} key={service_parent?.id}>
              <Card className="credit-services-card test-card mb-3">
                <CardContent
                  className={
                    service_parent?.title == 'Credit Services'
                      ? 'credit-services-content'
                      : service_parent?.title == 'Social Media Marketing'
                        ? 'social-media-services-content'
                        : 'other-services-content'
                  }
                >
                  {/* <div className="credit-service-content-tint" /> */}
                  <div style={{ zIndex: 2, position: 'relative' }}>
                    <Typography
                      variant="h5"
                      className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                      component="div"
                    >
                      {service_parent?.title}
                      {/* Credit Services */}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="fs-11 text-white mb-3 font-gotham"
                    // component="div"
                    >
                      {service_parent?.subtitles}
                      {/* Credit Services */}
                    </Typography>
                    {/* <Typography
                    variant="body2"
                    className="fs-14 text-white mb-3 font-gotham"
                  >
                    {service_parent?.children.map(
                      (child_data) => child_data.title + ' '
                    )} */}
                    {/* {service_parent?.children[0]?.title} */}
                    {/* Personal Loans, Invoice Crediting, <br></br>
              Business Loans, etc. */}
                    {/* </Typography> */}
                    <Button
                      variant="contained"
                      className="learn-more-btn"
                      onClick={() => {
                        handleMultipleServices(service_parent);
                      }}
                    >
                      Learn More
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* <Card className="digital-marketing-card mb-3">
          <CardContent className="digital-marketing-content">
            <Typography
              variant="h5"
              className="fs-16 text-white mb-2 fw-bold font-skmodernist"
              component="div"
            >
              Digital Marketing
            </Typography>
            <Typography
              variant="body2"
              className="fs-14 text-white mb-3 font-gotham"
            >
              Graphic Design, Social Media <br></br>
              Management, SEO, etc.
            </Typography>
            <Button variant="contained" className="learn-more-btn">
              Learn More
            </Button>
          </CardContent>
        </Card> */}
      </>
    );
  };

  const CreditServicesTemp = ({ itm, idx }) => {
    console.log(itm, idx);
    const cardArray = [
      {
        id: 1,
        title: 'eSsential',
        subtitles:
          'Get ready to unlock the essentials—our upcoming package will power your business like never before.',
        buttontext: 'Coming Soon!',
      },
      {
        id: 2,
        title: 'eManager',
        subtitles:
          'Streamline your operations and drive growth with our expert business management services.',
        buttontext: 'Coming Soon!',
      },
      {
        id: 3,
        title: 'Free Digital Portfolio',
        subtitles:
          'Get your business online with a free digital portfolio, now with estarter.',
        buttontext: 'Get Started',
      },
    ];
    return (
      <>
        <Grid
          container
          columnSpacing={3}
          justifyContent={'center'}
          className="mt-4"
        >
          {cardArray?.map((service_parent) => (
            <Grid item xs={12} md={5} key={service_parent?.id}>
              <Card className="essential-services-card test-card mb-3">
                <CardContent
                  className={
                    service_parent?.title == 'eSsential' ||
                      service_parent?.title == 'eManager'
                      ? 'esential-services-content'
                      : 'freeportfolio-media-services-content'
                  }
                >
                  {/* <div className="credit-service-content-tint" /> */}
                  <div style={{ zIndex: 2, position: 'relative' }}>
                    <Typography
                      variant="h5"
                      className="fs-20 text-white mb-2 fw-bold font-skmodernist"
                      component="div"
                    >
                      {service_parent?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="fs-13 text-white mb-3 font-gotham"
                    // component="div"
                    >
                      {service_parent?.subtitles}
                    </Typography>

                    {service_parent?.buttontext == 'Get Started' ? (
                      <Button
                        variant="contained"
                        className="learn-more-btn"
                        onClick={() => {
                          GAEvents(HOMEPAGE, 'view_banner', CLICKED, {
                            banner_id: service_parent.id,
                            banner_name: service_parent.title,
                          });

                          history.push('/subscription');
                        }}
                      >
                        {service_parent?.buttontext}
                      </Button>
                    ) : (
                      <Typography
                        variant="body2"
                        className="fs-13 text-white mb-3 font-gotham"
                      // component="div"
                      >
                        {service_parent?.buttontext}
                      </Typography>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };
  const JobsList = ({ itm, idx }) => {
    console.log(itm, idx);
    return (
      <>
        <Typography
          variant="h4"
          align="left"
          alignItems="center"
          className="fs-20 text-black mb-2 fw-bold font-skmodernist"
        >
          Jobs Near You
        </Typography>
        <List>
          {homepageJobsActive.slice(0, 2).map((item) => (
            <ListItem key={item.id} className="list-item">
              <Card
                className="card d-flex"
                onClick={() => {
                  saveJobData(item);
                  setJobID(item?.id);
                  history.push('/job-detail', {
                    from: '/homepage',
                    to: '/job-detail',
                  });
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: '102px',
                    height: '92px',
                    borderRadius: '8px',
                  }}
                  image={
                    item?.categories[0]?.image_path
                      ? CATEGORY_LOGO_BASE_URL + item?.categories[0]?.image_path
                      : elynker_default_image
                  }
                  alt={item?.title}
                // onClick={() => { handleClickOnDetail(tool) }}
                />
                <CardContent className="content">
                  <div className="d-flex justify-content-between align-items-center">
                    <Typography
                      variant="h5"
                      className="fs-16 text-black mb-2 fw-bold font-skmodernist"
                      component="div"
                    >
                      {item.title}
                    </Typography>
                    <div className="actions mt-0">
                      <IconButton className="cat-edit-icon p-0">
                        <RightArrowIcon />
                      </IconButton>
                    </div>
                  </div>

                  <Typography
                    variant="body2"
                    className="fs-13 text-grey mb-2 font-gotham"
                  >
                    {item.description}
                  </Typography>

                  <Typography
                    variant="h5"
                    className="fs-13 text-darkgrey mb-0 font-skmodernist"
                  >
                    Upto
                  </Typography>

                  <Typography
                    variant="h5"
                    className="fs-15 text-secondary fw-bold mb-1 font-skmodernist"
                  >
                    ₹ {item.salary_offered}/{' '}
                    <span className="fs-12 text-grey fw-normal">Month</span>
                  </Typography>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="center" mt={0}>
          <Button
            type="submit"
            // fullWidth
            // variant="contained"
            className="text-secondary font-skmodernist fw-bold fs-14 text-capitalize align-items-center"
            onClick={() => {
              saveJobSearchText('%20');
              history.push('/job-listing', {
                from: '/homepage',
                to: '/job-listing',
              });
            }}
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '1px', marginLeft: '6px' }} />
          </Button>
        </Box>

        {/* <Box display="flex" justifyContent="center" mt={2}>
          <Button
            className="text-secondary font-skmodernist fw-bold fs-14 text-capitalize align-items-center"
            href="#view-more"
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '3px', marginLeft: '6px' }} />
          </Button>
        </Box> */}
      </>
    );
  };

  const ElynkerCompletion = ({ itm, idx }) => {
    console.log(itm, idx);
    return (
      <>
        {userData != null && registration_type !== B2C_ID && (
          <Card className="complete-profile-card">
            <CardContent className="complete-profile-content">
              {profileProgress !== 100 && (
                <div style={{ position: 'relative', width: 90, height: 90 }}>
                  <GradientCircularProgress value={profileProgress} size={90} />
                  <Typography
                    variant="caption"
                    className="progress-percentage fs-16 text-darkgrey fw-bold font-skmodernist"
                  >
                    {profileProgress}%
                  </Typography>
                </div>
              )}
              <div className="ms-4">
                <Typography
                  variant="body2"
                  className="fs-16 text-darkgrey fw-bold mb-3 font-skmodernist"
                >
                  {profileProgress !== 100
                    ? userStatus
                      ? 'Profile ' + registrationData?.status
                      : 'Profile Completed'
                    : 'View profile details'}
                </Typography>
                <Button
                  variant="contained"
                  className="complete-profile-btn"
                  onClick={() => {
                    {
                      profileProgress !== 100
                        ? userData?.registration?.registration_type ==
                          BUSINESS_ID
                          ? history.push('/update-profile')
                          : userData?.registration?.registration_type ==
                            FREELANCER_ID
                            ? history.push('/fl-update-profile')
                            : null
                        : history.push('/profile');
                    }
                  }}
                >
                  {profileProgress !== 100
                    ? 'Complete Your Profile'
                    : 'Dashboard'}
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
        {/* 
        {userData !== null && registration_type !== 1 ? (
          profileProgress == 100 ? (
           <Card className="insights-card" key={`B${itm?.id}-${idx}`}>
               <CardContent className="insights-content">
                <div className="d-flex justify-content-between">
                   <div>
                    <Typography
                      variant="h6"
                      className=" fs-15 text-darkgrey fw-normal mb-2 font-skmodernist"
                    >
                      Your Insights
                    </Typography>
                    <Typography
                      variant="h4"
                      className=" fs-24 text-secondary fw-normal mb-2 font-skmodernist"
                    >
                      10 Connects
                    </Typography>
                  </div> 
                  <div>
                    <InsightsIcon />
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="d-flex align-items-center">
                    <InsightsGainArrowIcon />
                    <Typography
                      variant="h4"
                      className="fs-14 text-gain ms-1 fw-normal font-gotham"
                    >
                      100%
                    </Typography>
                  </div>
                  <Typography
                    variant="h4"
                    className="fs-14 text-connects ms-4 fw-normal font-gotham"
                  >
                    Connect Made
                  </Typography>
                </div>
								<Button variant="contained" className="complete-profile-btn">
									See your dashboard
								</Button>
							</CardContent> 
						</Card> 
					) : (
						<></>
					)
				) : (
					<></>
				)} */}
      </>
    );
  };

  const FreelancerCategory = ({ itm, idx }) => {
    return (
      <>
        <Typography
          variant="h4"
          align="left"
          alignItems="center"
          className="fs-20 text-black mb-4 fw-bold font-skmodernist"
        >
          Connect with Reliable Experts for Your Projects
        </Typography>
        <Grid container spacing={1.5} key={`FL${itm?.id}-${idx}`}>
          {flancerLvl1Categories &&
            flancerLvl1Categories
              ?.slice(0, HOME_PAGE_FL_COUNT)
              .map((fCategory, k) => (
                <Grid item xs={3} key={k}>
                  <Item
                    className="d-flex flex-column align-items-center"
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledImageContainer
                      onClick={() => {
                        resetAllVendorData();
                        setSelectedVCategory(fCategory);
                        setSelectedVCategoryUUID(fCategory?.id);
                        history.push('/fl-freelance-category');
                      }}
                    >
                      <ImageProfile
                        path={CATEGORY_LOGO_BASE_URL + fCategory.image_path}
                        placeholder={e_business_bg}
                        loadError={elynker_default_image}
                        style={{
                          // marginBottom: '8px',
                          width: '40px',
                          borderRadius: '8px',
                          height: '40px',
                        }}
                        loading="lazy"
                      />
                    </StyledImageContainer>
                    <Typography className="fs-12 text-darkgrey font-gotham fw-normal mt-2">
                      {fCategory.title}
                    </Typography>
                  </Item>
                </Grid>
              ))}
          <Grid item xs={3} key={itm + idx}>
            <Item
              onClick={() => {
                history.push('/fl-freelance');
              }}
            >
              <div
                style={{
                  border: '1px solid #E3E3E3',
                  padding: '10px',
                  borderRadius: '8px',
                }}
              >
                <MoreIcon style={{ width: '40px', height: '40px' }} />
              </div>
              <Typography className="fs-12 text-darkgrey font-gotham fw-normal mt-2">
                More
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Divider
          component="div"
          className="main-divider my-3"
          role="presentation"
          key={itm + idx}
        />
      </>
    );
  };

  const BusinessCategory = ({ itm, idx }) => {
    console.log('b2bLvl1Categories', b2bLvl1Categories);

    const sortedArray = b2bLvl1Categories?.sort((a, b) => {
      if (a.rank === 0) return 1;
      if (b.rank === 0) return -1;
      return a.rank - b.rank;
    });
    return (
      <>
        <Typography
          variant="h4"
          align="left"
          alignItems="center"
          className="fs-20 text-black mb-4 fw-bold font-skmodernist"
        >
          Discover Your Ideal Business Partner for Expansion
        </Typography>
        <Grid container spacing={1.5} key={`B${itm?.id}-${idx}`}>
          {sortedArray &&
            sortedArray?.slice(0, HOME_PAGE_FL_COUNT).map((bCategory, k) => (
              <Grid item xs={3} key={k}>
                <Item
                  className="d-flex flex-column align-items-center"
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledImageContainer
                    onClick={() => {
                      const gaName = bCategory.title;
                      const gaId = bCategory.id;
                      const gaPos = bCategory.rank;

                      GAEvents(HOMEPAGE, 'Business category', CLICKED, {
                        item_name: gaName,
                        item_id: gaId,
                        item_position: gaPos,
                        list_name: 'homepage_business_categories',
                      });

                      resetAllVendorData();
                      setSelectedVCategory(bCategory);
                      setSelectedVCategoryUUID(bCategory?.id);
                      history.push('/b2b-category' + `/${bCategory?.uuid}`);
                    }}
                  >
                    <ImageProfile
                      path={CATEGORY_LOGO_BASE_URL + bCategory.image_path}
                      placeholder={e_business_bg}
                      loadError={elynker_default_image}
                      style={{
                        // marginBottom: '8px',
                        width: '40px',
                        borderRadius: '8px',
                        height: '40px',
                      }}
                      loading="lazy"
                    />
                  </StyledImageContainer>
                  <Typography className="fs-12 text-darkgrey font-gotham fw-normal mt-2">
                    {bCategory.title}
                  </Typography>
                </Item>
              </Grid>
            ))}
          <Grid item xs={3} key={itm + idx}>
            <Item
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                GAEvents(HOMEPAGE, 'Business category', CLICKED, {
                  item_name: 'More', // e.g., 'Agriculture & Farming'
                  item_id: 0,
                  item_position: -1,
                  list_name: 'homepage_business_categories',
                });
                history.push('/b2b-business');
              }}
            >
              <div>
                <MoreBlueIcon style={{ width: '60px', height: '60px' }} />
              </div>

              <Typography className="fs-12 text-darkgrey font-gotham fw-normal mt-2">
                More
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Divider
          component="div"
          className="main-divider my-3"
          role="presentation"
          key={itm + idx}
        />
      </>
    );
  };

  const ParentWidget = ({ itm }) => {
    return (
      <Card
        className={`mb-3`}
        // key={id}
        style={{
          width: '100%',
          borderRadius: '8px',
          // backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${WIDGET_IMAGE_BASE_URL + main_image || ''})`,
          backgroundColor: 'red',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white', // text color on the image
          justifyContent: 'flex-end',
          // template !== 'template1' ? 'flex-end' : 'flex-start',
          alignItems: 'flex-start',
          zIndex: 0,
        }}
      >
        <CardContent
          style={{
            textAlign: 'left',
            // template !== 'template1' ? 'right' : 'left',
          }}
        >
          <Typography
            variant="h5"
            className="fs-15 text-white mb-2 fw-bold font-skmodernist"
            component="div"
            style={{ color: 'black' }} // Change text color to black when background is white
          >
            {itm?.widget_name}
          </Typography>
          <Typography
            variant="body2"
            className="fs-14 text-white mb-3 font-gotham"
          >
            {itm?.page_name}
          </Typography>
          <Button variant="contained" className="post-requirement-btn">
            {itm?.button_text || 'Learn More'}
          </Button>
        </CardContent>
      </Card>
    );
  };

  const CardWidget = ({ itm, idx }) => {
    const { widget_details } = itm;
    return (
      <Carousel
        className="carousel-container"
        showIndicators={true}
        key={`BA1${itm?.id}-${idx}`}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        showThumbs={false}
      >
        {widget_details.map((r) => {
          const { title, sub_title, button_text, id, main_image, template } = r;
          return (
            <Card
              className={`mb-3`}
              key={id}
              style={{
                width: '100%',
                borderRadius: '8px',
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${WIDGET_IMAGE_BASE_URL + main_image
                  })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white', // text color on the image
                justifyContent:
                  template !== 'template1' ? 'flex-end' : 'flex-start',
                alignItems: 'flex-start',
                zIndex: 0,
              }}
            >
              <CardContent
                style={{
                  textAlign: template !== 'template1' ? 'right' : 'left',
                }}
              >
                <Typography
                  variant="h5"
                  className="fs-15 text-white mb-2 fw-bold font-skmodernist"
                  component="div"
                  style={{ color: 'black' }} // Change text color to black when background is white
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  className="fs-14 text-white mb-3 font-gotham"
                >
                  {sub_title}
                </Typography>
                <Button variant="contained" className="post-requirement-btn">
                  {button_text}
                </Button>
              </CardContent>
            </Card>
          );
        })}
      </Carousel>
    );
  };
  // const ElynkerServices = ({ itm, idx }) => {
  //   return (
  //     <>
  //       <Typography
  //         variant="h4"
  //         align="left"
  //         alignItems="center"
  //         className="fs-20 text-black mb-3 fw-bold font-skmodernist"
  //       >
  //         Elynker Services
  //       </Typography>
  //       <div
  //         className="elynker-services-slider mb-4"
  //         ref={sliderRef}
  //         key={`ES${itm?.id}-${idx}`}
  //       >
  //         {images.map((image) => {
  //           return (
  //             <div className="images-container" key={image?.id}>
  //               <img className="image" alt="sliderImage" src={image?.url} />
  //               <Typography className="image-text">{image?.text}</Typography>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </>
  //   );
  // };
  const ElynkerTools = () => {
    return (
      <div>
        <Typography
          variant="h4"
          align="left"
          className="fs-20 text-black mb-3 fw-bold font-skmodernist"
        >
          Get the Support You Need to Grow Your Business
        </Typography>
        <Grid container spacing={3}>
          {tools.slice(0, 6).map((tool, index) => (
            <>
              {tool?.service_type === 'Tools' && (
                <Grid item xs={4} sm={4} md={4} key={index}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: '110px',
                      height: '110px',
                      borderRadius: '8px',
                    }}
                    key={index}
                    image={tool?.cover_image}
                    alt={tool?.title}
                  // onClick={() => { handleClickOnDetail(tool) }}
                  />
                  <Typography
                    className="fs-13 text-darkgrey font-gotham mt-2"
                    align="center"
                  >
                    {tool.title}
                  </Typography>
                </Grid>
              )}
            </>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            className="text-secondary font-skmodernist fw-bold fs-14 text-capitalize align-items-center"
            onClick={() => {
              GAEvents(HOMEPAGE, 'select_business_service', {
                item_name: 'More',
                item_id: 0,
                item_position: -1,
                list_name: 'homepage_services',
              });

              handleViewTools();
            }}
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '3px', marginLeft: '6px' }} />
          </Button>
        </Box>
      </div>
    );
  };

  const ElynkerServices = ({ itm }) => {
    return (
      <div>
        <Typography
          variant="h4"
          align="left"
          className="fs-20 text-black mb-3 fw-bold font-skmodernist"
        >
          Services for Your Business to Grow
        </Typography>

        <Grid container>
          {itm &&
            itm?.map((tool, index) =>
              tool.widget_details.map((r) => (
                <StyledImageContainer
                  key={index}
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                    marginBottom: 10,
                    padding: -5,
                  }}
                >
                  <Grid item xs={4} sm={4} md={4}>
                    <CardMedia
                      component="img"
                      sx={{
                        width: '110px',
                        height: '110px',
                        borderRadius: '8px',
                      }}
                      image={WIDGET_IMAGE_BASE_URL + r?.main_image}
                      alt={r?.title}
                    />
                    <Typography
                      className="fs-13 text-darkgrey font-gotham mt-2"
                      align="center"
                    >
                      {r?.title}
                    </Typography>
                  </Grid>
                </StyledImageContainer>
              ))
            )}
        </Grid>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            className="text-secondary font-skmodernist fw-bold fs-14 text-capitalize align-items-center"
            href="#view-more"
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '3px', marginLeft: '6px' }} />
          </Button>
        </Box>
      </div>
    );
  };

  const Banner2 = ({ itm, idx }) => {
    // const { widget_name = '', page_name = '',widget_details=[] } = itm;
    const { widget_name = '', page_name = '' } = itm;
    return (
      <>
        <Card
          className="business-service-card d-flex"
          key={`B2${itm?.id}-${idx}`}
        >
          <CardContent className="business-service-content">
            <div>
              <Typography
                variant="body2"
                className="fs-14 text-white mb-2 font-gotham"
              >
                {widget_name}
              </Typography>
              <Typography
                variant="h5"
                className="fs-18 text-offwhite mb-3 fw-bold font-skmodernist"
                component="div"
              >
                {page_name}
              </Typography>
              <Button variant="contained" className="business-service-btn">
                List your Business /Service
              </Button>
            </div>
          </CardContent>
          <CardMedia sx={{ width: 160 }} key={itm + idx}>
            <BusinessServiceImg />
          </CardMedia>
        </Card>
      </>
    );
  };

  const PostJob = () => {
    return (
      <>
        <Grid
          container
          columnSpacing={3}
          justifyContent={'center'}
          className="mt-4"
        >
          <Grid item xs={12} md={5} key={1}>
            <Card className="credit-services-card test-card mb-3">
              <CardContent className="post-job-content">
                <div className="post-job-content-tint" />
                <div style={{ zIndex: 2, position: 'relative' }}>
                  <Typography
                    variant="h5"
                    className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                    component="div"
                  >
                    {POSTJOB_TITLE}
                    {/* Credit Services */}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="fs-11 text-white mb-3 font-gotham"
                  // component="div"
                  >
                    {POSTJOB_SUBTITLE}
                    {/* Credit Services */}
                  </Typography>
                  <Button
                    variant="contained"
                    className="learn-more-btn"
                    onClick={() => {
                      history.push(POSTJOB_REDIRECT);
                    }}
                  >
                    {POSTJOB_BUTTON}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  ElynkerCompletion.propTypes = {
    itm: object,
    idx: number,
  };

  FreelancerCategory.propTypes = {
    itm: object,
    idx: number,
  };

  BusinessCategory.propTypes = {
    itm: object,
    idx: number,
  };

  CardWidget.propTypes = {
    itm: object,
    idx: number,
  };

  ParentWidget.propTypes = {
    itm: object,
  };

  ElynkerServices.propTypes = {
    itm: object,
    idx: number,
  };

  ElynkerTools.propTypes = {
    itm: array,
    idx: number,
  };

  Banner2.propTypes = {
    itm: object,
    idx: number,
  };

  PostJob.propTypes = {
    itm: object,
    idx: number,
  };

  const returnRenderComponent = (widget) => {
    const widgetMapping = {
      [ELYNKER_COMPLETION]: <ElynkerCompletion />,
      [FREELANCER_CATEGORY]: <FreelancerCategory />,
      [BUSINESS_CATEGORY]: <BusinessCategory />,
      // [CREDIT_SERVICES]:
      //   userData?.registration?.registration_type !== 3 ? (
      //     <CreditServices />
      //   ) : (
      //     <></>
      //   ),
      [ELYNKER_TOOLS]:
        userData?.registration?.registration_type !== 3 ? (
          <ElynkerTools />
        ) : (
          <></>
        ),
      [JOBLIST]: <JobsList />,
      [POSTJOB]:
        userData?.registration?.registration_type !== 3 ? <PostJob /> : <></>,
    };

    if (widgetMapping[widget.widget_name]) {
      return widgetMapping[widget.widget_name];
    }
    return null;
  };

  const renderHomePageWidgets = () => {
    return [...widgets, { widget_name: 'credit services', rank: '999' }]
      ?.filter((wg) => wg?.rank)
      ?.sort((a, b) => Number(a?.rank) - Number(b?.rank))
      .map((widget) => returnRenderComponent(widget));
  };

  CreditServices.propTypes = {
    itm: object,
    idx: number,
  };

  CreditServicesTemp.propTypes = {
    itm: object,
    idx: number,
  };

  JobsList.propTypes = {
    itm: object,
    idx: number,
  };
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  const handleConfirm = () => {
    if (userType !== B2C_ID) {
      handleClose();
      history.push('/login');
    } else {
      handleClose();
      history.push('/complete-profile', {
        fromHomePage: true,
      });
    }
  };
  return responsive(
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SEO_HOMEPAGE_TITLE}</title>
        <link rel="canonical" href={SEO_HOMEPAGE_URL} />
        <meta name="description" content={SEO_HOMEPAGE_DESC} />
        <meta property="og:title" content={SEO_HOMEPAGE_URL} />
        <meta property="og:description" content={SEO_HOMEPAGE_DESC} />
        <meta property="og:url" content={SEO_HOMEPAGE_URL} />
        <meta property="og:image" content={SEO_FAV_ICON_URL} />
        <meta property="og:type" content="website" />
        <meta name="keywords" content={SEO_HOMEPAGE_KEYWORDS} />
      </Helmet>
      <Container component="main" maxWidth="xl">
        <Dialog
          open={openSuccCallModal}
          onClose={handleCloseSuccCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    dispatch({
                      type: SET_ENQUIRY,
                      payload: {},
                    });
                    handleCloseSuccCallModal();
                    // history.replace('/login');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMsg}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button
              onClick={() => {
                handleCloseSuccCallModal();
                history.replace('/login');
              }}
              className="btn-save"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <PreRegisterModal
          open={openPreRegisterModal}
          onClose={() => setOpenPreRegisterModal(false)}
          startOnboarding={startOnboardingProcess}
          startSubscription={startSubscritptionProcess}
        />
        <ConfirmationDialog
          open={openModal}
          handleClose={handleClose}
          title={DIALOG_TITLE}
          content={
            userType !== B2C_ID
              ? DIALOG_NOT_LOGIN_MSG
              : 'Please Login as Seller to use this feature'
          }
          onConfirm={handleConfirm}
          confirmText="Yes"
          cancelText="No"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="homepage-background">
              <div className="homepage-web-background-tint" />
              <div style={{ zIndex: 2, position: 'relative' }}>
                <AppBar position="static">
                  <Toolbar>
                    <div style={{ flexGrow: 1 }}>
                      <ElynkerLogoComponent>
                        <ElynkerLogo width={'100px'} height={'30px'} />
                      </ElynkerLogoComponent>
                    </div>
                    {
                      <div>
                        {!registration_type && (
                          <Button
                            onClick={() => {
                              GAEvents(
                                HOMEPAGE,
                                'Home page login button',
                                CLICKED,
                                {
                                  user_id:
                                    userData?.id ||
                                    sessionStorage.getItem('random_id'),
                                  reg_uuid:
                                    userData?.registration?.uuid ||
                                    sessionStorage.getItem('random_id'),
                                }
                              );
                              // ReactGA.event('login', {
                              //   category: 'login',
                              //   action: CLICKED,
                              //   ...{
                              //     userId: userData?.id || '',
                              //   },
                              // });
                              history.push('/login');
                            }}
                            variant="contained"
                            className="login-btn"
                          >
                            Login
                          </Button>
                        )}
                        {registration_type && (
                          <IconButton
                            size="medium"
                            aria-label="user icon"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={() => {
                              registration_type !== 1
                                ? history.push('/profile')
                                : history.push('/b2c-profile');
                            }}
                          >
                            <UserIcon />
                          </IconButton>
                        )}

                        {registration_type === 1 && (
                          <IconButton
                            size="large"
                            aria-label="user icon"
                            aria-controls="menu-appbar"
                            onClick={() =>
                              history.push('/complete-profile', {
                                fromHomePage: true,
                              })
                            }
                            color="inherit"
                            className="pe-0"
                          >
                            <ShopIcon />
                          </IconButton>
                        )}
                      </div>
                    }
                  </Toolbar>
                </AppBar>
                <Typography
                  variant="h4"
                  align="center"
                  alignItems="center"
                  className="fs-20 mt-4 text-white mb-2 fw-bold font-skmodernist"
                  gutterBottom
                  style={{ whiteSpace: 'pre-line', lineHeight: '32px' }}
                >
                  {categoryText}
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  alignItems="center"
                  className="fs-15 text-white fw-normal text-capitalize font-gotham text-center mb-4"
                  gutterBottom
                >
                  Empowering all business owners through smart solutions designed for success
                </Typography>

                <Box className="d-flex justify-content-center align-items-center" sx={{marginBottom: '34px'}}>
                  <Button

                    variant="contained"
                    className="subscribe-now-btn"
                    onClick={()=>{
                      history.push('/subscription');
                    }}
                  >
                    Subscribe Now
                  </Button>
                </Box>


                <Box className="d-flex justify-content-center align-items-center">
                  <PersonBanner />
                </Box>


                {/* <Box className="text-center mt-3">
                  <Button
                    variant="contained"
                    className="start-growing-btn"

                  >
                    Start Growing Today
                  </Button>
                </Box> */}
                <div className="">
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="subCategoryInput"
                      type="text"
                      placeholder="Search here..."
                      // required
                      // disabled={categoriesTreeFilter.length === 0}
                      fullWidth
                      disabled
                      margin="none"
                      value={vendorSearchVal}
                      // onChange={handleVendorSearchInput}
                      onTouchEnd={handleClick}
                      sx={{
                        backgroundColor: 'white',
                      }}
                      // onFocus={handleClick}
                      startAdornment={
                        <SearchIcon style={{ margin: '8px', width: '30px' }} />
                      }
                    />
                    {open && (
                      <div
                        style={{
                          position: 'absolute',
                          boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                          top: '50px',
                          background: 'white',
                          zIndex: 1,
                          borderRadius: 4,
                          width: '100%',
                        }}
                      >
                        {searchOptions?.length > 0 &&
                          searchOptions?.map((option, vnIdx) => {
                            return (
                              <Box
                                style={{
                                  marginRight: '10px',
                                  marginLeft: '10px',
                                }}
                                key={vnIdx}
                                onClick={() => {
                                  if (option?.registration_type[0] == 2) {
                                    // getVendorDetails(option.id);
                                    setSearchOptions([]);
                                    resetSearchVendors();
                                    history.push(`/b2b-detail/${option.id}`);
                                  } else {
                                    //registration_type == 2
                                    // getVendorDetails(option.id);
                                    setSearchOptions([]);
                                    resetSearchVendors();
                                    history.push(
                                      `/freelancer-detail/${option.id}`
                                    );
                                  }
                                }}
                              >
                                <div>
                                  {option?.registration_type[0] == 2
                                    ? option?.company_name
                                    : option?.name}
                                </div>
                                <div>{option?.city ? option.city : ''}</div>
                                <br />
                              </Box>
                            );
                          })}
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
            <form style={{ marginTop: '40px' }}>
              {renderHomePageWidgets()}

              {userData?.registration?.registration_type !== FREELANCER_ID ? (
                <CreditServicesTemp />
              ) : (
                <></>
              )}

              {/* <Box>
                <Grid container spacing={4} justifyContent="center" className='mt-4 mb-5'>
                  
                  <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                      <Box className="inner-box position-relative">
                        <Typography className='plan-type'>Basic</Typography>
                        <Typography className='plan-price'>Free</Typography>
                        <Typography className='plan-info'>Basic package</Typography>

                      </Box>

                      <Box className="benefits-services">
                        <ul className='package-list'>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Marketplace Listing</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Requirement posting</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Connects</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Inquiries</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Digital Business Card</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>WhatsApp Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Google Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Single Pager Website creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Digital Catalog</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Instagram profile</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Facebook profile</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>LinkedIn profile</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Dedicated Helpline</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Advisory Services</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Email Marketing</Typography>
                          </li>
                        </ul>
                      </Box>

                      <Typography className="caption">Terms & conditions apply*</Typography>

                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="get-now-btn"
                      // onClick={toggleCallBackDrawer}
                      >
                        Get Now
                      </Button>


                    </Box>
                  </Grid>

                  
                  <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                      <Box className="inner-box position-relative">
                        <Box className="d-flex justify-content-between">
                          <Typography className='plan-type'>eStarter</Typography>
                          <Typography className='offer-ribbon'>LIMITED TIME OFFER!</Typography>
                        </Box>
                        <Typography className='plan-price'>₹6,000 <span className='now-text'>Now</span> <span className='free-text'>FREE!!</span></Typography>
                        <Typography className='plan-info'>Come Experience elynker</Typography>

                      </Box>

                      <Box className="benefits-services">
                        <ul className='package-list'>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Marketplace Listing</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Requirement posting</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Connects</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Inquiries</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Digital Business Card</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>WhatsApp Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Google Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Single Pager Website creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Digital Catalog (Upto <span className='list-text-bold'>50</span> products/services)</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Instagram profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Facebook profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>LinkedIn profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Dedicated Helpline</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Advisory Services</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Email Marketing</Typography>
                          </li>
                        </ul>
                      </Box>

                      <Typography className="caption">Terms & conditions apply*</Typography>

                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="get-now-btn"
                      // onClick={toggleCallBackDrawer}
                      >
                        Get Now
                      </Button>


                    </Box>
                  </Grid>

                  
                  <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                      <Box className="inner-box position-relative">
                        <Typography className='plan-type'>eSsential</Typography>
                        <Typography className='plan-price'>₹0,00 </Typography>
                        <Typography className='plan-info'>Coming Soon!!!</Typography>

                      </Box>

                      <Box className="benefits-services">
                        <ul className='package-list'>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Marketplace Listing</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Requirement posting</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Connects</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Inquiries</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Digital Business Card</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>WhatsApp Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Google Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Single Pager Website creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Digital Catalog (Upto <span className='list-text-bold'>50</span> products/services)</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Instagram profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Facebook profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>LinkedIn profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Dedicated Helpline</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Advisory Services</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Email Marketing</Typography>
                          </li>
                        </ul>
                      </Box>

                      <Typography className="caption">Terms & conditions apply*</Typography>

                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="get-now-btn"
                      // onClick={toggleCallBackDrawer}
                      >
                        Get Now
                      </Button>


                    </Box>
                  </Grid>

                </Grid>
              </Box> */}

              <Typography
                variant="h4"
                align="left"
                alignItems="center"
                className="fs-20 text-black mb-2 fw-bold font-skmodernist"
              >
                Elynker is where your needs meet solutions.
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className="fs-14 text-gray fw-normal mb-3 font-gotham text-justify"
                gutterBottom
              >
                Elynker is your ultimate partner in transforming how Micro,
                Small, and Medium Enterprises (MSMEs) thrive in the digital
                world. We offer innovative solutions to boost your online
                presence, optimise financial management, and enhance customer
                engagement. Our comprehensive services are designed to help you
                streamline operations, reach new customers, and drive sales
                effectively. Discover how Elynker can make your path to online
                growth easier and help your business progress. With our
                personalised support, you can concentrate on what you do best
                while we assist you in establishing a strong online presence.
              </Typography>

              <Typography className="fs-16 text-dark fw-semibold mb-2 font-gotham text-justify">
                Why Choose Elynker?
              </Typography>

              <List
                className="fs-14 text-gray fw-normal mb-3 font-gotham text-justify"
                sx={{ listStyleType: 'disc', pl: 2 }}
              >
                <ListItem
                  sx={{
                    display: 'list-item',
                    padding: '0px',
                    textAlign: 'justify',
                  }}
                >
                  <ListItemText primary="Elynker makes digital transformation easy by bringing all services and solutions together in one place." />
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item',
                    padding: '0px',
                    textAlign: 'justify',
                  }}
                >
                  <ListItemText primary="We are India’s leading marketplace, offering a one-stop solution for businesses of all sizes." />
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item',
                    padding: '0px',
                    textAlign: 'justify',
                  }}
                >
                  <ListItemText primary="Providing everything you need to progress in today’s digital market." />
                </ListItem>
              </List>

              <div className="custom-accordion mobile-accordion">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                    className="fs-15 fontskmodernist text-darkgrey custom-summary"
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="What is Elynker?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker is a comprehensive platform designed to empower
                    MSMEs and B2B businesses by providing a suite of tailored
                    services. Our tools and resources help you manage your
                    digital presence and access high-quality vendors, enabling
                    you to thrive in the competitive business landscape.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="How can Elynker help my business grow?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker offers a variety of services, including digital
                    business cards, digital catalogues, website development,
                    chat engagement solutions, business listing, and advisory
                    services. By leveraging these resources, you can enhance
                    your online visibility, streamline operations, and connect
                    with potential partners and customers, ultimately driving
                    growth and success.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="What types of businesses can benefit from Elynker?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker is designed for a wide range of businesses,
                    including MSMEs, B2B enterprises, service agencies,
                    startups, and home business owners. Our platform caters to
                    various industries, providing tailored solutions that meet
                    the unique needs of each business type.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="What is the process for finding partners and suppliers on Elynker?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker facilitates connections between businesses and
                    potential partners or suppliers through our networking
                    features. You can search for partners based on your
                    industry, needs, and location, helping you forge valuable
                    relationships that drive growth and innovation.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="What digital tools does Elynker offer to support my business?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker provides a comprehensive suite of digital services,
                    including website builders, social media management
                    platforms and more. These tools are designed to enhance your
                    operational efficiency and improve your online presence.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="How can Elynker assist with compliances for MSMEs?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker works with expert agencies to connect you with legal
                    and compliance professionals who can help you understand
                    regulations. Our advisory services keep your business
                    compliant, reducing risks so you can focus on what you do
                    best.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="What kind of support can I expect from Elynker’s concierge services?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker’s concierge services provide dedicated support
                    tailored to your business needs. You will have a direct line
                    for quick solutions, personalised assistance, and access to
                    a wide range of resources, ensuring you receive the help you
                    need when you need it.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Are there financial services available through Elynker?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Yes, Elynker connects businesses with financial institutions
                    that offer tailored credit facilities, loans, and other
                    financing options. Our platform helps you navigate the
                    financial landscape to secure the funding necessary for
                    growth and investment.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    className="fs-15 fontskmodernist"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                    }}
                  >
                    <ListItemIcon
                      className="align-items-start"
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: '#1843A0',
                          width: '7px',
                          height: '7px',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="What are the costs associated with using Elynker’s services?" />
                  </AccordionSummary>
                  <AccordionDetails>
                    Elynker offers various pricing plans depending on the
                    services you choose. For detailed information on pricing and
                    packages, please visit our subscription page.
                    <br></br>
                    <Link
                      className="fs-14 font-gotham mt-2 "
                      // href="https://support.elynker.com"
                      underline="always"
                    >
                      {'support@elynker.com'}
                    </Link>
                    <Typography className="fs-14 font-gotham mt-1 text-gray">
                      +91 9820596537
                    </Typography>
                    <Typography className="fs-14 font-gotham text-gray">
                      {'Remember us with ease -  '}
                      <Link
                        component="button"
                        underline="none"
                        variant="body2"
                        color="#FFF"
                        onClick={() => {
                          window.location.href = 'tel:+919820596537';
                        }}
                      >
                        9820 LYNKER
                      </Link>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <Typography
                variant="h4"
                align="left"
                alignItems="center"
                className="fs-20 text-black mb-4 fw-bold font-skmodernist"
              >
                What Our Users Say
              </Typography>

              <Carousel
                className="carousel-container"
                showIndicators={true}
                showThumbs={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
              >
                {carouselItems.map((item) => (
                  <div className=" homepage-carousel-field" key={item.id}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <QuotesIcon
                        style={{
                          marginRight: '10px',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    </div>
                    <Typography
                      align="justify"
                      variant="body1"
                      className="fs-14 text-darkgrey fw-normal font-gotham mb-3"
                    >
                      {item.paragraph}
                    </Typography>
                    <div>
                      <Typography
                        align="left"
                        variant="body2"
                        className="fs-14 text-black fw-bold font-skmodernist mb-1"
                      >
                        {item.text1}
                      </Typography>
                      <Typography
                        align="left"
                        variant="body2"
                        className="fs-12 text-darkgrey fw-normal font-gotham"
                      >
                        {item.text2}
                      </Typography>
                    </div>
                  </div>
                ))}
              </Carousel>
            </form>
            <div
              className="homepage-mobile-footer"
              style={{ paddingTop: '25px !important' }}
            >
              <Grid container className="homepage-footer-content mb-4">
                <Box sx={{ marginTop: '4px', marginBottom: '10px' }}>
                  <Typography
                    variant="body1"
                    className="fs-20 text-white font-skmodernist fw-bold mb-4"
                  >
                    Get In Touch
                  </Typography>

                  <Link
                    className="support-link"
                    underline="always"
                    color="#FFF"
                  >
                    {'support@elynker.com'}
                  </Link>
                  <Typography className="fs-14 font-gotham text-light-white mt-2">
                    +91 9820596537
                  </Typography>
                  <Typography className="fs-14 font-gotham text-light-white">
                    {'Remember us with ease -  '}
                    <Link
                      component="button"
                      underline="none"
                      variant="body2"
                      color="#FFF"
                      onClick={() => {
                        window.location.href = 'tel:+919820596537';
                      }}
                    >
                      9820 LYNKER
                    </Link>
                  </Typography>
                </Box>

                <Box className="usefullink-container">
                  <Typography
                    variant="body1"
                    className="fs-20 text-white font-skmodernist fw-bold mb-4"
                  >
                    Useful Links
                  </Typography>

                  <Box className="link-container"></Box>
                  {/* <Box >
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={()=> {
                        GAEvents(HOMEPAGE, 'btn_shipping_delivery', 'Clicked on shipping delivery link on homepage', {
                          user_id: userData?.id || sessionStorage.getItem('random_id'),
                          reg_uuid: userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id')
                        })
                        handleShippingDelivery();
                      }}
                    >
                      Shipping Delivery
                    </Typography>
                  </Box> */}
                  <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={() => {
                        GAEvents(HOMEPAGE, 'btn_privacy_policy', CLICKED, {
                          user_id:
                            userData?.id || sessionStorage.getItem('random_id'),
                          reg_uuid:
                            userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id'),
                        });
                        handlePrivacy();
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={() => {
                        GAEvents(HOMEPAGE, 'btn_terms_of_use', CLICKED, {
                          user_id:
                            userData?.id || sessionStorage.getItem('random_id'),
                          reg_uuid:
                            userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id'),
                        });
                        handleTerms();
                      }}
                    >
                      Terms of Use
                    </Typography>
                  </Box>
                  {/* <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={()=>{
                        GAEvents('btn_cancellation_and_refund', 'btn_cancellation_and_refund', 'Clicked on cancellation and refund link on homepage', {
                          user_id: userData?.id || sessionStorage.getItem('random_id'),
                          reg_uuid: userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id')
                        });
                        handleCancellationRefund();
                      }}
                    >
                      Cancellation and Refund
                    </Typography>
                  </Box> */}
                  <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={() => {
                        GAEvents(HOMEPAGE, 'btn_contact_us', CLICKED, {
                          user_id:
                            userData?.id || sessionStorage.getItem('random_id'),
                          reg_uuid:
                            userData?.registration?.uuid ||
                            sessionStorage.getItem('random_id'),
                        });
                        handleContactUs();
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '16px', mt: 3 }}>
                    <Link
                      href="https://www.instagram.com/elynker_growth"
                      underline="none"
                      color="inherit"
                      target="_blank"
                    >
                      <InstagramIcon sx={{ color: '#FFF', fontSize: 32 }} />
                    </Link>
                    <Link
                      href="https://www.facebook.com/profile.php?id=61560971700927&mibextid=ZbWKwL"
                      underline="none"
                      color="inherit"
                      target="_blank"
                    >
                      <FacebookIcon sx={{ color: '#FFF', fontSize: 32 }} />
                    </Link>
                    <Link
                      href="https://www.linkedin.com/company/elynker/"
                      underline="none"
                      color="inherit"
                      target="_blank"
                    >
                      <LinkedInIcon sx={{ color: '#FFF', fontSize: 32 }} />
                    </Link>
                    <Link
                      href="https://x.com/Elynker_Growth"
                      underline="none"
                      color="inherit"
                      target="_blank"
                    >
                      <TwitterIcon sx={{ color: '#FFF', fontSize: 32 }} />
                    </Link>
                  </Box>
                </Box>
              </Grid>

              <Box className="d-flex justify-content-around">
                <Typography
                  className="fs-12 font-gotham text-light-white"
                  sx={{ marginTop: '30px' }}
                  onClick={() => {
                    GAEvents(HOMEPAGE, 'El_Graces_Aggregators', CLICKED, {
                      user_id:
                        userData?.id || sessionStorage.getItem('random_id'),
                      reg_uuid:
                        userData?.registration?.uuid ||
                        sessionStorage.getItem('random_id'),
                    });
                    openInNewTab('https://www.elgraces.com/');
                  }}
                >
                  © El Graces Aggregators Private Limited, 2024
                </Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={sbOpen}
        // autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          variant="filled"
          className="font-gotham"
          sx={{
            width: '100%',
            backgroundColor: '#864FFD',
            borderRadius: '6px',
            alignItems: 'center',
          }}
          action={
            <div className="profile-review-content">
              <Button
                onClick={handleCloseSnackBar}
                variant="contained"
                className="ok-btn"
              >
                OK
              </Button>
            </div>
          }
          icon={<ProfileIcon />}
        >
          Your profile is under review
        </Alert>
      </Snackbar>
    </ThemeProvider>,
    <WebHomepage
      widgets={widgets}
      b2bLvl1Categories={b2bLvl1Categories}
      registrationData={registrationData}
      flancerLvl1Categories={flancerLvl1Categories}
      BusinessCategory={BusinessCategory}
      // CreditServices={CreditServices}
      ElynkerTools={ElynkerTools}
      userStatus={userStatus}
      renderHomePageWidgets={renderHomePageWidgets}
      FreelancerCategory={FreelancerCategory}
      resetAllVendorData={resetAllVendorData}
      tools={tools}
      // handleClickOnDetail={handleClickOnDetail}
      handleViewTools={handleViewTools}
      handleVendorSearchInput={handleVendorSearchInput}
      vendorSearchVal={vendorSearchVal}
      open={open}
      handleClick={handleClick}
      searchOptions={searchOptions}
      setSearchOptions={setSearchOptions}
      resetSearchVendors={resetSearchVendors}
      carouselItems={carouselItems}
      handleTerms={handleTerms}
      handlePrivacy={handlePrivacy}
      openInNewTab={openInNewTab}
      homepageJobsActive={homepageJobsActive}
      handleShippingDelivery={handleShippingDelivery}
      handleCancellationRefund={handleCancellationRefund}
      handleContactUs={handleContactUs}
      CreditServicesTemp={CreditServicesTemp}
      openModal={openModal}
      handleClose={handleClose}
      handleOpen={handleOpen}
      handleConfirm={handleConfirm}
      userType={userType}
      enquiry={enquiry}
      openPreRegisterModal={openPreRegisterModal}
      setOpenPreRegisterModal={setOpenPreRegisterModal}
      nameParam={nameParam}
      mobileNoParam={mobileNoParam}
      emailParam={emailParam}
      typeParam={typeParam}
      startOnboarding={startOnboardingProcess}
      startSubscription={startSubscritptionProcess}
      openSuccCallModal={openSuccCallModal}
      handleCloseSuccCallModal={handleCloseSuccCallModal}
      errorMsg={errorMsg}
    />
  );
};

const mapStateToProps = ({
  signin,
  b2bOnboardStep,
  profileDetails,
  offeredServices,
  flUploadResume,
  flAddBio,
  flUploadService,
  flProfileDetails,
  homepage,
  homepageTools,
  solrSearch,
  jobListReducer,
  b2bVendorlisting,
}) => ({
  userData: signin.userData,
  businessDocuments: b2bOnboardStep.businessDocuments,
  socialLinks: profileDetails.socialLinks,
  allProducts: offeredServices.allProducts,
  flancerLvl1Categories: offeredServices.flancerLvl1Categories,
  b2bLvl1Categories: offeredServices.b2bLvl1Categories,
  registrationData: signin.userData?.registration || '',

  resumeData: flUploadResume.resumeData,
  projects: flAddBio.projects,
  allServices: flUploadService.allServices,
  certificates: flProfileDetails.certificates,
  widgets: homepage.widgets,
  homepageJobsActive: homepage.homepageJobsActive,
  tools: homepageTools.tools,
  searchVendor: solrSearch.searchVendor,
  allJobs: jobListReducer.allJobs,
  userType: signin?.userData?.registration?.registration_type,
  enquiry: b2bVendorlisting?.enquiry,
  waEnquiry: b2bVendorlisting?.waEnquiry,
});
export default connect(mapStateToProps, {
  getResumeData,
  getBannerData,
  getAllService,
  getAllProducts,
  getCertificateData,
  getProductCategories,
  getAllJobs,
  getUpdatedRegistrationData,
  getHomepageJobs,
  getHomepageWidgetDetails,
  getElynkerToolsData,
  saveChildWidget,
  setChildWidgetData,
  searchVendors,
  setSearchFrom,
  setTemplateRender,
  saveJobSearchText,
  resetAllVendorData,
  setSelectedVCategory,
  getVendorDetails,
  resetSearchVendors,
  saveJobData,
  setJobID,
  resetTemplateData,
  setSelectedVCategoryUUID,
  sendSignupData,
  getUpdatedUserData,
})(HomepageForm);
