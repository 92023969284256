import { SET_BUISNESS_OUTREACH, SET_SINGLE_BUISNESS_OUTREACH } from '../types';

const initial_state = {
  formState: {
    company_name: '',
    mobile_number: '',
    email: '',
    business_type: '2',
    uuid: '',
  },
};

const buisnessOutReachReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_BUISNESS_OUTREACH:
      return {
        ...state,
        formState: action.payload,
      };
    case SET_SINGLE_BUISNESS_OUTREACH:
      return {
        ...state,
        formState: { ...state.formState, ...action.payload },
      };
    default:
      return state;
  }
};

export default buisnessOutReachReducer;
