import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTemplateDataPage } from '../Login/redux/actions/submit-mobileno-action';
import parse from 'html-react-parser';

import '../../common/fontsize.css';
import '../../index.css';
import { Container, Grid, Typography, Box } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
// import { useMediaQuery } from 'react-responsive';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  getPageContentbySearch,
  resetTemplateData,
} from './redux/action.js/template-action';
const theme = createTheme();
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const TemplatePages = ({
  getPageContentbySearch,
  pageContent,
  resetTemplateData,
}) => {
  const history = useHistory();
  const { name } = useParams();

  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    if (name) getPageContentbySearch('page_slug', name);
  }, [name]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-4 ms-2">
              <ArrowBackIosIcon
                onClick={() => {
                  resetTemplateData();
                  history.goBack('/login');
                }}
              />
              <ElynkerLogo
                style={{ cursor: 'pointer' }}
                className="ms-3"
                width={'100px'}
                height={'30px'}
                onClick={() => history.push('/homepage')}
              />
            </div>
            <Divider
              component="div"
              className="main-divider mt-1"
              role="presentation"
            />
            {pageContent?.length > 0 && (
              <form style={{ marginTop: '20px' }}>
                <Box>
                  <Typography className="terms-description mb-2">
                    {parse(pageContent[0]?.page_content ?? '')}
                  </Typography>
                </Box>
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

TemplatePages.propTypes = {
  set_template: PropTypes.string,
  getTemplateDataPage: PropTypes.func,
  all_template: PropTypes.array,
  getPageContentbySearch: PropTypes.func,
  pageContent: PropTypes.object,
  resetTemplateData: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    set_template: state.template.set_template,
    all_template: state.mobile.all_template,
    pageContent: state.template.pageContent,
  };
};

export default connect(mapStateToProps, {
  getTemplateDataPage,
  getPageContentbySearch,
  resetTemplateData,
})(TemplatePages);
