import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import UserLogo from '../../assets/user-icon.svg';
import CheckSquareLogo from '../../assets/check-square-icon.svg';
import ShieldLogo from '../../assets/shield-check-icon.svg';
import { Container, Grid, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
// const theme = createTheme();
// const customBlueColor = '#416AD4';
const userText = "Build your profile ";
const checkSquareText = "Get verified";
const shieldText = "Personalise ";


function WebOnboarding({
    onSubmit = () => { },
    userData = {}
}) {

    return (
        <Container maxWidth="xl" sx={{ padding: '0px !important' }}>
            <Grid container className="root" style={{ height: '100vh' }}> {/* Apply class name from CSS */}
                {/* The image half */}
                <Grid item md={7} lg={7} className="bgImage"> {/* Apply class name from CSS */}

                </Grid>

                {/* The content half */}
                <Grid item md={5} lg={5} xs={12} className="bgMain" sx={{ margin: 'auto' }}> {/* Apply class name from CSS */}
                    <div className="login">
                        {/* Demo content */}
                        <Container cla>
                            <Grid justifyContent="center" display={'flex'} alignContent={'center'}>

                                <Grid item xs={12} md={8} lg={8} xl={8}>
                                    <div className="">
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            alignItems="center"
                                            className="fs-26 mb-1  fw-bold text-black font-skmodernist"
                                            gutterBottom>
                                            Hey <span style={{ color: '#864FFD' }}>{userData?.user?.name},</span>  ready to get more customers?
                                        </Typography>

                                    </div>



                                    <form style={{ marginTop: '50px' }} onSubmit={onSubmit}>
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={UserLogo}
                                                alt="user-logo" width={24} height={24}
                                                className="user-logo me-3 mb-2"
                                            />
                                            <Typography className="text-start fs-14 font-gotham text-grey line-height" style={{ whiteSpace: 'pre-line' }}>
                                                {userText}
                                            </Typography>
                                        </div>
                                        <hr style={{ borderTop: '1px solid #DEDEDE', opacity: '1' }}></hr>

                                        <div className="d-flex align-items-center">
                                            <img
                                                src={CheckSquareLogo}
                                                alt="check-square-logo" width={24} height={24}
                                                className="check-square-logo me-3 mb-2"
                                            />
                                            <Typography className="text-start fs-14 font-gotham text-grey line-height" style={{ whiteSpace: 'pre-line' }}>
                                                {checkSquareText}
                                            </Typography>
                                        </div>
                                        <hr style={{ borderTop: '1px solid #DEDEDE', opacity: '1' }}></hr>

                                        <div className="d-flex align-items-center">
                                            <img
                                                src={ShieldLogo}
                                                alt="shield-logo" width={24} height={24}
                                                className="shield-logo me-3 mb-2"
                                            />
                                            <Typography className="text-start fs-14 font-gotham text-grey line-height" style={{ whiteSpace: 'pre-line' }}>
                                                {shieldText}
                                            </Typography>
                                        </div>
                                        <hr style={{ borderTop: '1px solid #DEDEDE', opacity: '1' }}></hr>

                                        <Typography className="fs-14 font-gotham text-grey mb-2">Create a personalized service page that reflects your brand. Come back to edit anytime.</Typography>
                                        {/* <Typography className="fs-14 font-gotham text-primary mb-2">Save as you go.</Typography> */}
                                        <Typography className="fs-14 fw-normal mb-3 font-gotham text-grey">Stand out to potential clients with a complete profile</Typography>

                                        {/* <Box>
                                            <Typography className="text-start fs-13 font-gotham text-grey line-height">
                                                By signing up, you agree to{' '}
                                                <span className="fw-bold">Terms</span>. See how
                                                we use your data in our{' '}
                                                <span className="fw-bold">Privacy Policy</span>.
                                            </Typography>
                                        </Box> */}
                                        {/* <FormControlLabel
                                            className="d-flex align-items-start"
                                            control={
                                                <Checkbox
                                                    id="customCheck1"
                                                    className="py-0"
                                                    sx={{
                                                        color: customBlueColor,
                                                        borderWidth: 1,
                                                        '& .MuiSvgIcon-root': {
                                                            borderWidth: 1,
                                                            borderRadius: 4
                                                        },
                                                        '&.Mui-checked': {
                                                            color: customBlueColor,
                                                            borderRadius: 15,
                                                            borderWidth: 2
                                                        }
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography className="text-start fs-13 font-gotham text-grey line-height">
                                                    By signing up, you agree to{' '}
                                                    <span className="fw-bold">Terms</span>. See how
                                                    we use your data in our{' '}
                                                    <span className="fw-bold">Privacy Policy</span>.
                                                </Typography>
                                            }
                                        /> */}


                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="mt-4 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                                            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
                                            Get Started
                                        </Button>
                                    </form>
                                </Grid>

                            </Grid>

                        </Container>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
WebOnboarding.propTypes = {
    onSubmit: PropTypes.func,
    userData: PropTypes.any
}
export default WebOnboarding;