import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Container, Grid, Typography, Button, RadioGroup, FormControlLabel, Radio, Paper, TableContainer, Table, TableBody, TableRow, TableCell, TableFooter, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';

const theme = createTheme();

const PaymentDetailsForm = () => {
    // const billSummary = {
    //     yearlySubscription: 8338,
    //     gst: 1510,
    //     totalCost: 9848,
    // };
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '67vh' }}>
                    <Grid item xs={12}>
                        <div className="my-3 ms-2 d-flex align-items-center">
                            <ArrowBackIosIcon onClick={() => {
                                history.goBack()
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <ElynkerLogoComponent>
                                    <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
                                </ElynkerLogoComponent>

                            </div>
                        </div>
                        <Divider
                            component="div"
                            className="main-divider mt-2 mb-3"
                            role="presentation"
                        />




                        <form style={{ marginBottom: '90px' }}>
                            <Typography
                                variant="h4"
                                align="left"
                                alignItems="center"
                                className="fs-20  mb-2 fw-bold font-skmodernist"
                                gutterBottom style={{ whiteSpace: 'pre-line' }}>
                                Payment
                            </Typography>



                            <Typography
                                variant="h4"
                                align="left"
                                alignItems="center"
                                className="fs-14  mb-2 fw-normal text-grey font-gotham"
                                gutterBottom style={{ whiteSpace: 'pre-line' }}>
                                Select preferred payment options
                            </Typography>

                            <div className="mb-2">
                                <FormControl fullWidth sx={{ my: 1 }}>

                                    <RadioGroup
                                        defaultValue={'2'}>
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Debit & Credit Cards"
                                        />
                                        <Divider
                                            component="div"
                                            className="divider my-1"
                                            role="presentation"
                                        ></Divider>
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="Net Banking"
                                        />
                                        <Divider
                                            component="div"
                                            className="divider my-1"
                                            role="presentation"
                                        ></Divider>
                                        {/* <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="Wallets"
                                        />
                                        <Divider
                                            component="div"
                                            className="divider mb-3 mt-1"
                                            role="presentation"
                                        ></Divider> */}
                                    </RadioGroup>

                                </FormControl>
                            </div>

                            <Typography
                                variant="h4"
                                align="left"
                                alignItems="center"
                                className="fs-20  mb-2 fw-bold font-skmodernist"
                                gutterBottom style={{ whiteSpace: 'pre-line' }}>
                                Edit Payment Details
                            </Typography>
                            <div className="mb-2">
                                <InputLabel
                                    htmlFor="inputCIN"
                                    className="text-start fs-14 text-black font-skmodernist">
                                    Name
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                    <OutlinedInput
                                        id="inputCIN"
                                        // label="Mobile Number"
                                        type="text"
                                        placeholder="Enter name"
                                        required
                                        autoFocus
                                        fullWidth
                                        margin="none"
                                    />
                                </FormControl>
                            </div>

                            <div className="mb-3">
                                <InputLabel
                                    htmlFor="inputCIN"
                                    className="text-start fs-14 text-black font-skmodernist">
                                    Email ID
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                    <OutlinedInput
                                        id="inputCIN"
                                        // label="Mobile Number"
                                        type="text"
                                        placeholder="Enter email id"
                                        required
                                        autoFocus
                                        fullWidth
                                        margin="none"
                                    />
                                </FormControl>
                            </div>

                            <div className="mb-2">
                                <InputLabel
                                    htmlFor="inputCIN"
                                    className="text-start fs-14 text-black font-skmodernist">
                                    Phone No.
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                    <OutlinedInput
                                        id="inputCIN"
                                        // label="Mobile Number"
                                        type="text"
                                        placeholder="Enter phone no."
                                        required
                                        autoFocus
                                        fullWidth
                                        margin="none"
                                    />
                                </FormControl>
                            </div>

                            <Paper className="payment-details-table mb-3">
                                <Typography className="fs-18 fw-bold font-skmodernist payment-details-heading" gutterBottom>
                                    Final Bill Summary
                                </Typography>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">eStarter</TableCell>
                                                <TableCell align="right">₹999</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Add-on</TableCell>
                                                <TableCell align="right">₹0</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Discount</TableCell>
                                                <TableCell align="right">₹100</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                                        <OutlinedInput
                                                            id="inputCIN"
                                                            // label="Mobile Number"
                                                            type="text"
                                                            placeholder="Enter Coupon Code"
                                                            required
                                                            autoFocus
                                                            fullWidth
                                                            margin="none"
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        variant="contained"
                                                        className="btn-search"
                                                    >
                                                        Apply
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Total Cost</TableCell>
                                                <TableCell align="right">₹899</TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Paper>

                            <div className="addon-accordion-container">
                                <Typography variant="h6" className="accordion-title">Add - On</Typography>

                                <Accordion
                                    expanded={expanded === 'panel1'}
                                    onChange={handleChange('panel1')}
                                    className="accordion"
                                >


                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="accordion-summary"
                                    >
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <ExpandMoreIcon className="expand-icon" style={{ transform: expanded === 'panel1' ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
                                                <Typography className="accordion-header">eStarter</Typography>
                                            </div>
                                            <Typography className="accordion-add">+ Add</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-details">
                                        <Typography>Minimum 25K emails</Typography>
                                        <Typography>Upto 25 keywords will be optimized - including Basic SEO</Typography>
                                        <Typography>Traffic Ads to the Single Pager Website</Typography>
                                        <Typography>Drive Clicks to the Single Pager Website</Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion
                                    expanded={expanded === 'panel2'}
                                    onChange={handleChange('panel2')}
                                    className="accordion"
                                >
                                    <AccordionSummary
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        className="accordion-summary"
                                    >
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <ExpandMoreIcon className="expand-icon" style={{ transform: expanded === 'panel1' ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
                                                <Typography className="accordion-header">Advanced</Typography>
                                            </div>
                                            <Typography className="accordion-add">+ Add</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-details">
                                        <Typography>Minimum 50K emails</Typography>
                                        <Typography>Upto 50 keywords will be optimized - including Basic SEO</Typography>
                                        <Typography>Traffic Ads to the Single Pager Website</Typography>
                                        <Typography>Drive Clicks to the Single Pager Website</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </form>
                        <Box className="complete-payment">
                            <Button
                                variant="contained"
                                className="btn-complete-payment"
                                fullWidth
                                onClick={() => {
                                    // handle payment logic here
                                    console.log('Payment completed');
                                }}
                            >
                                <Typography className="payment-price">Pay ₹9848 </Typography>
                                <Typography className="payment-text">Tap to complete payment </Typography>

                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider >
    );
};



export default PaymentDetailsForm;