const companyNameRegex = /^[a-zA-Z\s]*$/;
const aboutCompanyRegex = /^[a-zA-Z\s]*$/;
const cinRegex = /^[a-zA-Z0-9\s]*$/;
const gstRegex = /^[a-zA-Z0-9\s]*$/;
const panRegex = /^[a-zA-Z0-9\s]*$/;
export {
    companyNameRegex,
    aboutCompanyRegex,
    cinRegex,
    gstRegex,
    panRegex
}