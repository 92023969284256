/*

  AUSTRALIA - Component for Business Documents

*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { connect } from 'react-redux';
import { setACNNumber } from './redux/actions/au-bussiness-document-form-action';

const AUBussinessDocumentForm = ({
	onChange,
	companyName,
	businessDocuments,
	acn,
	setACNNumber,
	// setCompletedFields
}) => {
	// const totalBoxes = 1;
	const handleChangeACN = (event) => {
		const input = event.target.value;
		const regex = /^[0-9]*$/;
		if (regex.test(input)) {
			setACNNumber(input);
		}
	};

	useEffect(() => {
		let businessDoc = [];
		let ACNIdx = -1;
		if (businessDocuments) {
			ACNIdx = businessDocuments?.findIndex(
				(val) => val.document_name === 'ACN'
			);
		}
		// if (acn) {
		let cinObj = {};
		if (ACNIdx !== -1) {
			let existingCIN = { ...businessDocuments[ACNIdx] };
			existingCIN.company_name = companyName;
			existingCIN.document_number = acn;
			cinObj = existingCIN;
		} else {
			cinObj = {
				company_name: companyName,
				document: 'N/A',
				is_active: 1,
				document_name: 'ACN',
				document_number: acn,
				file_location: '',
				file_name: '',
				is_provided: acn ? true : false,
				no_document_reason: '',
			};
		}
		businessDoc.push(cinObj);
		// }

		if (businessDoc.length > 0) {
			onChange({ key: 'businessDoc', value: businessDoc });
		} else {
			onChange(null);
		}
	}, [acn, companyName, businessDocuments]);

	return (
		<>
			<div className=" mb-3">
				<InputLabel
					htmlFor="inputACN"
					className="text-start fs-14 text-black font-skmodernist"
				>
					ACN Number: <span className="text-mandatory">*</span>
				</InputLabel>
				<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
					<OutlinedInput
						id="inputACN"
						type="text"
						placeholder="Enter ACN"
						required
						fullWidth
						inputProps={{
							maxLength: 9,
						}}
						margin="none"
						value={acn}
						onChange={handleChangeACN}
					/>
				</FormControl>
			</div>
		</>
	);
};

AUBussinessDocumentForm.propTypes = {
	onChange: PropTypes.func,
	// setCompletedFields: PropTypes.func,
	companyName: PropTypes.string,
	businessDocuments: PropTypes.array,
	acn: PropTypes.string,
	setACNNumber: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { companyName } = state.companyDetails;
	const { acn } = state.AUBusinessDoc;
	const { businessDocuments } = state.b2bOnboardStep;

	return {
		companyName,
		businessDocuments,
		acn,
	};
};

export default connect(mapStateToProps, {
	setACNNumber,
})(AUBussinessDocumentForm);

