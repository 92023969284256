import { PROFESSIONAL_ROLE, RESET_PROFESSIONAL_ROLE_DATA } from '../types';

export const setProfessionalRole = (role) =>
  function (dispatch) {
    dispatch({ type: PROFESSIONAL_ROLE, payload: role });
  };

  export const resetFLUProfRoleData = () =>
  function (dispatch) {
    dispatch({ type: RESET_PROFESSIONAL_ROLE_DATA });
  };



