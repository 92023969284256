import { RESET_CREATE_JOB_DATA,
     SAVE_JOB_DESCRIPTION,
      SAVE_JOB_EXP,
       SAVE_JOB_LOCATION,
       SAVE_JOB_SALARY,
       SAVE_JOB_TITLE,
       SELECTED_JOB_CATEGORY,
       ALL_JOB_CATEGORY,
       SET_JOB_LOCATION_COORDS,
       SAVE_SINGLE_JOB_DATA,
       JOB_LOC_SEARCH_VAL,
       JOB_ACTION_TYPE
    } from "../types";

const INITIAL_STATE  = {
    title: '',
    desc: '',
    minExp: '',
    location: '',
    salary: null,
    selCategory: null,
    flCategory:[],
    jobActionType:"Add",
    singleJob: {},
    locSearchVal: '',
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SAVE_JOB_TITLE:
             return {
                ...state,
                title: action.payload
             };
        case SAVE_JOB_DESCRIPTION:
            return {
                ...state,
                desc: action.payload
            };
        case SAVE_JOB_EXP:
            return {
                ...state,
                minExp: action.payload
            };
        case SAVE_JOB_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case SAVE_JOB_SALARY:
            return {
                ...state,
                salary: action.payload
            };
        case SELECTED_JOB_CATEGORY:
            return {
                ...state,
                selCategory: action.payload
            };
        case ALL_JOB_CATEGORY:
            return {
                ...state,
                flCategory: action.payload
            };
        case SET_JOB_LOCATION_COORDS:
            return {
                ...state,
                locationCoords: action.payload
            };
        case SAVE_SINGLE_JOB_DATA:
            return {
                ...state,
                singleJob: action.payload
            };
        case JOB_LOC_SEARCH_VAL:
            return {
                ...state,
                locSearchVal: action.payload
            };
        case JOB_ACTION_TYPE:
            return {
                ...state,
                jobActionType: action.payload
            };
        case RESET_CREATE_JOB_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}