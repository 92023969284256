import {
  getJobListByFilterApi,
  getJobListByTitleApi,
} from '../../../../utils/APIs/endpoints';
import {
  SAVE_JOB_SERCH_TEXT,
  SET_ALL_JOBS,
  SET_FILTER_JCATEGORY,
  SET_FILTER_JEXP,
  SET_FILTER_JSORT,
  SET_JOBS_ALL_CATEGORY,
  SET_JOBS_ALL_CITY,
  SET_JOBS_ALL_EXP,
  SET_JOB_FILTER_CITY,
} from '../types';
//  SET_JOBS_ALL_CATEGORY, SET_JOBS_ALL_CITY
export const getJobListByTitle = (title, params) => async (dispatch) => {
  try {
    let response;
    if (params == '') {
      //Call API
      response = await getJobListByTitleApi(title);
    } else {
      console.log('Call 2nd api call', title, params);
      response = await getJobListByFilterApi(title, params);
    }

    if (response?.status == 200) {
      //dispatch vendors city
      // const filtered = response?.data?.locations?.filter((e) => e !== null);
      if (params == null || params == '') {
        // console.log("All location", response?.data?.locations );
        // console.log("All category", response?.data?.categories);
        // console.log("All experience", response?.data?.experience);
        //save locations
        dispatch({
          type: SET_JOBS_ALL_CITY,
          payload: response?.data?.locations,
        });
        // save categories
        dispatch({
          type: SET_JOBS_ALL_CATEGORY,
          payload: response?.data?.categories,
        });
        // save experience
        dispatch({
          type: SET_JOBS_ALL_EXP,
          payload: response?.data?.experience,
        });
      }
      console.log('job response : ', response?.data?.data);
      dispatch({ type: SET_ALL_JOBS, payload: response?.data?.data });
    } else {
      dispatch({ type: SET_ALL_JOBS, payload: null });
    }
  } catch (error) {
    dispatch({ type: SET_ALL_JOBS, payload: [] });
  }
};

export const saveJobSearchText = (text) => async (dispatch) => {
  dispatch({ type: SAVE_JOB_SERCH_TEXT, payload: text });
};

export const setFilterCategory = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_JCATEGORY, payload: data });
};

export const setFilterMinExp = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_JEXP, payload: data });
};

export const setFilterCity = (data) => async (dispatch) => {
  dispatch({ type: SET_JOB_FILTER_CITY, payload: data });
};

export const setFilterSort = (data) => async (dispatch) => {
  dispatch({ type: SET_FILTER_JSORT, payload: data });
};
