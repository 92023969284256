import React from 'react';
import {
    string
} from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageProfile = ({ path, placeholder, loadError, ...props }) => {
    const [img, initImg] = React.useState(placeholder || path);
    const onLoad = React.useCallback(() => {
        initImg(path);
    }, [path]);
    const onError = React.useCallback(() => {
        initImg(loadError || placeholder);
    }, [loadError, placeholder]);
    React.useEffect(() => {
        const imageObjt = new Image();
        imageObjt.src = path;
        imageObjt.addEventListener('load', onLoad);
        imageObjt.addEventListener('error', onError);

        return () => {
            imageObjt.removeEventListener("load", onLoad);
            imageObjt.removeEventListener("error", onError);
        };
    }, [path, onLoad, onError]);
    return <LazyLoadImage
        {...props} alt={img} src={img} />;
}

ImageProfile.propTypes = {
    path: string,
    placeholder: string,
    loadError: string
};
export default ImageProfile;