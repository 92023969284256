import {
	GET_SUBSCRIPTION,
	GET_SINGLE_SUBSCRIPTION,
	GET_ALL_SUBSCRIPTION_SERVICES,
	CLEAR_ALL_SUBSCRIPTION_SERVICES,
	GET_PREMIUM_PLAN,
	SET_SUBSCRIPTION_ORDER_DATA,
	SET_SUBSCRIPTION_DETAILS_DATA,
	SET_CURRENT_SUBSCRIPTION_DETAIL,
	RESET_SUBSCRIPTION_DATA,
} from '../types';
const init = {
	subscription: [],
	single_subscription: [],
	single_subscription_services: [],
	premiumPlan: null,
	orderData: null,
	subscriptionDetail: null,
	viewSubscriptionDetail: null,
};
const subscription = (state = init, action) => {
	switch (action.type) {
		case GET_SUBSCRIPTION:
			return {
				...state,
				subscription: action.payload,
			};
		case GET_PREMIUM_PLAN:
			return { ...state, premiumPlan: action.payload };
		case GET_SINGLE_SUBSCRIPTION:
			return {
				...state,
				single_subscription: action.payload,
			};
		case GET_ALL_SUBSCRIPTION_SERVICES: {
			const newPayload = action.payload;
			return {
				...state,
				single_subscription_services: [
					...state.single_subscription_services.filter(
						(r) => r.name !== action.payload.name
					),
					{
						...state.single_subscription_services.filter(
							(r) => r.name === action.payload.name
						)[0],
						...newPayload,
					},
				],
			};
		}
		case SET_SUBSCRIPTION_DETAILS_DATA:
			return { ...state, subscriptionDetail: action.payload };
		case SET_SUBSCRIPTION_ORDER_DATA:
			return { ...state, orderData: action.payload };
		case SET_CURRENT_SUBSCRIPTION_DETAIL:
			return { ...state, viewSubscriptionDetail: action.payload };
		case CLEAR_ALL_SUBSCRIPTION_SERVICES: {
			return {
				...state,
				single_subscription_services: [],
			};
		}
		case RESET_SUBSCRIPTION_DATA:
			return {
				...state,
				single_subscription_services: [],
				premiumPlan: null,
				orderData: null,
				subscriptionDetail: null,
				viewSubscriptionDetail: null,
			};
	}
	return state;
};
export default subscription;
