import { SET_SELECTED_VCATEGORY, SET_SELECTED_VCATEGORY_UUID } from "../types";

const INITIAL_STATE = {
    selectedVCategory: null,
    selectedVcategoryUUID: null
};


export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SET_SELECTED_VCATEGORY:
            return {
                ...state,
                selectedVCategory: action.payload
            };
        case SET_SELECTED_VCATEGORY_UUID:
            return {
                ...state,
                selectedVcategoryUUID: action.payload
            };
        default:
            return state;
    }
}