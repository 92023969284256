import { HOURLY_RATE, RESET_HOURLY_RATE } from "../types"

const INITIAL_STATE={
    hourlyRate:"",
}

export default (state=INITIAL_STATE, action) => {

    switch(action.type){
        case HOURLY_RATE:
            return {
                ...state, hourlyRate: action.payload
            };

        case RESET_HOURLY_RATE:
            return INITIAL_STATE;

        default:
            return state;
    }
}