import {
  GET_SEARCH_VENDORS,
  SEARCH_TEXT,
  RESET_SEARCH_DATA,
  SAVE_TREDNING_PRODUCT_CATEGORIES,
  SAVE_TREDNING_JOB_CATEGORIES,
} from '../types';

const INITIAL_STATE = {
  searchVendor: [],
  tredningProductCategories: [],
  tredningJobCategories: [],
  searchText: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SEARCH_VENDORS:
      return {
        ...state,
        searchVendor: action.payload,
      };
    case SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case SAVE_TREDNING_PRODUCT_CATEGORIES:
      return {
        ...state,
        tredningProductCategories: action.payload,
      };
    case SAVE_TREDNING_JOB_CATEGORIES:
      return {
        ...state,
        tredningJobCategories: action.payload,
      };
    case RESET_SEARCH_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
