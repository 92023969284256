import { searchUUIDApi } from '../../../../utils/APIs/endpoints';
import { SET_SINGLE_BUISNESS_OUTREACH } from '../types';

export const setBuisnessOutReachData = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SINGLE_BUISNESS_OUTREACH, payload: data });
  };
};

export const getUserByUUID = (
  uuid,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    try {
      const response = await searchUUIDApi(uuid);
      console.log('getUserByUUID response_data : ', response?.data?.data);
      if (response.status === 200) {
        successCallback(response?.data?.data);
        dispatch({
          type: SET_SINGLE_BUISNESS_OUTREACH,
          payload: {
            ...response?.data?.data[0],
            userId: response?.data?.data[0]?.user.id,
            mobile_number: response?.data?.data[0].user?.mobile_number,
            email: response?.data?.data[0]?.user.email,
          },
        });
      } else {
        errorCallback(response?.data);
      }
    } catch (error) {
      console.log('putUpdateUserDetails Error', error);
      errorCallback(error);
    }
  };
};
