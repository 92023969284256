import {
    PROFESSIONAL_ROLE,
    RESET_PROFESSIONAL_ROLE_DATA
  } from '../types';

  const INITIAL_STATE = {
    freelancerRole: '',
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case PROFESSIONAL_ROLE:
        return { ...state, freelancerRole: action.payload };
      case RESET_PROFESSIONAL_ROLE_DATA:
        return INITIAL_STATE;
      default:
        return state;
    }
  };
  