import { useState } from 'react';
import {
  addressValidation,
  cityValidation,
  companyAboutValidation,
  companCINValidation,
  companPanValidation,
  companyNameValidation,
  emailValidation,
  outReachEmail,
  nameValidation,
  companGstValidation,
  companGstReasonValidation,
  productPhotosValidation,
  productNameValidation,
  subCategoryValidation,
  productDescriptionValidation,
  priceValidation,
  unitValidation,
  orderQuantityValidation,
  logoPhotoValidation,
  coverPhotoValidation,
  locationValidation,
  socialLinkValidation,
  stateValidation,
  mobileNumberValidation,
  profileRoleValidation,
  hrRateDecimalValidation,
  flSerNameValidation,
  flSerCategoryValidation,
  flSerDescValidation,
  flPriceValidation,
  flEducationValidation,
  flAddress1Validation,
  flAddress2Validation,
  bioNameValidation,
  dobValidation,
  languageValidation,
  flProtLink,
  addCityValidation,
  about,
  comment,
  whatsappNumberValidation,
  feedbackDetail,
  feedbackRating,
  flResumeUploadValidation,
  flSkillsValidation,
  flWorkUploadValidation,
  jobTitleValidation,
  jobCategoryValidation,
  jobDescValidation,
  jobExpValidation,
  jobLocValidation,
  jobSalaryValidation,
  ProdNameValidation,
  orderQuantValidation,
  estimatedBudgetValidation,
  JobApplicantNameValidation,
  pincodeValidation,
  subDomainUrlValidation,
  subDomainValidation,
  client_name,
  designation,
  testimonial,
  checkedCount,
  buisnessTypeValidation,
  websiteTestimonial,
} from '../utils/validation';
// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const useValidation = () => {
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    city: '',
    state: '',
    address: '',
    pincode: '',
    company_name: '',
    about_company: '',
    cin: '',
    gst: '',
    photos: '',
    product_name: '',
    product_subCategory: '',
    product_description: '',
    product_price: '',
    product_unit: '',
    product_orderQuantity: '',
    logoImage: '',
    coverPhoto: '',
    location: '',
    socialLink: '',
    flRole: '',
    hrRate: '',
    flYoE: '',
    flProtLink: '',
    flPriceHr: '',
    flSerDesc: '',
    flSerCategory: '',
    flSerName: '',
    flSerImg: '',
    flEducation: '',
    flAddress1: '',
    flAddress2: '',
    gst_reason: '',
    cityEdit: '',
    comment: '',
    whatsapp: '',
    feedbackDetail: '',
    feedbackRating: '',
    flResumeUpload: '',
    flSkills: '',
    flWorkUpload: '',
    jobTitle: '',
    jobCategory: '',
    jobDesc: '',
    jobExp: '',
    jobLoc: '',
    jobSal: '',
    productName: '',
    orderQuantity: '',
    budget: '',
    jobApplicantName: '',
    jobEmail: '',
    jobMobileNo: '',
    subDomainUrl: '',
    subDomain: '',
    client_name: '',
    designation: '',
    testimonial: '',
    checkedCount: '',
    business_type: '',
    outReachEmail: '',
    websiteTestimonial: '',
  });

  const validateInput = async (name, value) => {
    try {
      const validationSchema = {
        name: nameValidation,
        bio: bioNameValidation,
        dob: dobValidation,
        email: emailValidation,
        outReachEmail: outReachEmail,
        business_type: buisnessTypeValidation,
        language: languageValidation,
        city: cityValidation,
        address: addressValidation,
        mobileNumber: mobileNumberValidation,
        location: locationValidation,
        company_name: companyNameValidation,
        comment: comment,
        about_company: companyAboutValidation,
        about: about,
        cin: companCINValidation,
        pan: companPanValidation,
        gst: companGstValidation,
        gst_reason: companGstReasonValidation,
        photos: productPhotosValidation,
        logoImage: logoPhotoValidation,
        coverPhoto: coverPhotoValidation,
        product_name: productNameValidation,
        product_subCategory: subCategoryValidation,
        product_description: productDescriptionValidation,
        product_price: priceValidation,
        product_unit: unitValidation,
        product_orderQuantity: orderQuantityValidation,
        socialLink: socialLinkValidation,
        flProtLink: flProtLink,
        state: stateValidation,
        flRole: profileRoleValidation,
        hrRate: hrRateDecimalValidation,
        flSerName: flSerNameValidation,
        flSerCategory: flSerCategoryValidation,
        flSerDesc: flSerDescValidation,
        flPriceHr: flPriceValidation,
        flEducation: flEducationValidation,
        flAddress1: flAddress1Validation,
        flAddress2: flAddress2Validation,
        cityEdit: addCityValidation,
        whatsapp: whatsappNumberValidation,
        feedbackDetail,
        feedbackRating,
        flResumeUpload: flResumeUploadValidation,
        flSkills: flSkillsValidation,
        flWorkUpload: flWorkUploadValidation,
        jobTitle: jobTitleValidation,
        jobCategory: jobCategoryValidation,
        jobDesc: jobDescValidation,
        jobExp: jobExpValidation,
        jobLoc: jobLocValidation,
        jobSal: jobSalaryValidation,
        productName: ProdNameValidation,
        orderQuantity: orderQuantValidation,
        budget: estimatedBudgetValidation,
        jobApplicantName: JobApplicantNameValidation,
        jobMobileNo: mobileNumberValidation,
        jobEmail: emailValidation,
        pincode: pincodeValidation,
        subDomainUrl: subDomainUrlValidation,
        subDomain: subDomainValidation,
        client_name,
        designation,
        testimonial,
        checkedCount,
        websiteTestimonial: websiteTestimonial
      };
      await validationSchema[name].validate(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  return { errors, validateInput, setErrors };
};

export default useValidation;
