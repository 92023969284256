/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Typography,
  Button,
  FormHelperText,
  Box,
  Checkbox,
  Autocomplete,
  TextField,
  TextareaAutosize,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import {
  submitBusinessDocuments,
  getExistingBusinessDocuments,
  updateUserData,
} from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { getUpdateAboutCompanyDetails } from './store/actions/profileActions';
import { putUpdateAboutCompanyDetails } from './store/actions/profileActions';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import MultiSelect from '../../components/MultiSelect/MultiSelect';
// import { object, string } from 'yup';
import { connect } from 'react-redux';
import {
  getUpdateUserDetails,
  putUpdateUserDetails,
  getProductMainCategory,
} from './store/actions/profileActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import INBussinessDocumentForm from '../../components/BussinessDocumentsForm/IN/IN-BussinessDocumentForm';
import AUBussinessDocumentForm from '../../components/BussinessDocumentsForm/AU/AU-BussinessDocumentForm';
import {
  AUSTRALIA,
  INDIA,
} from '../../components/BussinessDocumentsForm/constant';
import {
  setAboutCompany,
  setCompanyName,
  setAdditioanlDetails1,
  setAdditioanlDetails2,
  setAdditioanlDetails3,
} from '../CompanyDetails/redux/actions/company-details-form-action';
import { loading } from '../../store/actions/rootActions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import SocialLinks from '../../components/SocialLinks/SocialLinks';
import {
  getSocialLinks,
  getSocialMediaMasterData,
  postSocialLinks,
  putSocialLinks,
  setAddress1,
  setAddress2,
  setCity,
  setSocialData,
  setSocialLinks,
  setSocialName,
  setState,
  setWhatsappNumber,
  setPincode,
  setAreaName,
  delSocialLinkArr,
  deleteSocialLinksData,
  resetDelSocialLinkArr,
} from '../ProfileDetails/redux/actions/profile-details-form-action';
import { getTopLevelProductCategoriesByUser } from '../Profile/redux/actions/user-profile-actions';
import { hasAnyErrorPresent } from '../../utils/validation';
import useValidation from '../../hooks/useValidation';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import axios from 'axios';

// const getCompanyDetail = (documents) => {
//   const companyDocuments = {};
//   const allKeyValuePairs = {};
//   documents.forEach((document) => {
//     const { company_name, document_name, document_number } = document;

//     if (!companyDocuments[company_name]) {
//       companyDocuments[company_name] = {
//         company_name: company_name,
//       };
//     }

//     // Assign the document_number to the document_name key in companyDocuments
//     companyDocuments[company_name][document_name] = document_number;
//   });
//   for (const company in companyDocuments) {
//     const documents = companyDocuments[company];
//     // Iterate over each document in the company
//     for (const key in documents) {
//       // Rename the key from 'gst' to 'gstn'
//       const newKey = key === 'gst' ? 'gstn' : key;
//       allKeyValuePairs[newKey] = documents[key];
//     }
//   }
//   return allKeyValuePairs;
// };

const theme = createTheme();
// import { COUNTRY, LANG, LIMIT, MAPBOX_ACC } from '../../utils/constants';
import { setRequestLocation } from '../B2BRequirement/B2BSubmitRequirement/redux/actions/b2b-submit-requirement-action';
import { POSTOFFICE_PINCODE } from '../../utils/APIs/api';
import { stickyButtonStyle } from '../../utils/sx-styles';
// import { useMediaQuery } from 'react-responsive';
import {
  handleErrorsAndScroll,
  scrollToInput,
} from '../../utils/helper-funtions';
// import { UPDATE_SIGNIN_DATA } from '../Otp/redux/types';

const UpdateProfileForm = ({
  userData,
  country_code_name,
  companyName,
  aboutCompany,
  setCompanyName,
  setAboutCompany,
  updateUserData,
  loading,
  getExistingBusinessDocuments,
  // topLevelAddedCategories,
  putUpdateUserDetails,
  submitBusinessDocuments,
  socialData,
  socialLinks,
  setSocialLinks,
  setSocialData,
  getSocialLinks,
  postSocialLinks,
  setSocialName,
  socialName,
  putSocialLinks,
  socialMediaMaster,
  getSocialMediaMasterData,
  additionalDetails1,
  additionalDetails2,
  additionalDetails3,
  setAdditioanlDetails1,
  setAdditioanlDetails2,
  setAdditioanlDetails3,
  setWhatsappNumber,
  whatsapp,
  getTopLevelProductCategoriesByUser,
  // setRequestLocation,
  // location,
  isLoading,
  city,
  states,
  setCity,
  setState,
  setAddress1,
  setAddress2,
  address1,
  setPincode,
  //   pincode,
  areaname,
  setAreaName,
  // address2,
  delSocialLinkArr,
  deleteSocialLinksData,
  delSocialLinks,
  resetDelSocialLinkArr,
}) => {
  let history = useHistory();
  const { errors, validateInput } = useValidation();
  // const [isLoading, setLoading] = useState(null);
  const [businessDoc, setBusinessDocuments] = useState(null);
  // const [productCategories, setProductCategories] = useState(
  //   topLevelAddedCategories
  // );
  const searchLocInpRef = React.useRef(null);
  const mobile_number = userData.user?.mobile_number;
  const isExistingMobileasWhatsapp = whatsapp === mobile_number ? true : false;
  const [isNotExistingMobile, setisNotExistingMobile] = React.useState(
    isExistingMobileasWhatsapp
  );
  const [socialUrlValidateError, setSocialUrlValidateError] = useState('');
  const [count, setCount] = useState(
    aboutCompany.replace(/\n/g, '')?.length || 0
  );

  // const [socialLinkData] = useState([
  //   { value: 'Facebook', label: 'Facebook' },
  //   { value: 'Instagram', label: 'Instagram' },
  //   { value: 'Linkedin', label: 'Linkedin' },
  //   { value: 'Twitter', label: 'Twitter' },
  // ]);
  const [defaultPincodeVal, setDefaultPincodeVal] = useState({ pincode: [''] });
  const [locSearchVal, setLocSearchVal] = useState('');
  const [searchLoad, setSearchLoad] = useState(false);
  const [mapOtions, setMapOtions] = useState([]);
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
  // const dispatch = useDispatch();

  useEffect(() => {
    const {
      additional_detail1,
      additional_detail2,
      additional_detail3,
      about_company,
    } = userData?.registration || {};

    if (about_company) {
      setCompanyName(about_company);
    }

    if (additional_detail1) {
      setAdditioanlDetails1(additional_detail1);
    }

    if (additional_detail2) {
      setAdditioanlDetails2(additional_detail2);
    }

    if (additional_detail3) {
      setAdditioanlDetails3(additional_detail3);
    }
  }, [userData?.registration]);
  useEffect(() => {
    let getData = setTimeout(async () => {
      if (locSearchVal && searchLoad) {
        try {
          const response = await axios.get(
            // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
            POSTOFFICE_PINCODE + locSearchVal
          );
          if (response.status === 200) {
            if (response?.data?.status_code == 200) {
              console.log('SEARCH RESULT', response.data);
              setMapOtions(response.data?.data);
              setSearchLoad(false);
            } else {
              setMapOtions([]);
              setSearchLoad(false);
            }
          } else {
            pushMessage('No City Found!!');
          }
        } catch (err) {
          console.log('');
        }
      }
    }, 250);

    return () => clearTimeout(getData);
  }, [locSearchVal]);
  // React.useEffect(() => {
  //   resetDelSocialLinkArr();
  // }, []);
  const [newSocialLinkData, setNewSocialLinkData] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    city: '',
  });

  // console.log("Company_name data: ", companyName)

  // useEffect(() => {
  //   setProductCategories(topLevelAddedCategories);
  // }, [topLevelAddedCategories]);

  useEffect(() => {
    if (userData) {
      const name = userData?.user?.name;
      const email = userData?.user?.email;
      const city = userData?.user?.city;
      setFormData({
        ...formData,
        name: name,
        email: email,
        city: city,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    // getUpdateUserDetails(userData?.user?.id);
    // getUpdateAboutCompanyDetails(userId);
    // getProductMainCategory(userData?.registration?.id);
    getExistingBusinessDocuments(userData?.registration?.id, country_code_name);
    getTopLevelProductCategoriesByUser(userData?.registration?.id, pushMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData && userData?.accessToken) {
      getSocialLinks(userData?.registration?.id, userData?.accessToken);
      getSocialMediaMasterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socialLinks.length > 0) {
      let newFilteredSocialOptions = socialMediaMaster.filter((social) => {
        const idx = socialLinks.findIndex(
          (itm) =>
            itm?.social_name?.toLowerCase() ===
            social?.media_name?.toLowerCase()
        );
        if (idx !== -1) {
          return false;
        } else {
          return true;
        }
      });
      setNewSocialLinkData(newFilteredSocialOptions);
    } else {
      setNewSocialLinkData(socialMediaMaster);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLinks, socialMediaMaster]);

  function isDuplicateUrl(arr, newObj) {
    return arr.some((item) => item.social_url === newObj.social_url);
  }

  const handleAddSocialLinkBtn = () => {
    if (
      socialData?.social_name &&
      socialData?.social_url &&
      socialData?.socialmedia_id &&
      socialData?.registrationId
    ) {
      let socialArr = [...socialLinks]; //all previus objects
      const socialObj = { ...socialData }; //contain single added link
      // id: 1,
      console.log('===socialArr,socialObj', socialArr, socialObj);
      socialObj.social_name = socialData.social_name;
      if (socialObj.id) {
        socialObj.changed = true;
      } else {
        socialObj.changed = false;
      }
      console.log('===socialArr,socialObj after', socialArr, socialObj);
      if (
        socialData?.social_url?.includes('https://') ||
        socialData?.social_url?.includes('http://')
      ) {
        socialObj.social_url = socialData?.social_url;
      } else {
        socialObj.social_url = 'https://' + socialData?.social_url;
      }
      console.log('Social Object', socialObj);
      // Check if the URL already exists in the array
      if (isDuplicateUrl(socialArr, socialObj)) {
        pushMessage(
          'This social URL already exists. Please choose a different one.'
        );
        console.log(
          'This social URL already exists. Please choose a different one.'
        );
      } else {
        // If URL is unique, add the new social link
        socialArr.push(socialObj);
        setSocialLinks(socialArr);
        setSocialUrlValidateError('');
        setSocialData({ social_name: '', social_url: '' });
        setSocialName('');
        console.log('New social link added successfully!');
      }
    } else {
      setSocialUrlValidateError('Social Name and Link is required');
    }
  };
  const handleWhatsappChange = (e) => {
    setisNotExistingMobile(false);
    const input = e.target.value;
    const regex = /^[0-9]{0,10}$/;
    if (regex.test(input)) {
      setWhatsappNumber(input);
      validateInput('whatsapp', input);
    }
  };
  const hanldleIsExistingMobile = () => {
    if (!isNotExistingMobile) {
      validateInput('whatsapp', userData.user?.mobile_number);
      setWhatsappNumber(userData.user?.mobile_number);
    } else {
      setWhatsappNumber('');
    }
    setisNotExistingMobile((prev) => !prev);
  };
  const handleChange = (e) => {
    const { value, id } = e.target;
    let trimmedValue = value;

    if (id === 'email') {
      trimmedValue = value.trim();
    }

    console.log('====handleChange', trimmedValue, id);

    if (id === 'name') {
      validateInput('name', trimmedValue);
    } else if (id === 'email') {
      validateInput('email', trimmedValue);
    } else if (id === 'city') {
      validateInput('address', trimmedValue);
    }

    setFormData((prevState) => ({
      ...prevState,
      [id]: trimmedValue,
    }));
  };

  useEffect(() => {
    if (userData?.registration?.company_name) {
      setCompanyName(userData?.registration?.company_name);
    }
  }, [userData?.registration?.company_name]);

  useEffect(() => {
    if (userData?.registration?.whatsapp_number) {
      setWhatsappNumber(userData?.registration?.whatsapp_number);
    }
  }, [userData?.registration?.whatsapp_number]);

  const getInitialPincodeData = async (pincode) => {
    try {
      const response = await axios.get(
        // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
        POSTOFFICE_PINCODE + pincode
      );
      if (response.status === 200) {
        if (response?.data?.status_code == 200) {
          console.log('SEARCH RESULT', response.data);
          setMapOtions(response.data?.data);
          setSearchLoad(false);
          return response.data?.data;
        } else {
          setMapOtions([]);
          setSearchLoad(false);
          return [];
        }
      } else {
        pushMessage('No City Found!!');
        return [];
      }
    } catch (err) {
      console.log('');
      return [];
    }
  };

  function setDefaultAddressValues() {
    setDefaultPincodeVal({ pincode: [userData?.registration?.pincode] });
    setCity(userData?.registration?.city);
    setState(userData?.registration?.state);
    setAreaName(userData?.registration?.address2);
    setAddress2(userData?.registration?.address2);
  }

  useEffect(() => {
    async function getPincodeData(pincode) {
      try {
        const pincodeArr = await getInitialPincodeData(pincode);
        console.log('pincodeArr', pincodeArr);
        if (pincodeArr.length > 0) {
          const picodeDataExac = pincodeArr.find(
            (pin) =>
              pin.city[0] === userData?.registration?.city &&
              pin.statename[0] === userData?.registration?.state &&
              pin.pincode[0] === userData?.registration?.pincode &&
              pin.areaname[0] === userData?.registration?.address2
          );
          console.log('picodeDataExac', picodeDataExac);

          if (picodeDataExac) {
            // dispatch({
            // 	type: UPDATE_SIGNIN_DATA,
            // 	payload: {
            // 		address2: picodeDataExac?.areaname[0]?.toString(),
            // 		city: picodeDataExac?.city[0]?.toString(),
            // 		state: picodeDataExac?.statename[0]?.toString(),
            // 		pincode: picodeDataExac?.pincode[0],
            // 	},
            // });
            setDefaultPincodeVal(picodeDataExac);
            setAddress2(picodeDataExac?.areaname[0]?.toString());
            setCity(picodeDataExac?.city[0]?.toString());
            setState(picodeDataExac?.statename[0]?.toString());
            setAreaName(picodeDataExac?.areaname[0]?.toString());
          } else {
            setDefaultAddressValues();
          }
        } else {
          setDefaultAddressValues();
        }
      } catch (error) {
        setDefaultAddressValues();
      }
    }
    let pinDeb = setTimeout(() => {
      if (userData?.registration?.pincode) {
        getPincodeData(userData?.registration?.pincode);
        setLocSearchVal(userData?.registration?.pincode);
      }
    }, 2000);

    return () => clearTimeout(pinDeb);
  }, [userData?.registration?.pincode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!companyName) {
      validateInput('company_name', '');
      scrollToInput('company_name');
      return;
    } else if (!aboutCompany) {
      validateInput('about_company', '');
      scrollToInput('about_company');
      return;
    } else if (!formData.email) {
      // validateInput('email', '');
      // scrollToInput('email');
    } else if (!formData.name) {
      // validateInput('name', '');
      // scrollToInput('name');
    } else if (!defaultPincodeVal || !defaultPincodeVal?.pincode[0]) {
      validateInput('pincode', '');
    } else if (!formData.city) {
      // validateInput('address', '');
    } else if (!userData?.user?.mobile_number) {
      validateInput('mobile', '');
      scrollToInput('mobile');
      return;
    } else if (whatsapp?.length !== 0 && whatsapp?.length < 10) {
      validateInput('whatsapp', whatsapp?.length);
      scrollToInput('whatsapp');
      return;
    }
    if (!hasAnyErrorPresent(errors)) {
      loading(true);
      console.log('Submitted data:', formData);
      const { name, email } = formData;
      console.log('type of pincode ', Number(defaultPincodeVal?.pincode[0]));
      let newUserData = {
        name: name,
        about_company: aboutCompany,
        company_name: companyName,

        additional_detail1: additionalDetails1,
        additional_detail2: additionalDetails2,
        additional_detail3: additionalDetails3,
        address1: address1,
        address2: areaname,
        city: city, // need to add when city dropdown is added here from ProfileDetails.js B2B step 3
        state: states, // need to add when state dropdown is added here from ProfileDetails.js B2B step 3
        // pincode: Number(defaultPincodeVal?.pincode[0]), // need to add when pincode dropdown is added here from ProfileDetails.js B2B step 3
      };
      if (whatsapp?.length == 10) {
        newUserData = { ...newUserData, whatsapp_number: whatsapp };
      } else {
        newUserData = { ...newUserData, whatsapp_number: null };
      }
      const intPin = Number(defaultPincodeVal?.pincode[0]);
      if (String(intPin)?.length == 6) {
        newUserData = {
          ...newUserData,
          pincode: Number(defaultPincodeVal?.pincode[0]),
        };
      }

      console.log('===new user data', newUserData);
      //location
      await putUpdateUserDetails(
        userData?.user?.id,
        { name, email },
        userData,
        async (oldUsrData) => {
          // console.log('oldUsrData res', oldUsrData);

          await updateUserData(
            oldUsrData,
            newUserData,
            userData?.registration?.id,
            userData?.accessToken
          );
        },
        errorCallback
      );

      let postSocialLinksArr = [];
      for (const socialLink of socialLinks) {
        if (socialLink.id && socialLink.changed) {
          await putSocialLinks(socialLink.id, socialLink);
        } else {
          postSocialLinksArr.push(socialLink);
        }
      }
      console.log('delSocialLinkArr1211', delSocialLinks);

      if (delSocialLinks && delSocialLinks.length > 0) {
        const promises = delSocialLinks?.map((id) => deleteSocialLinksData(id));
        await Promise.all(promises);
      }

      // const fetchedData = responses.map(response => response.data);
      //  console.log("fetchedData : ", fetchedData);
      console.log('postSocialLinksArr', postSocialLinksArr);
      if (postSocialLinksArr.length > 0) {
        await postSocialLinks(
          userData?.registration?.id,
          userData?.accessToken,
          postSocialLinksArr
        );
      }

      await submitBusinessDocuments(
        userData?.registration?.id,
        userData?.accessToken,
        businessDoc,
        sucessCallBack,
        errorCallback
      );
      resetDelSocialLinkArr();
      history.goBack();
    } else {
      handleErrorsAndScroll(errors);
    }
  };

  const onChangeCompanyName = (e) => {
    const input = e.target.value;
    console.log('===onChangeCompanyName');
    // const regex = /^[a-zA-Z\s]*$/;
    // if (regex.test(input)) {
    //   validateInput("company_name", input);
    //   setCompanyName(input);
    // }
    const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
    if (regex.test(input)) {
      validateInput('company_name', e.target.value);
      setCompanyName(input);
    }
  };

  const onChangeAboutCompany = (e) => {
    const input = e.target.value;
    // const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
    const newCountInput = input.replace(/\n/g, '');
    if (newCountInput.length <= 5000) {
      validateInput('about_company', newCountInput);
      setAboutCompany(input);
      setCount(newCountInput.length);
    }
  };

  const sucessCallBack = () => {
    loading(false);
  };
  const errorCallback = (err) => {
    const type = 'error';
    if (err) {
      pushMessage(err, type);
    }
  };

  useEffect(() => {
    console.log('socialLinks uef', socialLinks);
  }, [socialLinks]);

  const renderBusinessDocumentForm = () => {
    switch (userData?.user?.country_code) {
      case INDIA.countryCodeNumber:
        return (
          <INBussinessDocumentForm
            errors={errors}
            validateInput={validateInput}
            setBusinessDocuments={setBusinessDocuments}
          />
        );
      case AUSTRALIA.countryCodeNumber:
        return (
          <AUBussinessDocumentForm
            setBusinessDocuments={setBusinessDocuments}
          />
        );
      default:
        return <></>;
    }
  };

  const onChangeAdditionalDetails = (e) => {
    const input = e.target;
    switch (input.id) {
      case 'additionalDetails1':
        setAdditioanlDetails1(input?.value);
        break;
      case 'additionalDetails2':
        setAdditioanlDetails2(input?.value);
        break;
      case 'additionalDetails3':
        setAdditioanlDetails3(input?.value);
        break;
    }
    console.log('onChangeAdditionalDetails', input.id);
  };

  const onPastAboutCompany = (event) => {
    const input = event.clipboardData.getData('Text');
    // const newInput = aboutCompany + input;
    const newInput = input + aboutCompany;
    const newCountInput =
      input?.replace(/\n/g, '') + aboutCompany.replace(/\n/g, '');
    // const newInput = input?.replace(/\n/g, '') + aboutCompany;
    console.log(
      'Pasted content: aboutCompany',
      newInput.length,
      aboutCompany.length
    );
    if (newCountInput.length <= 5000) {
      validateInput('about_company', newCountInput);
      setAboutCompany(newInput);
      setCount(newCountInput.length);
    }
    event.preventDefault();
  };

  const handleAddress1Change = (e) => {
    const input = e.target.value;
    validateInput('address', input);
    setAddress1(input);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '60vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => history.goBack()} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-20 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
            >
              Complete your Profile
            </Typography>

            <Typography
              variant="body1"
              align="left"
              className="fs-14 text-grey fw-normal font-gotham"
              gutterBottom
            >
              Better we know you better we can serve
            </Typography>
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
              <Grid container columnSpacing={8} alignItems="start">
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Name
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="name"
                      type="text"
                      placeholder="Enter your name"
                      // required
                      // autoFocus
                      fullWidth
                      value={formData.name}
                      onChange={handleChange}
                      margin="none"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.name}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputEmail"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Email ID
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="email"
                      type="text"
                      placeholder="Enter your email id"
                      // required
                      fullWidth
                      value={formData.email}
                      onChange={handleChange}
                      margin="none"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.email}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Add WhatsApp number
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="whatsapp"
                      type="text"
                      placeholder="Add WhatsApp number"
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      inputProps={{
                        maxLength: 10,
                      }}
                      fullWidth
                      margin="none"
                      value={
                        isNotExistingMobile
                          ? userData.user?.mobile_number
                          : whatsapp == 0
                            ? ''
                            : whatsapp
                      }
                      onChange={handleWhatsappChange}
                    />
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Checkbox
                        edge="start" // Aligning checkbox to the left
                        onChange={hanldleIsExistingMobile}
                        checked={isNotExistingMobile}
                        inputProps={{
                          'aria-labelledby': 'checkbox-list-secondary',
                        }}
                        sx={{
                          color: '#D4D4D4',
                          '&.Mui-checked': {
                            color: '#416AD4',
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          color: '#7c7d7d',
                          fontSize: theme.typography.fontSize,
                        }}
                      >
                        Same as Registered number
                      </Typography>
                    </Box>
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.whatsapp}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompany"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Company Name <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="company_name"
                      type="text"
                      placeholder="Enter your company name"
                      // required
                      fullWidth
                      value={companyName}
                      onChange={onChangeCompanyName}
                      margin="none"
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.company_name}
                  </FormHelperText>
                </Grid>
              </Grid>

              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <InputLabel
                    htmlFor="inputAboutCompany"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    About Company <span className="text-mandatory">*</span>
                  </InputLabel>
                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
                    gutterBottom
                  >
                    {5000 - count} Characters Left
                  </Typography>
                </div>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <TextareaAutosize
                    id="about_company"
                    placeholder="Write about your company"
                    minRows={3} // You can adjust the number of rows as needed
                    maxRows={3} // You can adjust the number of rows as needed
                    onChange={onChangeAboutCompany}
                    inputprops={{
                      maxLength: 5000,
                    }}
                    value={aboutCompany}
                    onPaste={onPastAboutCompany}
                    // defaultValue={singleProduct?.description || ''}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.about_company}
                </FormHelperText>
              </div>
              <Grid container columnSpacing={8} alignItems="start">
                <Grid item md={6} lg={6} xs={12} className="">
                  <InputLabel
                    htmlFor="inputAboutCompany"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Key details about your business
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="additionalDetails1"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Full Payment In Advance"
                      fullWidth
                      className=" mb-1"
                      value={additionalDetails1}
                      onChange={onChangeAdditionalDetails}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="additionalDetails2"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Return Only In Case Of Defected Products"
                      fullWidth
                      className=" mb-1"
                      margin="none"
                      value={additionalDetails2}
                      onChange={onChangeAdditionalDetails}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="additionalDetails3"
                      // label="Mobile Number"
                      type="text"
                      placeholder="1 Pieces:  Deals In Small Orders"
                      fullWidth
                      margin="none"
                      className=" mb-1"
                      value={additionalDetails3}
                      onChange={onChangeAdditionalDetails}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputMobile"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Mobile No.
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="mobile_number"
                      type="number"
                      placeholder="Enter your mobile no."
                      // required
                      fullWidth
                      value={userData?.user?.mobile_number}
                      disabled
                      margin="none"
                    />
                  </FormControl>
                  {/* <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.mobileNumber}
                </FormHelperText> */}
                </Grid>
              </Grid>

              {renderBusinessDocumentForm()}

              <Grid container columnSpacing={8} alignItems="start">
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputLocation"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Company Address
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Building/Street"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={address1}
                      onChange={handleAddress1Change}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.address}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputLocation"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Enter your Pincode
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <Autocomplete
                      disablePortal
                      ref={searchLocInpRef}
                      id={'SearchLocation'}
                      options={mapOtions}
                      fullWidth
                      value={defaultPincodeVal}
                      onInputChange={(e, value) => {
                        const numericValue = value.replace(/[^0-9]/g, '');
                        setLocSearchVal(numericValue);
                        validateInput('pincode', numericValue);
                        setSearchLoad(true);
                      }}
                      onChange={(e, value) => {
                        if (e?.target?.value?.length == 0) {
                          setAreaName('');
                          setCity('');
                          setState('');
                          setMapOtions([]);
                          setAddress2('');
                          setPincode(null);
                          setDefaultPincodeVal(null);
                          setSearchLoad(false);
                        } else {
                          setAddress2(value?.areaname[0]?.toString());
                          validateInput(
                            'pincode',
                            value?.pincode[0]?.toString()
                          );
                          setAreaName(value?.areaname[0]?.toString());
                          setCity(value?.city[0]?.toString());
                          setState(value?.statename[0]?.toString());
                          setDefaultPincodeVal(value);
                          setPincode(value);
                          setSearchLoad(false);
                        }
                      }}
                      open={open}
                      onOpen={openPopper}
                      onClose={closePopper}
                      getOptionLabel={(option) =>
                        option?.pincode[0]?.toString()
                      }
                      isOptionEqualToValue={(option, value) =>
                        option['pincode'] === value['pincode']
                      }
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.areaname[0]} - {option.city[0]} -{' '}
                          {option.pincode}
                        </Box>
                      )}
                      sx={{
                        '.MuiAutocomplete-input': {
                          paddingLeft: '14px !important',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          placeholder={'eg. 400070'}
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 6,
                            inputMode: 'numeric',
                            onKeyDown: (e) => {
                              if (
                                !/^[0-9]$/.test(e.key) &&
                                e.key !== 'Backspace' &&
                                e.key !== 'Delete'
                              ) {
                                e.preventDefault();
                              }
                            },
                          }}
                        />
                      )}
                      noOptionsText={'Please enter valid Pincode'}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.pincode}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Town/Area
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Town/Area"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={
                        (typeof areaname === 'object'
                          ? areaname?.name
                          : areaname) || ''
                      }
                      disabled
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.address}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    City/District
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="City"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={
                        (typeof city === 'object' ? city?.name : city) || ''
                      }
                      disabled
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{ color: 'red' }}
                    id="email-helper-text"
                  ></FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCompanyAddress"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    State
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCompanyAddress"
                      // label="Mobile Number"
                      type="text"
                      placeholder="State"
                      // required
                      inputProps={{
                        maxLength: 200,
                      }}
                      fullWidth
                      margin="none"
                      value={
                        (typeof states === 'object' ? states?.name : states) ||
                        ''
                      }
                      disabled
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{ color: 'red' }}
                    id="email-helper-text"
                  ></FormHelperText>
                </Grid>
              </Grid>

              <div className="mb-3">
                {/* <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <Autocomplete
                    disablePortal
                    ref={searchLocInpRef}
                    id={'SearchLocation'}
                    options={mapOtions}
                    fullWidth
                    // defaultValue={defaultPincodeVal}
                    value={pincode || defaultPincodeVal || { pincode: [''] }}
                    // inputValue={locSearchVal}
                    onInputChange={(e, value) => {
                      setLocSearchVal(value);
                      validateInput('location', value);
                      setSearchLoad(true);
                    }}
                    onChange={(e, value) => {
                      console.log('Selected value ', value);
                      const pincodeStr = value?.pincode[0]?.toString();
                      // setLocSearchVal(value);
                      setAddress2(value);
                      validateInput('location', pincodeStr);
                      setCity(value?.city[0]?.toString());
                      setState(value?.statename[0]?.toString());
                      setPincode(value);
                      setSearchLoad(false);
                    }}
                    open={open}
                    onOpen={openPopper}
                    onClose={closePopper}
                    // defaultValue={getAddress2DefaultVal()}
                    getOptionLabel={(option) => option?.pincode[0]?.toString()}
                    isOptionEqualToValue={(option, value) =>
                      option['pincode'] === value['pincode']
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.areaname[0]} - {option.city[0]} -{' '}
                        {option.pincode}
                      </Box>
                    )}
                    sx={{
                      '.MuiAutocomplete-input': {
                        paddingLeft: '14px !important',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        placeholder={'eg. 400070'}
                        inputProps={{
                          ...params.inputProps,
                          maxLength: 200,
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.location}
                </FormHelperText>
                {/* <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <Autocomplete
                    disablePortal
                    ref={searchLocInpRef}
                    id={'address'}
                    options={mapOtions}
                    fullWidth
                    defaultValue={userData?.registration?.address2}
                    value={location}
                    inputValue={
                      locSearchVal || userData?.registration?.address2 || ''
                    }
                    onInputChange={(e, value) => {
                      setLocSearchVal(value);
                      setSearchLoad(true);
                    }}
                    onChange={(e, value) => {
                      console.log('Value_data on b2b requiremnet :', value);
                      setRequestLocation(value);
                      if (value?.geometry?.coordinates?.length > 0) {
                        // setLocationCoords({
                        //     latitude: value?.geometry?.coordinates[1],
                        //     longitude: value?.geometry?.coordinates[0],
                        // });
                        // validateInput('flAddress2', "address2");
                      }
                      setSearchLoad(false);
                    }}
                    open={open}
                    onOpen={openPopper}
                    onClose={closePopper}
                    getOptionLabel={getLocationString}
                    isOptionEqualToValue={(option, value) =>
                      option?.properties?.name === value?.properties?.name
                    }
                    sx={{
                      '.MuiAutocomplete-input': {
                        paddingLeft: '14px !important',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        placeholder={
                          location != '' ? location : 'Search Address'
                        }
                        inputProps={{
                          ...params.inputProps,
                          maxLength: 200,
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.address}
            </FormHelperText> */}
              </div>
              <Box sx={{ marginBottom: 15 }}>
                <SocialLinks
                  socialLinks={socialLinks}
                  setSocialLinks={setSocialLinks}
                  socialName={socialName}
                  newSocialLinkData={newSocialLinkData}
                  socialData={socialData}
                  setSocialName={setSocialName}
                  setSocialData={setSocialData}
                  socialUrlValidateError={socialUrlValidateError}
                  setSocialUrlValidateError={setSocialUrlValidateError}
                  handleAddSocialLinkBtn={handleAddSocialLinkBtn}
                  registrationId={userData?.registration?.id}
                  socialMediaMaster={socialMediaMaster}
                  delSocialLinkArr={delSocialLinkArr}
                />
              </Box>

              <Box sx={{ width: '100%' }}>
                <Box sx={[stickyButtonStyle]}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Save Profile Details
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

UpdateProfileForm.propTypes = {
  putUpdateUserDetails: PropTypes.func,
  putUpdateAboutCompanyDetails: PropTypes.func,
  getUpdateUserDetails: PropTypes.func,
  updatedProfile: PropTypes.object,
  updateProfileDetail: PropTypes.array,
  userId: PropTypes.number,
  registrationId: PropTypes.number,
  submitBusinessDocuments: PropTypes.func,
  getUpdateAboutCompanyDetails: PropTypes.func,
  getProductMainCategory: PropTypes.func,
  userData: PropTypes.object,
  getExistingBusinessDocuments: PropTypes.func,
  businessDocuments: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  accessToken: PropTypes.string,
  companyDetails: PropTypes.array,
  country_code_name: PropTypes.string,
  companyName: PropTypes.string,
  setCompanyName: PropTypes.func,
  setAboutCompany: PropTypes.func,
  updateUserData: PropTypes.func,
  aboutCompany: PropTypes.string,
  loading: PropTypes.func,
  topLevelAddedCategories: PropTypes.array,
  pushMessage: PropTypes.func,

  getSocialLinks: PropTypes.func,
  postSocialLinks: PropTypes.func,
  socialLinks: PropTypes.array,
  setSocialLinks: PropTypes.func,
  socialName: PropTypes.object,
  newSocialLinkData: PropTypes.array,
  socialData: PropTypes.object,
  setSocialName: PropTypes.func,
  setSocialData: PropTypes.func,
  socialUrlValidateError: PropTypes.func,
  setSocialUrlValidateError: PropTypes.func,
  handleAddSocialLinkBtn: PropTypes.func,
  putSocialLinks: PropTypes.func,
  gstReason: PropTypes.string,
  cin: PropTypes.string,
  gst: PropTypes.string,
  pan: PropTypes.string,
  hasGST: PropTypes.string,
  companyLogo: PropTypes.object,
  address1: PropTypes.string,
  address2: PropTypes.object,
  selectedCoverImage: PropTypes.object,
  city: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  states: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  whatsapp: PropTypes.string,
  ///
  socialMediaMaster: PropTypes.array,
  getSocialMediaMasterData: PropTypes.func,
  additionalDetails1: PropTypes.string,
  additionalDetails2: PropTypes.string,
  additionalDetails3: PropTypes.string,
  setAdditioanlDetails1: PropTypes.func,
  setAdditioanlDetails2: PropTypes.func,
  setAdditioanlDetails3: PropTypes.func,
  setWhatsappNumber: PropTypes.func,
  getTopLevelProductCategoriesByUser: PropTypes.func,
  setRequestLocation: PropTypes.func,
  location: PropTypes.any,
  isLoading: PropTypes.bool,
  setCity: PropTypes.func,
  setState: PropTypes.func,
  setAddress1: PropTypes.func,
  setAddress2: PropTypes.func,
  setPincode: PropTypes.func,
  pincode: PropTypes.object,
  setAreaName: PropTypes.func,
  areaname: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  delSocialLinkArr: PropTypes.func,
  deleteSocialLinksData: PropTypes.array,
  delSocialLinks: PropTypes.any,
  resetDelSocialLinkArr: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { businessDocuments } = state.b2bOnboardStep;
  const { userData } = state.signin;
  const { country_code_name } = state.mobile;
  const {
    companyName,
    aboutCompany,
    additionalDetails1,
    additionalDetails2,
    additionalDetails3,
  } = state.companyDetails;
  const { topLevelAddedCategories } = state.userProfile;
  const { location } = state.b2bRequirement;
  const {
    socialLinks,
    socialData,
    socialName,
    socialMediaMaster,
    companyLogo,
    address1,
    address2,
    selectedCoverImage,
    city,
    states,
    whatsapp,
    pincode,
    areaname,
    delSocialLinks,
  } = state.profileDetails;
  const { gstReason, cin, gst, pan, hasGST } = state.INBusinessDoc;
  const { isLoading } = state.root;
  // console.log('====userData 567', userData);

  return {
    userData,
    businessDocuments,
    country_code_name,
    companyName,
    aboutCompany,
    gstReason,
    cin,
    gst,
    pan,
    hasGST,
    topLevelAddedCategories,
    socialLinks,
    socialData,
    socialName,
    //
    companyLogo,
    address1,
    address2,
    selectedCoverImage,
    city,
    states,
    additionalDetails1,
    additionalDetails2,
    additionalDetails3,
    //
    socialMediaMaster,
    whatsapp,
    location,
    isLoading,
    pincode,
    areaname,
    delSocialLinks,
  };
};
export default connect(mapStateToProps, {
  getUpdateUserDetails,
  submitBusinessDocuments,
  getExistingBusinessDocuments,
  getUpdateAboutCompanyDetails,
  putUpdateUserDetails,
  putUpdateAboutCompanyDetails,
  getProductMainCategory,
  setCompanyName,
  setAdditioanlDetails1,
  setAdditioanlDetails2,
  setAdditioanlDetails3,
  setAboutCompany,
  updateUserData,
  loading,
  pushMessage,
  setSocialLinks,
  setSocialData,
  getSocialLinks,
  postSocialLinks,
  setSocialName,
  putSocialLinks,
  getSocialMediaMasterData,
  deleteSocialLinksData,
  setWhatsappNumber,
  getTopLevelProductCategoriesByUser,
  setRequestLocation,
  setCity,
  setState,
  setAddress1,
  setAddress2,
  setPincode,
  setAreaName,
  delSocialLinkArr,
  resetDelSocialLinkArr,
})(UpdateProfileForm);
