import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as BandhanBankImg } from '../../assets/bandhan-bank-img.svg';
// import { ReactComponent as SecuritasImg } from '../../assets/securitas-img.svg';
// import { ReactComponent as BOBBankImg } from '../../assets/bob-bank-img.svg';
// import { ReactComponent as IciciBankImg } from '../../assets/icici-bank-img.svg';
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import CardMedia from '@mui/material/CardMedia';
// import { ReactComponent as PersonalLoansBanner } from '../../assets/credit-vas-landing-banner.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import Form from '../../components/DrawerForm/Form';
import useValidation from '../../hooks/useValidation';
import { ConfirmationDialog } from '../../components/ActionPopup';
import { postEnquiry } from '../B2BCategory/redux/actions/b2b-category-action';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getTwentyFourData } from './redux/actions/personal-service-action';
import FormService from '../../components/DrawerForm/FormService';
import { isMoreThan24HoursAgo } from '../FLFreelanceCategory/FL-Freelance-Category';
// import { ModalStyle } from '../../utils/constants';
// isMoreThan24HoursAgo

const theme = createTheme();

const PersonalLoanLandingForm = ({
  single_child_data,
  userData,
  postEnquiry,
  pushMessage,
  getTwentyFourData,
  enquiry,
  get_tnt_for_data,
  child_widget,
}) => {
  const { validateInput, errors } = useValidation();
  const { user } = userData || {};
  const { mobile_number = '', name = '', email = '' } = user || {};
  const history = useHistory();
  const [formVisible, setFormVisible] = useState(true);
  const [openCallModal, setOpenCallModal] = useState(false);
  const [hideForm, setHideForm] = useState(true);

  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);

  useEffect(() => {
    console.log('singleChildData :', single_child_data);
  }, [single_child_data]);

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  //     }, [])

  useEffect(() => {
    const regId = userData?.registration.id;
    getTwentyFourData(regId);
    if (isMoreThan24HoursAgo(enquiry?.createdAt)) {
      console.log('data on :', enquiry?.createdAt);
    }

    if (userData) {
      setHideForm(false);
      handleCloseCallModal();
    }
  }, []);

  useEffect(() => {
    console.log('get_tnt_for_data######### :', get_tnt_for_data);
    console.log('servic type :', single_child_data?.service_type);
    console.log('get tnt data type :', get_tnt_for_data?.type);
    const matchId = get_tnt_for_data.find(
      (element) =>
        element?.type_id === single_child_data?.id &&
        element?.type?.toLowerCase() ===
          single_child_data?.service_type?.toLowerCase()
    );
    console.log('MAtchID and serices', matchId);
    if (matchId) {
      setFormVisible(false);
    } else setFormVisible(true);
  }, [get_tnt_for_data, single_child_data]);

  useEffect(() => {
    console.log('hideForm', hideForm);
  }, [hideForm]);

  const requestCallBackSubmit = async (formData) => {
    const registrationId = userData?.registration?.id;
    if (hideForm == true) {
      setHideForm(false);
      handleOpenCallModal();
      return;
    }
    if (registrationId) {
      setHideForm(true);
      handleCloseCallModal();

      const { mobileNumber, comment, ...rest } = formData;
      const details = {
        ...rest,
        comments: comment,
        phone_number: mobileNumber,
        type: 'service',
        type_id: single_child_data?.id,
        registrationId,
      };
      console.log('Mobile number data :', formData);

      const postResponse = await postEnquiry(registrationId, details);
      if (postResponse)
        pushMessage(
          'Enquiry is submitted successfully. Admin will get back to you soon'
        );
      history.push('/successful');
    } else {
      history.push('/login');
    }
  };
  const handleLogin = () => {
    history.replace('/login');
  };

  const handleCancel = () => {
    history.replace('/personal-loan-landing');
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <ConfirmationDialog
          open={openCallModal}
          handleClose={handleCloseCallModal}
          title="Elynker"
          content="You are not logged in. Please log in to continue."
          confirmText="OK"
          cancelText="Cancel"
          onConfirm={handleLogin}
          onCancel={handleCancel}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3">
              <ArrowBackIosIcon
                onClick={() => {
                  // if(child_widget?.title !== "Credit Services" ){
                  //     history.replace("/homepage")
                  // } else{
                  //     history.replace("/credit-vas-landing")
                  // }
                  history.replace('/homepage');
                }}
              />
              <ElynkerLogo
                className="ms-3"
                width={'100px'}
                height={'30px'}
                onClick={() => {
                  history.push('/homepage');
                }}
              />
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />

            <Box
              className="mb-3"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <img
                src={single_child_data?.cover_image}
                style={{ width: '500px' }}
              />
              {/* <PersonalLoansBanner style={{ width: "100%" }} /> */}
            </Box>

            <Typography className="fs-20 fw-bold text-black mb-3 font-skmodernist">
              {/* Personal Loans */}
              {single_child_data?.title}
            </Typography>

            <Typography
              variant="body2"
              className="fs-14 text-gray mb-4 font-gotham"
            >
              {/* Get funds swiftly with tailored terms, ensuring financial flexibility and convenience */}
              {single_child_data?.description}
            </Typography>

            {/* <Typography className="fs-20 fw-bold text-black mb-3 font-skmodernist">
                            Our Verified Partners
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={3} md={1}>
                                <Box className="verified-partners-box">
                                    <Card className="verified-partners-card">
                                        <CardMedia >
                                            <BandhanBankImg />
                                        </CardMedia>
                                    </Card>
                                    <Typography className="fs-13 fw-normal text-darkgrey mb-3 font-gotham text-center">
                                        Bandhan Bank
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={3} md={1}>
                                <Box className="verified-partners-box">
                                    <Card className="verified-partners-card">
                                        <CardMedia >
                                            <SecuritasImg />
                                        </CardMedia>
                                    </Card>
                                    <Typography className="fs-13 fw-normal text-darkgrey mb-3 font-gotham text-center">
                                        Securitas
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={3} md={1}>
                                <Box className="verified-partners-box">
                                    <Card className="verified-partners-card">
                                        <CardMedia >
                                            <BOBBankImg />
                                        </CardMedia>
                                    </Card>
                                    <Typography className="fs-13 fw-normal text-darkgrey mb-3 font-gotham text-center">
                                        Bank of
                                        Baroda
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={3} md={1}>
                                <Box className="verified-partners-box">
                                    <Card className="verified-partners-card">
                                        <CardMedia >
                                            <IciciBankImg />
                                        </CardMedia>
                                    </Card>
                                    <Typography className="fs-13 fw-normal text-darkgrey mb-3 font-gotham text-center">
                                        ICICI
                                        Securities
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid> */}

            <Divider
              component="div"
              className="main-divider my-3"
              role="presentation"
            />
            {child_widget?.title !== 'Credit Services' ? (
              <div>
                <Grid container columnSpacing={8} alignItems="start">
                  <Grid item md={6} lg={6} xs={12}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className="mb-4 py-3 fs-15 font-skmodernist fw-bold"
                      sx={{
                        borderRadius: '6px',
                        textTransform: 'capitalize',
                        background: '#fff !important',
                        border: '1px solid #1843A0 !important',
                        color: '#1843A0 !important',
                        cursor: 'default !important',
                      }}
                    >
                      Subscribed Members: INR 4000
                    </Button>
                  </Grid>

                  <Grid item md={6} lg={6} xs={12}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className="mb-4 py-3 fs-15 font-skmodernist fw-bold"
                      sx={{
                        borderRadius: '6px',
                        textTransform: 'capitalize',
                        background: '#fff !important',
                        border: '1px solid #1843A0 !important',
                        color: '#1843A0 !important',
                        cursor: 'default !important',
                      }}
                    >
                      Non-Subscribed Members: INR 6000
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <></>
            )}

            {formVisible && (
              <>
                {/* <Typography className="fs-20 fw-bold text-black mb-3 font-skmodernist">
                            Request a Call Back from our Experts
                        </Typography> */}

                <FormService
                  onSubmit={requestCallBackSubmit}
                  validateInput={validateInput}
                  errors={errors}
                  mobile_number={mobile_number}
                  name={name}
                  email={email}
                  submitBtnText={'Apply Now'}
                  userData={userData}
                  hideForm={hideForm}
                />
              </>
            )}

            {!formVisible && (
              <div>
                <Alert severity="info">
                  {' '}
                  We already received your enquiry. Admin will get back to you
                  soon.
                </Alert>
              </div>
            )}

            {/* <div className="mb-3">
                            <InputLabel
                                htmlFor="inputEducation"
                                className="text-start fs-14 text-black font-skmodernist"
                            >
                                Name <span className="text-danger">*</span>
                            </InputLabel>
                            <FormControl
                                fullWidth
                                sx={{ my: 1 }}
                                variant="outlined"
                            >
                                <OutlinedInput
                                    id="inputEducation"
                                    // label="Mobile Number"
                                    type="text"
                                    placeholder="Enter name"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin="none"
                                />
                            </FormControl>
                        </div>

                        <div className="mb-3">
                            <InputLabel
                                htmlFor="inputEducation"
                                className="text-start fs-14 text-black font-skmodernist"
                            >
                                Email ID <span className="text-danger">*</span>
                            </InputLabel>
                            <FormControl
                                fullWidth
                                sx={{ my: 1 }}
                                variant="outlined"
                            >
                                <OutlinedInput
                                    id="inputEducation"
                                    // label="Mobile Number"
                                    type="text"
                                    placeholder="Enter email id"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin="none"
                                />
                            </FormControl>
                        </div>

                        <div className="mb-3">
                            <InputLabel
                                htmlFor="inputEducation"
                                className="text-start fs-14 text-black font-skmodernist"
                            >
                                Phone No. <span className="text-danger">*</span>
                            </InputLabel>
                            <FormControl
                                fullWidth
                                sx={{ my: 1 }}
                                variant="outlined"
                            >
                                <OutlinedInput
                                    id="inputEducation"
                                    // label="Mobile Number"
                                    type="text"
                                    placeholder="Enter phone no."
                                    required
                                    autoFocus
                                    fullWidth
                                    margin="none"
                                />
                            </FormControl>
                        </div>

                        <div className="mb-3">
                            <InputLabel
                                htmlFor="inputCompanyName"
                                className="text-start fs-14 text-black font-skmodernist"
                            >
                                Comment
                            </InputLabel>

                            <FormControl
                                fullWidth
                                sx={{ my: 1 }}
                                variant="outlined"
                            >
                                <TextareaAutosize
                                    id="inputAdditionalInfo"
                                    placeholder="Enter comment"
                                    minRows={3} // You can adjust the number of rows as needed
                                    maxRows={2} // You can adjust the number of rows as needed
                                />
                            </FormControl>
                        </div> 

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="mt-3 mb-3 btn-subscribe">
                            Apply Now
                        </Button> */}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

PersonalLoanLandingForm.propTypes = {
  single_child_data: PropTypes.array,
  userData: PropTypes.object,
  postEnquiry: PropTypes.func,
  pushMessage: PropTypes.func,
  getTwentyFourData: PropTypes.func,
  get_tnt_for_data: PropTypes.array,
  child_widget: PropTypes.object,
  enquiry: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { single_child_data } = state.personalService;
  const { userData } = state.signin;
  const { get_tnt_for_data } = state.personalService;
  const { child_widget } = state.creditVas;
  const { enquiry } = state.b2bVendorlisting;
  return {
    single_child_data,
    userData,
    get_tnt_for_data,
    child_widget,
    enquiry,
  };
};

export default connect(mapStateToProps, {
  postEnquiry,
  pushMessage,
  getTwentyFourData,
})(PersonalLoanLandingForm);
