// import/no-anonymous-default-export
import {
  UPDATE_ROOT_STATE,
  DEMO_TYPE,
  SHOW_LOADER,
  HIDE_LOADER,
  SEARCH_FROM
} from '../types';

const INITIAL_STATE = {
	demoState: '', // DEMO STATE BOILERPLATE
	root: {},
	isLoading: false,
	searchFrom: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
		case DEMO_TYPE:
			return {...state, [action.payload.prop]: action.payload.value};
		case UPDATE_ROOT_STATE:
			return { ...state, root: action.payload };
		case SHOW_LOADER:
			return { ...state, isLoading: action.payload };
		case HIDE_LOADER:
			return { ...state, isLoading: action.payload };
		case SEARCH_FROM:
			return { ...state, searchFrom: action.payload };
		default:
			return state;
  }
};
