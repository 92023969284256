import { SET_TOOLS_SERVICES } from "../types";

const initial_state = {
    set_tool : {}
}



const elynkerToolsReducer = (state=initial_state, action) => {
    switch (action.type){
        case SET_TOOLS_SERVICES:
            return {
                ...state,
                set_tool: action.payload
            }
        default:
            return state;
    }
}



export default elynkerToolsReducer;