import {
  HOURLY_RATE, RESET_HOURLY_RATE
} from '../types';

export const setFrlHourlyRate = (rate) =>
  function (dispatch) {
    dispatch({ type: HOURLY_RATE, payload: rate });
  };

export const resetHourlyRate = () =>
  function (dispatch) {
    dispatch({ type: RESET_HOURLY_RATE });
  };