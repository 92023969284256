import { loading } from "../../../../store/actions/rootActions";
import { getJobRatingData } from "../../../../utils/APIs/endpoints";
import { SAVE_JOB_DATA, SET_RATING_DATA } from "../types";


export const saveJobData = (data) => async (dispatch) => {
    dispatch({ type: SAVE_JOB_DATA, payload: data});
};

export const getJobRatingDataApi = (reg_id) => {
    return async (dispatch) => {
      dispatch(loading(true));
      try {
        const response = await getJobRatingData(reg_id);
        console.log('getJobRatingDataApi', response.data);
        if (response.status === 200) {
          dispatch({ type: SET_RATING_DATA, payload: response.data });
        }
      } catch (error) {
        console.log('error on rating data', error);
      } finally {
        dispatch(loading(false));
      }
    };
  };