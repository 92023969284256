import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  Button,
  Box,
  Select,
  MenuItem,
  Typography,
  Drawer,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CardMedia,
  Checkbox,
  CardContent,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import Responsive from '../../utils/responsive';
import { ReactComponent as AddCertificationIcon } from '../../assets/add-certification-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import AddTestimonialModal from './AddTestimonialModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import SearchIcon from '@mui/icons-material/Search';
import {
  checkSubDomainAvl,
  getSubDomains,
  setManageWebsiteData,
  setTestimonialWebsiteData,
  setTestinomial,
  getTestinomial,
  resetTestimonialWebsiteData,
  updateTestinomial,
  deleteTestinomial,
  getUserProduct,
  markProductData,
  addManageData,
  getSellerWebsiteData,
  localUpdateTestimonialData,
  setLocalTestimonial,
  deleteLocalTestinomial,
  updateManageData,
  resetManageWesiteData,
} from './redux/actions/manage-website-action';
import { connect } from 'react-redux';
import { ConfirmationDialog } from '../../components/ActionPopup';
import useValidation from '../../hooks/useValidation';
import {
  GAEvents,
  handleErrorsAndScroll,
  scrollToInput,
  sendDefaultPageEvent,
} from '../../utils/helper-funtions';
import { hasAnyErrorPresent } from '../../utils/validation';
import { useMediaQuery } from 'react-responsive';
import { PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { stickyButtonStyle } from '../../utils/sx-styles';
import { CLICKED, MANAGEWEBSITE_TITLE } from '../../utils/constants';
// const domainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.[A-Za-z]{2,}$/;
const theme = createTheme();
//setManageWebsiteData
const ManageWebsiteForm = ({
  manageWebsiteData,
  setManageWebsiteData,
  setTestimonialWebsiteData,
  getSubDomains,
  subDomains,
  testimonialWebsiteData,
  registrationId,
  setTestinomial,
  getTestinomial,
  all_testimonialWebsiteData,
  resetTestimonialWebsiteData,
  updateTestinomial,
  deleteTestinomial,
  deleteLocalTestinomial,
  getUserProduct,
  productData,
  addManageData,
  getSellerWebsiteData,
  localUpdateTestimonialData,
  setLocalTestimonial,
  deleted_testimonialWebsiteData,
  pushMessage,
  updateManageData,
  // setMarkProductData,
  resetManageWesiteData,
  isLoading,
  userData,
}) => {
  const [products, setProducts] = useState();
  const [testimonialIdToUpdate, setTestimonialIdToUpdate] =
    React.useState(false);
  const [isRefresh, setRefresh] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    const getAppData = async () => {
      try {
        await getSubDomains();
        await getTestinomial(registrationId);

        const userProductData = await new Promise((resolve) => {
          getUserProduct(
            registrationId,
            (data) => resolve(data),
            (err) => resolve(err)
          );
        });

        const sellerData =
          userProductData && userProductData.length > 0
            ? await getSellerWebsiteData(registrationId, userProductData)
            : await getSellerWebsiteData(registrationId, []);

        console.log('sellerData', sellerData);

        if (!sellerData) {
          setProducts(userProductData);
        } else {
          setProducts(sellerData);
        }
      } catch (error) {
        console.error('Error fetching app data:', error);
        setProducts([]);
      }
    };

    getAppData();
  }, [
    getSellerWebsiteData,
    getSubDomains,
    getTestinomial,
    getUserProduct,
    registrationId,
    isRefresh,
  ]);
  React.useEffect(() => {
    // console.log('manageWebsiteData', manageWebsiteData);
    if (manageWebsiteData.products) setProducts(manageWebsiteData.products);
  }, [manageWebsiteData]);
  React.useEffect(() => {
    if (!manageWebsiteData.domain)
      setManageWebsiteData({ subDomain: subDomains[0]?.value });
    else {
      setManageWebsiteData({ subDomain: manageWebsiteData.domain });
    }
    console.log('manageWebsiteData', manageWebsiteData);

    // console.log(
    //   'manageWebsiteData.domain',
    //   manageWebsiteData.domain,
    //   subDomains[0]?.value
    // );
  }, [
    manageWebsiteData.domain,
    manageWebsiteData.id,
    isRefresh,
    setManageWebsiteData,
    subDomains,
  ]);

  useEffect(() => {
    sendDefaultPageEvent(
      userData,
      'Manage Website',
      window.location.href,
      window.location.pathname
    );
  }, []);

  const { validateInput, errors, setErrors } = useValidation();
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = () => {
    resetTestimonialWebsiteData();
    setTestimonialIdToUpdate(null);
    setIsDrawerOpen(!isDrawerOpen);
    setTestinomialError(null);
  };
  const sucessCallBack = (msg) => {
    pushMessage(msg);
    const url = `https://${manageWebsiteData?.subDomainUrl}.${manageWebsiteData?.subDomain}`;
    GAEvents('Manage Website', 'Manage Website', 'Save_Success', {
      reg_uuid: userData?.registration?.uuid || '',
      company_name: userData?.registration?.company_name || '',
      subdomain: manageWebsiteData?.subDomainUrl,
      domain: manageWebsiteData?.subDomain,
      url: url,
    });
  };
  const errorCallback = (msg) => {
    const url = `https://${manageWebsiteData?.subDomainUrl}.${manageWebsiteData?.subDomain}`;
    pushMessage(msg);
    GAEvents('Manage Website', 'Manage Website', 'Save_Error', {
      reg_uuid: userData?.registration?.uuid || '',
      company_name: userData?.registration?.company_name || '',
      subdomain: manageWebsiteData?.subDomainUrl,
      domain: manageWebsiteData?.subDomain,
      url: url,
    });
  };
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setTestinomialError(null);
    resetTestimonialWebsiteData();
    setTestimonialIdToUpdate(null);
    setOpenModal(false);
    setTestinomialError(null);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirmDelete = () => {
    deleteLocalallyTestimonialData(
      testimonialWebsiteData?.id,
      testimonialWebsiteData?.local_id,
      all_testimonialWebsiteData
    );
    handleClose();
  };

  const handleCheckboxToggle = (index) => {
    const updatedProducts = [...products];
    const product = updatedProducts[index];
    validateInput('checkedCount', 1);
    if (product.checked) {
      product.checked = false;
    } else {
      const checkedCount = updatedProducts.filter((p) => p.checked).length;
      if (checkedCount < 16) {
        product.checked = true;
      } else {
        alert('You can select a maximum of 16 items.');
      }
    }

    setProducts(updatedProducts);
  };

  const [domainError, setDomainErrors] = React.useState('');
  const handleSubdomainChange = (e) => {
    e.preventDefault();
    const input = e.target.value.toLowerCase();

    const validSubdomainRegex = /^[a-zA-Z0-9-]*$/;

    if (validSubdomainRegex.test(input)) {
      if (input.length <= 14) {
        validateInput('subDomainUrl', input);
        setManageWebsiteData({ subDomainUrl: input });

        const getData = async (_input) => {
          try {
            const response = await checkSubDomainAvl(_input);
            if (response.status === 200) {
              setDomainErrors('Name already exists');
            } else {
              setDomainErrors('');
            }
          } catch (err) {
            console.log('Error checking subdomain availability:', err);
            setDomainErrors('');
          }
        };
        getData(`${input}.${manageWebsiteData?.subDomain}`);
      } else {
        // setDomainErrors('Subdomain must be 14 characters or less.');
      }
    } else {
      setDomainErrors('');
    }
  };
  const onChangeOfSubDomain = (e) => {
    setManageWebsiteData({ subDomain: e.target.value });
    validateInput('subDomain', e.target.value);
    // console.log('onChangeOfSubDomain', e.target.value);
  };

  //   const setTestimonialChanges = (e) => {
  //     const { name, value } = e.target;
  //     setTestimonialWebsiteData({ [name]: value });
  //   };

  const {
    validateInput: validateTestimonial,
    errors: testimonialError,
    setErrors: setTestinomialError,
  } = useValidation();
  const onTestimonialChange = (e) => {
    const { name, value } = e.target;
    setTestimonialWebsiteData({ [name]: value });
    validateTestimonial([name], value);
  };
  React.useEffect(() => {
    console.log('all_testimonialWebsiteData', all_testimonialWebsiteData);
    if (all_testimonialWebsiteData?.length > 0) {
      validateInput('websiteTestimonial', all_testimonialWebsiteData?.length);
    }
  }, [all_testimonialWebsiteData]);

  const onTestimonialSave = () => {
    all_testimonialWebsiteData?.forEach((data) => {
      const { id, ...rest } = data;
      if (id) {
        updateTestinomial(id, rest, sucessCallBack, errorCallback);
      } else {
        setTestinomial(
          {
            registrationId,
            ...data,
          },
          sucessCallBack,
          errorCallback
        );
      }
      console.log(
        'deleted_testimonialWebsiteData',
        deleted_testimonialWebsiteData
      );
    });
    if (
      deleted_testimonialWebsiteData &&
      deleted_testimonialWebsiteData.length > 0
    ) {
      deleted_testimonialWebsiteData.forEach((ids) => {
        deleteTestinomial(ids);
      });
    }
  };

  const onTestimonialLocalSave = () => {
    // console.log(
    //   'onTestimonialSave',
    //   !testimonialWebsiteData?.client_name ||
    //     !testimonialWebsiteData.designation ||
    //     !testimonialWebsiteData?.testimonial
    // );
    // console.log('onTestimonialSave error', testimonialError);
    if (
      !testimonialWebsiteData?.client_name ||
      !testimonialWebsiteData.designation ||
      !testimonialWebsiteData?.testimonial
    ) {
      if (!testimonialWebsiteData?.client_name) {
        scrollToInput('client_name');
        validateTestimonial('client_name');
      }
      if (!testimonialWebsiteData.designation) {
        scrollToInput('designation');
        validateTestimonial('designation');
      }
      if (!testimonialWebsiteData?.testimonial) {
        scrollToInput('testimonial');
        validateTestimonial('testimonial');
      }
    } else {
      if (!hasAnyErrorPresent(testimonialError)) {
        // console.log('testimonialWebsiteData', {
        //   registrationId,
        //   testimonialWebsiteData,
        // });
        if (testimonialIdToUpdate) {
          // console.log('on updateTestinomial', {
          //   registrationId,
          //   ...testimonialWebsiteData,
          // });

          const { id, ...rest } = {
            registrationId,
            ...testimonialWebsiteData,
          };
          console.log('data going', rest);

          localUpdateTestimonialData(
            id,
            {
              registrationId,
              ...testimonialWebsiteData,
            },
            all_testimonialWebsiteData
          );
        } else {
          if (!testimonialWebsiteData?.id) {
            setLocalTestimonial(
              {
                registrationId,
                local_id: Math.random(),
                ...testimonialWebsiteData,
              },
              all_testimonialWebsiteData
            );
          } else {
            setLocalTestimonial(
              {
                registrationId,
                ...testimonialWebsiteData,
              },
              all_testimonialWebsiteData
            );
          }
        }
        setIsDrawerOpen(false);
        handleCloseModal();
        setIsDrawerOpen(false);
        setTestimonialIdToUpdate(null);
      } else {
        handleErrorsAndScroll(testimonialError);
      }
    }
  };

  // const deleteTestimonialData = (id) => {
  //   console.log('deleteTestimonialData', id);
  //   deleteTestinomial(id, all_testimonialWebsiteData);
  // };
  const deleteLocalallyTestimonialData = (id) => {
    // console.log('deleteTestimonialData', id);
    deleteLocalTestinomial(
      id,
      testimonialWebsiteData.local_id,
      all_testimonialWebsiteData
    );
  };

  const searchProduct = (e) => {
    const value = e.target.value.toLowerCase();
    if (value === '') {
      setProducts(productData);
    } else {

      const filterData =  productData?.filter((prod)=>prod?.title?.toLowerCase()?.includes(value));
      setProducts(filterData);
    }
  };
  React.useEffect(() => {
    console.log('productData useEffect', productData);
  }, [productData]);
  const handleSubmit = () => {
    const checkedCount = (Array.isArray(products) ? products : [])?.filter(
      (p) => p.checked
    ).length;

    const product_ids = (Array.isArray(productData) ? productData : [])
      ?.filter((product) => product.checked)
      .map((product) => product.id)
      .join(',');

    console.log(
      'handleSubmit checkedCount',
      product_ids,
      productData,
      checkedCount
    );
    if (productData && productData.length == 0) {
      setConfirmationMessageType('navigation');
      setOpen(true);
    }
    // console.log(
    //   'handleSubmit111',
    //   manageWebsiteData?.subDomainUrl,
    //   manageWebsiteData?.subDomain,
    //   // all_testimonialWebsiteData.length,
    //   checkedCount
    // );
    // console.log('handleSubmit111 errors', errors);
    // console.log(
    //   'manageWebsiteData',

    //   !manageWebsiteData.subDomainUrl,
    //   !manageWebsiteData?.subDomain,
    //   all_testimonialWebsiteData.length == 0,
    //   checkedCount == 0
    // );

    if (
      !manageWebsiteData.subDomainUrl ||
      !manageWebsiteData?.subDomain ||
      all_testimonialWebsiteData?.length == 0 ||
      checkedCount == 0
    ) {
      if (!manageWebsiteData?.subDomainUrl) {
        scrollToInput('subDomainUrl');
        validateInput('subDomainUrl');
      }
      if (!manageWebsiteData?.subDomain) {
        scrollToInput('subDomain');
        validateInput('subDomain');
      }
      if (all_testimonialWebsiteData.length == 0) {
        scrollToInput('testimonial');
        validateInput('websiteTestimonial', 0);
      }
      if (!checkedCount || checkedCount == 0) {
        scrollToInput('checkedCount');
        validateInput('checkedCount', 0);
      }
    } else {
      const obj = {
        subdomain: manageWebsiteData?.subDomainUrl,
        domain: manageWebsiteData?.subDomain,
        url: `https://${manageWebsiteData?.subDomainUrl}.${manageWebsiteData?.subDomain}`,
        product_ids,
        registrationId,
      };
      console.log('obj manageWebsiteData handleSubmit', manageWebsiteData);

      manageWebsiteData && !manageWebsiteData.id
        ? addManageData(obj, sucessCallBack, errorCallback, productData)
        : updateManageData(
            { ...obj, registrationId },
            manageWebsiteData?.id,
            sucessCallBack,
            errorCallback
          );
      onTestimonialSave();
      setRefresh((prev) => !prev);
    }
    setErrors(null);
  };
  const [confirmationMessageType, setConfirmationMessageType] =
    React.useState('delete');
  const handleNavigation = () => {
    history.push('/my-catalog');
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <ConfirmationDialog
          open={open}
          handleClose={handleClose}
          title="Elynker"
          content={
            confirmationMessageType === 'delete'
              ? 'Are you sure you want to delete testimonial?'
              : 'Catalog not found. Would you like to create one? Click yes to create!'
          }
          onConfirm={
            confirmationMessageType === 'delete'
              ? handleConfirmDelete
              : handleNavigation
          }
          confirmText="Yes"
          cancelText="No"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={async () => {
                  // clear image array or product data
                  resetManageWesiteData();
                  history.goBack();
                }}
              />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '30px' }}>
              {Responsive(
                <>
                  <Box className="d-flex  align-items-center my-4" gap={4}>
                    {/* <Button
                      variant="text"
                      type="button"
                      fullWidth
                      className="btn-publish"
                    >
                      Publish
                    </Button> */}
                    {/* <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      className="btn-preview px-4"
                    >
                      Preview
                    </Button> */}
                  </Box>
                </>,
                <>
                  <Box
                    className="d-flex justify-content-end align-items-center my-4"
                    gap={4}
                  >
                    {/* <Button
                      variant="text"
                      type="button"
                      className="btn-publish"
                    >
                      Publish
                    </Button> */}
                    {/* <Button
                      type="submit"
                      variant="contained"
                      className="btn-preview px-4"
                    >
                      Preview
                    </Button> */}
                  </Box>
                </>
              )}
              <Grid item md={6} lg={6} xs={12} className="mb-2">
                <InputLabel
                  htmlFor="inputCompanyName"
                  sx={{
                    color: 'text.primary',
                    fontSize: '0.875rem',
                    fontFamily: 'SkModernist, sans-serif',
                    textAlign: 'left',
                    mb: 1,
                  }}
                >
                  Website URL
                </InputLabel>
                <Typography
                  id="inputCompanyName"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.875rem', // 14px
                    fontFamily: 'SkModernist, sans-serif',
                    wordBreak: 'break-all',
                    mb: 1,
                  }}
                >
                  {`( https://${manageWebsiteData?.subDomainUrl || ''}.${manageWebsiteData?.subDomain || ''} )`}
                </Typography>
              </Grid>
              <Grid container columnSpacing={4} alignItems="start">
                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <InputLabel
                    htmlFor="inputCompanyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Website Name
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="subDomainUrl"
                      name="subDomainUrl"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter Website Name"
                      required
                      autoFocus
                      fullWidth
                      value={manageWebsiteData.subDomainUrl}
                      margin="none"
                      autoComplete="off"
                      onChange={handleSubdomainChange}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors?.subDomainUrl || domainError}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <InputLabel
                    htmlFor="inputCompanyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Select Domain
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <Select
                      displayEmpty
                      id="subDomain"
                      name="subDomain"
                      onChange={onChangeOfSubDomain}
                      value={manageWebsiteData?.subDomain || ''}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {subDomains &&
                        subDomains.map((sd) => (
                          <MenuItem key={sd?.id} value={sd?.value}>
                            {sd?.value}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {errors?.subDomain}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  {Responsive(
                    <Button
                      className="d-flex p-0 align-items-center"
                      onClick={toggleDrawer}
                    >
                      <AddCertificationIcon />
                      <Typography
                        variant="body1"
                        align="left"
                        className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
                        gutterBottom
                      >
                        Add Testimonial
                      </Typography>
                    </Button>,
                    <Button
                      className="d-flex p-0 align-items-center"
                      onClick={handleOpenModal}
                    >
                      <AddCertificationIcon />
                      <Typography
                        variant="body1"
                        align="left"
                        className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
                        gutterBottom
                      >
                        Add Testimonial
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Drawer
                xs={12}
                anchor="bottom"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: 'auto',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  },
                }}
              >
                <div className="p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <Typography
                      variant="h5"
                      align="left"
                      alignItems="center"
                      className="fs-18 mb-2 fw-bold font-skmodernist "
                      gutterBottom
                    >
                      Add Testimonial
                    </Typography>
                    <CloseIcon onClick={()=>{
                       GAEvents(MANAGEWEBSITE_TITLE, "Add Testimonial", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Close"
                      });
                      toggleDrawer();
                      }} />
                  </div>
                  <div className="mb-3">
                    <InputLabel
                      htmlFor="inputEducation"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Client Name <span className="text-mandatory">*</span>
                    </InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <OutlinedInput
                        id="client_name"
                        name="client_name"
                        // label="Mobile Number"
                        type="text"
                        placeholder="Enter client name"
                        value={testimonialWebsiteData.client_name}
                        onChange={onTestimonialChange}
                        fullWidth
                        margin="none"
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {testimonialError?.client_name}
                    </FormHelperText>
                  </div>

                  <div className="mb-3">
                    <InputLabel
                      htmlFor="inputEducation"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Designation <span className="text-mandatory">*</span>
                    </InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <OutlinedInput
                        id="designation"
                        name="designation"
                        value={testimonialWebsiteData.designation}
                        onChange={onTestimonialChange}
                        // label="Mobile Number"
                        type="text"
                        placeholder="Enter designation"
                        fullWidth
                        margin="none"
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {testimonialError?.designation}
                    </FormHelperText>
                  </div>

                  <div className=" mb-3">
                    <InputLabel
                      htmlFor="inputCompanyName"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Testimonial <span className="text-mandatory">*</span>
                    </InputLabel>

                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <TextareaAutosize
                        id="testimonial"
                        name="testimonial"
                        value={testimonialWebsiteData.testimonial}
                        onChange={onTestimonialChange}
                        placeholder="Enter testimonial"
                        minRows={3} // You can adjust the number of rows as needed
                        maxRows={2} // You can adjust the number of rows as needed
                        maxLength={500}
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {testimonialError?.testimonial}
                    </FormHelperText>
                  </div>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{
                      borderRadius: '6px',
                      textTransform: 'capitalize',
                    }}
                    onClick={()=>{
                      GAEvents(MANAGEWEBSITE_TITLE, "Manage Website", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Save"
                      });
                      onTestimonialLocalSave();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Drawer>
              <Divider
                component="div"
                className="main-divider my-3"
                role="presentation"
              />{' '}
              <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                {/* {all_testimonialWebsiteData &&
                all_testimonialWebsiteData.length == 0
                  ? 'Atleast one Testimonial is required'
                  : ''} */}
                {errors?.websiteTestimonial}
              </FormHelperText>
              <Grid
                md={12}
                lg={12}
                xs={12}
                className="custom-accordion product-accordion"
                sx={{ marginBottom: '10px' }}
              >
                <Accordion
                // defaultExpanded={
                //     certificates && certificates.length > 0 ? true : false
                // }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="testimonial"
                    name={'testimonial'}
                    className="fs-15 font-skmodernist text-black fw-bold mb-3"
                  >
                    Testimonials {`(${all_testimonialWebsiteData.length || 0})`}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid
                      container
                      columnSpacing={4}
                      rowSpacing={4}
                      alignItems="stretch"
                    >
                      {all_testimonialWebsiteData &&
                        all_testimonialWebsiteData.map((testimonialData) => (
                          <>
                            <Grid item md={6} lg={6} xs={12}>
                              <Box className="website-testimonial-card">
                                <div
                                  className="d-flex justify-content-between align-items-start mb-2"
                                  style={{ gap: '10px' }}
                                >
                                  <div>
                                    <Typography
                                      className="fs-14 fw-normal font-gotham text-darkgrey line-height-24"
                                      sx={{
                                        textAlign: 'left',
                                        textWrap: 'balance',
                                        lineBreak: 'anywhere',
                                      }}
                                    >
                                      {testimonialData?.testimonial}
                                    </Typography>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{ gap: '10px' }}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        // console.log(
                                        //   'testimonialData on click',
                                        //   testimonialData
                                        // );
                                        GAEvents(MANAGEWEBSITE_TITLE, "Edit Testimonial", CLICKED, {
                                          userId: userData?.id || sessionStorage.getItem('random_id'),
                                          reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                          title: "Edit"
                                        });

                                        setTestimonialIdToUpdate(true);
                                        setTestimonialWebsiteData({
                                          testimonial:
                                            testimonialData?.testimonial,
                                          client_name:
                                            testimonialData?.client_name,
                                          designation:
                                            testimonialData?.designation,
                                          id: testimonialData?.id,
                                        });
                                        if (isTabletOrMobile) {
                                          setIsDrawerOpen(true);
                                        } else {
                                          setOpenModal(true);
                                        }
                                      }}
                                      className="cat-edit-icon p-0"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      className="cat-delete-icon p-0"
                                      onClick={() => {
                                        GAEvents(MANAGEWEBSITE_TITLE, "Delete Testimonial", CLICKED, {
                                          userId: userData?.id || sessionStorage.getItem('random_id'),
                                          reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                          title: "Delete"
                                        });
                                        setTestimonialWebsiteData({
                                          id: testimonialData?.id,
                                          local_id: testimonialData?.local_id,
                                        });
                                        handleOpen();
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="fs-15 text-black fw-bold font-skmodernist line-height-20 mb-2"
                                  gutterBottom
                                  sx={{
                                    textAlign: 'left',
                                    textWrap: 'balance',
                                    lineBreak: 'anywhere',
                                  }}
                                >
                                  {testimonialData?.client_name}
                                </Typography>
                                <Typography
                                  className="fs-13 font-gotham text-slatgray"
                                  sx={{
                                    textAlign: 'left',
                                    textWrap: 'balance',
                                    lineBreak: 'anywhere',
                                  }}
                                >
                                  {testimonialData?.designation}
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                md={12}
                lg={12}
                xs={12}
                className="custom-accordion product-accordion"
                sx={{ marginBottom: '10px' }}
              >
                <Accordion expanded={products && products.length > 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="fs-15 font-skmodernist text-black fw-bold mb-3"
                  >
                    Select Product
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" alignItems="center" mb={2}>
                      <TextField
                        fullWidth
                        onChange={searchProduct}
                        placeholder="Search catalog..."
                        variant="outlined"
                        InputProps={{
                          endAdornment: <SearchIcon color="action" />,
                        }}
                      />
                    </Box>
                    <Grid
                      container
                      columnSpacing={4}
                      key={'checkedCount'}
                      rowSpacing={4}
                      alignItems="start"
                    >
                      {products &&
                        products?.length > 0 &&
                        products?.map((product, index) => (
                          <Grid
                            item
                            md={6}
                            lg={6}
                            xs={12}
                            key={index}
                            className="website-catalog-card"
                          >
                            <Box className="d-flex justify-content-between w-100 py-3 website-catalog-content">
                              <Box className="d-flex" sx={{ gap: '15px' }}>
                                <CardMedia
                                  component="img"
                                  sx={{ width: 60, height: 60 }}
                                  image={
                                    PRODUCT_IMAGE_BASE_URL +
                                    product.product_images
                                  }
                                  alt={product.name}
                                />

                                <CardContent sx={{ padding: 0 }}>
                                  <Typography className="fs-15 font-skmodernist text-black">
                                    {product.title}
                                  </Typography>
                                  <Typography className="fs-14 font-skmodernist text-secondary">
                                    {product.budget}
                                  </Typography>
                                </CardContent>
                              </Box>

                              <Checkbox
                                checked={product?.checked}
                                onChange={() => handleCheckboxToggle(index)}
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'primary.main',
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <FormHelperText
                sx={{ color: 'red', marginBottom: 5, marginTop: 2 }}
                id="email-helper-text"
              >
                {errors?.checkedCount}
              </FormHelperText>
            </form>
          </Grid>
        </Grid>
        <AddTestimonialModal
          testimonialWebsiteData={testimonialWebsiteData}
          setTestimonialWebsiteData={setTestimonialWebsiteData}
          open={openModal}
          onClose={handleCloseModal}
          registrationId={registrationId}
          onTestimonialChange={onTestimonialChange}
          onTestimonialSave={onTestimonialLocalSave}
          errors={testimonialError}
          userData={userData}
        />

        <Box sx={{ width: '100%', marginBottom: '2px', marginTop: '50px' }}>
          <Box sx={[stickyButtonStyle]}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              name="add_product"
              value="add_product"
              className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
              onClick={()=>{
                GAEvents(MANAGEWEBSITE_TITLE, "Manage Website", CLICKED, {
                  userId: userData?.id || sessionStorage.getItem('random_id'),
                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                  title: "Close"
                });
                handleSubmit();
              }}
              sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
            >
              {'Save'}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
ManageWebsiteForm.propTypes = {
  setManageWebsiteData: PropTypes.func,
  manageWebsiteData: PropTypes.any,
  getSubDomains: PropTypes.func,
  subDomains: PropTypes.any,
  setTestimonialWebsiteData: PropTypes.any,
  testimonialWebsiteData: PropTypes.any,
  registrationId: PropTypes.any,
  setTestinomial: PropTypes.func,
  getTestinomial: PropTypes.func,
  resetTestimonialWebsiteData: PropTypes.func,
  all_testimonialWebsiteData: PropTypes.any,
  updateTestinomial: PropTypes.func,
  deleteTestinomial: PropTypes.func,
  getUserProduct: PropTypes.func,
  productData: PropTypes.any,
  markProductData: PropTypes.func,
  addManageData: PropTypes.func,
  getSellerWebsiteData: PropTypes.func,
  setMarkProductData: PropTypes.func,
  localUpdateTestimonialData: PropTypes.func,
  setLocalTestimonial: PropTypes.func,
  deleteLocalTestinomial: PropTypes.func,
  deleted_testimonialWebsiteData: PropTypes.any,
  pushMessage: PropTypes.func,
  updateManageData: PropTypes.func,
  resetManageWesiteData: PropTypes.func,
  isLoading: PropTypes.any,
  userData: PropTypes.object,
};

const mapStateToProps = (state) => {
  const manageWebsiteData = state.manageWebsite?.manageWebsiteData;
  const subDomains = state.manageWebsite?.subDomains;
  const testimonialWebsiteData = state.manageWebsite?.testimonialWebsiteData;
  const all_testimonialWebsiteData =
    state.manageWebsite?.all_testimonialWebsiteData;
  const productData = state.manageWebsite?.productData;
  const deleted_testimonialWebsiteData =
    state.manageWebsite?.deleted_testimonialWebsiteData;
  const { isLoading } = state.root;

  // console.log(
  //   'state.signin?.registration',
  //   state.signin.userData.registration?.id
  // );

  const registrationId = state.signin.userData.registration?.id;
  const userData = state.signin.userData;
  return {
    manageWebsiteData,
    subDomains,
    testimonialWebsiteData,
    registrationId,
    all_testimonialWebsiteData,
    productData,
    deleted_testimonialWebsiteData,
    isLoading,
    userData,
  };
};

export default connect(mapStateToProps, {
  setManageWebsiteData,
  getSubDomains,
  setTestimonialWebsiteData,
  setTestinomial,
  getTestinomial,
  resetTestimonialWebsiteData,
  updateTestinomial,
  deleteTestinomial,
  deleteLocalTestinomial,
  getUserProduct,
  markProductData,
  addManageData,
  getSellerWebsiteData,
  localUpdateTestimonialData,
  setLocalTestimonial,
  pushMessage,
  updateManageData,
  resetManageWesiteData,
})(ManageWebsiteForm);
