import { APPLICANT_COUNT, SAVE_ALL_JOBS, SET_DELETE_JOB_POST_POPUP_VISIBLE, SET_VIEW_JOB } from '../types';

const INITIAL_STATE = {
    allJobs: [],
    applicant_count: null,
    deletePopup: false,
    viewJob: ''
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SAVE_ALL_JOBS:
            return {
                ...state,
                allJobs: action.payload
            }
        case APPLICANT_COUNT:
            return {
                ...state,
                applicant_count: action.payload
            }
        case SET_DELETE_JOB_POST_POPUP_VISIBLE:
            return {...state, deletePopup: action.payload}
        case SET_VIEW_JOB:
            return {
                ...state,
                viewJob: action.payload
            }
        default:
            return state;
    }
}