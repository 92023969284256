import {
  GET_PRODUCT_ERROR,
  GET_PRODUCT_LIST,
  RESET_MY_SERVCIE_DATA,
} from '../types';
import {
  getAllCatalogProducts,
  deleteSingleProductUpdate,
} from '../../../../utils/APIs/endpoints';

export const getProductList = (id) =>
  async function (dispatch) {
    try {
      const response = await getAllCatalogProducts(id);
      if (response.status === 200) {
        const prodList = response.data;
        await dispatch({
          type: GET_PRODUCT_LIST,
          payload: prodList,
        });
      } else {
        await dispatch({
          type: RESET_MY_SERVCIE_DATA,
        });
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
      if (
        error?.response?.status === 500 &&
        error?.response?.data?.error === 'No Product Found'
      ) {
        await dispatch({
          type: RESET_MY_SERVCIE_DATA,
        });
      } else {
        dispatch({
          type: GET_PRODUCT_ERROR,
          payload: error?.response?.data,
        });
      }
    }
  };

export const deleteSingleProduct = (sku) =>
  async function (dispatch) {
    try {
      const response = await deleteSingleProductUpdate(sku);
      if (response.status === 200) {
        //Reload list
        // await dispatch({
        //   type: RESET_MY_SERVCIE_DATA,
        // });
      } else {
        await dispatch({
          type: GET_PRODUCT_ERROR,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
      dispatch({
        type: GET_PRODUCT_ERROR,
        payload: error?.response?.data,
      });
    }
  };

export const resetMyServiceData = () =>
  function (dispatch) {
    dispatch({ type: RESET_MY_SERVCIE_DATA });
  };

export default getProductList;
