import React from 'react';
import { Typography, Box, Divider, IconButton, Container } from '@mui/material';
import {ReactComponent as FacebookIcon} from '../../assets/facebook-cat-icon.svg';
import {ReactComponent as TwitterIcon} from '../../assets/twitter-cat-icon.svg';
import {ReactComponent as LinkedInIcon} from '../../assets/linkedin-cat-icon.svg';
import {ReactComponent as InstagramIcon} from '../../assets/instagram-cat-icon.svg';
import ProductImg from '../../assets/product-catalog-detail-img.png';
import Responsive from '../../utils/responsive';

const ProductCatalogDetailForm = () => {
    return (
        <Container>
            <Box maxWidth="sm" class style={{ maxWidth: '800px', margin: '80px auto' }}>
                <img
                    src={ProductImg}
                    alt="Company Image"
                    style={{ width: '100%', marginBottom: '60px' }}
                />

                <Typography className='fs-34 fs-m-20 font-skmodernist fw-bold line-height-20 mb-4'>
                    About Company
                </Typography>
                <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                    a type specimen book. It has survived not only five Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                </Typography>
                <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since the 1500s, when an unknown printer took a galley
                </Typography>

                <Divider style={{ margin: '20px 0' }} />

                <Typography className='fs-34 fs-m-20 font-skmodernist fw-bold line-height-20 mb-4'>
                    Key Details
                </Typography>
                <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                    a type specimen book. It has survived not only five Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                </Typography>
                <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                    a type specimen book. It has survived not only five Lorem Ipsum
                </Typography>

                <Divider style={{ margin: '20px 0' }} />

                <Box display="flex" justifyContent="start" alignItems="center" marginTop="20px" marginBottom="50px">
                    <IconButton className='ps-0' href="https://www.facebook.com">
                        <FacebookIcon />
                    </IconButton>
                    <IconButton color="primary" href="https://www.twitter.com">
                        <TwitterIcon />
                    </IconButton>
                    <IconButton color="primary" href="https://www.linkedin.com">
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton color="primary" href="https://www.instagram.com">
                        <InstagramIcon />
                    </IconButton>
                </Box>

                {Responsive (
                    <Box className="d-flex align-items-center py-3" sx={{
                        gap: '10px', position: 'sticky',
                        bottom: 0,
                        // left: 0,
                        justifyContent: 'space-between',
                        background: "#fff"
                    }}>
                        <Typography className='fs-14  font-skmodernist'>Powered by: www.elynker.com</Typography>
                        <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '30%' }} />
                        <Typography className='fs-18 font-skmodernist'>03</Typography>
                    </Box>,
                    <Box className="d-flex align-items-center py-3" sx={{
                        gap: '24px', position: 'sticky',
                        bottom: 0,
                        // left: 0,
                        justifyContent: 'space-between',
                        background: "#fff"
                    }}>
                        <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography>
                        <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '55%' }} />
                        <Typography className='fs-18 font-skmodernist'>03</Typography>
                    </Box>
                )}

                
            </Box>
        </Container>

    );
}

export default ProductCatalogDetailForm;
