export const SUBMIT_REQUIREMENT = "SUBMIT_REQUIREMENT";
export const SAVE_MOQ = "SAVE_MOQ";
export const SAVE_BTYPE = "SAVE_BTYPE";
export const SAVE_NAME = "SAVE_NAME";
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const SAVE_DESCRIPTION = "SAVE_DESCRIPTION";
export const SAVE_LOCATION = "SAVE_LOCATION";
export const SAVE_B2B_CATEGORY = "SAVE_B2B_CATEGORY";
export const SAVE_FL_CATEGORY = "SAVE_FL_CATEGORY";
export const SAVE_BUDGET = "SAVE_BUDGET";
export const RESET_REQUIREMENT_STATE = "RESET_REQUIREMENT_STATE";
export const SINGLE_REQUIREMENT = "SINGLE_REQUIREMENT";
export const PAGE_ACTION_TYPE = "PAGE_ACTION_TYPE";
export const REQ_SUB_CATEGORY = "REQ_SUB_CATEGORY";