export const SAVE_OTP = 'SAVE_OTP';
export const SEND_OTP = 'SEND_OTP';
export const SAVE_SIGNIN_DATA = 'SAVE_SIGNIN_DATA';
export const RESEND_OTP = 'RESEND_OTP';
export const ENABLE_RESEND = 'ENABLE_RESEND';
export const TIMER_DISABLE = 'TIMER_DISABLE';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SET_POST_LOGIN_ROUTE_NAME = 'SET_POST_LOGIN_ROUTE_NAME';
export const RESET_OTP_DATA = 'RESET_OTP_DATA';
export const UPDATE_SIGNIN_DATA = 'UPDATE_SIGNIN_DATA';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_UPDATED_NEW_DATA = 'RESET_UPDATED_NEW_DATA';
export const COPY_NEW_USER_DATA = 'COPY_NEW_USER_DATA';
export const UPDATE_REG_DATA = 'UPDATE_REG_DATA';
export const SAVE_OFFLINE_DATA = 'SAVE_OFFLINE_DATA';
