/* eslint-disable no-useless-catch */
import { postReviews, getReviews } from '../../../../utils/APIs/endpoints';
import { STATUS_CODE_200, STATUS_CODE_201 } from '../../../../utils/constants';
import {
  ADD_FEEDBACK,
  CLEAR_FEEDBACK,
  REMOVE_FEEDBACK_IMAGE,
  SAVE_FEEDBACK,
} from '../types';
export function addFeedback(payload) {
  return async (dispatch) => {
    dispatch({ type: ADD_FEEDBACK, payload });
  };
}
export const removeFeedbackImages = (index) =>
  function (dispatch) {
    dispatch({ type: REMOVE_FEEDBACK_IMAGE, payload: index });
  };
export function saveFeedback(userData, feedback, id) {
  return async (dispatch) => {
    let formData = new FormData();
    if (feedback?.images && feedback?.images.length > 0) {
      feedback.images.forEach(function (image) {
        formData.append('image', image);
      });
    }
    formData.append('reviewer_user_id', userData?.user?.id || '');

    formData.append('review_description', feedback?.feedbackDetail || '');
    formData.append('review_star', feedback?.feedbackRating || '');
    formData.append('registrationId', id || '');

    try {
        const addedReviewResponse = await postReviews(formData);
      if (
        addedReviewResponse &&
        (addedReviewResponse.status === STATUS_CODE_200 ||
          addedReviewResponse.status === STATUS_CODE_201)
      ) {
        dispatch(getFeedback());
        return addedReviewResponse.data?.message;
      } else {
        /* eslint-disable-next-line */
        throw new Error('Error adding review.');
      }
    } catch (error) {
      throw error;
    }
  };
}

export function getFeedback() {
  return async (dispatch) => {
    try {
      const addedReviewResponse = await getReviews();
      if (
        addedReviewResponse.status === STATUS_CODE_200 ||
        addedReviewResponse.status === STATUS_CODE_201
      ) {
        dispatch({
          type: SAVE_FEEDBACK,
          payload: addedReviewResponse?.data?.data,
        });
        return addedReviewResponse.data?.message;
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export const clearFeedBackFormData = () =>
  function (dispatch) {
    dispatch({ type: CLEAR_FEEDBACK });
  };
