import {
  SAVE_PRODUCT_IMAGES,
  GET_PRODUCTS_DATA,
  SAVE_PRODUCT_DATA,
  PRODUCT_DESC,
  PRODUCT_DETAIL,
  PRODUCT_BUDGET,
  PRODUCT_MOQ,
  PRODUCT_SUB_CATEGORY,
  SINGLE_PRODUCT,
  PAGE_ACTION_TYPE,
  RESET_CATALOG_DATA,
  REMOVE_PROD_IMAGE,
  PRODUCT_UNIT,
  SET_DELETE_PROD_IMAGE,
  GET_ALL_UNIT,
} from '../types';

const INITIAL_STATE = {
  images: null,
  registrationId: 0,
  title: '',
  description: '',
  budget: 0,
  moq: 0,
  category_id: 0,
  subCategoryId: 0,
  singleProduct: {},
  pageActionType: 'Add',
  unit: '',
  deleteProdImgs: [],
  adminUnits: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_IMAGES: {
      if (state.images !== null) {
        const allImgs = Array.from(action.payload).concat(
          Array.from(state.images)
        );
        return {
          ...state,
          images: allImgs,
        };
      } else {
        return {
          ...state,
          images: action.payload,
        };
      }
      // return {
      //     ...state, images:action.payload
      // };
    }

    case GET_PRODUCTS_DATA:
      return state;

    case SAVE_PRODUCT_DATA:
      return {
        ...state,
        products: action.payload,
      };
    case GET_ALL_UNIT:
      {
        return {
          ...state,
          adminUnits: action.payload,
        };
      }

    case PRODUCT_DESC:
      return {
        ...state,
        description: action.payload,
      };
    case PRODUCT_DETAIL:
      return {
        ...state,
        title: action.payload,
      };
    case PRODUCT_BUDGET:
      return {
        ...state,
        budget: action.payload,
      };
    case PRODUCT_MOQ:
      return {
        ...state,
        moq: action.payload,
      };
    case PRODUCT_SUB_CATEGORY:
      return {
        ...state,
        subCategoryId: action.payload,
      };
    case SINGLE_PRODUCT:
      return {
        ...state,
        singleProduct: action.payload,
      };
    case PAGE_ACTION_TYPE:
      return {
        ...state,
        pageActionType: action.payload,
      };
    case REMOVE_PROD_IMAGE: {
      const fileListArr = [...state.images];
      fileListArr.splice(action.payload, 1);
      const retImg = {
        ...state,
        images: fileListArr,
      };
      return retImg;

    }
    case PRODUCT_UNIT:
      return {
        ...state,
        unit: action.payload,
      };
    case SET_DELETE_PROD_IMAGE:
      return { ...state, deleteProdImgs: action.payload };
    case RESET_CATALOG_DATA:
      return {
        ...state,
        images: null,
        pageActionType: 'Add',
        singleProduct: {},
        title: '',
        description: '',
        budget: 0,
        moq: 0,
        category_id: 0,
        subCategoryId: 0,
        unit: '',
        deleteProdImgs: [],
      };

    default:
      return state;
  }
};
