import { SUBMIT_REQUIREMENT, SAVE_MOQ,
    SAVE_BTYPE,
    SAVE_NAME,
    SAVE_CATEGORY,
    SAVE_DESCRIPTION,
    SAVE_LOCATION,
    SAVE_B2B_CATEGORY,
    SAVE_FL_CATEGORY,
    SAVE_BUDGET,
    RESET_REQUIREMENT_STATE,
    PAGE_ACTION_TYPE,
    SINGLE_REQUIREMENT,
    REQ_SUB_CATEGORY
} from "../types";

const INITIAL_STATE = {
    businessType: "1",
    moq: 0,
    name: '',
    category: null,
    location: null,
    description: '',
    b2bCategory: null,
    flCategory: null,
    budget: 0,
    pageActionType: 'Post',
    singleRequirement: {},
    subCategoryId: 0,
    category_id: 0
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SUBMIT_REQUIREMENT:
            return state;

        case SAVE_MOQ:
            return {
                ...state,
                moq: action.payload
            };
        case SAVE_BTYPE:
            return {
                ...state,
                businessType: action.payload
            };
        case SAVE_NAME:
            return {
                ...state,
                name: action.payload
            };
        case SAVE_CATEGORY:
            return {
                ...state,
                category: action.payload
            };
        case SAVE_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            };
        case SAVE_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case SAVE_B2B_CATEGORY:
            return {
                ...state,
                b2bCategory: action.payload
            };
        case SAVE_FL_CATEGORY:
            return {
                ...state,
                flCategory: action.payload
            };
        case SAVE_BUDGET:
            return {
                ...state,
                budget: action.payload
            }
        case PAGE_ACTION_TYPE:
            return {
                ...state,
                pageActionType: action.payload,
            }
        case SINGLE_REQUIREMENT:
            return {
                ...state,
                singleRequirement: action.payload,
            }
        case REQ_SUB_CATEGORY:
            return {
                ...state,
                subCategoryId: action.payload,
            };
        case RESET_REQUIREMENT_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}