import {
  deleteCertificate,
  getBannersData,
  getCertificate,
  postCertificate,
  putCertificate,
  uploadBanner,
  getCityMasterApi,
  getStateMasterApi,
  getEducationDetails,
  getLanguageDetails,
} from '../../../../../utils/APIs/endpoints';
import { GET_BANNER_DATA } from '../../../FL-AddBio/redux/types';
import {
  FRL_PROFILE_PHOTO,
  FRL_PROFILE_DOB,
  FRL_PROFILE_EDU,
  FRL_PROFILE_LANGUAGE,
  FRL_GET_CERTIFICATE_DATA,
  CERT_NAME,
  CERT_DESC,
  CERT_ISSUE_DATE,
  RESET_FRL_PROFILE_DATA,
  SET_LOCATION_COORDS,
  SET_PROFILE_DETAILS_ADDRESS_1,
  SET_PROFILE_DETAILS_ADDRESS_2,
  SET_PROFILE_DETAILS_CITY,
  SET_PROFILE_DETAILS_STATE,
  SET_ALL_CITY,
  SET_ALL_STATE,
  // FRL_UPDATE_CERTIFICATE_DATA,
  // FRL_SET_CERTIFICATE_DATA,
  FRL_Delete_CERTIFICATE_DATA,
  FETCH_EDUCATION,
  FETCH_LANGUAGE,
  SET_PROFILE_DETAILS_AREANAME,
  SET_PROFILE_DETAILS_PINCODE
} from '../types';

export const setProfileImage = (image) =>
  function (dispatch) {
    dispatch({ type: FRL_PROFILE_PHOTO, payload: image });
  };

export const setProfileDate = (date) =>
  function (dispatch) {
    dispatch({ type: FRL_PROFILE_DOB, payload: date });
  };

export const setProfileEducation = (date) =>
  function (dispatch) {
    dispatch({ type: FETCH_EDUCATION, payload: date });
  };

export const setProfileLanguage = (lang) =>
  function (dispatch) {
    dispatch({ type: FRL_PROFILE_LANGUAGE, payload: lang });
  };

export const getMasterEducationDetails = () =>
  async function (dispatch) {
    try {
      const response = await getEducationDetails();
      console.log('response_data of education :', response);
      if (response.status === 200) {
        console.log('response_data of education :', response.data?.module);
        dispatch({ type: FRL_PROFILE_EDU, payload: response?.data?.module });
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 500) {
        console.log('get education Error code 500', error);
      }
      console.log('get Education Error', error);
    }
  };

export const getMasterLanguageDetails = () =>
  async function (dispatch) {
    try {
      const response = await getLanguageDetails();
      console.log('response_data of language :', response);
      if (response.status === 200) {
        console.log('response_data of language :', response.data?.module);
        dispatch({ type: FETCH_LANGUAGE, payload: response?.data?.module });
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 500) {
        console.log('get language Error code 500', error);
      }
      console.log('get language Error', error);
    }
  };

export const getFRLProfileData = (regId) =>
  async function (dispatch) {
    try {
      const response = await getBannersData(regId);

      if (response.status === 200) {
        console.log(
          '[add-bio-banner-action] get banner Success',
          response.data
        );
        // dispatch({ type: FRL_GET_PROFILE_DATA, payload: response?.data?.data });
        dispatch({ type: GET_BANNER_DATA, payload: response?.data?.data });
        //SAVE_PROJECT_DATA
        // dispatch data
      }
    } catch (error) {
      // dispatch error
      // dispatch({ type: PRODUCT_UNIT, payload: unit });
      if (error?.response?.data?.statusCode === 500) {
        console.log('[add-bio-banner-action] get banner Error code 500', error);
      }
      console.log('[add-bio-banner-action] get banner Error', error);
      // errorCallback();
    }
  };

export const createFRLProfile = (
  profImage,
  DOB,
  education,
  language,
  registrationId,
  successCallback,
  errorCallback
) =>
  async function () {
    if (!profImage) {
      console.log('upload-banner-action] ABORTING... No Resume file selected');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('images', profImage);

      const response = await uploadBanner(
        registrationId,
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Resume uploading Preogress - ', percentCompleted);
          // dispatch({
          //   type: SET_PRODUCT_UPLOAD_PROGRESS,
          //   payload: percentCompleted,
          // });
        }
      );
      if (response.status === 200) {
        // dispatch({ type: PRODUCT_UNIT, payload: unit });
        console.log(
          '[upload-banner-action] Uploading banner Successfully',
          response.data
        );
        successCallback();
      }
    } catch (error) {
      //error
      // dispatch({ type: PRODUCT_UNIT, payload: unit });
      console.log('[upload-banner-action] Uploading banner Error', error);
      errorCallback();
    }
  };

export const uploadProfileImage = (
  profImage,
  registrationId,
  successCallback,
  errorCallback
) =>
  async function () {
    if (!profImage) {
      console.log('upload-banner-action] ABORTING... No Resume file selected');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('images', profImage);
      // formData.append('banner_name', banner_name);
      const response = await uploadBanner(
        registrationId,
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Resume uploading Preogress - ', percentCompleted);
          // dispatch({
          //   type: SET_PRODUCT_UPLOAD_PROGRESS,
          //   payload: percentCompleted,
          // });
        }
      );
      if (response.status === 200) {
        // dispatch({ type: PRODUCT_UNIT, payload: unit });
        console.log(
          '[upload-banner-action] Uploading banner Successfully',
          response.data
        );
        successCallback();
      }
    } catch (error) {
      //error
      // dispatch({ type: PRODUCT_UNIT, payload: unit });
      console.log('[upload-banner-action] Uploading banner Error', error);
      errorCallback();
    }
  };

export const addCertificateData = (
  name,
  description,
  issueDate,
  registrationId,
  successCallback,
  errorCallback
) =>
  async function () {
    try {
      const jsonData = JSON.stringify({
        name: name,
        description: description,
        issued_on: issueDate,
        registrationId: registrationId,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await postCertificate(jsonData, customConfig);
      // console.log("===certificate added response?.data?.data", response?.data?.data);
      if (response.status === 201) {
        // dispatch({ type: FRL_SET_CERTIFICATE_DATA, payload: response?.data?.data })
        // dispatch({ type: PRODUCT_UNIT, payload: unit });
        console.log(
          '[certificate-action] Uploading certificate Successfully',
          response.data
        );
        successCallback(response.data);
      }
    } catch (error) {
      //error
      // dispatch({ type: PRODUCT_UNIT, payload: unit });
      console.log('[certificate-action] Uploading certificate Error', error);
      errorCallback(error);
    }
  };

export const getCertificateData = (regId) =>
  async function (dispatch) {
    try {
      const response = await getCertificate(regId);

      if (response.status === 200) {
        console.log(
          '[certificate-action] get certificate Success',
          response.data
        );
        dispatch({
          type: FRL_GET_CERTIFICATE_DATA,
          payload: response?.data?.data,
        });
        // dispatch data
      }
    } catch (error) {
      // dispatch error
      // dispatch({ type: PRODUCT_UNIT, payload: unit });
      if (error?.response?.data?.statusCode === 500) {
        console.log(
          '[certificate-action] get certificate Error code 500',
          error
        );
      }
      console.log('[certificate-action] get certificate Error', error);
      // errorCallback();
    }
  };

export const deleteCertificateData = (
  certId,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await deleteCertificate(certId);
      if (response.status === 200) {
        console.log(
          '[delete-certificate-action] deleting certificate Success',
          response.data
        );
        dispatch({
          type: FRL_Delete_CERTIFICATE_DATA,
          payload: response.data.data.id,
        });
        successCallback(response.data);
      } else {
        console.log(
          '[delete-certificate-action]  certificate delete Error',
          response.data
        );
        errroCallback();
      }
    } catch (err) {
      console.log('[delete-certificate-action] certificate delete Error', err);
      errroCallback();
    }
  };

export const updateCertificateData = (
  certId,
  name,
  description,
  issueDate,
  registrationId,
  successCallback,
  errorCallback
) =>
  async function () {
    try {
      const jsonData = JSON.stringify({
        name: name,
        description: description,
        issued_on: issueDate,
        registrationId: registrationId,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await putCertificate(certId, jsonData, customConfig);

      if (response.status === 200) {
        // dispatch({
        //   type: FRL_UPDATE_CERTIFICATE_DATA,
        //   payload: response?.data?.data,
        // });
        // dispatch({ type: PRODUCT_UNIT, payload: unit });
        console.log(
          '[certificate-action] Updating banner Successfully',
          response.data
        );
        successCallback(response.data);
      }
    } catch (error) {
      //error
      // dispatch({ type: PRODUCT_UNIT, payload: unit });
      console.log('[certificate-action] Updating banner Error', error);
      errorCallback(error);
    }
  };

export const setCertName = (name) =>
  function (dispatch) {
    dispatch({ type: CERT_NAME, payload: name });
  };

export const setCertDesc = (desc) =>
  function (dispatch) {
    dispatch({ type: CERT_DESC, payload: desc });
  };

export const setCertDate = (date) =>
  function (dispatch) {
    dispatch({ type: CERT_ISSUE_DATE, payload: date });
  };

export const resetFLProfileData = () =>
  function (dispatch) {
    dispatch({ type: RESET_FRL_PROFILE_DATA });
  };

export const setAddress1 = (address1) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_ADDRESS_1, payload: address1 });
  };

export const setAddress2 = (address2) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_ADDRESS_2, payload: address2 });
  };

export const setCity = (city) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_CITY, payload: city });
  };

export const setState = (state) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_STATE, payload: state });
  };

export const setLocationCoords = (locationCoord) =>
  function (dispatch) {
    dispatch({
      type: SET_LOCATION_COORDS,
      payload: locationCoord,
    });
  };

export const getCityMaster = () => async (dispatch) => {
  try {
    const allCity = await getCityMasterApi();
    dispatch({ type: SET_ALL_CITY, payload: allCity });
  } catch (error) {
    dispatch({ type: SET_ALL_CITY, payload: [] });
  }
};

export const getStateMaster = () => async (dispatch) => {
  try {
    const allState = await getStateMasterApi();
    dispatch({ type: SET_ALL_STATE, payload: allState });
  } catch (error) {
    dispatch({ type: SET_ALL_STATE, payload: [] });
  }
};


export const setAreaName = (areaname) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_AREANAME, payload: areaname });
  };

export const setPincode = (pincode) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_PINCODE, payload: pincode });
};
  