export const B2B_STEP_1_ACTIVE = [
  {
    id: 0,
    name: 'Company Details',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  {
    id: 1,
    name: 'Profile Details',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 2,
    name: 'Offered Services & Product Details',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
];

export const B2B_STEP_2_ACTIVE = [
  {
    id: 0,
    name: 'Company Details',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },

  {
    id: 1,
    name: 'Profile Details',
    completed: false,
    active: false,
    error: false,
    icon: 3,
  },
  {
    id: 2,
    name: 'Offered Services & Product Details',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
];

export const B2B_STEP_3_ACTIVE = [
  {
    id: 0,
    name: 'Company Details',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },

  {
    id: 1,
    name: 'Profile Details',
    completed: false,
    active: true,
    error: false,
    icon: 2,
  },
  {
    id: 2,
    name: 'Offered Services & Product Details',
    completed: true,
    active: false,
    error: false,
    icon: 1,
  },
];
