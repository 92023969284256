import {
  CIN_NUMBER,
  GST_NUMBER,
  GST_REASON,
  PAN_NUMBER,
  HAS_GST_NO,
} from '../types';

export const setGSTReason = (gstReason) =>
  function (dispatch) {
    dispatch({ type: GST_REASON, payload: gstReason });
  };

export const setCINNumber = (cin) =>
  function (dispatch) {
    dispatch({ type: CIN_NUMBER, payload: cin });
  };

export const setGSTNumber = (gst) =>
  function (dispatch) {
    dispatch({ type: GST_NUMBER, payload: gst });
  };

export const setPANNumber = (pan) =>
  function (dispatch) {
    dispatch({ type: PAN_NUMBER, payload: pan });
  };

export const hasGSTNumber = (hasGST) =>
  function (dispatch) {
    dispatch({ type: HAS_GST_NO, payload: hasGST });
  };

