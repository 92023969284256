import { GET_HOMEPAGE_JOBS_ACTIVE, SET_HOMEPAGE_WIDGETS } from "../types"

const initialState = {
    widgets: [],
    homepageJobsActive: []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case SET_HOMEPAGE_WIDGETS:
            return {
                ...state,
                widgets: action.payload
            }
        case GET_HOMEPAGE_JOBS_ACTIVE:
            return {...state, homepageJobsActive: action.payload}
        default:
            return state
    }
}
