export const stickyButtonStyle = {
  position: 'fixed',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  // left: '0px',
  // right: '0px',
  zIndex: 1000,
  padding: '10px !important',
  width: '100%',
  background: 'white',
  margin: 0,
};  
