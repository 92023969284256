import {
  GET_PRODUCT_ERROR,
  GET_PRODUCT_LIST,
  RESET_MY_CATALOG_DATA,
} from '../types';
import {
  getAllCatalogProducts,
  deleteSingleProductUpdate,
  getDownlaodUrlLink,
} from '../../../../utils/APIs/endpoints';

export const getProductList = (id) =>
  async function (dispatch) {
    try {
      const response = await getAllCatalogProducts(id);
      if (response.status === 200) {
        const prodList = response.data;
        await dispatch({
          type: GET_PRODUCT_LIST,
          payload: prodList,
        });
      } else {
        await dispatch({
          type: RESET_MY_CATALOG_DATA,
        });
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
      if (error?.response?.status === 404) {
        await dispatch({
          type: RESET_MY_CATALOG_DATA,
        });
      } else if (
        error?.response?.status === 500 &&
        error?.response?.data?.error === 'No Product Found'
      ) {
        await dispatch({
          type: RESET_MY_CATALOG_DATA,
        });
      } else {
        dispatch({
          type: GET_PRODUCT_ERROR,
          payload: error?.response?.data || '',
        });
      }
    }
  };
export const findDownloadUrlLink = async () => {
  try {
    const response = await getDownlaodUrlLink();
    if (response.status === 200 && response.data) {
      return response.data;
    }
    console.warn('Unexpected status code:', response.status);
    return null;
  } catch (error) {
    console.error('Error fetching download URL:', error);
    return null;
  }
};

export const deleteSingleProduct = (sku) =>
  async function (dispatch) {
    try {
      const response = await deleteSingleProductUpdate(sku);
      if (response.status === 200) {
        //Reload list
        // await dispatch({
        //   type: RESET_MY_CATALOG_DATA,
        // });
      } else {
        await dispatch({
          type: GET_PRODUCT_ERROR,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
      dispatch({
        type: GET_PRODUCT_ERROR,
        payload: error?.respons?.data,
      });
    }
  };

export const resetMyCatalogData = () =>
  function (dispatch) {
    dispatch({ type: RESET_MY_CATALOG_DATA });
  };

export default getProductList;
