import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Container, Grid, Typography, Button, RadioGroup, FormControlLabel, Radio, Paper, TableContainer, Table, TableBody, TableRow, TableCell, TableFooter, Box, Accordion, AccordionSummary, AccordionDetails, TableHead } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';

const theme = createTheme();

const WebPaymentDetailsForm = () => {
    // const billSummary = {
    //     yearlySubscription: 8338,
    //     gst: 1510,
    //     totalCost: 9848,
    // };
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '67vh' }}>
                    <Grid item xs={12}>
                        <div className="my-3 ms-2 d-flex align-items-center">
                            <ArrowBackIosIcon onClick={() => {
                                history.goBack()
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <ElynkerLogoComponent>
                                    <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
                                </ElynkerLogoComponent>

                            </div>
                        </div>
                        <Divider
                            component="div"
                            className="main-divider mt-2 mb-3"
                            role="presentation"
                        />




                        <form style={{ margin: '68px 0' }}>
                            <Box className="web-payment-details">


                                <Typography
                                    variant="h4"
                                    align="left"
                                    alignItems="center"
                                    className="fs-34  mb-5 fw-bold font-skmodernist"
                                    gutterBottom style={{ whiteSpace: 'pre-line' }}>
                                    Payment Details
                                </Typography>

                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Grid item xs={12} className='mb-4'>
                                            <div className='plan-details' >
                                                <Typography className='title'>Plan Details</Typography>
                                                <Typography className='subtitle'>Come Experience elynker</Typography>
                                                <Typography className='plan-type'>eStarter</Typography>
                                                <Box className="d-flex" sx={{ gap: '20px' }}>
                                                    <Typography className='plan-price'>₹6,000 <span className='now-text'>Now</span> <span className='free-text'>FREE!!</span></Typography>
                                                    <Typography className='offer-ribbon'>LIMITED TIME OFFER!</Typography>
                                                </Box>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className='addon-details'>
                                                <Typography className='title'>Add-On</Typography>
                                                <div className="addon-accordion-container">
                                                    <Accordion
                                                        expanded={expanded === 'panel1'}
                                                        onChange={handleChange('panel1')}
                                                        className="accordion"
                                                    >


                                                        <AccordionSummary
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                            className="accordion-summary"
                                                        >
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <ExpandMoreIcon className="expand-icon" style={{ transform: expanded === 'panel1' ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
                                                                    <Typography className="accordion-header">eStarter</Typography>
                                                                </div>
                                                                <Typography className="accordion-add">+ Add</Typography>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails className="accordion-details">
                                                            <TableContainer component={Paper} className='pricing-table-container'>
                                                                <Table className="pricing-table" aria-label="pricing table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell className='thead-none'>
                                                                                &nbsp;
                                                                            </TableCell>
                                                                            <TableCell className='thead-plan'>
                                                                                Basic
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">
                                                                                Search Engine Optimization
                                                                            </TableCell>
                                                                            <TableCell className="second-cell">
                                                                                Up to 5 Keywords will be optimized - including Basic SEO
                                                                            </TableCell>

                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Paid Media</TableCell>
                                                                            <TableCell className="second-cell">-</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Email</TableCell>
                                                                            <TableCell className="second-cell">Minimum 25K emails</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">WhatsApp</TableCell>
                                                                            <TableCell className="second-cell">Minimum 10K emails</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Facebook Ads</TableCell>
                                                                            <TableCell className="second-cell">Traffic Ads to the Single Pager Website</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Google Ads</TableCell>
                                                                            <TableCell className="second-cell">Drive Clicks to the Single Pager Website</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionDetails>
                                                    </Accordion>

                                                    <Accordion
                                                        expanded={expanded === 'panel2'}
                                                        onChange={handleChange('panel2')}
                                                        className="accordion"
                                                    >
                                                        <AccordionSummary
                                                            aria-controls="panel2-content"
                                                            id="panel2-header"
                                                            className="accordion-summary"
                                                        >
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <ExpandMoreIcon className="expand-icon" style={{ transform: expanded === 'panel1' ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
                                                                    <Typography className="accordion-header">Advanced</Typography>
                                                                </div>
                                                                <Typography className="accordion-add">+ Add</Typography>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails className="accordion-details">
                                                            <TableContainer component={Paper} className='pricing-table-container'>
                                                                <Table className="pricing-table" aria-label="pricing table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell className='thead-none'>
                                                                                &nbsp;
                                                                            </TableCell>
                                                                            <TableCell className='thead-plan'>
                                                                                Advanced
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">
                                                                                Search Engine Optimization
                                                                            </TableCell>
                                                                            <TableCell className="second-cell">
                                                                                Up to 10 Keywords will be optimized - including Basic SEO
                                                                            </TableCell>

                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Paid Media</TableCell>
                                                                            <TableCell className="second-cell">-</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Email</TableCell>
                                                                            <TableCell className="second-cell">Minimum 50K emails</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">WhatsApp</TableCell>
                                                                            <TableCell className="second-cell">Minimum 20K emails</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Facebook Ads</TableCell>
                                                                            <TableCell className="second-cell">Traffic Ads to the Single Pager Website</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className="first-cell">Google Ads</TableCell>
                                                                            <TableCell className="second-cell">Drive Clicks to the Single Pager Website</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                    <Grid item xs={12} className='mb-4'>
                                            <div className='payment-details'>
                                                <Typography className='title'>Payment Details</Typography>
                                                <Typography
                                                    variant="h4"
                                                    align="left"
                                                    alignItems="center"
                                                    className="fs-14  mb-2 fw-normal text-grey font-gotham"
                                                    gutterBottom style={{ whiteSpace: 'pre-line' }}>
                                                    Select preferred payment options
                                                </Typography>
                                                <div className="mb-2">
                                                    <FormControl fullWidth sx={{ my: 1 }}>

                                                        <RadioGroup
                                                            defaultValue={'2'}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio />}
                                                                label="Debit & Credit Cards"
                                                            />
                                                            <Divider
                                                                component="div"
                                                                className="divider my-1"
                                                                role="presentation"
                                                            ></Divider>
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio />}
                                                                label="Net Banking"
                                                            />
                                                            <Divider
                                                                component="div"
                                                                className="divider my-1"
                                                                role="presentation"
                                                            ></Divider>
                                                            {/* <FormControlLabel
                                                                value="3"
                                                                control={<Radio />}
                                                                label="Wallets"
                                                            />
                                                            <Divider
                                                                component="div"
                                                                className="divider mb-3 mt-1"
                                                                role="presentation"
                                                            ></Divider> */}
                                                        </RadioGroup>

                                                    </FormControl>
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    className="btn-search"
                                                >
                                                    Pay ₹899
                                                </Button>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className='summary-details'>
                                                <Typography className='title'>Summary</Typography>

                                                <Paper className="payment-details-table">

                                                    <TableContainer>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">eStarter</TableCell>
                                                                    <TableCell align="right">₹999</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Add-on</TableCell>
                                                                    <TableCell align="right">₹0</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Discount</TableCell>
                                                                    <TableCell align="right">₹100</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">
                                                                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                                                            <OutlinedInput
                                                                                id="inputCIN"
                                                                                // label="Mobile Number"
                                                                                type="text"
                                                                                placeholder="Enter Coupon Code"
                                                                                required
                                                                                autoFocus
                                                                                fullWidth
                                                                                margin="none"
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Button
                                                                            variant="contained"
                                                                            className="btn-search"
                                                                        >
                                                                            Apply
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>

                                                            </TableBody>
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Total Cost</TableCell>
                                                                    <TableCell align="right">₹899</TableCell>
                                                                </TableRow>
                                                            </TableFooter>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </div>
                                        </Grid>

                                        

                                    </Grid>
                                </Grid>
                            </Box>
                        </form>

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider >
    );
};



export default WebPaymentDetailsForm;