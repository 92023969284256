// CustomStepper.js
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ReactComponent as CompletedStepIcon } from '../../assets/completedstep.svg';
import { ReactComponent as ActiveStepIcon } from '../../assets/activestep.svg';
import { ReactComponent as PendingStepIcon } from '../../assets/pendingstep.svg';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import './custom-stepper.css';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)',
        color: '#FF0000',
    },


    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#864FFD',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#864FFD',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#DFE3EA;',
        borderRadius: 0,
    },
}));

const ColorlibStepIconRoot = styled('div')(() => ({
    zIndex: 1,
    color: '#fff',
    width: 26,
    height: 26,

}));

function ColorlibStepIcon(props, step) {
    const { className } = props;
    const { active, completed, icon } = step;

    const customImages = {
        1: <CompletedStepIcon />, // Replace with the path to your custom image
        2: <ActiveStepIcon />,
        3: <PendingStepIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {customImages[`${icon}`]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

// const steps = ['Company Details', 'Offered Services & Product Details', 'Profile Details'];

const CustomStepper = (props) => {
    const { stepperData, activeStep } = props;
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {stepperData.map((step, index) => (
                    <Step active={step.active} completed={step.completed} key={index}>
                        <StepLabel StepIconComponent={(props) => ColorlibStepIcon(props, step)} >
                            {step.name}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
};

CustomStepper.propTypes = {
    // If you need any props for customization, you can define them here
    stepperData: PropTypes.array,
    activeStep: PropTypes.number
};

export default CustomStepper;
