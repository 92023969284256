export default {
	0: (state) => {
		// Migration logic for version 0 (initial state structure)
		return state;
	},
	1: (state) => {
		// Migration logic for version 1 (if you made changes)
		// Handle any necessary data transformations here
		return state;
	},
	// Add more migrations for future versions as needed
};
