import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { Container, Grid, Divider, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, List, ListItem, ListItemText, Switch } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-icon-black.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import SubscriptionBanner from '../../assets/subscription-banner.png';
// import { ReactComponent as CheckCircleOutline } from '../../assets/checkmark-circle-sharp.svg';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { ReactComponent as WhatsappBusinessIcon } from '../../assets/package-whatsapp-business-icon.svg';
// import { ReactComponent as GoogleBusinessIcon } from '../../assets/package-google-business-icon.svg';
// import { ReactComponent as SinglePageIcon } from '../../assets/package-single-page-icon.svg';
// import { ReactComponent as InstaIcon } from '../../assets/package-instagram-icon.svg';
// import { ReactComponent as FacebookIcon } from '../../assets/package-facebook-icon.svg';
// import { ReactComponent as LinkedinIcon } from '../../assets/package-linkedin-icon.svg';
// import BasicSubscription from '../../assets/basic-subscription-img.png';
// import EssentialSubscription from '../../assets/essential-subscription-img.png';
// import EstarterSubscription from '../../assets/estarter-subscription-img.png';
// import LimitedOFferImg from '../../assets/limited-offer-img.png';
import { connect, useDispatch } from 'react-redux';
import DrawerForm from '../../components/DrawerForm';
import PropTypes from 'prop-types';
import { isMoreThan24HoursAgo } from '../FLFreelanceCategory/FL-Freelance-Category';
import { SET_ENQUIRY } from '../B2BCategory/redux/types';
import useValidation from '../../hooks/useValidation';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { getEnquiryById, postEnquiry } from '../B2BCategory/redux/actions/b2b-category-action';
import { useEffect } from 'react';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { resetTemplateData, setTemplateRender } from '../Templates/redux/action.js/template-action';
import { SUBSCRIPTION_TERMS, T_AND_C_SUB_SLUG } from '../../utils/constants';
import './Subscription.css';
// import { ToggleButton, ToggleButtonGroup } from "@mui/material";


const theme = createTheme();
const WebSubscriptionForm = ({
    userData,
    postEnquiry,
    pushMessage,
    enquiry,
    // isLoading
    setTemplateRender,
    resetTemplateData
}) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const { user } = userData || {};
    const { mobile_number = '', name = '', email = '' } = user || {};
    const { validateInput, errors } = useValidation();
    const [openCallModal, setOpenCallModal] = useState(false);
    const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
    const handleOpenCallModal = () => setOpenCallModal(true);
    const handleCloseCallModal = () => setOpenCallModal(false);

    const toggleCallBackDrawer = () => {
        if (isMoreThan24HoursAgo(enquiry?.createdAt)) {
            if (!userData) {
                dispatch({
                    type: SET_ENQUIRY,
                    payload: { isTrue: true, redirect_to: '/subscription' },
                });
                history.push('/login');
            } else {
                setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
            }
        } else if (enquiry && Object.keys(enquiry)?.length == 0) {
            if (!userData) {
                dispatch({
                    type: SET_ENQUIRY,
                    payload: { isTrue: true, redirect_to: '/subscription' },
                });
                handleOpenCallModal();
                // history.push('/login');
            } else {
                setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
            }
        } else {
            pushMessage(
                'We already received your enquiry. Admin will get back to you soon'
            );
        }
    };

    useEffect(() => {
        const regId = userData?.registration.id;
        getEnquiryById(regId);
        // if(enquiry)
        if (enquiry?.isTrue == true) {
            setIsCallBackDrawerOpen(true);
        }
    }, []);

    useEffect(() => {
        // const regId = userData?.registration.id;
        // getEnquiryById(regId);
        // if(enquiry)
        setIsCallBackDrawerOpen(false);
    }, []);

    const requestCallBackSubmit = async (formData) => {
        // const{email,name,mobile_number}=userData
        const registrationId = userData?.registration?.id;
        if (registrationId) {
            const { mobileNumber, comment, ...rest } = formData;
            const details = {
                ...rest,
                comments: comment,
                type: 'subscription',
                phone_number: mobileNumber,
                registrationId,
            };
            // const postResponse = await postEnquiry(registrationId, details);
            const postResponse = await postEnquiry(registrationId, details);
            pushMessage(postResponse.message);
        }
        setIsCallBackDrawerOpen(false);
    };

    const handleSubTermsCondition = () => {
        setTemplateRender(SUBSCRIPTION_TERMS);
        history.push(`/${T_AND_C_SUB_SLUG}`);
        resetTemplateData();
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 36,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#416AD4',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#416AD4',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#416AD4',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <DrawerForm
                    title="Request a Call Back"
                    isOpen={isCallBackDrawerOpen}
                    onClose={() => {
                        setIsCallBackDrawerOpen(false);
                    }}
                    mobile_number={mobile_number}
                    name={name}
                    email={email}
                    validateInput={validateInput}
                    onSubmit={requestCallBackSubmit}
                    errors={errors}
                    BuisnessType={false}
                />
                <Dialog
                    open={openCallModal}
                    onClose={handleCloseCallModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="sm"
                    fullWidth
                >
                    {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
                    <DialogTitle className="custom-border-header pb-0">
                        <Box className="position-relative d-flex justify-content-between">
                            <Typography
                                className="fs-22 fw-bold font-skmodernist"
                                id="alert-dialog-title"
                            >
                                Elynker
                            </Typography>
                            <Box>
                                <CloseIcon
                                    onClick={() => {
                                        handleCloseCallModal();
                                        history.push('/login');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are not login, Please login to use this feature?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="modal-footer">
                        <Button className="btn-cancel me-2" onClick={handleCloseCallModal}>
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                handleCloseCallModal();
                                history.push('/login');
                            }}
                            className="btn-save"
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 'auto' }}
                >
                    <Grid item xs={12}>

                        <div className="my-3 ms-2 d-flex align-items-center">
                            <ArrowBackIosIcon onClick={() => {
                                history.goBack()
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <ElynkerLogoComponent>
                                    <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
                                </ElynkerLogoComponent>

                            </div>
                        </div>
                        <Divider
                            component="div"
                            className="main-divider mt-2 mb-3"
                            role="presentation"
                        />

                        <form style={{ marginTop: '40px' }}>

                            <Box sx={{ width: '100%', marginBottom: '40px' }}>
                                {/* <img src={SubscriptionBanner} style={{ width: '100%' }}></img> */}

                                <Card className="subscription-new-banner-web-card mb-3">
                                    <CardContent className="subscription-new-banner-web-content">
                                        <div className="subscription-new-banner-web-content-tint" />
                                        <div style={{ zIndex: 2, position: 'absolute', top: '30%', left: '5%', width: '-webkit-fill-available' }}>
                                            <Typography
                                                variant="h5"
                                                className="subscription-banner-web-text"
                                                component="div"
                                            >
                                                Choose the plan that fits your business needs!
                                            </Typography>
                                            <List style={{ display: 'flex', gap: '40px', marginTop: '8px', justifyContent: 'start', listStyleType: 'disc', padding: '0', paddingLeft: '20px' }}>
                                                <ListItem style={{ display: 'list-item', maxWidth: 'max-content', padding: '0' }}>
                                                    <ListItemText primary="Straightforward pricing" />
                                                </ListItem>
                                                <ListItem style={{ display: 'list-item', maxWidth: 'max-content', padding: '0' }}>
                                                    <ListItemText primary="Dedicated Services" />
                                                </ListItem>
                                                <ListItem style={{ display: 'list-item', maxWidth: 'max-content', padding: '0' }}>
                                                    <ListItemText primary="Grow your business at scale" />
                                                </ListItem>
                                            </List>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Box>


                            <Box className='w-100 d-flex justify-content-center align-items-center' sx={{marginBottom:'40px', gap: '10px'}}>
                                {/* Left Label */}
                                <Typography
                                    className={`toggle-label ${!checked ? "active" : ""}`}
                                    variant="subtitle1"
                                >
                                    Monthly
                                </Typography>

                                {/* Custom IOS Switch */}
                                <IOSSwitch checked={checked} onChange={handleChange} />

                                {/* Right Label */}
                                <Typography
                                    className={`toggle-label ${checked ? "active" : ""}`}
                                    variant="subtitle1"
                                >
                                    Yearly
                                </Typography>
                            </Box>





                            <Grid container spacing={4} justifyContent="space-between" className='mb-5'>
                                {/* Basic Package */}
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                                        <Box className="inner-box position-relative">
                                            <Typography className='plan-type'>Basic</Typography>
                                            <Typography className='plan-price'>Free</Typography>
                                            <Typography className='plan-info'>Basic package</Typography>

                                        </Box>

                                        <Box className="benefits-services">
                                            <ul className='package-list'>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Marketplace Listing</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Requirement posting</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Unlimited Connects</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Unlimited Inquiries</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Digital Business Card</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>WhatsApp Business Profile creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Google Business Profile creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Single Pager Website creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Digital Catalog</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Instagram profile</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Facebook profile</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>LinkedIn profile</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Dedicated Helpline</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Advisory Services</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Email Marketing</Typography>
                                                </li>
                                            </ul>
                                        </Box>

                                        <Typography className="caption">Terms & conditions apply*</Typography>

                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            className="get-now-btn"
                                            onClick={toggleCallBackDrawer}
                                        >
                                            Get Now
                                        </Button>


                                    </Box>
                                </Grid>

                                {/* Estarter Package */}
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                                        <Box className="inner-box position-relative">
                                            <Box className="d-flex justify-content-between">
                                                <Typography className='plan-type'>eStarter</Typography>
                                                <Typography className='offer-ribbon'>LIMITED TIME OFFER!</Typography>
                                            </Box>
                                            <Typography className='plan-price'>₹6,000 <span className='now-text'>Now</span> <span className='free-text'>FREE!!</span></Typography>
                                            <Typography className='plan-info'>Come Experience elynker</Typography>

                                        </Box>

                                        <Box className="benefits-services">
                                            <ul className='package-list'>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Marketplace Listing</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Requirement posting</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Unlimited Connects</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Unlimited Inquiries</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Digital Business Card</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>WhatsApp Business Profile creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Google Business Profile creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Single Pager Website creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Digital Catalog (Upto <span className='list-text-bold'>50</span> products/services)</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Instagram profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Facebook profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>LinkedIn profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Dedicated Helpline</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Advisory Services</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon disable' />
                                                    <Typography className='list-text disable'>Email Marketing</Typography>
                                                </li>
                                            </ul>
                                        </Box>

                                        <Typography className="caption">Terms & conditions apply*</Typography>

                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            className="get-now-btn"
                                        // onClick={toggleCallBackDrawer}
                                        >
                                            Get Now
                                        </Button>


                                    </Box>
                                </Grid>

                                {/* Essential Package */}
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                                        <Box className="inner-box position-relative">
                                            <Typography className='plan-type'>eSsential</Typography>
                                            <Typography className='plan-price'>₹0,00 </Typography>
                                            <Typography className='plan-info'>Coming Soon!!!</Typography>

                                        </Box>

                                        <Box className="benefits-services">
                                            <ul className='package-list'>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Marketplace Listing</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Requirement posting</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Unlimited Connects</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Unlimited Inquiries</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Digital Business Card</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>WhatsApp Business Profile creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Google Business Profile creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>Single Pager Website creation</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Digital Catalog (Upto <span className='list-text-bold'>50</span> products/services)</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Instagram profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Facebook profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text'>LinkedIn profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Dedicated Helpline</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Advisory Services</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleIcon className='check-icon enabled' />
                                                    <Typography className='list-text '>Email Marketing</Typography>
                                                </li>
                                            </ul>
                                        </Box>

                                        <Typography className="caption">Terms & conditions apply*</Typography>

                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            className="get-now-btn"
                                        // onClick={toggleCallBackDrawer}
                                        >
                                            Get Now
                                        </Button>


                                    </Box>
                                </Grid>

                            </Grid>



                            <Typography className='font-gotham fs-16 text-gray fw-light'
                                sx={{ fontStyle: 'italic' }}
                                onClick={handleSubTermsCondition}
                            >
                                By proceeding with your subscription, you acknowledge and accept the <span className='text-primary-2'>Terms & Conditions of our subscription packages.</span>
                            </Typography>


                            <Grid
                                container
                                columnSpacing={3}
                                justifyContent={'center'}
                                className="mt-4"
                            >

                                <Grid item xs={12} sm={6} md={4} >
                                    <Card className="credit-services-card test-card mb-3">
                                        <CardContent
                                            className='credit-services-content'
                                        >
                                            <div style={{ zIndex: 2, position: 'relative' }}>
                                                <Typography
                                                    className="fs-18 text-white mb-2 fw-bold font-skmodernist text-offwhite"
                                                    component="div"
                                                >
                                                    Credit Services
                                                </Typography>
                                                <Typography
                                                    className="fs-15 text-white mb-2 font-gotham"
                                                >
                                                    Personal Loans, Invoice Crediting, Business
                                                    Loans, etc.
                                                </Typography>

                                                <Typography
                                                    className="fs-15 text-white mb-3 font-gotham fw-bold"
                                                >
                                                    Starting from ₹ 3000
                                                </Typography>

                                                <Button
                                                    variant="contained"
                                                    className="learn-more-btn"

                                                >
                                                    Buy Now
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} >
                                    <Card className="marketing-services-card test-card mb-3">
                                        <CardContent
                                            className='marketing-services-content'
                                        >

                                            <div style={{ zIndex: 2, position: 'relative' }}>
                                                <Typography
                                                    className="fs-18 text-white mb-2 fw-bold font-skmodernist text-offwhite"
                                                    component="div"
                                                >
                                                    Marketing Services
                                                </Typography>
                                                <Typography
                                                    className="fs-15 text-white mb-2 font-gotham"
                                                >
                                                    Personal Loans, Invoice Crediting, Business
                                                    Loans, etc.
                                                </Typography>

                                                <Typography
                                                    className="fs-15 text-white mb-3 font-gotham fw-bold"
                                                >
                                                    Starting from ₹ 3000
                                                </Typography>

                                                <Button
                                                    variant="contained"
                                                    className="learn-more-btn"

                                                >
                                                    Buy Now
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} >
                                    <Card className="credit-services-card test-card mb-3">
                                        <CardContent
                                            className='credit-services-content'
                                        >
                                            <div style={{ zIndex: 2, position: 'relative' }}>
                                                <Typography
                                                    className="fs-18 text-white mb-2 fw-bold font-skmodernist text-offwhite"
                                                    component="div"
                                                >
                                                    Credit Services
                                                </Typography>
                                                <Typography
                                                    className="fs-15 text-white mb-2 font-gotham"
                                                >
                                                    Personal Loans, Invoice Crediting, Business
                                                    Loans, etc.
                                                </Typography>

                                                <Typography
                                                    className="fs-15 text-white mb-3 font-gotham fw-bold"
                                                >
                                                    Starting from ₹ 3000
                                                </Typography>

                                                <Button
                                                    variant="contained"
                                                    className="learn-more-btn"

                                                >
                                                    Buy Now
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>


                        </form>

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

WebSubscriptionForm.propTypes = {
    userData: PropTypes.object,
    pushMessage: PropTypes.func,
    postEnquiry: PropTypes.func,
    enquiry: PropTypes.object,
    getEnquiryById: PropTypes.func,
    resetTemplateData: PropTypes.func,
    setTemplateRender: PropTypes.func
}

const mapStateToProps = (state) => {
    const { userData } = state.signin;
    const {
        enquiry,
    } = state.b2bVendorlisting;
    const { isLoading } = state.root;
    return {
        userData,
        enquiry,
        isLoading,
    }
}

export default connect(mapStateToProps, { postEnquiry, getEnquiryById, pushMessage, resetTemplateData, setTemplateRender })(WebSubscriptionForm);