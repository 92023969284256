import { GET_ALL_SERVICE, RESET_ALL_SERVICE_DATA } from "../types";


const INITIAL_STATE = {

  businessType: '0',
  selectedCoverImage: null,
  allServices: [],

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_ALL_SERVICE:
      return { ...state, allServices: action.payload };

    case RESET_ALL_SERVICE_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};