export const FRL_PROFILE_PHOTO = "FRL_PROFILE_PHOTO";
export const RESET_FRL_PROFILE_DATA = "RESET_FRL_PROFILE_DATA";

export const FRL_Delete_CERTIFICATE_DATA = "FRL_Delete_CERTIFICATE_DATA";
export const FRL_PROFILE_DOB = "FRL_PROFILE_DOB";
export const FRL_PROFILE_EDU = "FRL_PROFILE_EDU";
export const FRL_SET_CERTIFICATE_DATA = "FRL_SET_CERTIFICATE_DATA"
export const FRL_UPDATE_CERTIFICATE_DATA = "FRL_UPDATE_CERTIFICATE_DATA";
export const FRL_PROFILE_LANGUAGE = "FRL_PROFILE_LANGUAGE";
export const FRL_GET_PROFILE_DATA = "FRL_GET_PROFILE_DATA";
export const FRL_DELETED_CERTIFICATE_DATA = "FRL_DELETED_CERTIFICATE_DATA"

export const FRL_GET_CERTIFICATE_DATA = "FRL_GET_CERTIFICATE_DATA";
export const FRL_SEND_CERTIFICARE_DATA = "FRL_SEND_CERTIFICARE_DATA";
export const CERT_NAME = "CERT_NAME";
export const CERT_DESC = "CERT_DESC";
export const CLEAR_Delete_CERTIFICATE_DATA = "CLEAR_Delete_CERTIFICATE_DATA"
export const CERT_ISSUE_DATE = "CERT_ISSUE_DATE";
export const SET_LOCATION_COORDS = 'SET_LOCATION_COORDS';

export const SET_ALL_CITY = 'SET_ALL_CITY';
export const SET_ALL_STATE = 'SET_ALL_STATE';
export const SET_PROFILE_DETAILS_ADDRESS_1 = 'SET_PROFILE_DETAILS_ADDRESS_1';
export const SET_PROFILE_DETAILS_ADDRESS_2 = 'SET_PROFILE_DETAILS_ADDRESS_2';
export const SET_PROFILE_DETAILS_CITY = 'SET_PROFILE_DETAILS_CITY';
export const SET_PROFILE_DETAILS_STATE = 'SET_PROFILE_DETAILS_STATE';
export const FETCH_EDUCATION = 'FETCH_EDUCATION';
export const FETCH_LANGUAGE = 'FETCH_LANGUAGE'
export const SET_PROFILE_DETAILS_AREANAME = 'SET_PROFILE_DETAILS_AREANAME';
export const SET_PROFILE_DETAILS_PINCODE = 'SET_PROFILE_DETAILS_PINCODE';