import Compressor from 'compressorjs';
import Resizer from 'react-image-file-resizer';
export const compressFile = (file) => {
    // let compressedFile;
    if (!file) {
        return;
    }

    new Compressor(file, {
        quality: 0.6,
        success(result) {
            console.log("===compressed file", result);
        },
        error(err) {
            console.log("===compressed file error", err.message);
        },
    });

};

export const compressImages = (files) => {
    return Promise.all(
        Array.from(files).map((file) => {
            return new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    500, // New width
                    500, // New height
                    'JPEG', // Format
                    75, // Quality
                    0, // Rotation
                    (uri) => {
                        resolve(uri);
                    },
                    'file'
                );
            });
        })
    );
};

export function isImage(file) {
    // List of image MIME types
    const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

    // Get the MIME type of the file
    const fileType = file.type;

    // Check if the MIME type belongs to the image types
    return imageTypes.includes(fileType);
}




