export const SAVE_JOB_TITLE = "SAVE_JOB_TITLE";
export const SELECTED_JOB_CATEGORY = "SELECTED_JOB_CATEGORY";
export const ALL_JOB_CATEGORY = "ALL_JOB_CATEGORY";
export const SAVE_JOB_DESCRIPTION = "SAVE_JOB_DESCRIPTION";
export const SAVE_JOB_EXP = "SAVE_JOB_EXP";
export const SAVE_JOB_LOCATION = "SAVE_JOB_LOCATION";
export const SAVE_JOB_SALARY = "SAVE_JOB_SALARY";
export const RESET_CREATE_JOB_DATA = "RESET_CREATE_JOB_DATA";
export const PUT_POST_JOB_RESULT = "PUT_POST_JOB_RESULT";
export const SET_JOB_LOCATION_COORDS = "SET_JOB_LOCATION_COORDS";
export const JOB_ACTION_TYPE = "JOB_ACTION_TYPE";
export const SAVE_SINGLE_JOB_DATA = "SAVE_SINGLE_JOB_DATA";
export const JOB_LOC_SEARCH_VAL = 'JOB_LOC_SEARCH_VAL';