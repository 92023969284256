import { useEffect, useState } from 'react';

export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key) || [];
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            setStoredValue((prevItems) => {
                const updatedItems = [...prevItems, value];
                if (updatedItems.length > 4) {
                    updatedItems.shift(); // Remove the first item if length exceeds 4
                }
                window.localStorage.setItem(key, JSON.stringify(updatedItems));
                return updatedItems;
            });
        } catch (error) {
            console.error(error);
        }
    };

    const removeItem = (index) => {
        try{
            setStoredValue((prevItems) => {
                const updatedItems = [...prevItems];
                updatedItems.splice(index, 1);
                window.localStorage.setItem(key, JSON.stringify(updatedItems));
                return updatedItems;
            });
        } catch(error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const item = window.localStorage.getItem(key);
            setStoredValue(item ? JSON.parse(item) : initialValue);

        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [key, initialValue]);

    return [storedValue, setValue, removeItem];
};