import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { ReactComponent as ProductCatalogLogo } from '../../assets/product-catalog-logo.svg';
import { ReactComponent as ProductCatalogDividerLeft } from '../../assets/product-catalog-divider-left.svg';
import { ReactComponent as ProductCatalogDividerRight } from '../../assets/product-catalog-divider-right.svg';
import { ReactComponent as ProductCatalogDivider } from '../../assets/product-catalog-divider.svg';
import ProductCatalogBanner from '../../assets/product-catalog-front-banner.png';
import RemoveIcon from '@mui/icons-material/Remove';
import { Container, Grid, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Responsive from '../../utils/responsive';


const theme = createTheme();



const ProductCatalogFrontForm = () => {

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" style={{ maxwidth: '800px' }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 'auto' }}
                >
                    <Grid item xs={12}>
                        {Responsive(
                            <>
                                <Box className="text-center" sx={{ margin: "60px 0 40px 0" }}>
                                    <ProductCatalogLogo height={30} />

                                </Box>
                                <Typography className="sk-modernist fw-bold text-center text-uppercase" sx={{ fontSize: '35px' }}>Product</Typography>
                                <Typography className="sk-modernist fw-bold text-center text-uppercase text-primary-2" sx={{ fontSize: '60px', marginBottom: '10px' }}>Catalog</Typography>

                                <Box className="d-flex align-items-end justify-content-center" sx={{ gap: '24px', marginBottom: '54px' }}>
                                    <ProductCatalogDividerLeft />
                                    <Typography className="sk-modernist fw-bold " sx={{ fontSize: '18px', lineHeight: '10px' }}>2024/25</Typography>
                                    <ProductCatalogDividerRight />
                                </Box>

                                <Box className="text-center" sx={{ marginBottom: '62px' }}>
                                    <img src={ProductCatalogBanner} width={"100%"} height={"100%"} />
                                </Box>

                                <Box className="text-center">
                                    <ProductCatalogDivider width={'100%'} />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <List sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>

                                                <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} />

                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>ADDRESS</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            1st Block, Akshya Nagar,<br />
                                                            Bangalore-560016
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>
                                                <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} />
                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>CONTACT</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            +91 8822669900
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>
                                                <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} />
                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>EMAIL</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            info@product.com
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Box>

                                    <ProductCatalogDivider width={'100%'} />
                                </Box>

                                <Box className="d-flex align-items-center py-3" sx={{
                                    gap: '10px', position: 'sticky',
                                    bottom: 0,
                                    // left: 0,
                                    justifyContent: 'space-between',
                                    background: "#fff"
                                }}>
                                    <Typography className='fs-14  font-skmodernist'>Powered by: www.elynker.com</Typography>
                                    <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '30%' }} />
                                    <Typography className='fs-18 font-skmodernist'>03</Typography>
                                </Box>
                            </>,
                            <>
                                <Box className="text-center" sx={{ margin: "60px 0 40px 0" }}>
                                    <ProductCatalogLogo height={48} />
                                </Box>
                                <Typography className="sk-modernist fw-bold text-center text-uppercase" sx={{ fontSize: '62px', lineHeight: '74px' }}>Product</Typography>
                                <Typography className="sk-modernist fw-bold text-center text-uppercase text-primary-2" sx={{ fontSize: '130px', lineHeight: '156px', marginBottom: '10px' }}>Catalog</Typography>

                                <Box className="d-flex align-items-end justify-content-center" sx={{ gap: '24px', marginBottom: '54px' }}>
                                    <ProductCatalogDividerLeft />
                                    <Typography className="sk-modernist fw-bold " sx={{ fontSize: '18px', lineHeight: '10px' }}>2024/25</Typography>
                                    <ProductCatalogDividerRight />
                                </Box>

                                <Box className="text-center" sx={{ marginBottom: '62px' }}>
                                    <img src={ProductCatalogBanner} width={700} height={600} />
                                </Box>

                                <Box className="text-center">
                                    <ProductCatalogDivider />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '800px',margin: '0 auto' }}>
                                        <List sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>

                                                <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} />

                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>ADDRESS</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            1st Block, Akshya Nagar,<br />
                                                            Bangalore-560016
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>
                                                <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} />
                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>CONTACT</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            +91 8822669900
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>
                                                <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} />
                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>EMAIL</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            info@product.com
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Box>

                                    <ProductCatalogDivider />
                                </Box>

                                <Box className="d-flex align-items-center py-3" sx={{
                                    gap: '24px', position: 'sticky',
                                    bottom: 0,
                                    maxWidth: '800px',
                                    padding: '16px',
                                    margin: '0 auto',
                                    justifyContent: 'space-between',
                                    background: "#fff"
                                }}>
                                    <Typography className='fs-18 font-skmodernist'>Powered by: www.elynker.com</Typography>
                                    <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '55%' }} />
                                    <Typography className='fs-18 font-skmodernist'>01</Typography>
                                </Box>
                            </>
                        )}


                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default ProductCatalogFrontForm;