import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Autocomplete,
  TextField,
  FormHelperText,
  Backdrop,
  CircularProgress,
  IconButton,
  InputAdornment,
  Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import OutlinedInput from '@mui/material/OutlinedInput';
// import LocationLogo from '../../assets/location-icon.svg';
// import { ReactComponent as LocatemeLogo } from '../../assets/locateme-icon.svg';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FREELANCER, JOB_DESCRIPTION_COUNT } from '../../utils/constants';
import {
  getFreelancerCategories,
  postJob,
  putJOBPosting,
  resetCreateJobData,
  saveJobDesc,
  saveJobExp,
  saveJobLocation,
  saveJobSalary,
  saveJobTitle,
  saveSelCategory,
} from './redux/actions/create-job-action';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { searchInArray } from '../../utils/helper-funtions';
import useValidation from '../../hooks/useValidation';
import axios from 'axios';
import { loading } from '../../store/actions/rootActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { hasAnyErrorPresent } from '../../utils/validation';
import CustomizedTreeView from '../../components/TreeDropDown/TreeDropDown';
import { setProductSubCategory } from '../AddCatalog/redux/actions/add-catalog-action';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import Responsive from '../../utils/responsive';
import { POSTOFFICE_CITY } from '../../utils/APIs/api';
import { useMediaQuery } from 'react-responsive';
import { stickyButtonStyle } from '../../utils/sx-styles';

const theme = createTheme();
const categoryText = 'Post a Job';
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function filterData(data, filterValues) {
  return data.filter((value) => {
    return filterValues
      .trim()
      .split(', ')
      .every((filterValue) => checkValue(value, filterValue));
  });
}

function checkValue(value, filterValue) {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  } else if (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length > 0
  ) {
    if (Array.isArray(value)) {
      return value.some((v) => checkValue(v, filterValue));
    } else {
      return Object.values(value).some((v) => checkValue(v, filterValue));
    }
  } else {
    return false;
  }
}

export function list_to_tree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parent_id]]?.children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
const CreateJobRequirementForm = ({
  pushMessage,
  title,
  desc,
  minExp,
  location,
  salary,
  selCategory,
  flCategory,
  saveJobTitle,
  saveJobDesc,
  getFreelancerCategories,
  saveJobLocation,
  saveJobSalary,
  saveJobExp,
  postJob,
  loading,
  isLoading,
  userData,
  resetCreateJobData,
  singleJob,
  saveSelCategory,
  jobActionType,
  putJOBPosting,
  // setProductSubCategory,
  // b2bprodCategories,
  // b2bLvl2CategoriesTree,
  // b2bLvl1Categories,
  selectedLvl1Categories,
  // businessType,
}) => {
  // const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [mapOtions, setMapOtions] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [locSearchVal, setLocSearchVal] = useState('');
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const [categorySearch, setCategorySearch] = useState('');
  const [openSubCate, setOpenSubCate] = useState(false);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [categoriesTreeFilter, setCategoriesTreeFilter] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const history = useHistory();

  const handleClick = () => {
    setOpenSubCate(!openSubCate);
  };

  function getTitleById(data, id) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        return data[i].title;
      }
    }
    return null; // Return null if ID is not found
  }

  const { errors, validateInput } = useValidation();
  const onChangeTitle = (e) => {
    let jt = e.target.value;
    saveJobTitle(jt);
    validateInput('jobTitle', jt);
  };

  const onPasteDescription = (event) => {
    event.preventDefault();
    // clipboardData = event.clipboardData || event?.window?.clipboardData || event.originalEvent.clipboardData;
    const input = (event.clipboardData || event.window.clipboardData).getData(
      'text'
    );
    // Get the current input selection
    const inputCtrl = event.target;
    const start = inputCtrl.selectionStart;
    const end = inputCtrl.selectionEnd;

    let newInput;
    if(desc === undefined){
      newInput= input;
    }else {
      newInput= desc?.substring(0, start) + input + desc?.substring(end);
    }

    // const newInput = description ? description + input : input;
    const regex = /^[\s\S]*$/;
    if (regex.test(newInput)) {
      if (newInput?.length > 500) {
        const text = newInput.substr(0, 500);
        saveJobDesc(text);
        setCount(text?.length);
        validateInput('jobDesc', text);
      } else {
        saveJobDesc(newInput);
        setCount(newInput?.length);
        validateInput('jobDesc', newInput);
      }
    }
  };
  const OnChangeDescription = (e) => {
    console.log('(e.target.value.length', e.target.value.length);
    
    if (e.target.value.length > 500) {
      pushMessage('The number of characters exceeded.');
      e.target.value = null
      return;
    } else {
      let jobDesc = e.target.value;
      setCount(jobDesc.length);
      saveJobDesc(jobDesc);
      validateInput('jobDesc', jobDesc);
    }
  };

  const onChangeSalary = (e) => {
    const input = e.target.value;
    const regex = /^\s*(\d{0,7})(\.\d{1,2})?\s*$/;
    if (regex.test(input)) {
      saveJobSalary(Number(e.target.value));
      validateInput('jobSal', input);
      // // // DEV BRANCH CODE
    }
  };

  const onChangeYOE = (e) => {
    const input = e.target.value;
    const regex = /^[0-9]{0,2}$/;

    if (regex.test(input)) {
      saveJobExp(input);
      validateInput('jobExp', input);
      // // // DEV BRANCH CODE
    }
  };

  const handleSubCategoryChange = (e) => {
    console.log('==handleSubCategoryChange 67', e.target.value);
    if (!openSubCate) {
      setCategorySearch('');
      setOpenSubCate(true);
    }

    setCategorySearch(e.target.value);
    const filteredCategories = filterCategoriesInDeep(e.target.value);
    // console.log('filteredCategories0', filteredCategories);
    setFilteredCategory(filteredCategories);
    // saveSelCategory(filteredCategories);
    const searchResults = searchInArray(categoriesTree, e.target.value);

    setFilteredCategory(searchResults);
  };

  const filterCategoriesInDeep = (searchText) => {
    const filterCategorySearch = filterData(categoriesTreeFilter, searchText);
    if (filterCategorySearch.length > 0) {
      return checkChildrenAndLoop(filterCategorySearch, searchText);
    } else {
      return filterCategorySearch;
    }
  };

  const checkChildrenAndLoop = (childCategories, searchText) => {
    let newFilteredCategories = [];
    for (const category of childCategories) {
      if (category.children.length > 0) {
        const filterLvl2 = filterData(category.children, searchText);
        const filterLvl2Child = filterLvl2.findIndex(
          (child) => child.children.length > 0
        );
        if (filterLvl2Child !== -1) {
          const childArr = checkChildrenAndLoop(filterLvl2, searchText);
          newFilteredCategories.push({
            ...category,
            children: childArr,
          });
        } else {
          newFilteredCategories.push({ ...category, children: filterLvl2 });
        }
      } else {
        newFilteredCategories.push(category);
      }
    }
    return newFilteredCategories;
  };

  const successJobPostCallback = () => {
    loading(false);
    // clear data
    resetCreateJobData();
    // show Success dialog

    //redirect to prev page
    history.goBack();
    pushMessage('Job posted successfully');
  };
  const errorJobPostCallback = () => {
    loading(false);
    pushMessage('Job posting error');
  };
  const handleSubmit = async () => {
    if (
      title === undefined ||
      title === '' ||
      selCategory === undefined ||
      selCategory === '' ||
      desc === undefined ||
      desc === '' ||
      minExp === undefined ||
      minExp == '' ||
      // locAddr === undefined ||
      // locAddr.length < 0 ||
      salary === undefined ||
      isNaN(salary) ||
      salary <= 0
    ) {
      if (title === undefined || title === '') {
        validateInput('jobTitle', '');
        // return;
      }
      if (selCategory === undefined || selCategory === '') {
        validateInput('jobCategory', '');
        // return;
      }
      if (desc === undefined || desc === '') {
        validateInput('jobDesc', '');
        // return;
      }

      if (minExp === undefined || isNaN(minExp) || minExp == '') {
        validateInput('jobExp', '');
        // return;
      }
      //   if (locAddr === undefined || locAddr.length < 0) {
      //     validateInput('jobLoc', '');
      //     // return;
      //   }
      if (salary === undefined || isNaN(salary) || salary <= 0) {
        validateInput('jobSal', '');
        // return;
      }
    } else {
      if (!hasAnyErrorPresent(errors)) {
        var locAddr = getLocationString(location);
        var strCategory = selCategory.toString();
        const jsonData = {
          title: title,
          description: desc,
          category_id: strCategory,
          min_experience: minExp,
          job_location: locAddr,
          salary_offered: salary,
          registrationId: userData?.registration?.id,
        };
        if (jobActionType == 'Add') {
          loading(true);
          await postJob(jsonData, successJobPostCallback, errorJobPostCallback);
          loading(false);
        } else {
          loading(true);
          await putJOBPosting(
            singleJob.id,
            jsonData,
            successJobPostCallback,
            errorJobPostCallback
          );
          loading(false);
        }
      }
    }
  };
  //   const categoryOptions = flCategory || [];

  //   const getCategoryArr = () => {
  //     let valueArr = [];
  //     try{
  //         if (selCategory && categoryOptions?.length > 0) {
  //         let cateArr = selCategory?.split(',');

  //         cateArr.forEach((itm) => {
  //             let cateObj = categoryOptions?.find((itmm) => itmm.id === Number(itm));
  //             if (cateObj) {
  //             valueArr.push(cateObj);
  //             }
  //         });
  //         }
  //     }catch(err){
  //         console.log('Error from job category ',err)
  //     }
  //     return valueArr;
  //   };

  //   const handleCategoryChange = (e, newChips) => {
  //     console.log('newChips', newChips);
  //     if (newChips.length > 1) {
  //       pushMessage('You can add only 1 category.');
  //       return;
  //     }

  //     let cateStr = '';

  //     newChips.forEach((itm, idx) => {
  //       if (idx === newChips?.length - 1) {
  //         cateStr += itm.id;
  //       } else {
  //         cateStr += itm.id + ',';
  //       }
  //     });
  //     saveSelCategory(cateStr);
  //     validateInput('jobCategory', cateStr);
  //     // dispatch({
  //     //   type: SELECTED_JOB_CATEGORY,
  //     //   payload: cateStr
  //     // });
  //   };

  // async function getCurrentLocation() {
  //     if ('geolocation' in navigator) {
  //         navigator.geolocation.getCurrentPosition(async function (position) {
  //             setJobLocationCoords({
  //                 latitude: position.coords.latitude,
  //                 longitude: position.coords.longitude,
  //             });
  //             // validateInput('location', position);
  //             try {
  //                 const response = await axios.get(
  //                     `https://api.mapbox.com/search/searchbox/v1/reverse?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}&language=${LANG}&access_token=${MAPBOX_ACC}`
  //                 );
  //                 if (response.status === 200) {
  //                     console.log('REVERSE SEARCH RES', response.data);
  //                     setMapOtions(response.data?.features);

  //                     setOpen(true);
  //                 }
  //             } catch (error) {
  //                 console.log('Error fetch address by coordinates', error);
  //             }
  //         });
  //     } else {
  //         console.log('Geolocation is not available in your browser.');
  //     }
  // }

  const getAddressDefaultVal = () => {
    const addressVal = location;
    if (addressVal) {
      return addressVal;
    } else {
      return '';
    }
  };

  const getLocationString = (option) => {
    return option;
  };

  // const handleLocateMe = async () => {
  //     const res = await handleLocationPermission(pushMessage);
  //     if (res.result) {
  //         if (res.result.state === 'granted') {
  //             getCurrentLocation();
  //         } else {
  //             pushMessage('Location Permission not granted.');
  //         }
  //     }
  // };

  // const fetchOptions = async (value) => {
  //     try {
  //       const response = await axios.get(`your-api-endpoint?query=${value}`);
  //       setOptions(response.data);
  //     } catch (error) {
  //       console.error('Error fetching options:', error);
  //     }
  // };

  useEffect(() => {
    // const jsonString = CircularJSON.stringify(b2bprodCategories);
    // console.log("===b2bprodCategories678", jsonString);
    console.log('====flCategory', flCategory);
    if (singleJob && flCategory && flCategory.length > 0) {
      let singleProductCategory;
      //singleProduct.category_id
      if (singleJob?.category_id && flCategory.length > 0) {
        singleProductCategory = getTitleById(
          flCategory,
          Number(singleJob?.category_id)
        );
      }
      console.log('singleProductCategory', singleProductCategory);
      setCategorySearch(singleProductCategory);
      if (singleJob?.category_id !== '') {
        // setProductSubCategory(Number(singleJob?.category_id));
        saveSelCategory(Number(singleJob?.category_id));
      } else {
        // setProductSubCategory(singleJob?.category_id);
        saveSelCategory(Number(singleJob?.category_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flCategory, singleJob]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (locSearchVal && searchLoad) {
        try {
          const response = await axios.get(POSTOFFICE_CITY + locSearchVal);
          if (response.status === 200) {
            if (response?.data?.status_code === 200) {
              console.log('SEARCH RESULT', response.data);
              setMapOtions(response.data?.data);
              setSearchLoad(false);
            } else {
              setMapOtions([]);
              setSearchLoad(false);
            }
          } else {
            pushMessage('No City Found!!');
          }
        } catch (err) {
          pushMessage('Network Error!!');
        }
      }
    }, 250);

    return () => clearTimeout(getData);
  }, [locSearchVal, searchLoad]);

  const successCallback = () => {};
  const errorCallback = () => {};
  useEffect(() => {
    if (singleJob) {
      saveJobTitle(singleJob.title);
      saveJobDesc(singleJob.description);
      saveJobExp(Number(singleJob.min_experience));
      saveJobSalary(Number(singleJob.salary_offered));
      saveSelCategory(singleJob.category_id);
      saveJobLocation(singleJob.job_location);
      // setProductUnit(singleProduct?.unit);
    }
    getFreelancerCategories(FREELANCER, successCallback, errorCallback);
  }, []);

  useEffect(() => {
    // const jsonString = CircularJSON.stringify(b2bprodCategories);
    // console.log("===b2bprodCategories678", jsonString);
    console.log('====flCategory', flCategory);
    if (singleJob && flCategory && flCategory.length > 0) {
      let singleProductCategory;
      //singleProduct.category_id
      if (singleJob?.category_id && flCategory.length > 0) {
        singleProductCategory = getTitleById(
          flCategory,
          Number(singleJob?.category_id)
        );
      }
      console.log('singleProductCategory', singleProductCategory);
      setCategorySearch(singleProductCategory);
      if (singleJob?.category_id !== '') {
        // setProductSubCategory(Number(singleJob?.category_id));
        saveSelCategory(Number(singleJob?.category_id));
      } else {
        // setProductSubCategory(singleJob?.category_id);
        saveSelCategory(Number(singleJob?.category_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flCategory, singleJob]);

  useEffect(() => {
    // console.log('selectedLvl1Categories', selectedLvl1Categories);
    let filteredLvl1 = [];
    selectedLvl1Categories.forEach((category) => {
      let selectedIdx = categoriesTree.findIndex(
        (itm) => itm.id === category.id
      );
      if (selectedIdx !== -1) {
        filteredLvl1.push(categoriesTree[selectedIdx]);
      }
    });
    setCategoriesTreeFilter(filteredLvl1);
  }, [categoriesTree, selectedLvl1Categories]);

  useEffect(() => {
    if (flCategory.length > 0) {
      const categoryTree = list_to_tree(flCategory);
      // console.log('CATEGORY TREE', categoryTree);
      setCategoriesTree(categoryTree);
    }
  }, [flCategory]);

  const handleBackButton = () => {
    resetCreateJobData();
    history.push('/job-posted');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => history.goBack()} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>

            <form style={{ marginTop: '30px' }}>
              <div>
                {/* <InputLabel
                                    htmlFor="inputCIN"
                                    className="text-start fs-14 text-black font-skmodernist">
                                    Job Category
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={categoryOptions}
                                        value={getCategoryArr()}
                                        onChange={handleCategoryChange}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option?.title}
                                        isOptionEqualToValue={(option, value) => {
                                            return option == value;
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option?.title}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            placeholder={
                                                selCategory?.length > 0 ? '' : 'Categories'
                                            }
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                                    {errors.jobCategory}
                                </FormHelperText> */}
                {/* <FormControl fullWidth sx={{ my: 1 }}>
                                    <Select
                                        value={product}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value={10}>Select 1</MenuItem>
                                        <MenuItem value={20}>Select 2</MenuItem>
                                        <MenuItem value={30}>Select 3</MenuItem>
                                        <MenuItem value={40}>Select 4</MenuItem>
                                    </Select>
                                </FormControl> */}
              </div>
              <Grid container columnSpacing={8} alignItems="start">
                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Job Title
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter your job title"
                      fullWidth
                      margin="none"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={onChangeTitle}
                      value={title}
                      disabled={jobActionType == 'view'}
                      autoComplete="off"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.jobTitle}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <InputLabel
                    htmlFor="subCategoryInput"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Job Category <span className="text-mandatory">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="subCategoryInput"
                      type="text"
                      placeholder="Select Sub-Category"
                      // required
                      // disabled={categoriesTreeFilter.length === 0}
                      fullWidth
                      margin="none"
                      value={categorySearch}
                      onChange={handleSubCategoryChange}
                      onClick={() => {
                        console.log('===OutlinedInput onClick');
                        handleClick();
                      }}
                      disabled={jobActionType == 'view'}
                      autoComplete="off"
                      // onFocus={handleClick}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: 'black',
                          }}
                        >
                          {categorySearch && (
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => {
                                validateInput('product_subCategory', '');
                                console.log('=====on close');
                                setCategorySearch('');
                                const filteredCategories =
                                  filterCategoriesInDeep('');
                                setFilteredCategory(filteredCategories);
                                // saveSelCategory(filteredCategories);
                              }}
                            >
                              <CloseIcon
                                style={{
                                  color: '#727272',
                                  width: 16,
                                  height: 16,
                                  cursor: 'pointer',
                                  marginLeft: 4,
                                }}
                              />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <ArrowDropDownIcon className="arrow-icon" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {openSubCate && (
                      <div
                        style={{
                          position: 'absolute',
                          boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                          top: '50px',
                          background: 'white',
                          zIndex: 1,
                          borderRadius: 4,
                          width: '100%',
                        }}
                      >
                        <CustomizedTreeView
                          categoriesTree={
                            filteredCategory.length > 0
                              ? filteredCategory
                              : categoriesTreeFilter.length > 0
                                ? categoriesTreeFilter
                                : categoriesTree
                          }
                          selectedCategory={(value) => {
                            console.log('selectedCategory', value);
                            validateInput('product_subCategory', value?.title);
                            setOpenSubCate(!openSubCate);
                            setCategorySearch(value.title);

                            // setProductSubCategory(value.id);
                            saveSelCategory(value?.id?.toString());
                            setFilteredCategory([]);
                          }}
                        />
                      </div>
                    )}
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.product_subCategory}
                  </FormHelperText>
                </Grid>
              </Grid>
              <div className=" mb-2">
                <div className="d-flex justify-content-between">
                  <InputLabel
                    htmlFor="inputCompanyName"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Job Description
                  </InputLabel>
                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
                    gutterBottom
                  >
                    {JOB_DESCRIPTION_COUNT - count} Characters Left
                  </Typography>
                </div>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <TextareaAutosize
                    id="inputAdditionalInfo"
                    placeholder="Enter description"
                    minRows={3} // You can adjust the number of rows as needed
                    maxRows={3} // You can adjust the number of rows as needed
                    onChange={OnChangeDescription}
                    maxLength={500}
                    // inputprops={{maxLength: 500}}
                    value={desc}
                    disabled={jobActionType == 'view'}
                    onPaste={onPasteDescription}
                  />
                </FormControl>
                <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                  {errors.jobDesc}
                </FormHelperText>
              </div>
              <Grid container columnSpacing={8} alignItems="start">
                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Min. Years of Experience
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      inputMode="numeric"
                      placeholder="Enter experience"
                      fullWidth
                      margin="none"
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      inputProps={{
                        maxLength: 2,
                      }}
                      onChange={onChangeYOE}
                      disabled={jobActionType == 'view'}
                      // value={minExp === null ? '' : String(minExp)}
                      value={
                        isNaN(minExp) || minExp === 0 ? '' : String(minExp)
                      }
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.jobExp}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <div className=" mb-2">
                    <InputLabel
                      htmlFor="inputCIN"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      City
                    </InputLabel>
                    <FormControl
                      fullWidth
                      sx={{ my: 1 }}
                      variant="outlined"
                      className="autocomple-location"
                    >
                      <Autocomplete
                        // disablePortal
                        // freeSolo
                        id={'SearchLocation'}
                        options={mapOtions}
                        fullWidth
                        inputValue={location}
                        disabled={jobActionType == 'view'}
                        onInputChange={(e, value) => {
                          console.log(
                            'Autocomplete onInputChange value ',
                            value
                          );
                          setLocSearchVal(value);
                          saveJobLocation(value !== null ? value : '');
                          // validateInput('jobLoc', value !== null ? value : '');
                          setSearchLoad(true);
                        }}
                        onChange={(e, value) => {
                          console.log('Autocomplete onChange value ', value);
                          saveJobLocation(value !== null ? value : '');
                          // validateInput('location', value);
                          // validateInput('jobLoc', value);
                          setSearchLoad(false);
                        }}
                        open={open}
                        onOpen={openPopper}
                        onClose={closePopper}
                        defaultValue={getAddressDefaultVal()}
                        getOptionLabel={getLocationString}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        sx={{
                          '.MuiAutocomplete-input': {
                            paddingLeft: '14px !important',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            placeholder={'Search Address'}
                            inputProps={{
                              ...params.inputProps,
                              maxLength: 200,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {errors.jobLoc}
                    </FormHelperText>
                  </div>

                  {/* <div className="mb-3">
                                        <Button type="button" variant="text" className="p-0" onClick={handleLocateMe} disabled={jobActionType == 'view'}>
                                            <LocatemeLogo />
                                            <Typography
                                                variant="body1"
                                                align="left"
                                                className="fs-14 text-primary fw-normal font-gotham ms-2"
                                            >
                                                Locate Me
                                            </Typography>
                                        </Button>
                                    </div> */}
                </Grid>
              </Grid>

              <Grid container columnSpacing={8} alignItems="center">
                <Grid item md={6} lg={6} xs={12} className="mb-2">
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Salary / Month
                  </InputLabel>
                  {/* <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                    <OutlinedInput
                                        id="inputCIN"
                                        // label="Mobile Number"
                                        type="number"
                                        placeholder="Enter budget"
                                        fullWidth
                                        margin="none"
                                        onKeyDown={(evt) =>
                                            ['e', 'E', '+', '-'].includes(evt.key) &&
                                            evt.preventDefault()
                                          }
                                        inputProps= {{
                                            maxLength: 7
                                        }}
                                        inputMode="decimal"
                                        onChange={onChangeSalary}
                                        value={salary}
                                    />
                                </FormControl> */}
                  <FormControl fullWidth sx={{ my: 1, marginBottom: isTabletOrMobile ? 15 : 0 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter Salary"
                      fullWidth
                      margin="none"
                      disabled={jobActionType == 'view'}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      inputProps={{
                        maxLength: 7,
                      }}
                      onChange={onChangeSalary}
                      // defaultValue={singleProduct?.budget}
                      value={isNaN(salary) || salary === 0 ? '' : salary}
                      // value={Number(budget)}
                      inputMode="numeric"
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.jobSal}
                  </FormHelperText>
                </Grid>
              </Grid>

              {Responsive(
                <Box sx={{ width: '100%' }}>
                  <Box sx={[stickyButtonStyle]}>
                    {singleJob?.status !== 'fulfilled' && (
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                        sx={{
                          borderRadius: '6px',
                          textTransform: 'capitalize',
                        }}
                        onClick={handleSubmit}
                      >
                        {jobActionType == 'Add' ? 'Create Job' : 'Edit Job'}
                      </Button>
                    )}
                  </Box>
                </Box>,
                <>
                  <Box className="d-flex justify-content-between align-items-center my-4">
                    <Button
                      onClick={handleBackButton}
                      variant="text"
                      type="button"
                      className="btn-web-back"
                    >
                      Back
                    </Button>
                    {jobActionType !== 'view' && (
                      <div>
                        {singleJob?.status !== 'fulfilled' && (
                          <Button
                            type="button"
                            variant="contained"
                            className="btn-web-next"
                            onClick={handleSubmit}
                          >
                            {jobActionType == 'Add' ? 'Create Job' : 'Edit Job'}
                          </Button>
                        )}
                      </div>
                    )}
                  </Box>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

CreateJobRequirementForm.propTypes = {
  pushMessage: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  minExp: PropTypes.number,
  location: PropTypes.string,
  salary: PropTypes.number,
  selCategory: PropTypes.string,
  flCategory: PropTypes.array,
  saveJobTitle: PropTypes.func,
  saveJobDesc: PropTypes.func,
  getFreelancerCategories: PropTypes.func,
  saveJobLocation: PropTypes.func,
  saveJobSalary: PropTypes.func,
  saveJobExp: PropTypes.func,
  postJob: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  userData: PropTypes.object,
  resetCreateJobData: PropTypes.func,
  singleJob: PropTypes.object,
  saveSelCategory: PropTypes.func,
  jobActionType: PropTypes.string,
  putJOBPosting: PropTypes.func,
  setProductSubCategory: PropTypes.func,
  selectedLvl1Categories: PropTypes.array,
  b2bprodCategories: PropTypes.array,
};

const mapStateToProps = (state) => {
  console.log(state?.createJobReducer);
  const {
    title,
    desc,
    minExp,
    location,
    salary,
    selCategory,
    flCategory,
    singleJob,
    jobActionType,
  } = state.createJobReducer;
  const { userData } = state.signin;
  const { isLoading } = state.root;
  const {
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    selectedLvl1Categories,
    businessType,
  } = state.offeredServices;
  return {
    title,
    desc,
    minExp,
    location,
    salary,
    selCategory,
    flCategory,
    userData,
    isLoading,
    singleJob,
    jobActionType,
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    selectedLvl1Categories,
    businessType,
  };
};

export default connect(mapStateToProps, {
  pushMessage,
  saveJobTitle,
  saveJobDesc,
  getFreelancerCategories,
  saveJobLocation,
  saveJobSalary,
  saveJobExp,
  postJob,
  loading,
  resetCreateJobData,
  saveSelCategory,
  putJOBPosting,
  setProductSubCategory,
})(CreateJobRequirementForm);
