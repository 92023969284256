import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { connect } from 'react-redux';
import { InputWrapper, InputWrapperDisabled, Listbox, Root, StyledTag } from './MultiSelectStyles';
import { setSelectedLvl1Categories } from '../../pages/OfferedServices/redux/actions/offered-services-form-action';

function CustomizedAutoCompleteDropDown({
  productCategory,
  setSelectedLvl1Categories,
  maxSelection,
  selectedOptions = [],
  placeholder1 = '',
  placeholder2 = '',
  readOnly = false,
  disabled = false,
}) {
  const [defaultValue] = useState(selectedOptions);
  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: defaultValue,
    multiple: true,
    options: productCategory,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    readOnly: readOnly,
    disabled: disabled,
    getOptionLabel: (option) => option.title,
    
  });

  useEffect(() => {
    console.log('values', value);
    setSelectedLvl1Categories(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  

  return (
    <Root>
      <div {...getRootProps()}>
        {/* <Label {...getInputLabelProps()}>Customized hook</Label> */}
        {disabled ? (<InputWrapperDisabled ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => (
            <StyledTag
              key={index}
              label={option.title}
              {...getTagProps({ index })}
            />
          ))}
          <input
            {...getInputProps()}
            hidden={value.length < maxSelection ? false : true}
            placeholder={
              value.length < maxSelection ? placeholder1 : placeholder2
            }
            disabled= {disabled}
          />
          {(value.length < maxSelection) && (!disabled && <ArrowDropDownIcon className="arrow-icon" />)}
        </InputWrapperDisabled>) : (
           <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
           {value.map((option, index) => (
             <StyledTag
               key={index}
               label={option.title}
               {...getTagProps({ index })}
             />
           ))}
           <input
             {...getInputProps()}
             hidden={value.length < maxSelection ? false : true}
             placeholder={
               value.length < maxSelection ? placeholder1 : placeholder2
             }
             disabled= {disabled}
           />
           {(value.length < maxSelection) && (!disabled && <ArrowDropDownIcon className="arrow-icon" />)}
         </InputWrapper>
        )}
      </div>
      {groupedOptions.length > 0 && value.length < maxSelection ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={index} {...getOptionProps({ option, index })}>
              <span>{option.title}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

CustomizedAutoCompleteDropDown.propTypes = {
  productCategory: PropTypes.array,
  selectedOptions: PropTypes.array,
  setSelectedLvl1Categories: PropTypes.func,
  placeholder1: PropTypes.string,
  placeholder2: PropTypes.string,
  maxSelection: PropTypes.number, 
  readOnly: PropTypes.bool, 
  disabled: PropTypes.bool,
  className: PropTypes.string
    // New prop for maximum selection
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { setSelectedLvl1Categories })(
  CustomizedAutoCompleteDropDown
);
