import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SuccessImage } from '../../assets/success-banner.svg';
import Divider from '@mui/material/Divider';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const theme = createTheme();

const RequestCallbackSuccessfullForm = ({
	title = 'Your Request is Successfully Submitted',
	subtitle = 'Thanks for showing Interest. Our Sales Team will get back to you.',
	btnText = 'Go Back',
}) => {
	const history = useHistory();
	let location = useLocation();
	// const history = useHistory();
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	useEffect(() => {
		console.log('location', location);
	}, [location]);

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2">
							<ElynkerLogoComponent>
								<ElynkerLogo width={'100px'} height={'30px'} />
							</ElynkerLogoComponent>
						</div>
						<Divider
							component="div"
							className="main-divider mb-3"
							role="presentation"
						></Divider>

						<form
							onSubmit={(e) => {
								e.preventDefault();
								history.go(-2);
							}}
							style={{ marginTop: '60px' }}
						>
							<div className="mb-4 text-center">
								<SuccessImage />
							</div>

							{isDesktopOrLaptop && (
								<>
									<Typography
										variant="h4"
										align="center"
										alignItems="center"
										className="fs-28 mb-3  fw-bold text-black font-skmodernist"
										gutterBottom
									>
										{location?.state?.title ?? title}
									</Typography>

									<Typography className="fs-16 text-center font-gotham text-grey mb-2">
										{location?.state?.subtitle ?? subtitle}
									</Typography>
								</>
							)}

							{isTabletOrMobile && (
								<>
									<Typography
										variant="h4"
										align="center"
										alignItems="center"
										className="fs-28 mb-3  fw-bold text-black font-skmodernist"
										gutterBottom
									>
										{/* Your Request is <br></br>
										Successfully Submitted */}
										{location?.state?.title ?? title}
									</Typography>

									<Typography className="fs-16 text-center font-gotham text-grey mb-2">
										{/* Thanks for showing Interest. Our Sales <br></br>
										Team will get back to you. */}
										{location?.state?.subtitle ?? subtitle}
									</Typography>
								</>
							)}

							{isTabletOrMobile && (
								<>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
										sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
										// onClick={handleGoBack}
									>
										{btnText}
									</Button>
								</>
							)}

							{isDesktopOrLaptop && (
								<>
									<Box className="text-center my-5">
										<Button
											type="submit"
											variant="contained"
											className="btn-web-next"
											// onClick={handleGoBack}
										>
											{btnText}
										</Button>
									</Box>
								</>
							)}
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

RequestCallbackSuccessfullForm.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	btnText: PropTypes.string,
};

export default RequestCallbackSuccessfullForm;
